import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import Layout from "../../Layout/Layout";
import "./GettingStartedStyles.scss";
import BodyContainer from "../../../Components/BodyArea/Body";
import { Images } from "../../../Theme/Images";
import CircularLoading from "../../Common/CirculerLoading";
import { cdsAccountValidation } from "../../../Redux/Actions/commonAction";
import { genieLogoStyle } from "../../../Utils/commonFunctions";

class GettingStarted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
    };
  }

  componentDidMount() {
    this.setState({
      showLoading: true,
    });
    setTimeout(() => {
      this.setState({
        showLoading: false,
      });
    }, 2000);
  }

  buttonOnPress = () => {
    let token = sessionStorage.getItem("token");
    setTimeout(() => {
      this.props.cdsAccountValidation(token, () => {
        if (this.props.cdsAccountAvailability.description === "userNotExists") {
          this.props.history.push("user-validation");
        } else {
          this.props.history.push("already-exists");
        }
      });
    }, 1000);
  };

  render() {
    return (
      <Fragment>
        <Layout
          leftIcon={Images.common.ic_arrow_back}
          headerContentLeft="Stock trading"
          headerContentRight=""
          rightIcon={Images.common.img_genie_logo}
          rightIconLink="/"
          rightIconStyle={genieLogoStyle}
          leftIconLink="exitWebView"
        >
          {this.state.showLoading ? (
            <div
              style={{
                textAlign: "center",
                paddingTop: "70%",
              }}
            >
              <CircularLoading />
            </div>
          ) : (
            <BodyContainer>
              <header className="App-container" data-testid="bs-container">
                <div style={{}}>
                  <div class="container">
                    <img
                      className="topImage"
                      src={Images.common.img_getting_started}
                      alt="Snow"
                    />
                    <div class="top-left">
                      <h5 className="gs-img-title-text">Grow your</h5>
                      <h5 className="gs-img-text">
                        Financial portfolio
                        <br /> with Genie  
                      </h5>
                    </div>
                  </div>

                  <div
                    style={{
                      margin: "auto",
                      width: "100%",
                      justifySelf: "flex-start",
                      textAlign: "flex-start",
                      paddingRight: "1.5em",
                      paddingLeft: "2em",
                      marginBottom: "2em",
                    }}
                  >
                    <h3 className="gs-text-msg">
                      We make stock trading simple and easy! From opening a
                      stock trading account, instant access to market status and
                      making those all important trades on-time. Also, helping
                      you get the best returns from your investments in the
                      stock market and make more money! All of this now at your
                      fingertips.
                    </h3>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      paddingRight: "2em",
                      paddingLeft: "2em",
                      marginBottom: "1em",
                      textAlign: "flex-start",
                    }}
                  >
                    <h3 className="gs-input-title">Start trading</h3>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      paddingRight: "2em",
                      paddingLeft: "2em",
                      marginBottom: "1em",
                      textAlign: "flex-start",
                    }}
                  >
                    <h3 className="gs-input-text">
                      1) Open an account digitally
                    </h3>
                    <h3 className="gs-input-text">
                      2) Fund your account without a hassle
                    </h3>
                    <h3 className="gs-input-text">
                      3) Buy and Sell stock with ease
                    </h3>
                  </div>

                  <div
                    style={{
                      position: "fixed",
                      bottom: "3%",
                      left: 0,
                      right: 0,
                    }}
                  >
                    <button
                      data-testid="button-container"
                      style={{
                        margin: 0,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "5%",
                        height: "48px",
                      }}
                      className="btn-transaction remove-outline"
                      onClick={() => {
                        this.buttonOnPress();
                      }}
                    >
                      GET STARTED
                    </button>
                  </div>
                </div>
              </header>
            </BodyContainer>
          )}
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cdsAccountAvailability: state.CommonReducer.cdsAccountValidation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cdsAccountValidation: (data, callback) =>
      dispatch(cdsAccountValidation(data, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GettingStarted);
