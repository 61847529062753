import { put, call, select } from "redux-saga/effects";
import { Images } from "../../Theme/Images";
import * as types from "../Actions";
import {
  getBankDetails,
  getOtpRequest,
  getOtpValidate,
  postFundTransfer,
  customerBalanceWithdrawalService,
} from "../Services/withdrawService";
import { accessToken, selectedBroker } from "../Selectors/commonSelector";
import {
  mobileNumber,
  otpValue,
  withdrawalAmount,
} from "../Selectors/withdrawSelector";

export function* getBankDetailsSaga() {
  const access_Token = yield select(accessToken);
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(getBankDetails, access_Token);
    if (response.data.STATUS === "ACCEPTED") {
      yield put({
        type: types.GET_BANK_DETAILS_SUCCESS,
        payload: response.data.DATA,
      });
      yield put({
        type: types.SET_WITHDRAW_BANK_NAME,
        payload: response.data.DATA.bankId,
      });
      yield put({
        type: types.SET_WITHDRAW_BANK_ACCOUNT_NUMBER,
        payload: response.data.DATA.accountNo,
      });
      yield put({
        type: types.SET_WITHDRAW_MOBILE_NUMBER,
        payload: response.data.DATA.mobileNumber,
      });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
    } else {
      yield put({ type: types.GET_BANK_DETAILS_FAIL, payload: response });
      yield put({ type: types.SET_WITHDRAW_BANK_NAME, payload: "" });
      yield put({ type: types.SET_WITHDRAW_BANK_ACCOUNT_NUMBER, payload: "" });
      yield put({ type: types.SET_WITHDRAW_MOBILE_NUMBER, payload: "" });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.GET_BANK_DETAILS_FAIL, payload: error });
    yield put({ type: types.SET_WITHDRAW_BANK_NAME, payload: "" });
    yield put({ type: types.SET_WITHDRAW_BANK_ACCOUNT_NUMBER, payload: "" });
    yield put({ type: types.SET_WITHDRAW_MOBILE_NUMBER, payload: "" });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* getOtpRequestSaga(action) {
  const access_Token = yield select(accessToken);
  const broker_code = yield select(selectedBroker);
  const amount = yield select(withdrawalAmount);
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let request = { brokerCode: broker_code, amount: amount };
    let response = yield call(getOtpRequest, request, access_Token);
    if (response.data.STATUS === "ACCEPTED") {
      yield put({
        type: types.GET_OTP_REQUEST_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: types.SET_OTP_COUNTER_STATUS,
        payload: response.data.DATA.code,
      });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      action.callback();
    } else {
      yield put({ type: types.GET_OTP_REQUEST_FAIL, payload: response });
      yield put({ type: types.SET_OTP_COUNTER_STATUS, payload: "" });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
      action.callback();
    }
  } catch (error) {
    yield put({ type: types.GET_OTP_REQUEST_FAIL, payload: error });
    yield put({ type: types.SET_OTP_COUNTER_STATUS, payload: "" });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* getOtpValidateSaga(action) {
  const access_Token = yield select(accessToken);
  const broker_code = yield select(selectedBroker);
  const otp_value = yield select(otpValue);
  try {
    let request = { brokerCode: broker_code, otp: otp_value };
    let response = yield call(getOtpValidate, request, access_Token);
    if (response.data.STATUS === "ACCEPTED") {
      yield put({
        type: types.GET_OTP_VALIDATE_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: types.SET_OTP_COUNTER_STATUS,
        payload: response.data.DATA.code,
      });
      action.callback();
    } else {
      yield put({ type: types.GET_OTP_VALIDATE_FAIL, payload: response });
      yield put({ type: types.SET_OTP_COUNTER_STATUS, payload: "" });
      yield put({ type: types.SHOW_ERROR_POPUP });
      action.callback();
    }
  } catch (error) {
    yield put({ type: types.GET_OTP_VALIDATE_FAIL, response: error });
    yield put({ type: types.SET_OTP_COUNTER_STATUS, payload: "" });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* postFundTransferSaga() {
  const access_Token = yield select(accessToken);
  const mobile_number = yield select(mobileNumber);
  const withdrawal_amount = yield select(withdrawalAmount);
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let request = {
      mobileNumber: mobile_number,
      withdrawalAmount: withdrawal_amount,
    };
    let response = yield call(postFundTransfer, request, access_Token);
    if (response.data.STATUS === "ACCEPTED") {
      yield put({
        type: types.POST_FUND_TRANSFER_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
    } else {
      yield put({ type: types.POST_FUND_TRANSFER_FAIL, payload: response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.POST_FUND_TRANSFER_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* customerBalanceWithdrawalSaga() {
  const broker_code = yield select(selectedBroker);
  const withdrawal_amount = yield select(withdrawalAmount);
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let request = {
      brokerCode: broker_code,
      amount: withdrawal_amount,   
    };
    let response = yield call(customerBalanceWithdrawalService, request);
    if (response.data.STATUS === "ACCEPTED") {
      yield put({
        type: types.CUSTOMER_BALANCE_WITHDRAWAL_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({
        type: types.SHOW_ALERT_DIALOG,
        payload: {
          visibility: true,
          text: "",
          dialogTitle: "Successful",
          dialogMessage: `You have successfully withdrawn LKR ${withdrawal_amount} from your trading account. It will take 1-2 business days to release money from our-end`,
          dialogPrimaryButton: "DONE",
          dialogSecondaryButton: "",
          dialogIcon: Images.buyStocks.ic_correct,
        },
      });
    } else {
      yield put({
        type: types.CUSTOMER_BALANCE_WITHDRAWAL_FAIL,
        payload: response,
      });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({
        type: types.SHOW_ALERT_DIALOG,
        payload: {
          visibility: true,
          text: "",
          dialogTitle: "Failed",
          dialogMessage:
            "Sorry, your withdrawal request failed. Please try again.",
          dialogPrimaryButton: "TRY AGAIN",
          dialogSecondaryButton: "",
          dialogIcon: Images.withdrawMoney.ic_cancel,
        },
      });
    }
  } catch (error) {
    yield put({
      type: types.CUSTOMER_BALANCE_WITHDRAWAL_FAIL,
      response: error,
    });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({
      type: types.SHOW_ALERT_DIALOG,
      payload: {
        visibility: true,
        text: "",
        dialogTitle: "Failed",
        dialogMessage:
          "Sorry, your withdrawal request failed. Please try again.",
        dialogPrimaryButton: "TRY AGAIN",
        dialogSecondaryButton: "",
        dialogIcon: Images.withdrawMoney.ic_cancel,
      },
    });
  }
}
