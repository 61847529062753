import * as Types from "./index";

export const setSelectedWithdrawAmount = (payload) => {
  return {
    type: Types.SET_WITHDRAW_AMOUNT,
    payload,
  };
};

export const setSelectedWithdrawAmountDecimal = (payload) => {
  return {
    type: Types.SET_WITHDRAW_AMOUNT_DECIMAL,
    payload,
  };
};

export const setWithdrawalValue = (payload) => {
  return {
    type: Types.SET_WITHDRAW_VALUE,
    payload,
  };
};

export const setSelectedBankName = (payload) => {
  return {
    type: Types.SET_WITHDRAW_BANK_NAME,
    payload,
  };
};

export const setSelectedAccountNumber = (payload) => {
  return {
    type: Types.SET_WITHDRAW_BANK_ACCOUNT_NUMBER,
    payload,
  };
};

export const setSelectedMobileNumber = (payload) => {
  return {
    type: Types.SET_WITHDRAW_MOBILE_NUMBER,
    payload,
  };
};

export const setOtpSendingCounter = (payload) => {
  return {
    type: Types.SET_OTP_SENDING_COUNTER,
    payload,
  };
};

export const setOtpValue = (payload) => {
  return {
    type: Types.SET_OTP_VALUE,
    payload,
  };
};

export const setCircularLoadingFlag = (payload) => {
  return {
    type: Types.SET_CIRCULAR_LOADING_FLAG,
    payload,
  };
};

export const setTimerValue = (payload) => {
  return {
    type: Types.SET_TIMER_VALUE_FOR_CIRCULAR_BAR,
    payload,
  };
};

export const setButtonDisable = (payload) => {
  return {
    type: Types.SET_BUTTON_DISABLE,
    payload,
  };
};

export const setCashAvailableForWithdrawal = (payload) => {
  return {
    type: Types.SET_CASH_AVAILABLE_FOR_WITHDRAWAL,
    payload,
  };
};

export const getBankDetails = () => {
  return {
    type: Types.GET_BANK_DETAILS,
  };
};

export const getOtpRequest = (callback) => {
  return {
    type: Types.GET_OTP_REQUEST,
    callback,
  };
};

export const setOtpCounterStatus = () => {
  return {
    type: Types.SET_OTP_COUNTER_STATUS,
  };
};

export const getOtpValidate = (callback) => {
  return {
    type: Types.GET_OTP_VALIDATE,
    callback,
  };
};

export const setOtpSend = (payload) => {
  return {
    type: Types.SET_OTP_SEND,
    payload,
  };
};

export const postFundTransfer = () => {
  return {
    type: Types.POST_FUND_TRANSFER,
  };
};

export const setRequestId = (payload) => {
  return {
    type: Types.SET_REQUEST_ID,
    payload,
  };
};

export const customerBalanceWithdrawal = (payload) => {
  return {
    type: Types.CUSTOMER_BALANCE_WITHDRAWAL,
    payload,
  };
};
