import { put, call, select, delay } from "redux-saga/effects";
import * as types from "../Actions";
import {
  getDashboardDataService,
  getAccountSummaryService,
  getInvestmentAdvisorService,
  getMarketCloseTimeService,
} from "../Services/dashboardService";
import * as SELECTORS from "../Selectors/commonSelector";
import Constants from "../../Assets/Constants";
import * as dashboardActions from "../Actions/dashboardAction";

export function* getDashboardDataSaga(action) {
  yield delay(0);
  const accessToken = sessionStorage.getItem("token");
  const selectedBroker = yield select(SELECTORS.selectedBroker);
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(
      getDashboardDataService,
      accessToken,
      selectedBroker
    );
    if (response.data.STATUS === "ACCEPTED") {
      yield put({ type: types.GET_DASHBOARD_DATA_SUCCESS, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
    } else {
      yield put({ type: types.GET_DASHBOARD_DATA_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.GET_DASHBOARD_DATA_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
  action.callback();
}

export function* getAccountSummarySaga(action) {
  yield delay(0);
  const accessToken = sessionStorage.getItem("token");
  const selectedBroker = yield select(SELECTORS.selectedBroker);
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(
      getAccountSummaryService,
      accessToken,
      selectedBroker
    );
    if (response && response.data) {
      yield put({ type: types.GET_ACCOUNT_SUMMARY_SUCCESS, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
    } else {
      yield put({ type: types.GET_ACCOUNT_SUMMARY_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.GET_ACCOUNT_SUMMARY_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* getInvestmentAdvisorSaga(action) {
  yield delay(0);
  const accessToken = sessionStorage.getItem("token");
  const selectedBroker = yield select(SELECTORS.selectedBroker);
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(
      getInvestmentAdvisorService,
      accessToken,
      selectedBroker
    );
    if (response.data.STATUS === "ACCEPTED") {
      yield put({ type: types.GET_INVESTMENT_ADVISOR_SUCCESS, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      action.callback();
    } else {
      yield put({ type: types.GET_INVESTMENT_ADVISOR_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.GET_INVESTMENT_ADVISOR_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* getMarketOpenTimeSaga(action) {
  const accessToken = sessionStorage.getItem("token");
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(
      getMarketCloseTimeService,
      accessToken,
      action.payload
    );
    if (
      response &&
      response.data &&
      response.data.STATUS === Constants.RESPONSES.ACCEPTED &&
      response.data.DATA
    ) {
      yield put(dashboardActions.storeMarketCloseTime(response.data.DATA));
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
    } else {
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}
