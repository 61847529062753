import React from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, Card, CardHeader, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    backgroundColor: "#f8f8ff",
    borderRadius: "10px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  avatar: {
    backgroundColor: blue[500],
  },
  cardHeaderRoot: {
    padding: "4px",
  },
}));

export default function SimpleCard(props) {
  const classes = useStyles(props);
  const { bankName, accountNumber, bankLogo } = props;

  return (
    <Card className={classes.root} data-testid="card-container">
      <CardHeader
        className={classes.cardHeaderRoot}
        avatar={<Avatar src={bankLogo} className={classes.avatar} />}
        title={<Typography>Deposit to {bankName}</Typography>}
        subheader={accountNumber}
      />
    </Card>
  );
}
