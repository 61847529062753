import React from "react";
import COLORS from "../../Assets/Colors";
import { withStyles } from "@mui/styles";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const styles = () => ({
  mainEnabled: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    marginLeft: "1em",
    marginRight: "1em",
    backgroundColor: COLORS.BLACK,
    paddingTop: ".9em",
    paddingBottom: ".9em",
    borderRadius: "40px",
    alignItems: "center",
    justifyContent: "center",
  },
  mainDisabled: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    marginLeft: "1em",
    marginRight: "1em",
    backgroundColor: COLORS.LIGHT_GREY,
    paddingTop: ".87em",
    paddingBottom: ".87em",
    borderRadius: "40px",
    alignItems: "center",
    justifyContent: "center",
  },
  timerIcon: {
    marginRight: ".8em",
  },
  resendText: {
    fontFamily: "Open Sans",
    textAlign: "center",
    color: COLORS.WHITE,
    fontSize: ".97em",
    fontWeight: "600",
    letterSpacing: 1,
  },
});
class LoadingTimerButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonEnabled: false,
    };
  }
  onButtonClicked = () => {
    const { buttonEnabled } = this.state;
    if (buttonEnabled) {
      this.props.onButtonClicked();
      this.setState({ buttonEnabled: false });
    } else return;
  };

  render() {
    const { classes, buttonTxt } = this.props;
    const { buttonEnabled } = this.state;
    return (
      <div
        className={buttonEnabled ? classes.mainEnabled : classes.mainDisabled}
        onClick={() => this.onButtonClicked()}
      >
        {!buttonEnabled ? (
          <div className={classes.timerIcon}>
            <CountdownCircleTimer
              isPlaying
              duration={60}
              onComplete={() => {
                this.setState({ buttonEnabled: true });
                return [true, 0];
              }}
              colors={[[COLORS.BATTLESHIP_GREY]]}
              size={28}
              trailColor={COLORS.LIGHT_GREY}
              strokeWidth={7}
            />
          </div>
        ) : null}
        <div className={classes.resendText}>{buttonTxt}</div>
      </div>
    );
  }
}

LoadingTimerButton.defaultProps = {
  buttonTxt: "button",
};

export default withStyles(styles)(LoadingTimerButton);
