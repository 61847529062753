import React from "react";
import styles from "./Searchbar.module.scss";
import { Images } from "../../Theme/Images";

export default function SearchBar(props) {
  const { handleClocseIconClick, handleSearchRightIconClick } = props;
  const [showHeader, setValue] = React.useState(true);
  const [hasValue, setHasvalue] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");

  const handleLeftClick = () => {
    setValue(!showHeader);
    handleClocseIconClick(showHeader);
  };

  const handleRightIconClick = () => {
    setSearchValue("");
    handleSearchRightIconClick();
    setHasvalue(false);
  };

  const onChange = (e) => {
    let str = e.target.value.replace(/ /g, "");
    setSearchValue(str);
    if (str !== "") {
      setHasvalue(true);
    } else {
      setHasvalue(false);
    }
  };

  return (
    <div className={styles.searchbar_container}>
      <div
        className={styles.searchbar_leftIconBox}
        onClick={() => {
          handleLeftClick();
        }}
      >
        <img
          className={styles.searchbar_leftIconBox_icon}
          src={Images.dashboard.ic_close}
          alt="search-left-icon"
        />
      </div>
      <div className={styles.searchbar_headerTextBox}>
        <input
          className={styles.searchbar_headerTextBox_textStyle}
          value={searchValue}
          onInput={props.onInput}
          autoFocus={true}
          onChange={onChange}
          pattern={"[a-z]"}
          placeholder="Search companies"
          color="secondary"
        />
      </div>
      <div
        className={styles.searchbar_rightButtonBox}
        onClick={handleRightIconClick}
      >
        {hasValue ? (
          <img
            className={styles.searchbar_rightButtonBox_icon}
            src={Images.dashboard.ic_close_search}
            alt="search-right-icon"
          />
        ) : null}
      </div>
    </div>
  );
}
