import axios from "axios";
axios.defaults.timeout = 20000;

const BASE_URL = window._env_.REACT_APP_DEV_API_URL;

export const getBrokersService = async () => {
  const token = sessionStorage.getItem("token");
  if (token) {
    let REGISTER_API_ENDPOINT = `${BASE_URL}/api/ezs/stocks-management-service/brokers`;
    let res = await axios
      .get(REGISTER_API_ENDPOINT, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } else {
    return null
  }
};