import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import PullToRefresh from "react-simple-pull-to-refresh";
import Layout from "../../Layout/Layout";
import "./KYCBlockedStyles.scss";
import BodyContainer from "../../../Components/BodyArea/Body";
import { Images } from "../../../Theme/Images";
import { ButtonLogout } from "../../../Components/Buttons/index";
import { genieLogoStyle } from "../../../Utils/commonFunctions";

class KYCBlocked extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openBottomSheet: false,
      invaldPrice: false,
      invalidQty: false,
    };
  }

  buttonOnPress = () => {
    this.props.history.push("dashboard");
  };

  onLeftIconClick = () => {
    window.ReactNativeWebView.postMessage("Exit");
  };

  onExitClick = () => {
    window.ReactNativeWebView.postMessage("Exit");
  };

  render() {
    return (
      <Fragment>
        <Layout
          leftIcon={Images.common.ic_arrow_back}
          headerContentLeft="Stock trading"
          headerContentRight=""
          rightIcon={Images.common.img_genie_logo}
          rightIconLink="/"
          handleRightIconClick={() => this.handleRightOnClick()}
          showHeader={this.state.showHeader}
          rightIconStyle={genieLogoStyle}
          handleLeftIconClick={() => this.onLeftIconClick()}
        >
          <BodyContainer>
            <PullToRefresh
              onRefresh={() => window.location.reload()}
              refreshingContent=" "
              pullingContent=" "
            >
              <header className="App-container" data-testid="bs-container">
                <div style={{ width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        width: "20%",
                        margin: "auto",
                        justifySelf: "center",
                        marginTop: "25%",
                      }}
                    >
                      <img
                        src={Images.errImages.ic_stock_market}
                        alt="logo"
                        className="img"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      justifySelf: "center",
                      textAlign: "center",
                    }}
                  >
                    <h3 className="kb-input-text">Sorry!</h3>
                  </div>

                  <div
                    style={{
                      margin: "auto",
                      width: "80%",
                      justifySelf: "center",
                      textAlign: "center",
                    }}
                  >
                    <h3 className="kb-text-msg">
                      We cannot process your application. Please contact our
                      call center for more information
                    </h3>
                  </div>

                  <div
                    className="row no-padding"
                    style={{
                      position: "fixed",
                      bottom: "8%",
                      left: "5%",
                      right: 0,
                    }}  
                  >
                    <div className="col-12">
                      <a style={{ color: "white" }} href="tel:+94117277092">
                        <button className="btn-transaction remove-outline">
                          CONTACT
                        </button>
                      </a>
                    </div>
                  </div>
                  <div
                    style={{
                      margin: "auto",
                      width: "80%",
                      position: "fixed",
                      bottom: "3%",
                      left: 0,
                      right: 0,
                    }}
                  >
                    <div style={{ margin: "auto", width: "30%" }}>
                      <ButtonLogout
                        title="Exit"
                        icon={Images.errImages.ic_logout}
                        onPress={() => {
                          this.onExitClick();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </header>
            </PullToRefresh>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(KYCBlocked);
