import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import Layout from "../../Layout/Layout";
import BodyContainer from "../../../Components/BodyArea/Body";
import "./AddMoneyInitiateStyles.scss";
import { Images } from "../../../Theme/Images";
import * as Constants from "../../../Utils/constants";
import { Divider } from "@mui/material";

class AddMoneyInitiate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onLeftIconClick() {
    this.props.history.push(Constants.URL_ENDPOINT_DASHBOARD);
  }

  getRow(image, title, subtitle, redirectUrl) {
    return (
      <div
        onClick={() => this.props.history.push(`${redirectUrl}`)}
        className="ami-row"
      >
        <div className="ami-space-11">
          <div className="ami-flex">
            <img className="ami-img-main" src={image} alt="logo" />
            <div className="ami-ml-16">
              <div className="ami-row no-padding">
                <h6 className="ami-title">{title}</h6>
              </div>
              <div className="ami-row no-padding">
                <h6 className="ami-subtitle">{subtitle}</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="ami-space-1">
          <img
            className="ami-img-arrow"
            src={Images.dashboard.ic_arrow_right}
            alt="logo"
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft="Add Money"
          headerContentRight=""
          rightIcon=""
          leftIconLink=""
          handleLeftIconClick={() => this.onLeftIconClick()}
        >
          <BodyContainer>
            <header className="App-container" data-testid="ami-container">
              <div style={{ width: "100%" }}>
                <h5 className="ami-header-text">
                  {Constants.ADD_MONEY_INITIATE_HEADER_TEXT}
                </h5>

                <div className="ami-card">
                  {this.getRow(
                    Images.addMoney.ic_geniePay,
                    Constants.ADD_MONEY_INITIATE_PAYMENT_METHOD_GENIE,
                    Constants.ADD_MONEY_INITIATE_PAYMENT_METHOD_GENIE_DESCRIPTION,
                    Constants.URL_ENDPOINT_ADD_MONEY
                  )}

                  <Divider className="ami-divider" variant="middle" />

                  {this.getRow(
                    Images.addMoney.ic_fundTransfer,
                    Constants.ADD_MONEY_INITIATE_PAYMENT_METHOD_FUND_TRANSFER,
                    Constants.ADD_MONEY_INITIATE_PAYMENT_METHOD_FUND_TRANSFER_DESCRIPTION,
                    Constants.URL_ENDPOINT_FUND_TRANSFER_DETAILS_PAGE
                  )}
                </div>
              </div>
            </header>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMoneyInitiate);
