import { put, call, select, delay } from "redux-saga/effects";
import { Images } from "../../Theme/Images";
import * as types from "../Actions";
import {
  getMarketDepthService,
  getSecurityStatService,
  getSellStockListService,
  getSellStockStatustService,
  buyStockConfirmationService,
  sellStockConfirmationService,
  getMarketStatusService,
  getCommisionService,
  getOrderListBuyStatusService,
  cancelQueuedOrderService,
  editQueuedBuyOrderService,
  editQueuedSellOrderService,
  getMarketSummaryService,
  getTradeStatusService,
} from "../Services/stockService";
import {
  selectedSecurityId,
  selectedBroker,
  accessToken,
  tabSelected,
} from "../Selectors/commonSelector";
import {
  buyStockPrice,
  sellStockPrice,
  buyStockQuantity,
  sellStockQuantity,
  buyTif,
  buyTifDays,
  sellTif,
  sellTifDays,
  buyStockPriceDecimal,
  buyOrderEditItem,
  sellStockPriceDecimal,
  orderEditStatus,
  orderEditPrice,
  orderEditPriceDecimal,
  orderEditQty,
  orderItem,
  date,
} from "../Selectors/stockSelector";
import { triggerEditSellShareAmountService } from "../Services/addMoneyService";
import { saveEditSellShareAmount } from "../Actions/stockAction";
import Constants from "../../Assets/Constants";

export function* getMarketDepthSaga() {
  const securityId = yield select(selectedSecurityId);
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(getMarketDepthService, securityId);
    if (response.data.STATUS === "ACCEPTED" && response.data.DATA) {
      yield put({ type: types.GET_MARKET_DEPTH_SUCCESS, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.MARKET_DEPTH_FAIL, payload: false });
    } else {
      yield put({ type: types.GET_MARKET_DEPTH_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.MARKET_DEPTH_FAIL, payload: true });
    }
  } catch (error) {
    yield put({ type: types.GET_MARKET_DEPTH_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.MARKET_DEPTH_FAIL, payload: true });
  }
}

export function* getSecurityStatSaga() {
  const securityId = yield select(selectedSecurityId);
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(getSecurityStatService, securityId);
    if (response.data.STATUS === "ACCEPTED" && response.data.DATA) {
      yield put({ type: types.GET_SECURITY_STAT_SUCCESS, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SECURITY_STAT_FAIL, payload: false });
    } else {
      yield put({ type: types.GET_SECURITY_STAT_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SECURITY_STAT_FAIL, payload: true });
    }
  } catch (error) {
    yield put({ type: types.GET_SECURITY_STAT_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SECURITY_STAT_FAIL, payload: true });
  }
}

export function* getSellStocksSaga(action) {
  const selected_Broker = yield select(selectedBroker);
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(getSellStockListService, selected_Broker);
    if (response.data.STATUS === "ACCEPTED" && response.data.DATA) {
      yield put({ type: types.GET_SELL_STOCK_LIST_SUCCESS, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
    } else {
      yield put({ type: types.GET_SELL_STOCK_LIST_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.GET_SELL_STOCK_LIST_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
  action.callback();
}

export function* getSellStockStatusSaga() {
  const securityId = yield select(selectedSecurityId);
  const accToken = yield select(accessToken);
  const brokerCode = yield select(selectedBroker);

  let req = {
    securityId: securityId,
    accToken: accToken,
    brokerCode: brokerCode,
  };
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(getSellStockStatustService, req);
    if (response.data.STATUS === "ACCEPTED" && response.data.DATA) {
      yield put({ type: types.GET_SELL_STATUS_SUCCESS, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
    } else {
      yield put({ type: types.GET_SELL_STATUS_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.GET_SELL_STATUS_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* buyStockConfirmationSaga() {
  const securityId = yield select(selectedSecurityId);
  const stockPrice = yield select(buyStockPrice);
  const stckPriceDecimal = yield select(buyStockPriceDecimal);
  const stockQty = yield select(buyStockQuantity);
  const tif = yield select(buyTif);
  const tifDays = yield select(buyTifDays);
  const selected_Broker = yield select(selectedBroker);

  let price = stockPrice + "." + stckPriceDecimal;

  let req = {
    security: securityId,
    orderQty: stockQty,
    orderPrice: price,
    minFillQty: "0",
    disclosedQty: stockQty,
    orderType: "2",
    tif: tif,
    tifDays: tifDays,
    brokerClientVal: "1",
    marketPrice: price,
  };
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(
      buyStockConfirmationService,
      req,
      selected_Broker
    );
    if (
      response &&
      response.data.DATA.description ===
        "javascriptOrderSuccessesFullySubmitted"
    ) {
      yield put({ type: types.BUY_STOCK_CONFIRMATION_SUCCESS, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({
        type: types.SHOW_ALERT_DIALOG,
        payload: {
          visibility: true,
          text: "",
          dialogTitle: "Successful",
          dialogMessage: "Your order has been transferred",
          dialogPrimaryButton: "DONE",
          dialogSecondaryButton: "",
          dialogIcon: Images.buyStocks.ic_correct,
        },
      });
    } else {
      yield put({ type: types.BUY_STOCK_CONFIRMATION_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({
        type: types.SHOW_ALERT_DIALOG,
        payload: {
          visibility: true,
          text: "",
          dialogTitle: "Failed",
          dialogMessage:
            "Your order placement has been failed.Please try again",
          dialogPrimaryButton: "TRY AGAIN",
          dialogSecondaryButton: "",
          dialogIcon: Images.buyStocks.ic_close,
        },
      });
    }
  } catch (error) {
    yield put({ type: types.BUY_STOCK_CONFIRMATION_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({
      type: types.SHOW_ALERT_DIALOG,
      payload: {
        visibility: true,
        text: "",
        dialogTitle: "Failed",
        dialogMessage: "Your order placement has been failed.Please try again",
        dialogPrimaryButton: "TRY AGAIN",
        dialogSecondaryButton: "",
        dialogIcon: Images.buyStocks.ic_close,
      },
    });
  }
}

export function* sellStockConfirmationSaga() {
  const securityId = yield select(selectedSecurityId);
  const stockPrice = yield select(sellStockPrice);
  const stckPriceDecimal = yield select(sellStockPriceDecimal);
  const stockQty = yield select(sellStockQuantity);
  const tif = yield select(sellTif);
  const tifDays = yield select(sellTifDays);
  const selected_Broker = yield select(selectedBroker);

  let price = stockPrice + "." + stckPriceDecimal;

  let req = {
    security: securityId,
    orderQty: stockQty,
    orderPrice: price,
    minFillQty: "0",
    disclosedQty: stockQty,
    orderType: "2",
    tif: tif,
    tifDays: tifDays,
    brokerClientVal: "1",
    marketPrice: price,
  };
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(
      sellStockConfirmationService,
      req,
      selected_Broker
    );
    if (
      response &&
      response.data.DATA.description ===
        "javascriptOrderSuccessesFullySubmitted"
    ) {
      yield put({ type: types.SELL_STOCK_CONFIRMATION_SUCCESS, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({
        type: types.SHOW_ALERT_DIALOG,
        payload: {
          visibility: true,
          text: "",
          dialogTitle: "Successful",
          dialogMessage: "Your order has been transferred",
          dialogPrimaryButton: "DONE",
          dialogSecondaryButton: "",
          dialogIcon: Images.buyStocks.ic_correct,
        },
      });
    } else {
      yield put({ type: types.SELL_STOCK_CONFIRMATION_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({
        type: types.SHOW_ALERT_DIALOG,
        payload: {
          visibility: true,
          text: "",
          dialogTitle: "Failed",
          dialogMessage:
            "Your order placement has been failed.Please try again",
          dialogPrimaryButton: "TRY AGAIN",
          dialogSecondaryButton: "",
          dialogIcon: Images.buyStocks.ic_close,
        },
      });
    }
  } catch (error) {
    yield put({ type: types.SELL_STOCK_CONFIRMATION_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({
      type: types.SHOW_ALERT_DIALOG,
      payload: {
        visibility: true,
        text: "",
        dialogTitle: "Failed",
        dialogMessage: "Your order placement has been failed.Please try again",
        dialogPrimaryButton: "TRY AGAIN",
        dialogSecondaryButton: "",
        dialogIcon: Images.buyStocks.ic_close,
      },
    });
  }
}

export function* getMarketStatusSaga() {
  const accToken = yield select(accessToken);
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(getMarketStatusService, accToken);
    if (
      response &&
      response.data &&
      response.data.STATUS &&
      response.data.STATUS === "OK" &&
      response.data.DATA
    ) {
      yield put({ type: types.GET_MARKET_STATUS_SUCCESS, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
    } else {
      yield put({ type: types.GET_MARKET_STATUS_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.GET_MARKET_STATUS_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* getBuyCommissonSaga(action) {
  const stockPrice = yield select(buyStockPrice);
  const stckPriceDecimal = yield select(buyStockPriceDecimal);
  const stockQty = yield select(buyStockQuantity);
  const brokerCode = yield select(selectedBroker);
  const accToken = yield select(accessToken);
  const editStatus = yield select(orderEditStatus);
  const orderPrice = yield select(orderEditPrice);
  const orderPriceDecimal = yield select(orderEditPriceDecimal);
  const orderQty = yield select(orderEditQty);
  let price = "";
  let totPrice = "";

  if (editStatus) {
    price = orderPrice + "." + orderPriceDecimal;
    totPrice = parseFloat(price) * parseInt(orderQty);
  } else {
    price = stockPrice + "." + stckPriceDecimal;
    totPrice = parseFloat(price) * parseInt(stockQty);
  }
  let req = {
    totPrice: totPrice,
    brokerCode: brokerCode,
    accToken: accToken,
  };
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(getCommisionService, req);
    if (response.data.STATUS === "ACCEPTED") {
      yield put({ type: types.GET_BUY_COMMISSION_SUCCESS, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      if (action.callback) {
        action.callback();
      }
    } else {
      yield put({ type: types.GET_BUY_COMMISSION_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.GET_BUY_COMMISSION_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* getSellCommissonSaga(action) {
  const stockPrice = yield select(sellStockPrice);
  const stockQty = yield select(sellStockQuantity);
  const stckPriceDecimal = yield select(sellStockPriceDecimal);
  const brokerCode = yield select(selectedBroker);
  const accToken = yield select(accessToken);
  const editStatus = yield select(orderEditStatus);
  const orderPrice = yield select(orderEditPrice);
  const orderPriceDecimal = yield select(orderEditPriceDecimal);
  const orderQty = yield select(orderEditQty);

  let price = "";
  let totPrice = "";

  if (editStatus) {
    price = orderPrice + "." + orderPriceDecimal;
    totPrice = parseFloat(price) * parseInt(orderQty);
  } else {
    price = stockPrice + "." + stckPriceDecimal;
    totPrice = parseFloat(price) * parseInt(stockQty);
  }

  let req = {
    totPrice: totPrice,
    brokerCode: brokerCode,
    accToken: accToken,
  };

  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(getCommisionService, req);
    if (response.data.STATUS === "ACCEPTED") {
      yield put({ type: types.GET_SELL_COMMISSION_SUCCESS, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      if (action.callback) {
        action.callback();
      }
    } else {
      yield put({ type: types.GET_SELL_COMMISSION_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.GET_SELL_COMMISSION_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* getOrderListBuyStatusSaga(action) {
  const brokerCode = yield select(selectedBroker);
  const accToken = yield select(accessToken);
  let req = {
    brokerCode: brokerCode,
    accToken: accToken,
  };

  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(getOrderListBuyStatusService, req);
    if (response.data.STATUS === "ACCEPTED" && response.data.DATA) {
      yield put({ type: types.GET_ORDER_LIST_BUY_STATUS_SUCCESS, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      action.callback();
    } else {
      yield put({ type: types.GET_ORDER_LIST_BUY_STATUS_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.GET_ORDER_LIST_BUY_STATUS_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* cancelQueuedOrderSaga(action) {
  const accToken = yield select(accessToken);
  const brokerCode = yield select(selectedBroker);
  const item = yield select(buyOrderEditItem);
  const selectedTab = yield select(tabSelected);
  let req = {
    accToken: accToken,
    brokerCode: brokerCode,
    action: selectedTab,
    body: {
      security: item.securitycode,
      clientOrderId: item.clientorderid,
      exchangeOrderId: item.exchangeorderid ? item.exchangeorderid : "NA",
      orderPlacedDate: item.orderplacedate,
      orderStatus: item.orderStatus,
    },
  };

  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(cancelQueuedOrderService, req);
    if (response.data.STATUS === "ACCEPTED") {
      yield put({ type: types.CANCEL_QUEUED_ORDER_SUCCESS, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ALERT_POPUP });
      action.callback();
    } else {
      yield put({ type: types.CANCEL_QUEUED_ORDER_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.CANCEL_QUEUED_ORDER_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* editQueuedBuyOrderSaga() {
  const brokerCode = yield select(selectedBroker);
  const accToken = yield select(accessToken);
  const orderEditItem = yield select(orderItem);
  const orderPrice = yield select(orderEditPrice);
  const orderPriceDecimal = yield select(orderEditPriceDecimal);
  const orderQty = yield select(orderEditQty);

  let price = orderPrice + "." + orderPriceDecimal;

  let headers = {
    brokerCode: brokerCode,
    accToken: accToken,
  };

  let req = {
    security: orderEditItem.securitycode,
    orderQty: orderQty,
    orderPrice: price,
    minFillQty: "0",
    disclosedQty: orderQty,
    orderType: "2",
    tif: orderEditItem.timeinforce === "DAY" ? 0 : 6,
    tifDays: orderEditItem.tifdaysCount,
    brokerClientVal: "1",
    marketPrice: price,
    clientOrderId: orderEditItem.clientorderid,
    cseOrderId: orderEditItem.exchangeorderid,
    filledQty: orderEditItem.filledquantity,
    oldPrice: orderEditItem.orderprice,
    oldQty: orderEditItem.orderQuantity,
    orderPlacedDate: orderEditItem.orderplacedate,
    compId: orderEditItem.compId,
    orderStatus: orderEditItem.orderStatus,
  };
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(editQueuedBuyOrderService, req, headers);
    if (
      response &&
      response.data.DATA.description ===
        "javascriptOrderSuccessesFullySubmitted"
    ) {
      yield put({ type: types.EDIT_BUY_ORDER_SUCCESS, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({
        type: types.SHOW_ALERT_DIALOG,
        payload: {
          visibility: true,
          text: "",
          dialogTitle: "Successful",
          dialogMessage: "Your edit order has been transferred",
          dialogPrimaryButton: "DONE",
          dialogSecondaryButton: "",
          dialogIcon: Images.buyStocks.ic_correct,
        },
      });
    } else {
      yield put({ type: types.EDIT_BUY_ORDER_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({
        type: types.SHOW_ALERT_DIALOG,
        payload: {
          visibility: true,
          text: "",
          dialogTitle: "Failed",
          dialogMessage:
            "Your order placement has been failed.Please try again",
          dialogPrimaryButton: "TRY AGAIN",
          dialogSecondaryButton: "",
          dialogIcon: Images.buyStocks.ic_close,
        },
      });
    }
  } catch (error) {
    yield put({ type: types.EDIT_BUY_ORDER_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({
      type: types.SHOW_ALERT_DIALOG,
      payload: {
        visibility: true,
        text: "",
        dialogTitle: "Failed",
        dialogMessage: "Your order placement has been failed.Please try again",
        dialogPrimaryButton: "TRY AGAIN",
        dialogSecondaryButton: "",
        dialogIcon: Images.buyStocks.ic_close,
      },
    });
  }
}

export function* editQueuedSellOrderSaga() {
  const brokerCode = yield select(selectedBroker);
  const accToken = yield select(accessToken);
  const orderEditItem = yield select(orderItem);
  const orderPrice = yield select(orderEditPrice);
  const orderPriceDecimal = yield select(orderEditPriceDecimal);
  const orderQty = yield select(orderEditQty);

  let price = orderPrice + "." + orderPriceDecimal;

  let headers = {
    brokerCode: brokerCode,
    accToken: accToken,
  };

  let req = {
    security: orderEditItem.securitycode,
    orderQty: orderQty,
    orderPrice: price,
    minFillQty: "0",
    disclosedQty: orderQty,
    orderType: "2",
    tif: orderEditItem.timeinforce === "DAY" ? 0 : 6,
    tifDays: orderEditItem.tifdaysCount,
    brokerClientVal: "1",
    marketPrice: price,
    clientOrderId: orderEditItem.clientorderid,
    cseOrderId: orderEditItem.exchangeorderid,
    filledQty: orderEditItem.filledquantity,
    oldPrice: orderEditItem.orderprice,
    oldQty: orderEditItem.orderQuantity,
    orderPlacedDate: orderEditItem.orderplacedate,
    compId: orderEditItem.compId,
    orderStatus: orderEditItem.orderStatus,
  };
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(editQueuedSellOrderService, req, headers);
    if (
      response &&
      response.data.DATA.description ===
        "javascriptOrderSuccessesFullySubmitted"
    ) {
      yield put({ type: types.EDIT_SELL_ORDER_SUCCESS, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({
        type: types.SHOW_ALERT_DIALOG,
        payload: {
          visibility: true,
          text: "",
          dialogTitle: "Successful",
          dialogMessage: "Your edit order has been transferred",
          dialogPrimaryButton: "DONE",
          dialogSecondaryButton: "",
          dialogIcon: Images.buyStocks.ic_correct,
        },
      });
    } else {
      yield put({ type: types.EDIT_SELL_ORDER_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({
        type: types.SHOW_ALERT_DIALOG,
        payload: {
          visibility: true,
          text: "",
          dialogTitle: "Failed",
          dialogMessage:
            "Your order placement has been failed.Please try again",
          dialogPrimaryButton: "TRY AGAIN",
          dialogSecondaryButton: "",
          dialogIcon: Images.buyStocks.ic_close,
        },
      });
    }
  } catch (error) {
    yield put({ type: types.EDIT_SELL_ORDER_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({
      type: types.SHOW_ALERT_DIALOG,
      payload: {
        visibility: true,
        text: "",
        dialogTitle: "Failed",
        dialogMessage: "Your order placement has been failed.Please try again",
        dialogPrimaryButton: "TRY AGAIN",
        dialogSecondaryButton: "",
        dialogIcon: Images.buyStocks.ic_close,
      },
    });
  }
}

export function* getMarketSummarySaga(action) {
  const currentDate = yield select(date);
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(getMarketSummaryService, currentDate);
    if (
      response !== null &&
      response.data.STATUS === "ACCEPTED" &&
      response.data !== null &&
      response.data.DATA !== null
    ) {
      yield put({
        type: types.GET_MARKET_SUMMARY_DETAILS_SUCCESS,
        payload: response.data.DATA,
      });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
    } else {
      yield put({ type: types.GET_MARKET_SUMMARY_DETAILS_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.GET_MARKET_SUMMARY_DETAILS_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
  action.callback();
}

export function* getTradeStatusSaga(action) {
  const securityId = action.payload;
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(getTradeStatusService, securityId);
    if (response && response.data.STATUS === "ACCEPTED" && response.data.DATA) {
      yield put({
        type: types.GET_SECURITY_TRADE_STATUS_SUCCESS,
        payload: response,
      });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
    } else {
      yield put({ type: types.GET_SECURITY_TRADE_STATUS_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.GET_SECURITY_TRADE_STATUS_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* triggerEditSellShareAmountSaga(action) {
  try {
    yield delay(0);
    const accessToken = sessionStorage.getItem("token");
    const brokerName = yield select(selectedBroker);
    let response = yield call(
      triggerEditSellShareAmountService,
      accessToken,
      brokerName,
      action.payload
    );
    if (
      response &&
      response.data.STATUS === Constants.RESPONSES.ACCEPTED &&
      response.data.DATA
    ) {
      yield put(saveEditSellShareAmount(response.data.DATA));
    } else {
    }
  } catch (error) {}
}
