import ENDPOINTS from "./Endpoints";
import Api from "./Service";
import UTILS from "./Utils";

export function getDashboardDataService(accessToken, selected_Broker) {
  return Api(
    ENDPOINTS.BASE_URL,
    `${ENDPOINTS.MANAGEMENT_SERVICE_DASHBOARD}/${selected_Broker}`,
    UTILS.METHODS.GET,
    accessToken,
    null,
    null
  );
}
export function getAccountSummaryService(accessToken, selected_Broker) {
  return Api(
    ENDPOINTS.BASE_URL,
    `${ENDPOINTS.MANAGEMENT_SERVICE_ACCOUNT_SUMMARY}/${selected_Broker}`,
    UTILS.METHODS.GET,
    accessToken,
    null,
    null
  );
}
export function getInvestmentAdvisorService(accessToken, selected_Broker) {
  return Api(
    ENDPOINTS.BASE_URL,
    `${ENDPOINTS.AUTH_SERVICE_INVESTEMENT_ADVICE}/${selected_Broker}`,
    UTILS.METHODS.GET,
    accessToken,
    null,
    null
  );
}

export function getMarketCloseTimeService(accessToken, key) {
  return Api(
    ENDPOINTS.BASE_URL,
    `${ENDPOINTS.GET_MARKET_OPEN_API}/${key}`,
    UTILS.METHODS.GET,
    accessToken,
    null,
    null
  );
}
