import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Slide,
} from "@mui/material";
import "./AlertPopupStyles.scss";
import { makeStyles } from "@mui/styles";
import { hideAlertPopup } from "../../Redux/Actions/commonAction";
import { setSelectedTab } from "../../Redux/Actions/stockAction";

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    fontWeight: "bold",
    color: "#1e72b1",
    textAlign: "right",
  },
  centerView: {
    alignSelf: "center",
    "&:first-child": {
      paddingTop: 0,
    },
    padding: "0 15px",
  },
  dialogPaper: {
    borderRadius: 8,
    overflow: "auto",
    position: "fixed",
    bottom: 0,
    margin: "15px",
    background: "#000000",
  },
  contentText: {
    color: "#969696",
    marginBottom: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertPopup = () => {
  const dispatch = useDispatch();
  const alertPopup = useSelector((state) => state.CommonReducer.alertPopup);
  const item = useSelector((state) => state.BuyStockReducer.buyOrderEditItem);
  const classes = useStyles();

  const handleClose = React.useCallback(() => {
    dispatch(hideAlertPopup());
    if (item.action === "BUY") {
      dispatch(setSelectedTab(1));
    } else {
      dispatch(setSelectedTab(0));
    }
  }, [dispatch]);

  return (
    <div className="alert-popup">
      <Dialog
        open={alertPopup}
        TransitionComponent={Transition}
        keepMounted
        classes={{ paper: classes.dialogPaper }}
        onClose={handleClose}
        aria-labelledby="alert-popup"
      >
        <DialogContent className={`${classes.centerView} alert-popup-content`}>
          <div className="row no-padding">
            <div className="col-10 no-padding">
              <DialogContentText className={classes.contentText}>
                {item.securitycode}({item.action}) order has been cancelled
                successfully
              </DialogContentText>
            </div>
            <div className="col-2 no-padding">
              <DialogContentText
                className={classes.primaryButton}
                onClick={handleClose}
              >
                OK
              </DialogContentText>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AlertPopup;
