import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import PullToRefresh from "react-simple-pull-to-refresh";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import Layout from "../../Layout/Layout";
import BodyContainer from "../../../Components/BodyArea/Body";
import { Images } from "../../../Theme/Images";
import { getSnpList } from "../../../Redux/Actions/commonAction";
import { setSelectedStock } from "../../../Redux/Actions/buyStockListingAction";
import AlertDialog from "../../../Components/Alert/Alert";
import {
  getMarketDepth,
  getSecurityStat,
  getSellStatus,
  setSelectedSecurityForSell,
} from "../../../Redux/Actions/stockAction";
import "./ExploreStocksStyles.scss";
import { Divider, Typography } from "@mui/material";

const BASE_URL = window._env_.REACT_APP_DEV_API_URL;
let eventSource = undefined;
class ExploreStocks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: false,
      searchData: this.props.snpList,
      tempSearchData: [],
      showHeader: true,
    };
  }

  componentDidMount() {
    this.props.getSnpList();
    this.setState({
      searchData: this.props.snpList,
      showHeader: true,
    });
    this.handleEventSource();
  }

  componentWillUnmount() {
    eventSource.close();
    clearInterval(this.snpListInterval);
  }

  handleEventSource() {
    eventSource = new EventSource(
      `${BASE_URL}/api/notification/stocks-kafka-service/event/stream/watch-data`
    );
    eventSource.addEventListener("watchData", (event) =>
      this.handleWatchData(event)
    );
    eventSource.onopen = () => this.handleOpen();
    eventSource.onerror = (event) => this.handleError(event);
  }

  handleWatchData = (event) => {
    let watchData = JSON.parse(event.data);
    let securityValue = watchData.security;
    let kafkaTradePrice = watchData.tradeprice;

    if (this.state.searchData) {
      this.state.searchData.filter((item, idx) => {
        if (item.security === securityValue) {
          const searchData = [...this.state.searchData];
          searchData[idx] = { ...searchData[idx] };
          searchData[idx]["tradeprice"] = kafkaTradePrice;
          this.setState({ searchData });
        }
      });
    }
  };

  handleOpen = () => {};

  handleError = (event) => {
    if (event.target.readyState === EventSource.CLOSED) {
    }
    eventSource.close();
  };

  arrEq(arr1, arr2) {
    let arrLength;
    if ((arrLength = arr1.length) !== arr2.length) return false;
    for (let i = 0; i < arrLength; i++) if (arr1[i] !== arr2[i]) return false;
    return true;
  }

  numberWithCommas = (x) => {
    let srt = x.substr(0, x.indexOf("."));
    const value = srt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  };

  onViewPressed(item) {
    if (item.securityOld) {
      item.security = item.securityOld;
      item.companyname = item.companyOld;
    }
    this.props.setSelectedStock(item);
    this.props.getSecurityStat();
    this.props.getMarketDepth();
    this.props.setSelectedSecurityForSell(item.security);
    this.props.getSellStatus();

    setTimeout(() => {
      this.props.history.push("stock-details");
    }, 100);
  }

  handleSearch(e) {
    let str = e.target.value;
    const newArr = this.state.tempSearchData
      .filter(
        (item) =>
          item.security.toLowerCase().includes(str.toLowerCase()) ||
          item.companyname.toLowerCase().includes(str.toLowerCase())
      )
      .map((item) => {
        let newTitle = item.security.replace(
          new RegExp(str, "gi"),
          (match) => `<span style="color: #2598ed;">${match}</span>`
        );
        let newBody = "";
        let setPriority = false;
        if (item.security.toLowerCase().includes(str.toLowerCase())) {
          newBody = item.companyname;
          setPriority = true;
        } else {
          newBody = item.companyname.replace(
            new RegExp(str, "gi"),
            (match) => `<span style="color: #2598ed;">${match}</span>`
          );
        }

        return {
          ...item,
          priority: setPriority,
          securityOld: item.security,
          companyOld: item.companyname,
          security: newTitle,
          companyname: newBody,
        };
      });
    this.setState({
      searchData: newArr.sort(function (a, b) {
        return b.priority - a.priority;
      }),
    });
    if (str === "") {
      this.setState({
        searchData: [],
        search: false,
      });
    } else {
      this.setState({
        search: true,
      });
    }
  }
  createMarkup(html) {
    return { __html: html };
  }

  handleSearchOnClick(data) {
    const tempSearchData = [...this.state.searchData];
    this.setState(
      {
        showHeader: !this.state.showHeader,
        tempSearchData,
      },
      () => {
        if (!this.state.showHeader) {
          this.setState({
            searchData: [],
          });
        }
      }
    );
  }
  handleClocseClick(data) {
    this.setState(
      {
        showHeader: data,
      },
      () => {
        if (this.state.showHeader) {
          this.setState({
            searchData: this.state.tempSearchData,
          });
        }
      }
    );
  }

  handleSearchRightIcon() {
    this.setState({
      searchData: [],
      search: false,
    });
  }

  onLeftIconClick = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Fragment>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft="Explore stocks"
          headerContentRight=""
          rightIcon={Images.dashboard.ic_search}
          rightIconLink="/"
          leftIconLink="dashboard"
          handleRightIconClick={(data) => this.handleSearchOnClick(data)}
          handleClocseIconClick={(data) => this.handleClocseClick(data)}
          handleSearchRightIconClick={() => this.handleSearchRightIcon()}
          handleLeftIconClick={() => this.onLeftIconClick()}
          onInput={(e) => this.handleSearch(e)}
          showHeader={this.state.showHeader}
        >
          <BodyContainer>
            <AlertDialog />
            <PullToRefresh
              onRefresh={() => window.location.reload()}
              refreshingContent=" "
              pullingContent=" "
            >
              <header className="App-container" data-testid="as-container">
                <div style={{ width: "100%" }}>
                  {this.state.searchData.length === 0 && this.state.search ? (
                    <h3 style={{ textAlign: "center", marginTop: "50%" }}>
                      No records found
                    </h3>
                  ) : (
                    <div>
                      {this.state.searchData.map((item, index) => {
                        return (
                          <div key={index}>
                            <div className="row">
                              <div
                                className="col-7"
                                onClick={() => {
                                  this.onViewPressed(item);
                                }}
                              >
                                <Typography
                                  className="exp-company-code"
                                  gutterBottom
                                  dangerouslySetInnerHTML={this.createMarkup(
                                    item.security
                                  )}
                                />
                                <Typography
                                  className="exp-company-name"
                                  dangerouslySetInnerHTML={this.createMarkup(
                                    item.companyname
                                  )}
                                />
                              </div>
                              <div
                                className="col-5"
                                style={{ textAlign: "right", marginTop: "6px" }}
                              >
                                <span className="es-lkrText">LKR </span>
                                <span className="es-priceText">
                                  {item.tradeprice
                                    ? this.numberWithCommas(item.tradeprice)
                                    : "0"}
                                </span>
                                <span className="es-decimalText">
                                  .
                                  {item.tradeprice
                                    ? parseFloat(item.tradeprice)
                                        .toFixed(2)
                                        .split(".")
                                        .pop()
                                    : "00"}
                                </span>
                              </div>
                            </div>
                            <Divider variant="middle" />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </header>
            </PullToRefresh>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyList: state.CommonReducer.companyList,
    snpList: state.CommonReducer.snpList,
    loader: state.CommonReducer.showLoader,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSnpList: () => dispatch(getSnpList()),
    setSelectedStock: (data) => dispatch(setSelectedStock(data)),
    getMarketDepth: () => dispatch(getMarketDepth()),
    getSecurityStat: () => dispatch(getSecurityStat()),
    setSelectedSecurityForSell: (data) =>
      dispatch(setSelectedSecurityForSell(data)),
    getSellStatus: () => dispatch(getSellStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExploreStocks);
