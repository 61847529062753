export const CHECK_ACCOUNT_AVAILABILITY = "CHECK_ACCOUNT_AVAILABILITY";
export const SET_SELECTED_BROKER = "SET_SELECTED_BROKER";

export const GET_BROKER_LIST = "GET_BROKER_LIST";
export const GET_BROKER_LIST_SUCCESS = "GET_BROKER_LIST_SUCCESS";
export const GET_BROKER_LIST_FAIL = "GET_BROKER_LIST_FAIL";

export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAIL = "GET_DASHBOARD_DATA_FAIL";

export const GET_BUY_STOCK_LIST = "GET_BUY_STOCK_LIST";
export const GET_BUY_STOCK_LIST_SUCCESS = "GET_BUY_STOCK_LIST_SUCCESS";
export const GET_BUY_STOCK_LIST_FAIL = "GET_BUY_STOCK_LIST_FAIL";

export const SELECTED_STOCK = "SELECTED_STOCK";

export const GET_COMPANY_LIST = "GET_COMPANY_LIST";
export const GET_COMPANY_LIST_SUCCESS = "GET_COMPANY_LIST_SUCCESS";
export const GET_COMPANY_LIST_FAIL = "GET_COMPANY_LIST_FAIL";

export const GET_SNP_LIST = "GET_SNP_LIST";
export const GET_SNP_LIST_SUCCESS = "GET_SNP_LIST_SUCCESS";
export const GET_SNP_LIST_FAIL = "GET_SNP_LIST_FAIL";

export const GET_ACCOUNT_SUMMARY = "GET_ACCOUNT_SUMMARY";
export const GET_ACCOUNT_SUMMARY_SUCCESS = "GET_ACCOUNT_SUMMARY_SUCCESS";
export const GET_ACCOUNT_SUMMARY_FAIL = "GET_ACCOUNT_SUMMARY_FAIL";

export const FULL_SCREEN_LOADER = "FULL_SCREEN_LOADER";
export const SHOW_ALERT_DIALOG = "SHOW_ALERT_DIALOG";
export const HIDE_ALERT_DIALOG = "HIDE_ALERT_DIALOG";
export const SHOW_ERROR_POPUP = "SHOW_ERROR_POPUP";
export const HIDE_ERROR_POPUP = "HIDE_ERROR_POPUP";
export const SHOW_ALERT_POPUP = "SHOW_ALERT_POPUP";
export const HIDE_ALERT_POPUP = "HIDE_ALERT_POPUP";

export const GET_TOP_DATA = "GET_TOP_DATA";
export const GET_TOP_DATA_SUCCESS = "GET_TOP_DATA_SUCCESS";
export const GET_TOP_DATA_FAIL = "GET_TOP_DATA_FAIL";
export const GET_TOP_GAINERS_SUCCESS = "GET_TOP_GAINERS_SUCCESS";
export const GET_TOP_GAINERS_FAIL = "GET_TOP_GAINERS_FAIL";
export const GET_TOP_LOOSERS_SUCCESS = "GET_TOP_LOOSERS_SUCCESS";
export const GET_TOP_LOOSERS_FAIL = "GET_TOP_LOOSERS_FAIL";

export const GET_MARKET_DEPTH = "GET_MARKET_DEPTH";
export const GET_MARKET_DEPTH_SUCCESS = "GET_MARKET_DEPTH_SUCCESS";
export const GET_MARKET_DEPTH_FAIL = "GET_MARKET_DEPTH_FAIL";

export const GET_SECURITY_STAT = "GET_SECURITY_STAT";
export const GET_SECURITY_STAT_SUCCESS = "GET_SECURITY_STAT_SUCCESS";
export const GET_SECURITY_STAT_FAIL = "GET_SECURITY_STAT_FAIL";

export const GET_SELL_STOCK_LIST = "GET_SELL_STOCK_LIST";
export const GET_SELL_STOCK_LIST_SUCCESS = "GET_SELL_STOCK_LIST_SUCCESS";
export const GET_SELL_STOCK_LIST_FAIL = "GET_SELL_STOCK_LIST_FAIL";

export const SET_SELL_STOCK_PRICE = "SET_SELL_STOCK_PRICE";
export const SET_SELL_STOCK_PRICE_DECIMAL = "SET_SELL_STOCK_PRICE_DECIMAL";
export const SET_SELL_NUMBER_OF_SHARES = "SET_SELL_NUMBER_OF_SHARES";
export const SET_SELL_STOCK_DURATION = "SET_SELL_STOCK_DURATION";

export const SET_BUY_STOCK_PRICE = "SET_BUY_STOCK_PRICE";
export const SET_BUY_STOCK_PRICE_DECIMAL = "SET_BUY_STOCK_PRICE_DECIMAL";
export const SET_BUY_NUMBER_OF_SHARES = "SET_BUY_NUMBER_OF_SHARES";
export const SET_BUY_STOCK_DURATION = "SET_BUY_STOCK_DURATION";

export const GET_SELL_STATUS = "GET_SELL_STATUS";
export const GET_SELL_STATUS_SUCCESS = "GET_SELL_STATUS_SUCCESS";
export const GET_SELL_STATUS_FAIL = "GET_SELL_STATUS_FAIL";

export const BUY_STOCK_CONFIRMATION = "BUY_STOCK_CONFIRMATION";
export const BUY_STOCK_CONFIRMATION_SUCCESS = "BUY_STOCK_CONFIRMATION_SUCCESS";
export const BUY_STOCK_CONFIRMATION_FAIL = "BUY_STOCK_CONFIRMATION_FAIL";

export const SELL_STOCK_CONFIRMATION = "SELL_STOCK_CONFIRMATION";
export const SELL_STOCK_CONFIRMATION_SUCCESS =
  "SELL_STOCK_CONFIRMATION_SUCCESS";
export const SELL_STOCK_CONFIRMATION_FAIL = "SELL_STOCK_CONFIRMATION_FAIL";

export const GET_MARKET_STATUS = "GET_MARKET_STATUS";
export const GET_MARKET_STATUS_SUCCESS = "GET_MARKET_STATUS_SUCCESS";
export const GET_MARKET_STATUS_FAIL = "GET_MARKET_STATUS_FAIL";

export const GET_BUY_COMMISSION = "GET_BUY_COMMISSION";
export const GET_BUY_COMMISSION_SUCCESS = "GET_BUY_COMMISSION_SUCCESS";
export const GET_BUY_COMMISSION_FAIL = "GET_BUY_COMMISSION_FAIL";

export const GET_SELL_COMMISSION = "GET_SELL_COMMISSION";
export const GET_SELL_COMMISSION_SUCCESS = "GET_SELL_COMMISSION_SUCCESS";
export const GET_SELL_COMMISSION_FAIL = "GET_SELL_COMMISSION_FAIL";

export const GET_ORDER_LIST_BUY_STATUS = "GET_ORDER_LIST_BUY_STATUS";
export const GET_ORDER_LIST_BUY_STATUS_SUCCESS =
  "GET_ORDER_LIST_BUY_STATUS_SUCCESS";
export const GET_ORDER_LIST_BUY_STATUS_FAIL = "GET_ORDER_LIST_BUY_STATUS_FAIL";

export const SET_ORDER_EDIT_STATUS = "SET_ORDER_EDIT_STATUS";
export const SET_ORDER_EDIT_ITEM = "SET_ORDER_EDIT_ITEM";

export const SET_SELECTED_OREDR_ITEM_PRICE = "CLEAR_SELECTED_OREDR_ITEM_PRICE";
export const SET_SELECTED_ORDER_ITEM_PRICE_DECIMAL =
  "SET_SELECTED_ORDER_ITEM_PRICE_DECIMAL";
export const SET_SELECTED_OREDR_ITEM_QUANTITY =
  "CLEAR_SELECTED_OREDR_ITEM_QUANTITY";
export const SET_SELECTED_OREDR_ITEM_DURATION =
  "CLEAR_SELECTED_OREDR_ITEM_DURATION";

export const CANCEL_QUEUED_ORDER = "CANCEL_QUEUED_ORDER";
export const CANCEL_QUEUED_ORDER_SUCCESS = "CANCEL_QUEUED_ORDER_SUCCESS";
export const CANCEL_QUEUED_ORDER_FAIL = "CANCEL_QUEUED_ORDER_FAIL";

export const EDIT_BUY_ORDER = "EDIT_BUY_ORDER";
export const EDIT_BUY_ORDER_SUCCESS = "EDIT_BUY_ORDER_SUCCESS";
export const EDIT_BUY_ORDER_FAIL = "EDIT_BUY_ORDER_FAIL";

export const EDIT_SELL_ORDER = "EDIT_SELL_ORDER";
export const EDIT_SELL_ORDER_SUCCESS = "EDIT_SELL_ORDER_SUCCESS";
export const EDIT_SELL_ORDER_FAIL = "EDIT_SELL_ORDER_FAIL";

export const GET_BANK_DETAILS = "GET_BANK_DETAILS";
export const GET_BANK_DETAILS_SUCCESS = "GET_BANK_DETAILS_SUCCESS";
export const GET_BANK_DETAILS_FAIL = "GET_BANK_DETAILS_FAIL";

export const GET_OTP_REQUEST = "GET_OTP_REQUEST";
export const GET_OTP_REQUEST_SUCCESS = "GET_OTP_REQUEST_SUCCESS";
export const GET_OTP_REQUEST_FAIL = "GET_OTP_REQUEST_FAIL";

export const GET_OTP_VALIDATE = "GET_OTP_VALIDATE";
export const GET_OTP_VALIDATE_SUCCESS = "GET_OTP_VALIDATE_SUCCESS";
export const GET_OTP_VALIDATE_FAIL = "GET_OTP_VALIDATE_FAIL";

export const POST_FUND_TRANSFER = "POST_FUND_TRANSFER";
export const POST_FUND_TRANSFER_SUCCESS = "POST_FUND_TRANSFER_SUCCESS";
export const POST_FUND_TRANSFER_FAIL = "POST_FUND_TRANSFER_FAIL";

export const SET_WITHDRAW_AMOUNT = "SET_WITHDRAW_AMOUNT";
export const SET_WITHDRAW_BANK_NAME = "SET_WITHDRAW_BANK_NAME";
export const SET_WITHDRAW_BANK_ACCOUNT_NUMBER =
  "SET_WITHDRAW_BANK_ACCOUNT_NUMBER";
export const SET_WITHDRAW_MOBILE_NUMBER = "SET_WITHDRAW_MOBILE_NUMBER";
export const SET_OTP_SENDING_COUNTER = "SET_OTP_SENDING_COUNTER";
export const SET_OTP_VALUE = "SET_OTP_VALUE";
export const SET_CIRCULAR_LOADING_FLAG = "SET_CIRCULAR_LOADING_FLAG";
export const SET_TIMER_VALUE_FOR_CIRCULAR_BAR =
  "SET_TIMER_VALUE_FOR_CIRCULAR_BAR";
export const SET_BUTTON_DISABLE = "SET_BUTTON_DISABLE";
export const SET_CASH_AVAILABLE_FOR_WITHDRAWAL =
  "SET_CASH_AVAILABLE_FOR_WITHDRAWAL";
export const SET_OTP_COUNTER_STATUS = "SET_OTP_COUNTER_STATUS";
export const SET_OTP_SEND = "SET_OTP_SEND";
export const SET_REQUEST_ID = "SET_REQUEST_ID";
export const SET_WITHDRAW_AMOUNT_DECIMAL = "SET_WITHDRAW_AMOUNT_DECIMAL";
export const SET_WITHDRAW_VALUE = "SET_WITHDRAW_VALUE";

export const SET_BOTTOM_SHEET_STATUS = "SET_BOTTOM_SHEET_STATUS";
export const SET_SELECTED_TAB = "SET_SELECTED_TAB";

export const GET_INVESTMENT_ADVISOR = "GET_INVESTMENT_ADVISOR";
export const GET_INVESTMENT_ADVISOR_SUCCESS = "GET_INVESTMENT_ADVISOR_SUCCESS";
export const GET_INVESTMENT_ADVISOR_FAIL = "GET_INVESTMENT_ADVISOR_FAIL";

export const SET_ADD_MONEY_AMOUNT = "SET_ADD_MONEY_AMOUNT";
export const SET_APP_MONEY_AMOUNT_DECIMAL = "SET_APP_MONEY_AMOUNT_DECIMAL";
export const SET_ADD_MONEY_UNIQUE_ID = "SET_ADD_MONEY_UNIQUE_ID";

export const TOP_GAINERS_FAIL = "TOP_GAINERS_FAIL";
export const SECURITY_STAT_FAIL = "SECURITY_STAT_FAIL";
export const MARKET_DEPTH_FAIL = "MARKET_DEPTH_FAIL";

export const CUSTOMER_BALANCE_WITHDRAWAL = "CUSTOMER_BALANCE_WITHDRAWAL";
export const CUSTOMER_BALANCE_WITHDRAWAL_SUCCESS =
  "CUSTOMER_BALANCE_WITHDRAWAL_SUCCESS";
export const CUSTOMER_BALANCE_WITHDRAWAL_FAIL =
  "CUSTOMER_BALANCE_WITHDRAWAL_FAIL";

export const GET_MARKET_SUMMARY_DETAILS = "GET_MARKET_SUMMARY_DETAILS";
export const GET_MARKET_SUMMARY_DETAILS_SUCCESS =
  "GET_MARKET_SUMMARY_DETAILS_SUCCESS";
export const GET_MARKET_SUMMARY_DETAILS_FAIL =
  "GET_MARKET_SUMMARY_DETAILS_FAIL";

export const PRE_VALIDATE_ADD_MONEY = "PRE_VALIDATE_ADD_MONEY";
export const PRE_VALIDATE_ADD_MONEY_SUCCESS = "PRE_VALIDATE_ADD_MONEY_SUCCESS";
export const PRE_VALIDATE_ADD_MONEY_FAIL = "PRE_VALIDATE_ADD_MONEY_FAIL";

export const COMMON_PAYMENT_MODULE_STATE = "COMMON_PAYMENT_MODULE_STATE";
export const COMMON_PAYMENT_MODULE_STATE_SUCCESS =
  "COMMON_PAYMENT_MODULE_STATE_SUCCESS";
export const COMMON_PAYMENT_MODULE_STATE_FAIL =
  "COMMON_PAYMENT_MODULE_STATE_FAIL";

export const GET_MARKET_OPEN_DAYS = "GET_MARKET_OPEN_DAYS";
export const GET_MARKET_OPEN_DAYS_SUCCESS = "GET_MARKET_OPEN_DAYS_SUCCESS";
export const GET_MARKET_OPEN_DAYS_FAILED = "GET_MARKET_OPEN_DAYS_FAILED";

export const COMMON_KYC_INITIATION = "COMMON_KYC_INITIATION";
export const COMMON_KYC_INITIATION_SUCCESS = "COMMON_KYC_INITIATION_SUCCESS";
export const COMMON_KYC_INITIATION_FAIL = "COMMON_KYC_INITIATION_FAIL";

export const CDS_ACCOUNT_VALIDATION = "CDS_ACCOUNT_VALIDATION";
export const CDS_ACCOUNT_VALIDATION_SUCCESS = "CDS_ACCOUNT_VALIDATION_SUCCESS";
export const CDS_ACCOUNT_VALIDATION_FAIL = "CDS_ACCOUNT_VALIDATION_FAIL";

export const GET_SECURITY_TRADE_STATUS = "GET_SECURITY_TRADE_STATUS";
export const GET_SECURITY_TRADE_STATUS_SUCCESS =
  "GET_SECURITY_TRADE_STATUS_SUCCESS";
export const GET_SECURITY_TRADE_STATUS_FAIL = "GET_SECURITY_TRADE_STATUS_FAIL";

export const INITIATE_ADD_MONEY = "INITIATE_ADD_MONEY";

export const ATRAD_ADD_MONEY = "ATRAD_ADD_MONEY";
export const ATRAD_ADD_MONEY_SUCCESS = "ATRAD_ADD_MONEY_SUCCESS";
export const ATRAD_ADD_MONEY_FAIL = "ATRAD_ADD_MONEY_FAIL";

export const SET_SELECTED_SECURITY_FOR_SELL = "SET_SELECTED_SECURITY_FOR_SELL";

//addmoney
export const GET_FUND_TRANFER_INFO = "GET_FUND_TRANFER_INFO";
export const STORE_FUND_TRANFER_INFO = "STORE_FUND_TRANFER_INFO";

//kyc
export const TRIGGER_KYC_OTP = "TRIGGER_KYC_OTP";
export const VERIFY_KYC_OTP = "VERIFY_KYC_OTP";
export const TRIGGER_TIMER_POPUP = "TRIGGER_TIMER_POPUP";
export const CLEAR_TIMER_POPUP = "CLEAR_TIMER_POPUP";
export const STORE_VERIFIED_KYC_OTP_STATUS = "STORE_VERIFIED_KYC_OTP_STATUS";
export const SET_REMAINING_TIME__FOR_OTP = "SET_REMAINING_TIME__FOR_OTP";
export const SET_OTP_COUNTDOWN = "SET_OTP_COUNTDOWN";
export const STORE_OTP_MOBILE_NUMER = "STORE_OTP_MOBILE_NUMER";
export const CHECK_FRESH_USER_EMAIL_VERIFICATION_STATE =
  "CHECK_FRESH_USER_EMAIL_VERIFICATION_STATE";
export const STORE_FRESH_USER_EMAIL_VERIFICATION_STATE =
  "STORE_FRESH_USER_EMAIL_VERIFICATION_STATE";
export const TRIGGER_RESEND_EMAIL = "TRIGGER_RESEND_EMAIL";

export const GET_CUSTOMER_STATUS = "GET_CUSTOMER_STATUS";
export const STORE_CUSTOMER_STATUS = "STORE_CUSTOMER_STATUS";

export const TRIGGER_EDIT_SELL_SHARE_AMOUNT = "TRIGGER_EDIT_SELL_SHARE_AMOUNT";
export const SAVE_EDIT_SELL_SHARE_AMOUNT = "SAVE_EDIT_SELL_SHARE_AMOUNT";

export const GET_MARKET_OPEN_TIME = "GET_MARKET_OPEN_TIME";
export const STORE_MARKET_OPEN_TIME = "STORE_MARKET_OPEN_TIME";

