import { put, call, select, delay } from "redux-saga/effects";
import * as types from "../Actions";
import {
  preValidateAddMoneyService,
  commonPaymentModuleStatusService,
  atradAddMoneyService,
  getFundTranferDetailsService,
} from "../Services/addMoneyService";
import * as addMoneyActions from "../Actions/addMoneyAction";
import { selectedBroker } from "../Selectors/commonSelector";


export function* preValidateAddMoneySaga(action) {
  const brokerCode = yield select(selectedBroker);
  try {
    yield delay(0);
    const accessToken = sessionStorage.getItem("token");
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(
      preValidateAddMoneyService,
      accessToken,
      brokerCode,
      action.payload
    );
    if (response.data.STATUS === "ACCEPTED") {
      yield put({
        type: types.PRE_VALIDATE_ADD_MONEY_SUCCESS,
        payload: response,
      });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      action.callback();
    } else {
      yield put({ type: types.PRE_VALIDATE_ADD_MONEY_FAIL, payload: response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.PRE_VALIDATE_ADD_MONEY_FAIL, payload: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* commonPaymentModuleStatusSaga(action) {
  const requestId = action.payload;
  try {
    yield delay(0);
    const accessToken = sessionStorage.getItem("token");
    let response = yield call(
      commonPaymentModuleStatusService,
      accessToken,
      requestId
    );
    if (response.data.STATUS === "ACCEPTED") {
      yield put({
        type: types.COMMON_PAYMENT_MODULE_STATE_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: types.COMMON_PAYMENT_MODULE_STATE_FAIL,
        payload: response,
      });
    }
  } catch (error) {
    yield put({ type: types.COMMON_PAYMENT_MODULE_STATE_FAIL, payload: error });
  }
  action.callback();
}

export function* atradAddMoneySaga(action) {
  const brokerCode = yield select(selectedBroker);
  const requestId = action.payload;
  try {
    const accessToken = sessionStorage.getItem("token");
    let response = yield call(
      atradAddMoneyService,
      accessToken,
      brokerCode,
      requestId
    );
    if (
      response &&
      response.data &&
      response.data.STATUS &&
      response.data.STATUS === "ACCEPTED" &&
      response.data.DATA
    ) {
      yield put({ type: types.ATRAD_ADD_MONEY_SUCCESS, payload: response });
      action.callback();
    } else {
      yield put({ type: types.ATRAD_ADD_MONEY_FAIL, payload: response });
      action.callback();
    }
  } catch (error) {
    yield put({ type: types.ATRAD_ADD_MONEY_FAIL, payload: error });
    action.callback();
  }
}

export function* getFundTranferInfoDetailsSaga() {
  const brokerCode = yield select(selectedBroker);
  yield put({
    type: types.FULL_SCREEN_LOADER,
    payload: { visibility: true, text: "" },
  });
  try {
    yield delay(0);
    const accessToken = sessionStorage.getItem("token");
    let response = yield call(
      getFundTranferDetailsService,
      accessToken,
      brokerCode
    );
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });

    if (
      response &&
      response.data &&
      response.data.STATUS &&
      response.data.STATUS === "TX_SUCCESS" &&
      response.data.DATA
    ) {
      yield put(
        addMoneyActions.storeFundTranferInfoDetails(response.data.DATA)
      );
    } else {
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}
