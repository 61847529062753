const startBrowserBack = () => {
  window.onpopstate = undefined;
  window.history.back();
};
const stopBrowserBack = (callback) => {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = () => {
    window.history.pushState(null, "", window.location.href);
    callback();
  };
};
export { startBrowserBack, stopBrowserBack };
