const Images = {
  common: {
    ic_arrow_back: require("../Assets/Icons/arrow_left.svg").default,
    img_genie_logo: require("../Assets/Images/genie_new_logo.svg").default,
    img_getting_started: require("../Assets/Images/getting_started/getting_started.jpg"),
    img_user_verifing: require("../Assets/Images/user_verifying.svg").default,
    ic_kycsuccess: require("../Assets/Icons/kycsuccess.svg").default,
    ic_arrow_down: require("../Assets/Icons/arrow_down.svg").default,
    info: require("../Assets/Images/Common/InfoIcon/info.png"),
  },
  dashboard: {
    ic_market_summary: require("../Assets/Icons/market_summary.svg").default,
    ic_account_summary: require("../Assets/Icons/account_summary.svg").default,
    ic_explore_stocks: require("../Assets/Icons/explore_stocks.svg").default,
    ic_buy_stocks: require("../Assets/Icons/buy_stocks.svg").default,
    ic_sell_stocks: require("../Assets/Icons/sell_stocks.svg").default,
    ic_button_wrapper: require("../Assets/Icons/button_wrapper.svg").default,
    ic_search: require("../Assets/Icons/search.svg").default,
    ic_info: require("../Assets/Icons/info.svg").default,
    img_portfolio_button: require("../Assets/Images/mask_copy_2.svg").default,
    ic_arrow_right: require("../Assets/Icons/arrow_right.svg").default,
    ic_accordian_arrow: require("../Assets/Icons/chevron_right.svg").default,
    ic_add: require("../Assets/Icons/add.svg").default,
    ic_withdraw: require("../Assets/Icons/withdraw.svg").default,
    ic_close: require("../Assets/Icons/x.svg").default,
    ic_close_search: require("../Assets/Icons/cancel_24_px.svg").default,
    ic_order_list: require("../Assets/Icons/order_list.svg").default,
    img_investment_advisor: require("../Assets/Images/investment-advisor.svg")
      .default,
    ic_view_more: require("../Assets/Icons/view_more.svg").default,
    orderList: require("../Assets/Images/dashboard/stockiList/orderList.png"),
  },
  sellStocks: {
    ic_minus: require("../Assets/Icons/minus.svg").default,
  },
  loader: {
    img_loader: require("../Assets/Icons/loader.gif"),
  },
  buyStocks: {
    img_availableToInvest: require("../Assets/Images/availableToInvest.svg")
      .default,
    ic_danger: require("../Assets/Icons/danger.svg").default,
    ic_correct: require("../Assets/Icons/correct.svg").default,
    ic_plus: require("../Assets/Icons/plus.svg").default,
    ic_marketClose: require("../Assets/Icons/marketclosed.svg").default,
    ic_close: require("../Assets/Icons/close.svg").default,
    ic_plus_new: require("../Assets/Icons/plus_new.svg").default,
  },
  orderList: {
    ic_buyOrder: require("../Assets/Icons/buyOrder.svg").default,
    ic_buyOrderGray: require("../Assets/Icons/buyOrderGray.svg").default,
    ic_sellOrder: require("../Assets/Icons/sellOrder.svg").default,
    ic_sellOrderGray: require("../Assets/Icons/sellOrderGray.svg").default,
    ic_edit_order: require("../Assets/Icons/edit_order.svg").default,
    ic_cancel_order: require("../Assets/Icons/cancel_order.svg").default,
  },
  addMoney: {
    anm_processing: require("../Assets/Images/processing.gif"),
    anm_fail: require("../Assets/Images/failanime.gif"),
    anm_success: require("../Assets/Images/successanime.gif"),
    ic_addMoney: require("../Assets/Icons/addmoney.svg").default,
    ic_share: require("../Assets/Icons/share.svg").default,
    fundTransferDetails: require("../Assets/Images/AddMoney/FundTranferDetails/transferMoney.png"),
    ic_geniePay: require("../Assets/Icons/genie_pay.svg").default,
    ic_fundTransfer: require("../Assets/Icons/fund_trans.svg").default,
  },
  withdrawMoney: {
    ic_cancel: require("../Assets/Icons/cancel.svg").default,
  },
  errImages: {
    ic_error: require("../Assets/Icons/errorIcon.svg").default,
    ic_logout: require("../Assets/Images/log_out.svg").default,
    ic_stock_market: require("../Assets/Images/stock_market.svg").default,
  },
  brokers: {
    img_asi: require("../Assets/Images/brokers/asi_logo.png"),
    img_softlogic: require("../Assets/Images/brokers/softlogic_logo.jpg"),
  },
  buyNsell: {
    warningBlack: require("../Assets/Images/warningBlack.svg").default,
    warningRed: require("../Assets/Images/warningRed.svg").default,
  },
};

export { Images };
