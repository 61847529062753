import React from "react";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import COLORS from "../Colors";

const styles = () => ({
  buttonBackground: {
    padding: "1em",
    borderRadius: "1.5em",
    backgroundColor: COLORS.BLACK,
    alignItems: "center",
    justifyContent: "center",
    marginRight: "1em",
    marginLeft: "1em",
    marginBottom: "1.8em",
  },
  buttonText: {
    textAlign: "center",
    color: COLORS.WHITE,
    fontSize: "0.87em",
    fontWeight: "700",
    letterSpacing: "0.06em",
  },
});

class FundTransferDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onButtonPressed = () => {
    const { onPressed } = this.props;
    if (onPressed) {
      onPressed();
    } else {
      return;
    }
  };

  render() {
    const { classes, buttonText } = this.props;

    return (
      <div
        className={classes.buttonBackground}
        onClick={() => this.onButtonPressed()}
      >
        <div className={classes.buttonText}>{buttonText}</div>
      </div>
    );
  }
}

FundTransferDetailPage.propTypes = {
  buttonText: PropTypes.string,
};

FundTransferDetailPage.defaultProps = {
  buttonText: "button",
};

export default withStyles(styles)(FundTransferDetailPage);
