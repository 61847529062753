import { takeLatest } from "redux-saga/effects";
import * as types from "../Actions";
import {
  getDashboardDataSaga,
  getAccountSummarySaga,
  getInvestmentAdvisorSaga,
  getMarketOpenTimeSaga,
} from "./StockDashboardSaga";
import { getBrokerListSaga } from "./UserValidationSaga";
import {
  getCompanyListSaga,
  getSnpListSaga,
  getTopgainersLoosersSaga,
  getMarketOpenDaysSaga,
  cdsAccountValidationSaga,
  kycInitiationSaga,
  getCustomerStatusSaga,
} from "./commonSaga";
import {
  getMarketDepthSaga,
  getSecurityStatSaga,
  getSellStocksSaga,
  getSellStockStatusSaga,
  buyStockConfirmationSaga,
  sellStockConfirmationSaga,
  getMarketStatusSaga,
  getBuyCommissonSaga,
  getSellCommissonSaga,
  getOrderListBuyStatusSaga,
  cancelQueuedOrderSaga,
  editQueuedBuyOrderSaga,
  editQueuedSellOrderSaga,
  getMarketSummarySaga,
  getTradeStatusSaga,
  triggerEditSellShareAmountSaga,
} from "./stockSaga";
import {
  getBankDetailsSaga,
  getOtpRequestSaga,
  getOtpValidateSaga,
  postFundTransferSaga,
  customerBalanceWithdrawalSaga,
} from "./WithdrawSaga";
import * as addMoneySaga from "./addMoneySaga";
import * as KycSaga from "./KycSaga";

export default function* watchUserAction() {
  yield takeLatest(types.GET_DASHBOARD_DATA, getDashboardDataSaga);
  yield takeLatest(types.GET_BROKER_LIST, getBrokerListSaga);
  yield takeLatest(types.GET_COMPANY_LIST, getCompanyListSaga);
  yield takeLatest(types.GET_SNP_LIST, getSnpListSaga);
  yield takeLatest(types.GET_ACCOUNT_SUMMARY, getAccountSummarySaga);
  yield takeLatest(types.GET_TOP_DATA, getTopgainersLoosersSaga);
  yield takeLatest(types.GET_SECURITY_STAT, getSecurityStatSaga);
  yield takeLatest(types.GET_MARKET_DEPTH, getMarketDepthSaga);
  yield takeLatest(types.GET_SELL_STOCK_LIST, getSellStocksSaga);
  yield takeLatest(types.GET_SELL_STATUS, getSellStockStatusSaga);
  yield takeLatest(types.BUY_STOCK_CONFIRMATION, buyStockConfirmationSaga);
  yield takeLatest(types.SELL_STOCK_CONFIRMATION, sellStockConfirmationSaga);
  yield takeLatest(types.GET_MARKET_STATUS, getMarketStatusSaga);
  yield takeLatest(types.GET_BUY_COMMISSION, getBuyCommissonSaga);
  yield takeLatest(types.GET_SELL_COMMISSION, getSellCommissonSaga);
  yield takeLatest(types.GET_ORDER_LIST_BUY_STATUS, getOrderListBuyStatusSaga);
  yield takeLatest(types.CANCEL_QUEUED_ORDER, cancelQueuedOrderSaga);
  yield takeLatest(types.EDIT_BUY_ORDER, editQueuedBuyOrderSaga);
  yield takeLatest(types.EDIT_SELL_ORDER, editQueuedSellOrderSaga);
  yield takeLatest(types.GET_BANK_DETAILS, getBankDetailsSaga);
  yield takeLatest(types.GET_OTP_REQUEST, getOtpRequestSaga);
  yield takeLatest(types.GET_OTP_VALIDATE, getOtpValidateSaga);
  yield takeLatest(types.POST_FUND_TRANSFER, postFundTransferSaga);
  yield takeLatest(types.GET_INVESTMENT_ADVISOR, getInvestmentAdvisorSaga);
  yield takeLatest(
    types.CUSTOMER_BALANCE_WITHDRAWAL,
    customerBalanceWithdrawalSaga
  );
  yield takeLatest(types.GET_MARKET_SUMMARY_DETAILS, getMarketSummarySaga);
  yield takeLatest(
    types.PRE_VALIDATE_ADD_MONEY,
    addMoneySaga.preValidateAddMoneySaga
  );
  yield takeLatest(
    types.COMMON_PAYMENT_MODULE_STATE,
    addMoneySaga.commonPaymentModuleStatusSaga
  );
  yield takeLatest(types.GET_MARKET_OPEN_DAYS, getMarketOpenDaysSaga);
  yield takeLatest(types.CDS_ACCOUNT_VALIDATION, cdsAccountValidationSaga);
  yield takeLatest(types.COMMON_KYC_INITIATION, kycInitiationSaga);
  yield takeLatest(types.GET_SECURITY_TRADE_STATUS, getTradeStatusSaga);
  yield takeLatest(types.ATRAD_ADD_MONEY, addMoneySaga.atradAddMoneySaga);
  yield takeLatest(
    types.GET_FUND_TRANFER_INFO,
    addMoneySaga.getFundTranferInfoDetailsSaga
  );
  yield takeLatest(types.TRIGGER_KYC_OTP, KycSaga.triggerKycOtpSaga);
  yield takeLatest(types.VERIFY_KYC_OTP, KycSaga.verifyKycOtpSaga);
  yield takeLatest(
    types.CHECK_FRESH_USER_EMAIL_VERIFICATION_STATE,
    KycSaga.checkFreshUserEmailVerificationStateSaga
  );
  yield takeLatest(types.TRIGGER_RESEND_EMAIL, KycSaga.triggerResendEmailSaga);
  yield takeLatest(types.GET_CUSTOMER_STATUS, getCustomerStatusSaga);
  yield takeLatest(
    types.TRIGGER_EDIT_SELL_SHARE_AMOUNT,
    triggerEditSellShareAmountSaga
  );
  yield takeLatest(types.GET_MARKET_OPEN_TIME, getMarketOpenTimeSaga);
}
