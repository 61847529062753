import React from "react";
import CircularProgress from "./../../Features/Common/CircularProgress";
import { makeStyles } from "@mui/styles";
import Timer from "./../../Features/Common/Timer";
import { Button } from "@mui/material";

const useStyles = makeStyles({
  buttonDisableStyle: {
    display: "flex",
    backgroundColor: "#e8ecf1",
    height: "48px",
    borderRadius: "20px",
    fontSize: "0.84em",
    borderColor: "#e8ecf1",
  },
  buttonEnableStyle: {
    display: "flex",
    backgroundColor: "#353232",
    height: "48px",
    borderRadius: "20px",
    fontSize: "0.84em",
    borderColor: "#353232",
  },
  timerStyles: {
    color: "#2598ed",
    marginBottom: "3%",
    textAlign: "center",
  },
  circularStyle: {
    position: "relative",
    right: "5%",
  },
});

const ButtonWithCircularProgress = (props) => {
  const classes = useStyles(props);
  const {
    onButtonClick,
    circularLoadingFlag,
    timerValue,
    buttonDisable,
    children,
    ...rest
  } = props;
  return (
    <div>
      <div className={classes.timerStyles}>
        {circularLoadingFlag && <Timer timerValue={timerValue} />}
      </div>
      <div>
        <div
          className={
            buttonDisable
              ? classes.buttonDisableStyle
              : classes.buttonEnableStyle
          }
        >
          <Button
            data-testid="button-container"
            fullWidth
            disabled={buttonDisable}
            style={buttonDisable ? { color: "#cccccc" } : { color: "#ffffff" }}
            onClick={() => onButtonClick()}
          >
            <div className={classes.circularStyle}>
              {circularLoadingFlag && (
                <CircularProgress
                  timer={timerValue}
                  size={15}
                  thickness={10}
                  {...rest}
                />
              )}
            </div>
            {children}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ButtonWithCircularProgress;
