import React, { Component } from "react";
import { Images } from "../../Theme/Images";
import { connect } from "react-redux";

class CircularUnderLoad extends Component {
  render() {
    return (
      <div>
        <div className="" role="status">
          <img
            src={Images.loader.img_loader}
            className="loading-gif"
            alt="logo"
          />
          <p className="loading-gif-text">Loading...</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(CircularUnderLoad);
