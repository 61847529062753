import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import "./TabComponent.module.scss";
import { AppBar, Divider, Tab, Tabs } from "@mui/material";
const BASE_URL = window._env_.REACT_APP_DEV_API_URL;
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const getPriceStyle = () => {
    return {
      fontSize: "0.998em",
      fontFamily: "Open Sans",
      color: "#333333",
      fontWeight: "bold",
    };
  };
  const getDecimalStyle = () => {
    return {
      fontSize: "0.749em",
      fontFamily: "Open Sans",
      color: "#333333",
      fontWeight: "normal",
    };
  };
  const getTabComponentRow = () => {
    return {
      display: "flex",
      flexWrap: "wrap",
      marginRight: "-15px",
      marginLeft: "-15px",
      padding: "15px 15px 20px 15px",
      height: "20px",
    };
  };
  const getTabComponentRowLast = () => {
    return {
      display: "flex",
      flexWrap: "wrap",
      marginRight: "-15px",
      marginLeft: "-15px",
      padding: "15px 15px 25px 15px",
      height: "20px",
    };
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Divider />
          <div style={getTabComponentRow()}>
            <div style={{ width: "35%", alignItems: "flex-end" }}>
              <span
                style={{
                  color: "#767676",
                  fontSize: "0.749em",
                  marginRight: "4%",
                }}
              >
                ASPI
              </span>
            </div>
            <div style={{ width: "5%" }}>
              <span style={{ color: "#767676", fontSize: "0.749em" }}>:</span>
            </div>
            <div style={{ width: "60%", textAlign: "right" }}>
              <span style={getPriceStyle()}>
                {children.asivalue
                  ? numberWithCommas(children.asivalue.split(".")[0])
                  : "0"}
              </span>
              .
              <span style={getDecimalStyle()}>
                {children.asivalue && children.asivalue.split(".")[1]
                  ? children.asivalue.split(".")[1]
                  : "00"}
              </span>
            </div>
          </div>
          <Divider style={{ marginTop: "4%" }} />
          <div style={getTabComponentRow()}>
            <div style={{ width: "35%", alignItems: "flex-end" }}>
              <span
                style={{
                  color: "#767676",
                  fontSize: "0.749em",
                  marginRight: "4%",
                }}
              >
                S&P SL20
              </span>
            </div>
            <div style={{ width: "5%" }}>
              <span style={{ color: "#767676", fontSize: "0.749em" }}>:</span>
            </div>
            <div style={{ width: "60%", textAlign: "right" }}>
              <span style={getPriceStyle()}>
                {children.sl20value
                  ? numberWithCommas(children.sl20value.split(".")[0])
                  : "0"}
              </span>
              .
              <span style={getDecimalStyle()}>
                {children.sl20value && children.sl20value.split(".")[1]
                  ? children.sl20value.split(".")[1]
                  : "00"}
              </span>
            </div>
          </div>
          <Divider style={{ marginTop: "4%" }} />
          <div style={getTabComponentRow()}>
            <div style={{ width: "35%", alignItems: "flex-end" }}>
              <span
                style={{
                  color: "#767676",
                  fontSize: "0.749em",
                  marginRight: "4%",
                }}
              >
                Share volume
              </span>
            </div>
            <div style={{ width: "5%" }}>
              <span style={{ color: "#767676", fontSize: "0.749em" }}>:</span>
            </div>
            <div style={{ width: "60%", textAlign: "right" }}>
              <span style={getPriceStyle()}>
                {children.volume
                  ? numberWithCommas(children.volume.split(".")[0])
                  : "0"}
              </span>
              .
              <span style={getDecimalStyle()}>
                {children.volume && children.volume.split(".")[1]
                  ? children.volume.split(".")[1]
                  : "00"}
              </span>
            </div>
          </div>
          <Divider style={{ marginTop: "4%" }} />
          <div style={getTabComponentRowLast()}>
            <div style={{ width: "35%", alignItems: "flex-end" }}>
              <span
                style={{
                  color: "#767676",
                  fontSize: "0.749em",
                  marginRight: "4%",
                }}
              >
                Turnover
              </span>
            </div>
            <div style={{ width: "5%" }}>
              <span style={{ color: "#767676", fontSize: "0.749em" }}>:</span>
            </div>
            <div style={{ width: "60%", textAlign: "right" }}>
              <span style={getPriceStyle()}>
                {children.turnover
                  ? numberWithCommas(children.turnover.split(".")[0])
                  : "0"}
              </span>
              .
              <span style={getDecimalStyle()}>
                {children.turnover && children.turnover.split(".")[1]
                  ? children.turnover.split(".")[1]
                  : "00"}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const numberWithCommas = (x) => {
  const value = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return value;
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  appBarStyles: {
    backgroundColor: "#ffffff !important",
    color: "#333333 !important",
  },
  header: {
    color: "black",
    fontWeight: "normal",
    fontSize: "16sp",
    outline: "none !important",
    width: "33.33%",
    textTransform: "none",
  },
  indicator: {
    backgroundColor: "#2598ed",
  },
}));

export default function TabComponent(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [counter, setCounter] = React.useState(0);
  let [todayData, setTodayData] = React.useState({});
  let [todayDataSec, secTodayDataSec] = React.useState({});
  const [listening, setListening] = React.useState(false);

  let oneDayData = {};
  let threeDayData = {};
  const { data } = props;

  const checkTabDisable = (obj) => {
    if (Object.keys(obj).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  let eventSource = undefined;
  if (counter === 0 && data.length !== 0) {
    data.forEach((x, i) => {
      if (x.timePeriod === "TODAY") {
        setTodayData(x);
      }
      if (x.timePeriod === "1DAY") {
        oneDayData = x;
      }
      if (x.timePeriod === "3DAY") {
        threeDayData = x;
      }
    });
    setCounter(counter + 1);
  } else {
    data.forEach((x, i) => {
      if (x.timePeriod === "1DAY") {
        oneDayData = x;
      }
      if (x.timePeriod === "3DAY") {
        threeDayData = x;
      }
    });
  }

  useEffect(() => {
    if (!listening) {
      eventSource = new EventSource(
        `${BASE_URL}/api/notification/stocks-kafka-service/event/stream/sector-index`
      );
      eventSource.addEventListener("sectorData", function (event) {
        var sectorData = JSON.parse(event.data);
        setTodayData(sectorData);
      });
      eventSource.onerror = (event) => {
        if (event.target.readyState === EventSource.CLOSED) {
        }
        eventSource.close();
      };
      eventSource.onopen = (event) => {};
      setListening(true);
    }
    return () => {
      eventSource.close();
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={0} className={classes.appBarStyles}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          classes={{
            indicator: classes.indicator,
          }}
        >
          <Tab className={classes.header} label="Today" {...a11yProps(0)} />
          <Tab
            disabled={checkTabDisable(oneDayData)}
            className={classes.header}
            label="1 day"
            {...a11yProps(1)}
          />
          <Tab
            disabled={checkTabDisable(threeDayData)}
            className={classes.header}
            label="3 days"
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} children={todayData} />
      <TabPanel value={value} index={1} children={oneDayData} />
      <TabPanel value={value} index={2} children={threeDayData} />
    </div>
  );
}
