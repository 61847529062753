import React, { Fragment, Component } from "react";
import Layout from "../../Layout/Layout";
import "./OrderSuccessStyles.scss";
import BodyContainer from "../../../Components/BodyArea/Body";
import { Images } from "../../../Theme/Images";

class OrderSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAccountButtonStatus: true,
      token: "",
      sesToken: "",
    };
  }

  buttonOnPress() {
    window.ReactNativeWebView.postMessage("goToDashboard");
  }

  render() {
    return (
      <Fragment>
        <Layout>
          <BodyContainer>
            <header className="App-container" data-testid="app-container">
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30%",
                  }}
                >
                  <img
                    src={Images.common.ic_kycsuccess}
                    alt="logo"
                    className="icon"
                  />
                </div>
                <div className="ks-title-div">
                  <h4 className="ks-title">Successful</h4>
                </div>
                <div className="ks-messageTemplate">
                  <span>Your order has been transferred</span>
                </div>

                <div
                  className="row no-padding"
                  style={{ position: "fixed", bottom: 0, left: 10, right: 0 }}
                >
                  <div className="col-12">
                    <a style={{ color: "white" }}>
                      <button
                        className="btn-transaction remove-outline"
                        onClick={() => {
                          this.buttonOnPress();
                        }}
                      >
                        DONE
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </header>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

export default OrderSuccess;
