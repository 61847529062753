import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default function OutlinedButton(props) {
  const { onPress, title, buttonWidth, buttonBorderColor } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {},
    },
    button: {
      color: "#2598ed !important",
      borderRadius: "50px !important",
      borderColor: buttonBorderColor,
      width: buttonWidth,
      textTransform: "none !important",
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button variant="outlined" className={classes.button} onClick={onPress}>
        {title}
      </Button>
    </div>
  );
}

OutlinedButton.propTypes = {
  onPress: PropTypes.func,
  title: PropTypes.string,
  style: PropTypes.object,
  btnTextStyle: PropTypes.object,
};

OutlinedButton.defaultProps = {
  buttonWidth: 95,
  buttonBorderColor: "#2598ed",
};
