import React, { Component,useState } from "react";
import { connect } from "react-redux";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import "./SellStockStyles.scss";
import moment from "moment";
import { TextInputStandard } from "../../../Components/TextInput/index";
import BottomSheet from "../../../Components/BottomSheet/BottomSheet";
import * as commonFunctions from "../../../Utils/commonFunctions";
import * as stockActions from "../../../Redux/Actions/stockAction";
import * as Utils from "../../../Utils/constants";
import { getMarketOpenDays } from "../../../Redux/Actions/commonAction";
import values from "../../../Assets/ConfigurableValues";
import { Images } from "../../../Theme/Images";
import { Divider } from "@mui/material";
import Header from "../../../Components/Header/Header";
import {
  getMarketStatus,
 
} from "../../../Redux/Actions/stockAction";
//import React, { useEffect, useState } from "react";

const BASE_URL = window._env_.REACT_APP_DEV_API_URL;
const SELL_STOCKS_CALCULATION_VALIDATION_AMOUNT =
  window._env_.REACT_APP_SELL_STOCKS_MAX_LIMIT;
let eventSource = undefined;

const CONSIDERD_VALUE_FOR_INCREAMENT = 100;
const INCREAMENT_BELOW_HUNDRAD = 10;
const INCREAMENT_ABOVE_HUNDRAD = 25;

class SellStock extends Component {
  constructor(props) {
    super(props);
    const {
      orderSelectedItemPrice,
      sellStockPrice,
      orderSelectedItemPriceDecimal,
      sellStockPriceDecimal,
      orderSelectedItemQty,
      noOfShares,
    } = props;

    this.state = {
      openBottomSheet: false,
      noOfShares: orderSelectedItemQty
        ? orderSelectedItemQty
        : noOfShares
        ? noOfShares
        : "",
      sellStockPrice: orderSelectedItemPrice
        ? orderSelectedItemPrice
        : sellStockPrice
        ? sellStockPrice
        : "",
      sellStockPriceDecimal: orderSelectedItemPriceDecimal
        ? commonFunctions.fixDecimalPoints(orderSelectedItemPriceDecimal)
        : sellStockPriceDecimal
        ? sellStockPriceDecimal
        : "",
      invaldPrice: false,
      invalidQuantity: false,
      invalidQty: false,
      buttonDisabled: false,
      currentPrice: "",
      lastTradedPrice: "",
      securityValue: "",
      marketOpenDays: [],
      priceOnFocus: false,
      amountExceedError: false,
      validDecimalEnteredErrorBelowHundrad: false,
      validDecimalEnteredErrorAboveHundrad: false,
      noValueValidation: false,
      lastTradedPrice: "",
      marketStatus: "",
      infoIconStatus: false,
    };

  }

  componentDidMount() {
    const { triggerEditSellShareAmount, orderEditStatus, orderEditItem } =
      this.props;    
      this.props.getMarketStatus();
    this.handleEventSource();
    if (orderEditStatus) {
      triggerEditSellShareAmount(orderEditItem.securitycode);
    }
  }

  componentWillUnmount() {
    eventSource.close();
  }

  handleEventSource() {
    eventSource = new EventSource(
      `${BASE_URL}/api/notification/stocks-kafka-service/event/stream/watch-data`
    );
    eventSource.addEventListener("watchData", (event) =>
      this.handleWatchData(event)
    );
    eventSource.onopen = () => this.handleOpen();
    eventSource.onerror = (event) => this.handleError(event);
  }

  handleWatchData = (event) => {
    const { orderEditItem, orderEditStatus, selectedStock } = this.props;
    let watchData = JSON.parse(event.data);
    let securityValue = watchData.security;
    let currentPrice = watchData.askprice;
    let lastTradedPrice = watchData.tradeprice;

    if (orderEditStatus) {
      if (orderEditItem && orderEditItem.securitycode !== "") {
        if (orderEditItem.securitycode === securityValue) {
          this.setState({ securityValue, currentPrice, lastTradedPrice });
        }
      }
    } else {
      if (selectedStock && selectedStock.security !== "") {
        if (selectedStock.security === securityValue) {
          this.setState({ securityValue, currentPrice, lastTradedPrice });
        }
      }
    }
  };

  handleOpen = () => {};

  handleError = (event) => {
    if (event.target.readyState === EventSource.CLOSED) {
    }
    eventSource.close();
  };

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  buttonOnPress() {
    const { orderEditStatus, orderEditItem, getSellCommission, selectedStock } =
      this.props;
    const { noOfShares, sellStockPrice, sellStockPriceDecimal } = this.state;

    if (!sellStockPrice && !sellStockPriceDecimal) {
      this.setState({
        invaldPrice: true,
      });
      return;
    } else if (!sellStockPrice || !sellStockPriceDecimal) {
      this.setState({
        noValueValidation: true,
      });
      return;
    } else if (noOfShares && sellStockPrice) {
      let sellStockPriceDecimal_ =
        sellStockPriceDecimal && sellStockPriceDecimal !== 0
          ? sellStockPriceDecimal
          : 0.0;
      let amnt =
        Number(noOfShares) *
        (Number(sellStockPrice) + Number(sellStockPriceDecimal_) * 0.01);
      if (
        SELL_STOCKS_CALCULATION_VALIDATION_AMOUNT &&
        SELL_STOCKS_CALCULATION_VALIDATION_AMOUNT > 0 &&
        amnt > SELL_STOCKS_CALCULATION_VALIDATION_AMOUNT
      ) {
        this.setState({
          amountExceedError: true,
        });
        return;
      }
    } else {
      this.setState({
        invaldPrice: false,
      });
    }

    if (orderEditStatus) {
      if (parseFloat(noOfShares) <= 0 || noOfShares === "") {
        this.setState({
          invalidQty: true,
        });
      } else if (parseInt(orderEditItem.remainder) < parseInt(noOfShares)) {
        this.setState({
          invalidQuantity: true,
        });
      } else {
        getSellCommission(() => {
          this.props.history.push("sell-stock-confirmation");
        });
      }
    } else {
      if (parseFloat(noOfShares) <= 0 || noOfShares === "") {
        this.setState({
          invalidQty: true,
        });
      } else if (parseInt(selectedStock.quantity) < parseInt(noOfShares)) {
        this.setState({
          invalidQuantity: true,
        });
      } else {
        this.setBottomSheetData();
        getSellCommission();
      }
    }
  }

  setBottomSheetData = () => {
    // getMarketStatus,
   // this.props.getMarketStatus();
   this.props.getMarketStatus();
    const { getMarketOpenDays, setBottomSheetStatus, bottomSheetStatus,getMarketStatus} =
      this.props;

    getMarketOpenDays(() => {
      if (
        this.props.marketOpenDays &&
        this.props.marketOpenDays.DATA !== null
      ) {
        this.setState({ marketOpenDays: this.props.marketOpenDays.DATA });
        setBottomSheetStatus(!bottomSheetStatus);
      }
    });

    if(this.props.marketStatus ==="Open"){
      this.props.setSellStockDuration({
        value: moment(),
        name: "Current trading day",
        tif: 0,
        tifDays: 1,
      });
    }else{
      this.props.setSellStockDuration({
        value: moment(),
        name: "Next trading day",
        tif: 6,
        tifDays: 1,
      });
    }
   
  };

  handleOnClose = () => {
    this.props.setBottomSheetStatus(false);
  };

  handleIconClickOpen = () => {
    //console.log("Test");
   this.props.setBottomSheetStatus(false);
    this.setState({
      infoIconStatus: true,
    });
   // console.log(this.state.infoIconStatus);
  };

   closePopup=()=>{
    this.props.setBottomSheetStatus(true);
    this.setState({
      infoIconStatus: false,
    });
  }


  onHandleNoOfShare = (data) => {
    const regex = Utils.REGEX_SELL_BUY_FUNC;
    const { SetSellNumberOfShares, setSelectedOrderItemQuantity } = this.props;

    this.setState({
      noOfShares: data,
    });

    if (data === "" || regex.test(data)) {
      this.setState({
        invalidQty: false,
        invalidQuantity: false,
        buttonDisabled: false,
        amountExceedError: false,
      });
    } else {
      this.setState({
        invalidQty: true,
        buttonDisabled: true,
        amountExceedError: false,
      });
    }
    SetSellNumberOfShares(data);
    setSelectedOrderItemQuantity(data);
  };

  onHandleSellStockPrice = (data) => {
    const regex = Utils.REGEX_SELL_BUY_FUNC;
    const { setSellStockPrice, setSelectedOrderItemPrice } = this.props;
    const { sellStockPriceDecimal } = this.state;

    this.setState({
      sellStockPrice: data,
    });

    if (data) {
      if (regex.test(data)) {
        this.setState({
          invaldPrice: false,
          noValueValidation: false,
          amountExceedError: false,
        });
      } else {
        this.setState({
          invaldPrice: true,
          noValueValidation: false,
          amountExceedError: false,
        });
      }
    } else {
      if (!sellStockPriceDecimal && !data) {
        this.setState({
          noValueValidation: false,
          invaldPrice: true,
          amountExceedError: false,
        });
      } else {
        this.setState({
          noValueValidation: true,
          invaldPrice: false,
          amountExceedError: false,
        });
      }
    }

    let str = data;

    setSellStockPrice(str);
    setSelectedOrderItemPrice(str);

    this.setState({
      validDecimalEnteredErrorBelowHundrad: false,
      validDecimalEnteredErrorAboveHundrad: false,
    });

    if (sellStockPriceDecimal && data) {
      if (
        parseInt(data) < CONSIDERD_VALUE_FOR_INCREAMENT &&
        parseInt(sellStockPriceDecimal) % INCREAMENT_BELOW_HUNDRAD !== 0
      ) {
        this.setState({ validDecimalEnteredErrorBelowHundrad: true });
      } else if (
        parseInt(data) >= CONSIDERD_VALUE_FOR_INCREAMENT &&
        parseInt(sellStockPriceDecimal) % INCREAMENT_ABOVE_HUNDRAD !== 0
      ) {
        this.setState({ validDecimalEnteredErrorAboveHundrad: true });
      } else {
        this.setState({
          validDecimalEnteredErrorBelowHundrad: false,
          validDecimalEnteredErrorAboveHundrad: false,
        });
      }
    }
  };

  onHandleSellStockPriceDecimal = (data) => {
    const regex = Utils.REGEX_SELL_BUY_FUNC;
    const { setSelectedOrderItemPriceDecimal, setSellStockPriceDecimal } =
      this.props;
    const { sellStockPrice } = this.state;

    this.setState({
      sellStockPriceDecimal: data,
    });

    if (data) {
      if (regex.test(data)) {
        this.setState({
          invaldPrice: false,
          noValueValidation: false,
          amountExceedError: false,
        });
      } else {
        this.setState({
          invaldPrice: true,
          noValueValidation: false,
          amountExceedError: false,
        });
      }
    } else {
      if (!sellStockPrice && !data) {
        this.setState({
          noValueValidation: false,
          invaldPrice: true,
          amountExceedError: false,
        });
      } else {
        this.setState({
          noValueValidation: true,
          invaldPrice: false,
          amountExceedError: false,
        });
      }
    }
    setSelectedOrderItemPriceDecimal(data);
    setSellStockPriceDecimal(data);

    this.setState({
      validDecimalEnteredErrorBelowHundrad: false,
      validDecimalEnteredErrorAboveHundrad: false,
    });

    if (sellStockPrice && data) {
      if (
        parseInt(sellStockPrice) < CONSIDERD_VALUE_FOR_INCREAMENT &&
        parseInt(data) % INCREAMENT_BELOW_HUNDRAD !== 0
      ) {
        this.setState({ validDecimalEnteredErrorBelowHundrad: true });
      } else if (
        parseInt(sellStockPrice) >= CONSIDERD_VALUE_FOR_INCREAMENT &&
        parseInt(data) % INCREAMENT_ABOVE_HUNDRAD !== 0
      ) {
        this.setState({ validDecimalEnteredErrorAboveHundrad: true });
      } else {
        this.setState({
          validDecimalEnteredErrorBelowHundrad: false,
          validDecimalEnteredErrorAboveHundrad: false,
        });
      }
    }
  };

  handleBottomSheetNavigation = () => {
    this.props.history.push("sell-stock-confirmation");
  };

  handleOrderDuration = (data) => {
    this.props.setSellStockDuration(data);
  };

  onLeftIconClick = () => {
    this.props.SetSellNumberOfShares("");
    this.props.setSelectedOrderItemQuantity("");
    this.props.setSellStockPrice("");
    this.props.setSelectedOrderItemPrice("");
    this.props.setSelectedOrderItemPriceDecimal("");
    this.props.setSellStockPriceDecimal("");
    this.props.history.goBack();
  };

  onBlurBuyStockPriceDecimal = () => {
    this.setState({
      priceOnFocus: false,
    });
  };

  onFocusBuyStockPriceDecimal = () => {
    this.setState({
      priceOnFocus: true,
    });
  };

  onBlurBuyStockPrice = () => {
    this.setState({
      priceOnFocus: false,
    });
  };
  renderCurrentPriceText = () => {
    const { currentPrice } = this.state;
    const { selectedStock } = this.props;

    if (selectedStock) {
      if (!currentPrice || currentPrice === "") {
        if (selectedStock.bidprice) {
          if (selectedStock.bidprice === "") {
            return "0";
          } else {
            return selectedStock.bidprice.substr(
              0,
              selectedStock.bidprice.indexOf(".")
            );
          }
        } else {
          return "0";
        }
      } else {
        if (currentPrice) {
          return currentPrice.substr(0, currentPrice.indexOf("."));
        }
      }
    } else {
      return "0";
    }
  };

  renderLastTradedPriceText = () => {
    const { lastTradedPrice, currentPrice } = this.state;
    const { selectedStock } = this.props;

    if (selectedStock) {
      if (!lastTradedPrice || lastTradedPrice === "") {
        if (selectedStock.tradeprice) {
          if (selectedStock.tradeprice === "") {
            return "0";
          } else {
            return selectedStock.tradeprice.substr(
              0,
              selectedStock.tradeprice.indexOf(".")
            );
          }
        } else {
          return "0";
        }
      } else {
        if (lastTradedPrice && currentPrice) {
          return lastTradedPrice.substr(0, currentPrice.indexOf("."));
        }
      }
    } else {
      return "0";
    }
  };

  renderEditCurrentPriceText = () => {
    const { currentPrice } = this.state;
    const { orderEditItem } = this.props;

    if (orderEditItem) {
      if (!currentPrice || currentPrice === "") {
        if (orderEditItem.bidprice) {
          if (orderEditItem.bidprice === "") {
            return "0";
          } else {
            return orderEditItem.bidprice.substr(
              0,
              orderEditItem.bidprice.indexOf(".")
            );
          }
        } else {
          return "0";
        }
      } else {
        if (this.state.currentPrice) {
          return this.state.currentPrice.substr(
            0,
            this.state.currentPrice.indexOf(".")
          );
        }
      }
    } else {
      return "0";
    }
  };

  renderEditLastTradedPriceText = () => {
    const { lastTradedPrice, currentPrice } = this.state;
    const { orderEditItem } = this.props;

    if (orderEditItem) {
      if (!lastTradedPrice || lastTradedPrice === "") {
        if (orderEditItem.tradeprice) {
          if (orderEditItem.tradeprice === "") {
            return "0";
          } else {
            return orderEditItem.tradeprice.substr(
              0,
              orderEditItem.tradeprice.indexOf(".")
            );
          }
        } else {
          return "0";
        }
      } else {
        if (lastTradedPrice && currentPrice) {
          return lastTradedPrice.substr(0, currentPrice.indexOf("."));
        }
      }
    } else {
      return "0";
    }
  };

  renderCurrentPriceDecimal = () => {
    const { currentPrice } = this.state;
    const { selectedStock } = this.props;

    if (selectedStock) {
      if (!currentPrice || currentPrice === "") {
        if (selectedStock.bidprice) {
          if (selectedStock.bidprice === "") {
            return "00";
          } else {
            return commonFunctions.fixDecimalPoints(
              selectedStock.bidprice.split(".").pop()
            );
          }
        } else {
          return "00";
        }
      } else {
        return commonFunctions.fixDecimalPoints(currentPrice.split(".").pop());
      }
    } else {
      return "00";
    }
  };

  renderLastTradedPriceDecimal = () => {
    const { lastTradedPrice } = this.state;
    const { selectedStock } = this.props;

    if (selectedStock) {
      if (!lastTradedPrice || lastTradedPrice === "") {
        if (selectedStock.tradeprice) {
          if (selectedStock.tradeprice === "") {
            return "00";
          } else {
            return commonFunctions.fixDecimalPoints(
              selectedStock.tradeprice.split(".").pop()
            );
          }
        } else {
          return "00";
        }
      } else {
        return commonFunctions.fixDecimalPoints(
          lastTradedPrice.split(".").pop()
        );
      }
    } else {
      return "00";
    }
  };

  renderEditCurrentPriceDecimal = () => {
    const { currentPrice } = this.state;
    const { orderEditItem } = this.props;

    if (orderEditItem) {
      if (!currentPrice || currentPrice === "") {
        if (orderEditItem.bidprice) {
          if (orderEditItem.bidprice === "") {
            return "00";
          } else {
            return commonFunctions.fixDecimalPoints(
              orderEditItem.bidprice.split(".").pop()
            );
          }
        } else {
          return "00";
        }
      } else {
        return commonFunctions.fixDecimalPoints(currentPrice.split(".").pop());
      }
    } else {
      return "00";
    }
  };

  renderEditLastTradedPricDecimal = () => {
    const { lastTradedPrice } = this.state;
    const { orderEditItem } = this.props;

    if (orderEditItem) {
      if (!lastTradedPrice || lastTradedPrice === "") {
        if (orderEditItem.tradeprice) {
          if (orderEditItem.tradeprice === "") {
            return "00";
          } else {
            return commonFunctions.fixDecimalPoints(
              orderEditItem.tradeprice.split(".").pop()
            );
          }
        } else {
          return "00";
        }
      } else {
        return commonFunctions.fixDecimalPoints(
          lastTradedPrice.split(".").pop()
        );
      }
    } else {
      return "00";
    }
  };

  getRow = (valueOne, valueTwo, valueThree) => {
    return (
      <div className="bs-row-2">
        <div style={{ width: "49%" }}>
          <span className="bs_card_Text">{valueOne}</span>
        </div>
        <div style={{ width: "2%" }} className="bs_colan">
          :
        </div>
        <div style={{ width: "49%", textAlign: "end" }}>
          <span className="bs_lkrText">{"LKR"}</span>
          <span className="bs_priceText">
            {Number(parseInt(valueTwo)).toLocaleString()}
          </span>
          <span className="bs_decimalText">.{valueThree}</span>
        </div>
      </div>
    );
  };

  styles = {
    dashedBorder: {
      marginLeft: "16px",
      marginRight: "16px",
      borderBottomWidth: "1px",
      borderBottomColor: "#dee0e9",
      borderBottomStyle: "dashed",
    },
  };

  renderOnFocusValidation = () => {
    return (
      <div
        style={{
          alignContent: "center",
          marginLeft: "16px",
          marginRight: "16px",
          marginBottom: "16px",
        }}
      >
        <h6 className="bs-info-text">
          {`If the price entered is less than 100, the cents value should be
          stated in increments of 10 cents (ex: 54.10 or 54.20 or 54.30)`}
        </h6>
        <h6 className="bs-info-text">
          {`If the price entered is 100 or more, the cents value should be stated
          in increments of 25 cents (ex: 112.00 or 112.25 or 112.50)`}
        </h6>
      </div>
    );
  };

  renderStockDeatilsComponent = () => {
    const {
      orderEditStatus,
      orderSelectedItemPriceDecimal,
      orderSelectedItemQty,
      orderSelectedItemPrice,
      sellStockPriceDecimal,
      sellStockPrice,
    } = this.props;

    const {
      validDecimalEnteredErrorBelowHundrad,
      validDecimalEnteredErrorAboveHundrad,
      noValueValidation,
    } = this.state;

    return (
      <div>
        {this.getRow(
          "Current price",
          orderEditStatus
            ? this.renderEditCurrentPriceText()
            : this.renderCurrentPriceText(),
          orderEditStatus
            ? this.renderEditCurrentPriceDecimal()
            : this.renderCurrentPriceDecimal()
        )}

        <Divider
          variant="middle"
          style={{
            marginLeft: "32px",
            marginRight: "32px",
            marginTop: "11px",
            color: "#edeff6",
          }}
        />

        {this.getRow(
          "Last traded price",
          orderEditStatus
            ? this.renderEditLastTradedPriceText()
            : this.renderLastTradedPriceText(),
          orderEditStatus
            ? this.renderEditLastTradedPricDecimal()
            : this.renderLastTradedPriceDecimal()
        )}

        <Divider
          variant="middle"
          style={{
            marginLeft: "32px",
            marginRight: "32px",
            marginTop: "11px",
            color: "#edeff6",
          }}
        />

        {orderEditStatus
          ? this.getRow(
              "Value of shares",
              !validDecimalEnteredErrorBelowHundrad &&
                !validDecimalEnteredErrorAboveHundrad &&
                !noValueValidation &&
                orderSelectedItemPriceDecimal &&
                orderSelectedItemPrice &&
                orderSelectedItemQty
                ? orderSelectedItemPriceDecimal &&
                  orderSelectedItemPriceDecimal !== 0
                  ? Math.floor(
                      Number(orderSelectedItemQty) *
                        (Number(orderSelectedItemPrice) +
                          Number(orderSelectedItemPriceDecimal) * 0.01)
                    )
                      .toString()
                      .split(".")[0]
                  : Math.floor(
                      Number(orderSelectedItemQty) *
                        (Number(orderSelectedItemPrice) + 0.0)
                    )
                      .toString()
                      .split(".")[0]
                : "00.00",
              !validDecimalEnteredErrorBelowHundrad &&
                !validDecimalEnteredErrorAboveHundrad &&
                !noValueValidation &&
                orderSelectedItemPriceDecimal &&
                orderSelectedItemQty &&
                orderSelectedItemPrice
                ? orderSelectedItemPriceDecimal &&
                  orderSelectedItemPriceDecimal !== 0
                  ? (
                      Number(orderSelectedItemQty) *
                      (Number(orderSelectedItemPrice) +
                        Number(orderSelectedItemPriceDecimal) * 0.01)
                    )
                      .toFixed(2)
                      .toString()
                      .split(".")[1]
                  : "00"
                : "00"
            )
          : this.getRow(
              "Value of shares",
              !validDecimalEnteredErrorBelowHundrad &&
                !validDecimalEnteredErrorAboveHundrad &&
                !noValueValidation &&
                sellStockPriceDecimal &&
                orderSelectedItemQty &&
                sellStockPrice
                ? sellStockPriceDecimal && sellStockPriceDecimal !== 0
                  ? Math.floor(
                      Number(orderSelectedItemQty) *
                        (Number(sellStockPrice) +
                          Number(sellStockPriceDecimal) * 0.01)
                    )
                      .toString()
                      .split(".")[0]
                  : Math.floor(
                      Number(orderSelectedItemQty) *
                        (Number(sellStockPrice) + 0.0)
                    )
                      .toString()
                      .split(".")[0]
                : "00.00",
              !validDecimalEnteredErrorBelowHundrad &&
                !validDecimalEnteredErrorAboveHundrad &&
                !noValueValidation &&
                sellStockPriceDecimal &&
                orderSelectedItemQty &&
                sellStockPrice
                ? sellStockPriceDecimal && sellStockPriceDecimal !== 0
                  ? (
                      Number(orderSelectedItemQty) *
                      (Number(sellStockPrice) +
                        Number(sellStockPriceDecimal) * 0.01)
                    )
                      .toFixed(2)
                      .toString()
                      .split(".")[1]
                  : "00"
                : "00"
            )}
      </div>
    );
  };

  validationInfoContainer = () => {
    const { amountExceedError } = this.state;
    return (
      <div>
        {SELL_STOCKS_CALCULATION_VALIDATION_AMOUNT &&
        SELL_STOCKS_CALCULATION_VALIDATION_AMOUNT > 0 ? (
          <>
            <div style={this.styles.dashedBorder} />
            <div className="warningDivOne">
              <div>
                <img
                  src={
                    amountExceedError
                      ? Images.buyNsell.warningRed
                      : Images.buyNsell.warningBlack
                  }
                  className="warningIcon"
                  alt="logo"
                />
              </div>
              <div
                className={
                  amountExceedError ? "warningError" : "stockInfoTxtInBlack"
                }
              >
                {"You can only sell shares worth Rs"}
                <div
                  className={
                    amountExceedError ? "amountBold" : "amountBoldBlack"
                  }
                >
                  &nbsp;
                  {Number(
                    SELL_STOCKS_CALCULATION_VALIDATION_AMOUNT
                  ).toLocaleString()}
                </div>
                {`.${
                  values.SELL_STOCKS_CALCULATION_VALIDATION_DECIMAL === 0
                    ? "00"
                    : values.SELL_STOCKS_CALCULATION_VALIDATION_DECIMAL
                } at a time`}
              </div>
            </div>
            <div className="warningDivTwo">
              <div>
                <img
                  src={Images.buyNsell.warningBlack}
                  className="warningIcon"
                  alt="logo"
                />
              </div>
              <div className={"stockInfoTxtInBlack"}>
                {"You can perform multiples of Rs"}
                <div className={"amountBoldBlack"}>
                  &nbsp;
                  {Number(
                    SELL_STOCKS_CALCULATION_VALIDATION_AMOUNT
                  ).toLocaleString()}
                </div>
                {`.${
                  values.SELL_STOCKS_CALCULATION_VALIDATION_DECIMAL === 0
                    ? "00"
                    : values.SELL_STOCKS_CALCULATION_VALIDATION_DECIMAL
                } transaction`}
              </div>
            </div>
            <div style={this.styles.dashedBorder} />
          </>
        ) : null}
      </div>
    );
  };

  render() {
    const {
      invalidQty,
      invaldPrice,
      amountExceedError,
      validDecimalEnteredErrorBelowHundrad,
      validDecimalEnteredErrorAboveHundrad,
      noValueValidation,
      infoIconStatus,
    } = this.state;
    const {
      orderEditStatus,
      orderEditItem,
      selectedStock,
      sellEditShareAmount,
    } = this.props;

    return (
      <div className="bodyContainer">
        <Header
          leftIcon={ArrowLeftIcon}
          headerContentLeft={`Sell ${
            orderEditStatus
              ? orderEditItem.securitycode
              : selectedStock.security
          }`}
          leftIconLink="sell-stocks-list"
          handleLeftIconClick={() => this.onLeftIconClick()}
        />
        <div className="bodyContainer">
          <div className="innerContent">
            <div>
              <div className="priceConatainer">
                <h3 className="bs-input-title">
                  {"Your price per share (LKR)"}
                </h3>
                <div className="txtInputView">
                  <TextInputStandard
                    label="sell-price"
                    onChange={(data) => {
                      this.onHandleSellStockPrice(data);
                    }}
                    placeholder="00"
                    maxlength="9"
                    value={this.state.sellStockPrice}
                    alignInput="right"
                    handleFocus={() => this.onFocusBuyStockPriceDecimal()}
                    handleBlur={() => this.onBlurBuyStockPrice()}
                  />
                  <h1
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    .
                  </h1>
                  <TextInputStandard
                    label="sell-decimal"
                    onChange={(data) => {
                      this.onHandleSellStockPriceDecimal(data);
                    }}
                    placeholder="00"
                    maxlength="2"
                    alignInput="left"
                    value={this.state.sellStockPriceDecimal}
                    fontSize="2em"
                    handleBlur={() => this.onBlurBuyStockPriceDecimal()}
                    handleFocus={() => this.onFocusBuyStockPriceDecimal()}
                  />
                </div>
                {invaldPrice ? (
                  <h6 className="ss-error-value-req">{"Value required"}</h6>
                ) : null}
                {noValueValidation ? (
                  <h6 className="ss-error-value-req">
                    {"Invalid price entered"}
                  </h6>
                ) : null}
                {validDecimalEnteredErrorBelowHundrad ? (
                  <h6 className="bs-error-value-decimal-validation">
                    {
                      "Cents value should be in increments of 10 cents ( for values less than Rs.100)"
                    }
                  </h6>
                ) : null}
                {validDecimalEnteredErrorAboveHundrad ? (
                  <h6 className="bs-error-value-decimal-validation">
                    {
                      "Cents value should be in increments of 25 cents ( for values more than Rs.100)"
                    }
                  </h6>
                ) : null}
                {this.state.priceOnFocus
                  ? this.renderOnFocusValidation()
                  : null}
                <div className="dashedBorder" />
              </div>

              <div className="sharesContainer">
                <h3 className="bs-input-title">{"Number of shares"}</h3>
                <TextInputStandard
                  label="sell-shares"
                  maxlength="6"
                  onChange={(data) => {
                    this.onHandleNoOfShare(data);
                  }}
                  alignInput="center"
                  value={this.state.noOfShares}
                  handleBlur={() => this.onBlurBuyStockPriceDecimal()}
                />
              </div>

              {this.state.invalidQuantity ? (
                <h6 className="bs-error-value-req">
                  {orderEditStatus
                    ? `The stock quantity has to be less than 
                  ${parseInt(this.props.orderEditItem.remainder) + 1}`
                    : `The stock quantity has to be less than 
                  ${parseInt(this.props.selectedStock.quantity) + 1}`}
                </h6>
              ) : null}
              {this.state.invalidQty ? (
                <h6 className="bs-error-value-req">{"Value required"}</h6>
              ) : null}
              {amountExceedError ? (
                <h6 className="bs-error-value-req-withPadding-sell">
                  {"Please reduce the number of shares or the price per share"}
                </h6>
              ) : null}

              {this.validationInfoContainer()}

              {orderEditStatus ? (
                <h6 className="ATItxt">
                  {"Available to sell "}
                  {sellEditShareAmount && sellEditShareAmount.sharesamount
                    ? sellEditShareAmount.sharesamount
                    : 0}
                </h6>
              ) : (
                <div>
                  <h6 className="ATItxt">
                    {"Available to sell"}
                    {` ${this.numberWithCommas(selectedStock.quantity)}`}
                  </h6>
                  <BottomSheet
                    bottomSheetStatus={this.props.bottomSheetStatus}
                    handleOnClose={this.handleOnClose}
                      marketStatusOpen={this.props.marketStatus}
                      handleIconClickOpen={this.handleIconClickOpen}
                    dropdownTitle={"Order duration \n (Time in force)"}
                    handleNavigation={this.handleBottomSheetNavigation}
                    setSelectedOderDuration={(data) => {
                      this.handleOrderDuration(data);
                    }}
                    dataArr={this.state.marketOpenDays}
                  />
                </div>
              )}

              {this.renderStockDeatilsComponent()}
            </div>

            <div>
                {this.state.infoIconStatus?
               <div className="main">
                  <div className="popup" role="dialog">
                  <div className="MuiDialogContent-root makeStyles-centerView css-ypiqx9-MuiDialogContent-root">
                       <img className="makeStyles-icon" style={{marginLeft: "44%"}}
                       src={Images.buyStocks.ic_marketClose}
                        alt="logo"/>
                    </div>
                      <p className="popup-header2">
                    Market is currently closed
                    </p>
                    
                    <div className="MuiDialogContent-root css-ypiqx9-MuiDialogContent-root">
                      <p className="popup-detail"> Will be applicable for orders placed during active market hours for same day fulfillment.Please use the next trading day option when placing orders after trading hours to be 
                        fulfilled immediately on the next market opening.</p>
                    </div>
                    {/* <hr className="MuiDivider-root MuiDivider-fullWidth css-9mgopn-MuiDivider-root"></hr> */}
                    
                    <p  onClick={this.closePopup} className="close-text2">
                    CLOSE</p>
                   </div>
                  </div> :""}
              </div> 

            <div className="buttonContainer">
              <button
                className="bs-btn-transaction"
                disabled={
                  (invalidQty && invaldPrice) ||
                  noValueValidation ||
                  validDecimalEnteredErrorBelowHundrad ||
                  validDecimalEnteredErrorAboveHundrad
                }
                onClick={() => {
                  this.buttonOnPress();
                }}
              >
                {"NEXT"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedStock: state.BuyStockReducer.selectedStock,
    sellStockPrice: state.BuyStockReducer.sellStockPrice,
    sellStockPriceDecimal: state.BuyStockReducer.sellStockPriceDecimal,
    noOfShares: state.BuyStockReducer.sellNumberOfShares,
    orderEditStatus: state.BuyStockReducer.buyOrderEditStatus,
    orderEditItem: state.BuyStockReducer.buyOrderEditItem,
    orderSelectedItemPrice: state.BuyStockReducer.orderEditItemPrice,
    orderSelectedItemQty: state.BuyStockReducer.orderEditItemQty,
    orderSelectedItemPriceDecimal:
      state.BuyStockReducer.orderEditItemPriceDecimal,
    bottomSheetStatus: state.BuyStockReducer.bottomSheetStatus,
    securityStat: state.BuyStockReducer.securityStat,
    marketOpenDays: state.CommonReducer.marketOpenDays,
    marketStatus: state.BuyStockReducer.marketStatus,
    sellEditShareAmount: state.BuyStockReducer.sellEditShareAmount,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSellStockPrice: (data) => dispatch(stockActions.setSellStockPrice(data)),
    SetSellNumberOfShares: (data) =>
      dispatch(stockActions.SetSellNumberOfShares(data)),
    setSellStockDuration: (data) =>
      dispatch(stockActions.setSellStockDuration(data)),
    getSellCommission: (callback) =>
      dispatch(stockActions.getSellCommission(callback)),
    setSelectedOrderItemQuantity: (data) =>
      dispatch(stockActions.setSelectedOrderItemQuantity(data)),
    setSelectedOrderItemPrice: (data) =>
      dispatch(stockActions.setSelectedOrderItemPrice(data)),
    setSelectedOrderItemDuration: (data) =>
      dispatch(stockActions.setSelectedOrderItemDuration(data)),
    setSellStockPriceDecimal: (data) =>
      dispatch(stockActions.setSellStockPriceDecimal(data)),
    setSelectedOrderItemPriceDecimal: (data) =>
      dispatch(stockActions.setSelectedOrderItemPriceDecimal(data)),
    setBottomSheetStatus: (data) =>
      dispatch(stockActions.setBottomSheetStatus(data)),
    getMarketOpenDays: (callback) => dispatch(getMarketOpenDays(callback)),
    getMarketStatus: () => dispatch(getMarketStatus()),
    triggerEditSellShareAmount: (payload) =>
      dispatch(stockActions.triggerEditSellShareAmount(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SellStock);
