import React from "react";
import { connect } from "react-redux";
import COLORS from "../../Assets/Colors";
import { withStyles } from "@mui/styles";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  Slide,
} from "@mui/material";
import Constants from "../../Assets/Constants";
import {
  setOTPCounter,
  setRemainingTimeForOTP,
} from "../../Redux/Actions/KycActions";
import * as ComponentActions from "../../Redux/Actions/ComponentActions";
import history from "../../Navigation/Navigation";

const styles = () => ({
  title: {
    fontFamily: "Open Sans !important",
    fontSize: "1.15em !important",
    fontWeight: "bold !important",
    color: `${COLORS.BLACK} !important`,
    textAlign: "center !important",
    lineHeight: "1.56em !important",
    marginBottom: "-1em !important",
  },
  description: {
    fontFamily: "Open Sans !important",
    fontSize: "0.825em !important",
    color: `${COLORS.BLACK} !important`,
    textAlign: "center !important",
    lineHeight: "1.56em !important",
  },
  tryAgainTxt: {
    fontFamily: "Open Sans !important",
    fontSize: "0.78em !important",
    color: `${COLORS.BATTLESHIP_GREY} !important`,
    textAlign: "center !important",
    marginBottom: "-1.5em !important",
    marginTop: "0.4em !important",
  },
  centerView: {
    fontFamily: "Open Sans !important",
    fontSize: "0.874em !important",
    fontWeight: "normal !important",
    alignSelf: "center !important",
    textAlign: "center !important",
    marginLeft: "6% !important",
    marginRight: "6% !important",
  },
  dialogPaper: {
    borderRadius: "16px !important",
    width: "343px !important",
  },
  icon: {
    height: "37px !important",
    width: "30px !important",
    marginBottom: "-2em !important",
  },
  timerView: {
    display: "flex !important",
    flex: 1,
    flexDirection: "row !important",
    alignItems: "center !important",
    justifyContent: "center !important",
  },
  timeBX: {
    display: "flex !important",
    flexDirection: "row !important",
    alignItems: "flex-end !important",
    justifyContent: "flex-end !important",
  },
  timeTxt: {
    fontFamily: "Open Sans !important",
    fontSize: "1.6em !important",
    fontWeight: "600 !important",
    color: `${COLORS.GENIE_ORANGE} !important`,
    marginRight: ".2em !important",
  },
  dividerTxt: {
    fontFamily: "Open Sans !important",
    fontSize: "1.6em !important",
    fontWeight: "600 !important",
    color: `${COLORS.GENIE_ORANGE} !important`,
    marginRight: ".4em !important",
  },
  timeAnnotationTxt: {
    fontFamily: "Open Sans !important",
    fontSize: "1em !important",
    color: `${COLORS.GENIE_ORANGE} !important`,
    marginRight: ".4em !important",
    marginBottom: "3px !important",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class TimerPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hr: 0,
      min: 0,
      sec: 0,
    };
  }

  renderTopIcon = (popUpType) => {
    switch (popUpType) {
      case Constants.popUp.KYC_OTP_BLOCKED:
        return require("../../Assets/Images/KYC/blockedIcon.svg").default;
      case Constants.popUp.KYC_OTP_REQUESTED:
        return require("../../Assets/Images/KYC/blockedIcon.svg").default;
      default:
        break;
    }
  };

  renderRemainingTime = () => {
    let interval = setInterval(() => {
      const { timerPopUp } = this.props;
      if (timerPopUp.remainingTime <= 0) {
        clearInterval(interval);
        this.props.clearTimerPopUp();
        // this.props.history.push("user-validation");
        history.push("stocks-mobile-app#/user-validation");
      }
      let hr = Math.floor(timerPopUp.remainingTime / 3600);
      let min = Math.floor((timerPopUp.remainingTime % 3600) / 60);
      let sec = Math.floor((timerPopUp.remainingTime % 3600) % 60);

      this.props.setOTPCounter({ hr: hr, min: min, sec: sec });
      this.props.setRemainingTimeForOTP(timerPopUp.remainingTime - 1);
    }, 1000);
  };

  renderTimer = () => {
    const { classes, otpTimer } = this.props;
    return (
      <div className={classes.timerView}>
        <div className={classes.timeBX}>
          <div className={classes.timeTxt}>{otpTimer.hr}</div>
          <div className={classes.timeAnnotationTxt}>{"h"}</div>
        </div>
        <div className={classes.dividerTxt}>{" : "}</div>
        <div className={classes.timeBX}>
          <div className={classes.timeTxt}>{otpTimer.min}</div>
          <div className={classes.timeAnnotationTxt}>{"m"}</div>
        </div>
        <div className={classes.dividerTxt}>{" : "}</div>
        <div className={classes.timeBX}>
          <div className={classes.timeTxt}>{otpTimer.sec}</div>
          <div className={classes.timeAnnotationTxt}>{"s"}</div>
        </div>
      </div>
    );
  };

  render() {
    const { classes, timerPopUp } = this.props;

    return (
      <div>
        <Dialog
          open={timerPopUp.visibility}
          TransitionComponent={Transition}
          classes={{ paper: classes.dialogPaper }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          onFocus={() => this.renderRemainingTime()}
        >
          <DialogContent className={classes.centerView}>
            <img
              src={this.renderTopIcon(timerPopUp.popUpType)}
              className={classes.icon}
              alt="logo"
            />
          </DialogContent>
          <DialogContent style={{ alignSelf: "center" }}>
            <DialogContentText className={classes.title}>
              {timerPopUp.title}
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={classes.description}
            >
              {timerPopUp.decription}
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={classes.tryAgainTxt}
            >
              {"Try again after,"}
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={classes.centerView}
            >
              {timerPopUp.remainingTime >= 0 ? this.renderTimer() : null}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export function mapDispatchToProps(dispatch) {
  return {
    setRemainingTimeForOTP: (payload) =>
      dispatch(setRemainingTimeForOTP(payload)),
    setOTPCounter: (payload) => dispatch(setOTPCounter(payload)),
    clearTimerPopUp: (payload) =>
      dispatch(ComponentActions.clearTimerPopUp(payload)),
  };
}

function mapStateToProps(state) {
  return {
    timerPopUp: state.ComponentReducer.timerPopUp,
    otpTimer: state.ComponentReducer.otpTimer,
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(TimerPopUp)
);
