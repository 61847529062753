import React from "react";
import { connect } from "react-redux";
import "./TransactionSuccessStyles.scss";
import { Images } from "../../../Theme/Images";
import { fixDecimalPoints } from "../../../Utils/commonFunctions";
import VerticalTextPair from "../../../Components/VerticalTextPair/VerticalTextPair";

class TransactionSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      transactionInfo: {},
    };
  }

  componentDidMount() {
    setTimeout(() => {
      let amount = localStorage.getItem("amount");
      this.setState({
        amount: amount,
        transactionInfo: this.props.transactionDetails
          ? JSON.parse(this.props.transactionDetails)
          : {},
      });
    }, 1000);
  }

  numberWithCommas = (x) => {
    if (x) {
      let srt = x.substr(0, x.indexOf(".")) ? x.substr(0, x.indexOf(".")) : x;
      const value = srt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return value;
    } else {
      return null;
    }
  };

  buttonOnPress = () => {
    this.props.history.push("dashboard");
  };

  getTopStyle = () => {
    return {
      fontFamily: "Open Sans",
      fontSize: "0.874em",
      fontWeight: "normal",
      color: "#333333",
    };
  };

  getBottomStyle = () => {
    return {
      fontFamily: "Open Sans",
      fontSize: "0.874em",
      fontWeight: "bold",
      color: "#333333",
    };
  };

  render() {
    return (
      <div className="add-money-processing-main">
        <div className="add-money-processing-title">
          {"Transaction successful"}
        </div>
        <img
          src={Images.addMoney.anm_success}
          alt="img"
          className="add-money-processing-img2"
        />
        <div className="add-money-processing-description-with-padding">
          {"You have added money to your trading account"}
        </div>
        <div className="add-money-processing-description-amnt-txt">
          {`Amount (LKR)`}
        </div>

        <div className="ts-price-row">
          <span className="ts-price">
            {this.state.amount
              ? this.numberWithCommas(
                  this.state.amount ? this.state.amount : "00"
                )
              : ""}
          </span>
          <span className="ts-decimal">
            .
            {this.state.amount
              ? fixDecimalPoints(this.state.amount.split(".")[1])
              : ""}
          </span>
          <h3 className="ts-input-text">
            Using,{" "}
            {this.state.transactionInfo
              ? this.state.transactionInfo.paidSof
              : ""}{" "}
            :{" "}
            {this.state.transactionInfo
              ? this.state.transactionInfo.maskedAccountNumber
              : ""}
          </h3>
        </div>

        <div className="add-divider2" />
        <div className="add-bottom-description1">
          {"Your buying power will get updated within 1 working day."}
        </div>
        <div className="add-bottom-description2">
          {`You will receive a confirmation email from Softlogic Stockbrokers (Pvt) Ltd`}
        </div>
        <div>
          <VerticalTextPair
            topValue="Transaction ID"
            bottomValue={
              this.state.transactionInfo &&
              this.state.transactionInfo.merchantRequestId
                ? this.state.transactionInfo.merchantRequestId
                : ""
            }
            topStyle={this.getTopStyle()}
            bottomStyle={this.getBottomStyle()}
          />
        </div>
        <div className="add-money-processing-btnView">
          <button
            className="add-btn-transaction"
            onClick={() => {
              this.buttonOnPress();
            }}
          >
            <div className="add-money-processing-btn-txt">{"OK"}</div>
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    transactionDetails: state.AddMoneyReducer.commonPaymentStatus.data,
  };
};
const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionSuccess);
