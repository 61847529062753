import { put, call, select, delay } from "redux-saga/effects";
import * as types from "../Actions";
import {
  getCompanyListService,
  getSnpListService,
  getTopGainerLoosersService,
  getMarketOpenDaysService,
  kycInitiationService,
  cdsAccountValidationService,
} from "../Services/commonService";
import { selectedBroker } from "../Selectors/commonSelector";
import { getCustomerStatusService } from "../Services/addMoneyService";
import Constants from "../../Assets/Constants";
import { storeCustomerStatus } from "../Actions/commonAction";

export function* getCompanyListSaga() {
  yield delay(0);
  const access_Token = sessionStorage.getItem("token");
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(getCompanyListService, access_Token);
    if (response && response.data) {
      yield put({ type: types.GET_COMPANY_LIST_SUCCESS, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
    } else {
      yield put({ type: types.GET_COMPANY_LIST_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.GET_COMPANY_LIST_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* getSnpListSaga(action) {
  yield delay(0);
  const access_Token = sessionStorage.getItem("token");
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(getSnpListService, access_Token);
    if (response.data.STATUS === "ACCEPTED") {
      yield put({ type: types.GET_SNP_LIST_SUCCESS, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      if (action.callback) {
        action.callback();
      }
    } else {
      yield put({ type: types.GET_SNP_LIST_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.GET_SNP_LIST_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* getTopgainersLoosersSaga(action) {
  yield delay(0);
  const access_Token = sessionStorage.getItem("token");
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(getTopGainerLoosersService, access_Token);
    let data = response.data.DATA;
    let topGainers = [];
    let topLoosers = [];
    if (response.data.STATUS === "ACCEPTED") {
      data.forEach((element) => {
        if (Math.sign(element.netChangeConverted) > 0) {
          topGainers.push(element);
        } else if (Math.sign(element.netChangeConverted) < 0) {
          topLoosers.push(element);
        }
      });
      yield put({ type: types.GET_TOP_GAINERS_SUCCESS, topGainers });
      yield put({ type: types.GET_TOP_LOOSERS_SUCCESS, topLoosers });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.TOP_GAINERS_FAIL, payload: false });
    } else {
      yield put({ type: types.GET_TOP_GAINERS_FAIL, response });
      yield put({ type: types.GET_TOP_LOOSERS_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.TOP_GAINERS_FAIL, payload: true });
    }
  } catch (error) {
    yield put({ type: types.GET_TOP_GAINERS_FAIL, response: error });
    yield put({ type: types.GET_TOP_LOOSERS_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.TOP_GAINERS_FAIL, payload: true });
  }
  action.callback();
}

export function* getMarketOpenDaysSaga(action) {
  yield delay(0);
  const access_Token = sessionStorage.getItem("token");
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(getMarketOpenDaysService, access_Token);
    if (
      response &&
      response.data.STATUS === "ACCEPTED" &&
      response.data !== null &&
      response.data.DATA !== null
    ) {
      yield put({
        type: types.GET_MARKET_OPEN_DAYS_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
    } else {
      yield put({ type: types.GET_MARKET_OPEN_DAYS_FAILED, payload: response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
    }
  } catch (error) {
    yield put({ type: types.GET_MARKET_OPEN_DAYS_FAILED, payload: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
  }
  action.callback();
}

export function* cdsAccountValidationSaga(action) {
  const selected_broker = yield select(selectedBroker);
  let token = action.payload;

  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(
      cdsAccountValidationService,
      selected_broker,
      token
    );
    if (response.data.STATUS === "ACCEPTED") {
      yield put({
        type: types.CDS_ACCOUNT_VALIDATION_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      action.callback();
    } else {
      yield put({ type: types.CDS_ACCOUNT_VALIDATION_FAIL, payload: response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.CDS_ACCOUNT_VALIDATION_FAIL, payload: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* kycInitiationSaga(action) {
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(kycInitiationService);
    if (response.data.DATA.STATUS === "SUCCESS") {
      yield put({
        type: types.COMMON_KYC_INITIATION_SUCCESS,
        payload: response,
      });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      if (
        response &&
        response.data &&
        response.data.DATA &&
        response.data.DATA.STATUS === "SUCCESS"
      ) {
        window.ReactNativeWebView.postMessage(
          `https://apps.finpal.lk/api/tokenization?kycToken=${response.data.DATA.DATA}&product=stocks`
        );
      }
      // action.callback();
    } else {
      yield put({ type: types.COMMON_KYC_INITIATION_FAIL, payload: response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.COMMON_KYC_INITIATION_FAIL, payload: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* getCustomerStatusSaga() {
  try {
    yield delay(0);
    const access_Token = sessionStorage.getItem("token");
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(getCustomerStatusService, access_Token);
    if (
      response &&
      response.data &&
      response.data.DATA &&
      response.data.STATUS &&
      response.data.STATUS === Constants.RESPONSES.TX_SUCCESS
    ) {
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put(storeCustomerStatus(response.data.DATA.stockStatus));
    } else {
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}
