import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import PullToRefresh from "react-simple-pull-to-refresh";
import Layout from "../../Layout/Layout";
import "./AlreadyExistsStyles.scss";
import BodyContainer from "../../../Components/BodyArea/Body";
import { Images } from "../../../Theme/Images";
import { genieLogoStyle } from "../../../Utils/commonFunctions";

class AlreadyExists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openBottomSheet: false,
      invaldPrice: false,
      invalidQty: false,
    };
  }

  buttonOnPress = () => {
    window.ReactNativeWebView.postMessage("Exit");
  };

  onLeftIconClick = () => {
    this.props.history.push("/");
  };

  render() {
    return (
      <Fragment>
        <Layout
          leftIcon={Images.common.ic_arrow_back}
          headerContentLeft="Stock trading"
          headerContentRight=""
          rightIcon={Images.common.img_genie_logo}
          rightIconLink="/"
          rightIconStyle={genieLogoStyle}
          handleRightIconClick={() => this.handleRightOnClick()}
          showHeader={this.state.showHeader}
          handleLeftIconClick={() => this.onLeftIconClick()}
        >
          <BodyContainer>
            <PullToRefresh
              onRefresh={() => window.location.reload()}
              refreshingContent=" "
              pullingContent=" "
            >
              <header className="App-container" data-testid="bs-container">
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      margin: "auto",
                      width: "90%",
                      justifySelf: "center",
                      textAlign: "left",
                      marginTop: "10%",
                    }}
                  >
                    <span className="ae-text-msg">
                      We noticed that you already have a CDS account with
                    </span>
                    <span className="ae-bold-text">
                      Softlogic Stockbrokers (Pvt) Ltd
                    </span>
                  </div>

                  <div
                    style={{
                      margin: "auto",
                      width: "90%",
                      justifySelf: "center",
                      textAlign: "left",
                      marginTop: "10%",
                    }}
                  >
                    <span className="ae-text-msg">
                      Currently we are unable to connect to your existing CDS
                      account. We will notify you when this feature is
                      available.
                    </span>
                  </div>
                  <div
                    style={{
                      position: "fixed",
                      bottom: "3%",
                      left: 0,
                      right: 0,
                    }}
                  >
                    <button
                      data-testid="button-container"
                      style={{
                        margin: 0,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "5%",
                        height: "48px",
                      }}
                      className="btn-transaction remove-outline"
                      onClick={() => {
                        this.buttonOnPress();
                      }}
                    >
                      EXIT TO HOME
                    </button>
                  </div>
                </div>
              </header>
            </PullToRefresh>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AlreadyExists);
