import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import Layout from "../../Layout/Layout";
import "./UserValidation.scss";
import BodyContainer from "../../../Components/BodyArea/Body";
import { Images } from "../../../Theme/Images";
import * as commonActions from "../../../Redux/Actions/commonAction";
import { genieLogoStyle } from "../../../Utils/commonFunctions";
import * as KycActions from "../../../Redux/Actions/KycActions";

class UserValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAccountButtonStatus: true,
      token: "",
      sesToken: "",
    };
  }

  buttonOnPress = () => {
    let payload = {
      brokerName: this.props.selectedBroker,
      OTPnavigation: () => {
        this.props.history.push("kycotpVerificationScreen");
      },
    };
    this.props.triggerKycOTP(payload);
  };

  onLeftIconClick = () => {
    const { customerStatus } = this.props;
    if (customerStatus && customerStatus === "KYC_REJECTED_BY_CSE") {
      window.ReactNativeWebView.postMessage("Exit");
    } else {
      this.props.history.push("/");
    }
  };

  componentDidMount() {
    const { getCustomerStatus } = this.props;
    setTimeout(() => {
      getCustomerStatus();
    }, 500);
  }

  render() {
    return (
      <Fragment>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft="Stock trading"
          headerContentRight=""
          rightIcon={Images.common.img_genie_logo}
          rightIconLink="/"
          rightIconStyle={genieLogoStyle}
          handleLeftIconClick={() => this.onLeftIconClick()}
        >
          <BodyContainer>
            <header className="App-container" data-testid="app-container">
              <div style={{ width: "100%" }}>
                <div className="user-validation-messageTemplate">
                  <span>Your account will be created with</span>
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Softlogic Stockbrokers (Pvt) Ltd
                  </span>
                  <span style={{ marginLeft: "2%" }}>
                    who are licensed by the Securities and Exchange commission
                    of Sri Lanka (SEC)
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10%",
                  }}
                >
                  <img
                    src={Images.brokers.img_softlogic}
                    alt="logo"
                    className="uv-img"
                  />
                </div>

                <div
                  className="row no-padding"
                  style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
                >
                  <div className="col-12">
                    <button
                      className="btn-transaction remove-outline"
                      onClick={() => {
                        this.buttonOnPress();
                      }}
                    >
                      CONTINUE
                    </button>
                  </div>
                </div>
              </div>
            </header>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAccountvAvailability: state.UserValidationReducer.accountAvailable,
    brokerList: state.UserValidationReducer.brokerList,
    selectedBroker: state.UserValidationReducer.selectedBroker,
    cdsAccountAvailability: state.CommonReducer.cdsAccountValidation,
    kycInit: state.CommonReducer.kycInitiation,
    customerStatus: state.CommonReducer.customerStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    commonKYCInitiation: (callback) =>
      dispatch(commonActions.commonKYCInitiation(callback)),
    triggerKycOTP: (payload) => dispatch(KycActions.triggerKycOTP(payload)),
    getCustomerStatus: () => dispatch(commonActions.getCustomerStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserValidation);
