import React from "react";
import Routes from "./Navigation/Routers";
import "./App.scss";

function App() {
  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
}

export default App;   
