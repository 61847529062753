import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import PullToRefresh from "react-simple-pull-to-refresh";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import Layout from "../../Layout/Layout";
import "./AccountSummaryStyles.scss";
import BodyContainer from "../../../Components/BodyArea/Body";
import { fixDecimalPoints } from "../../../Utils/commonFunctions";
import InfoComponent from "../../../Components/InfoComponent/InfoComponent";
import { Divider } from "@mui/material";

class AccountSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoStatus: false,
      infoTitle: "",
      infoText: "",
    };
  }

  numberWithCommas = (x) => {
    if (x) {
      let convert = x.toString()
      let srt =   convert.split(".")[0];
      if (srt.includes("-")) {
        return 0;
      } else {
        const value = srt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return value;
      }
    } else {
      return 0;
    }
  };

  numberWithCommasWithoutNegative = (x) => {
    if (x) {
      let convert = x.toString()
      let srt =   convert.split(".")[0];
      const value = srt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return value;
    } else {
      return 0;
    }
  };

  onLeftIconClick = () => {
    this.props.history.push("dashboard");
  };

  convertDecimal = (value) => {
    if (value) {
      if (value.includes("-")) {
        return "00";
      } else {
        if (JSON.stringify(Number(value)).includes(".")) {
          return fixDecimalPoints(
            JSON.stringify(Number(value)).split(".").pop()
          );
        } else {
          return "00";
        }
      }
    } else {
      return "00";
    }
  };

  convertDecimalWithoutNegative = (value) => {
    if (value) {
      if (JSON.stringify(Number(value)).includes(".")) {
        return fixDecimalPoints(JSON.stringify(Number(value)).split(".").pop());
      } else {
        return "00";
      }
    } else {
      return "00";
    }
  };

  getInfoText = (status) => {
    this.setState({
      infoStatus: true,
    });
    let infoTitle = "";
    let infoText = "";
    switch (status) {
      case 0:
        infoTitle = "Total cost of the portfolio";
        infoText =
          "The cost of acquiring the current portfolio of the customer";
        break;
      case 1:
        infoTitle = "Total market value of the portfolio";
        infoText =
          "The value of the customer’s current portfolio at the current market price";
        break;
      case 2:
        infoTitle = "Total gain / loss";
        infoText =
          "The difference between Market value of the portfolio and the cost of the portfolio";
        break;
      case 3:
        infoTitle = "Cash balance";
        infoText =
          "Value of funds available for the customer to withdraw as cash (Cash Balance – Unsettled cash – pending buy order value)";
        break;
      case 4:
        infoTitle = "Available to withdraw";
        infoText =
          "Value of funds available for the customer to withdraw as cash (Cash Balance – Unsettled cash – pending buy order value)";
        break;
      case 5:
        infoTitle = "Total pending buy orders value";
        infoText =
          "Total value of the active pending buy orders placed by the customers";
        break;
      default:
        break;
    }

    this.setState({
      infoTitle: infoTitle,
      infoText: infoText,
    });
  };

  handleClose = () => {
    this.setState({
      infoStatus: false,
    });
  };

  getAccountSummaryDetail = (textValue, value, infoIndex, dividerValue) => {
    return (
      <div>
        <div className="as-row">
          <div className="column-6">
            <span className="as-header-text-card">{textValue}</span>
          </div>
          <div className="column-1">
            <span className="as-header-text-card">:</span>
          </div>

          <div
            className="column-5"
            style={{ textAlign: "right", paddingRight: "30px" }}
          >
            <span className="as_header_lkrText">LKR </span>
            <span className="as_header_priceText">
              {value ? this.numberWithCommas(value) : "0"}
            </span>
            <span className="as_header_decimalText">
              .{value ? this.convertDecimal(value) : "00"}
            </span>
          </div>
        </div>
        {dividerValue && <Divider variant="middle" />}
      </div>
    );
  };

  getAccountSummaryDetailWithoutNegative = (
    textValue,
    value,
    infoIndex,
    dividerValue
  ) => {
    return (
      <div>
        <div className="as-row">
          <div className="column-6">
            <span className="as-header-text-card">{textValue}</span>
          </div>
          <div className="column-1">
            <span className="as-header-text-card">:</span>
          </div>

          <div
            className="column-5"
            style={{ textAlign: "right", paddingRight: "30px" }}
          >
            <span className="as_header_lkrText">LKR </span>
            <span className="as_header_priceText">
              {value ? this.numberWithCommasWithoutNegative(value) : "0"}
            </span>
            <span className="as_header_decimalText">
              .{value ? this.convertDecimalWithoutNegative(value) : "00"}
            </span>
          </div>
        </div>
        {dividerValue && <Divider variant="middle" />}
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft="Account summary"
          headerContentRight=""
          rightIcon=""
          rightIconLink=""
          leftIconLink="dashboard"
          handleLeftIconClick={() => this.onLeftIconClick()}
        >
          <BodyContainer>
            <PullToRefresh
              onRefresh={() => window.location.reload()}
              refreshingContent=" "
              pullingContent=" "
            >
              <header className="App-container" data-testid="as-container">
                <div style={{ width: "100%" }}>
                  <InfoComponent
                    bottomSheetStatus={this.state.infoStatus}
                    dropdownTitle={this.state.infoTitle}
                    dropdownText={this.state.infoText}
                    handleOnClose={() => this.handleClose()}
                  />

                  <div className="row">
                    <div className="col-7">
                      <span className="as-header-text-text">
                        {this.props.accounrSummary.accountNumber
                          ? this.props.accounrSummary.accountNumber
                          : "Not Available"}
                      </span>
                    </div>
                  </div>
                  {this.getAccountSummaryDetail(
                    "Total cost of the portfolio",
                    this.props.accounrSummary.totalPortfolioCost,
                    0,
                    true
                  )}
                  {this.getAccountSummaryDetail(
                    "Total market value of the portfolio",
                    this.props.dashboardData.portfolioValue,
                    1,
                    true
                  )}
                  {this.getAccountSummaryDetailWithoutNegative(
                    "Total gain / loss",
                    this.props.accounrSummary.totalGainLoss,
                    2,
                    true
                  )}
                  {this.getAccountSummaryDetail(
                    "Cash balance",
                    this.props.accounrSummary.cashBalance,
                    3,
                    true
                  )}
                  {this.getAccountSummaryDetailWithoutNegative(
                    "Available to withdraw",
                    this.props.accounrSummary.cashAvailableForWithdrawal,
                    4,
                    true
                  )}
                  {this.getAccountSummaryDetail(
                    "Total pending buy orders value",
                    this.props.accounrSummary.totalPendingBuyOrderValue,
                    5,
                    true
                  )}
                </div>
              </header>
            </PullToRefresh>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accounrSummary: state.DashboardReducer.accountSummary,
    dashboardData: state.DashboardReducer.dashboard,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSummary);
