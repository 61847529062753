import * as types from "../Actions";

export const initailState = {
  companyList: [],
  snpList: [],
  topGainers: [],
  topLoosers: [],
  userToken:
    "Bearer eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MjE0MTUyOTUsImV4cCI6MTYyMTc2MDg5NSwic3ViIjoiNzE1ODk2NzU2OjE5NjIwODMwNTA1NiIsImlzcyI6InN0b2Nrcy1hdXRoLXNlcnZpY2UuY29tIiwicm9sZXMiOltdfQ.F2eB9NVG5XTw2anVSWwcEvo5OJCEhC43fp42WhQovzk",
  showLoader: false,
  showAlertDialog: false,
  sellStockList: [],
  dialogTitle: "",
  dialogMessage: "",
  dialogPrimaryButton: "",
  dialogSecondaryButton: "",
  dialogIcon: "",
  errorPopUp: false,
  alertPopup: false,
  topGainerFail: false,
  securityStatFail: false,
  marketDepthFail: false,
  marketOpenDays: [],
  cdsAccountValidation: {},
  kycInitiation: {},
  customerStatus: "",
};

export default function (state = initailState, action) {
  let response = action.response;
  switch (action.type) {
    case types.GET_COMPANY_LIST_SUCCESS:
      return { ...state, companyList: response.data };
    case types.GET_COMPANY_LIST_FAIL:
      return { ...state, dashboard: [] };
    case types.GET_SNP_LIST_SUCCESS:
      return { ...state, snpList: response.data.DATA };
    case types.GET_SNP_LIST_FAIL:
      return { ...state, snpList: [] };
    case types.GET_TOP_GAINERS_SUCCESS:
      return { ...state, topGainers: action.topGainers };
    case types.GET_TOP_GAINERS_FAIL:
      return { ...state, topGainers: [] };
    case types.GET_TOP_LOOSERS_SUCCESS:
      return { ...state, topLoosers: action.topLoosers };
    case types.GET_TOP_LOOSERS_FAIL:
      return { ...state, topLoosers: [] };
    case types.GET_SELL_STOCK_LIST_SUCCESS:
      return { ...state, sellStockList: response.data.DATA };
    case types.GET_SELL_STOCK_LIST_FAIL:
      return { ...state, sellStockList: [] };

    case types.FULL_SCREEN_LOADER:
      return { ...state, showLoader: action.payload.visibility };
    case types.SHOW_ALERT_DIALOG:
      return {
        ...state,
        showAlertDialog: action.payload.visibility,
        dialogMessage: action.payload.dialogMessage,
        dialogPrimaryButton: action.payload.dialogPrimaryButton,
        dialogSecondaryButton: action.payload.dialogSecondaryButton,
        dialogTitle: action.payload.dialogTitle,
        dialogIcon: action.payload.dialogIcon,
      };
    case types.HIDE_ALERT_DIALOG:
      return {
        ...state,
        showAlertDialog: false,
        dialogMessage: "",
        dialogPrimaryButton: "",
        dialogSecondaryButton: "",
        dialogTitle: "",
        dialogIcon: "",
      };
    case types.SHOW_ERROR_POPUP:
      return {
        ...state,
        errorPopUp: true,
      };
    case types.HIDE_ERROR_POPUP:
      return {
        ...state,
        errorPopUp: false,
      };
    case types.SHOW_ALERT_POPUP:
      return {
        ...state,
        alertPopup: true,
      };
    case types.HIDE_ALERT_POPUP:
      return {
        ...state,
        alertPopup: false,
      };
    case types.TOP_GAINERS_FAIL:
      return {
        ...state,
        topGainerFail: action.payload,
      };
    case types.SECURITY_STAT_FAIL:
      return {
        ...state,
        securityStatFail: action.payload,
      };
    case types.MARKET_DEPTH_FAIL:
      return {
        ...state,
        marketDepthFail: action.payload,
      };
    case types.GET_MARKET_OPEN_DAYS_SUCCESS:
      return { ...state, marketOpenDays: action.payload };
    case types.GET_MARKET_OPEN_DAYS_FAILED:
      return { ...state, marketOpenDays: [] };
    case types.CDS_ACCOUNT_VALIDATION_SUCCESS:
      return { ...state, cdsAccountValidation: action.payload.DATA };
    case types.CDS_ACCOUNT_VALIDATION_FAIL:
      return { ...state, cdsAccountValidation: {} };
    case types.COMMON_KYC_INITIATION_SUCCESS:
      return { ...state, kycInitiation: action.payload.data.DATA };
    case types.COMMON_KYC_INITIATION_FAIL:
      return { ...state, kycInitiation: {} };
    case types.STORE_CUSTOMER_STATUS:
      return { ...state, customerStatus: action.payload };
    default:
      return state;
  }
}
