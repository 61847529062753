const Constants = {
  popUp: {
    KYC_OTP_BLOCKED: "KYC_OTP_BLOCKED",
    KYC_OTP_REQUESTED: "KYC_OTP_REQUESTED",
  },
  RESPONSES: {
    TX_SUCCESS: "TX_SUCCESS",
    ACCEPTED: "ACCEPTED",
  },
  COMMON_API: {
    MARKET_OPENING_HOURS: "MARKET_OPENING_HOURS",
  },
  TX_COMMON_PAYMENT_FAILED: "TX_COMMON_PAYMENT_FAILED",
  TX_COMMON_PAYMENT_TIMEOUT: "TX_COMMON_PAYMENT_TIMEOUT",
  SUCCESS: "SUCCESS",
};

export default Constants;
