import React from "react";
import { connect } from "react-redux";
import "./AddMoneyProcessStyles.scss";
import { Images } from "../../../Theme/Images";
import * as addMoneyActions from "../../../Redux/Actions/addMoneyAction";
import Constants from "../../../Assets/Constants";
class AddMoneyProcessing extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      let token = localStorage.getItem("token");
      sessionStorage.setItem("token", token);

      let requestId = localStorage.getItem("requestId");

      this.props.setAddMoneyInitiateState(true);
      let count = 1;
      this.processingInterval = setInterval(() => {
        count += 1;
        if (count > 10) {
          clearInterval(this.processingInterval);
          this.props.history.push("dashboard");
        } else {
          this.props.commonPaymentModuleStatus(requestId, () => {
            if (
              this.props.commonPaymentStatus &&
              this.props.commonPaymentStatus.code
            ) {
              clearInterval(this.processingInterval);
              switch (this.props.commonPaymentStatus.code) {
                case Constants.RESPONSES.TX_SUCCESS:
                  if (this.props.commonPaymentStatus.autoAddMoney) {
                    if (
                      this.props.commonPaymentStatus.atradTransactionStatus &&
                      this.props.commonPaymentStatus.atradTransactionStatus !==
                        Constants.SUCCESS
                    ) {
                      this.props.history.push("auto-transaction-process");
                    } else this.props.history.push("auto-transaction-success");
                  } else {
                    this.props.history.push("transaction-success");
                  }
                  break;
                case Constants.TX_COMMON_PAYMENT_FAILED:
                case Constants.TX_COMMON_PAYMENT_TIMEOUT:
                  this.props.history.push("transaction-fail");
                  break;
                default:
                  break;
              }
            }
          });
        }
      }, 3000);
    }, 1000);
  }

  numberWithCommas = (x) => {
    const value = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  };

  render() {
    return (
      <div className="add-money-processing-main">
        <div className="add-money-processing-title">{"Processing"}</div>
        <img
          src={Images.addMoney.anm_processing}
          alt="img"
          className="add-money-processing-img"
        />
        <div className="add-money-processing-description">
          {`Hold on we're processing your transaction`}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addMoneyAmount: state.AddMoneyReducer.addMoneyAmount,
    commonPaymentStatus: state.AddMoneyReducer.commonPaymentStatus,
    atradAddMoneyNew: state.AddMoneyReducer.atradAddMoney,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    commonPaymentModuleStatus: (data, callback) =>
      dispatch(addMoneyActions.commonPaymentModuleStatus(data, callback)),
    setAddMoneyInitiateState: (data) =>
      dispatch(addMoneyActions.setAddMoneyInitiateState(data)),
    atradAddMoney: (data, callback) =>
      dispatch(addMoneyActions.atradAddMoney(data, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMoneyProcessing);
