import React from "react";
import CircularProgressWithLabel from "./CircularProgressWithLabel";

const CircularStatic = (props) => {
  const {
    timerValue,
    size,
    thickness,
    ...rest
  } = props;
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 100 / timerValue
      );
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  });

  return (
    <CircularProgressWithLabel
      value={progress}
      size={size}
      thickness={thickness}
      {...rest}
    />
  );
};

export default CircularStatic;
