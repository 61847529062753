import React from "react";
import { HashRouter as Router, Switch } from "react-router-dom";
import ScrollToTop from "../Components/ScrollToTop/ScrollToTop";
import history from "../Navigation/Navigation";
import {
  NavigateToFundTranferDetailPage,  
  NavigateToAccountSummaryRoute,
  NavigateToAddMoneyRoute,
  NavigateToAddMoneyInitiateRoute,
  NavigateToAddMoneyProcessRoute,
  NavigateToAutoTransactionProcessingRoute,
  NavigateToTransactionFailRoute,
  NavigateToTransactionSuccessRoute,
  NavigateToBuyStocksRoute,
  NavigateToBuyStockConfirmationRoute,
  NavigateToBuyStockRoute,
  NavigateToKYCBlockedRoute,
  NavigateToExploreStocksRoute,
  NavigateToAlreadyExistsRoute,
  NavigateToGettingStartedRoute,
  NavigateToInvestmentsRoute,
  NavigateToInvestmentAdvisorRoute,
  NavigateToKYCSuccessRoute,
  NavigateToMarketSummaryRoute,
  NavigateToOrderListRoute,
  NavigateToOrderSuccessRoute,
  NavigateToSellStockRoute,
  NavigateToSellStockConfirmationRoute,
  NavigateToSellStocksRoute,
  NavigateToStockDashboardRoute,
  NavigateToStockDetailsRoute,
  NavigateToTopGainersRoute,
  NavigateToTopLoosersRoute,
  NavigateToUserValidationRoute,
  NavigateToUserVerificationRoute,
  NavigateToWithdrawMoneyRoute,
  NavigateToWithdrawMoneyConfirmRoute,
  NavigateToWithdrawMoneyOtpRoute,
  NavigateToKYCotpVerificationScreenRoute,
  NavigateToAutoTransactionSuccessRoute,
} from "../Navigation/Route";

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <ScrollToTop>
          <React.Fragment>
            <NavigateToAccountSummaryRoute />
            <NavigateToAddMoneyRoute />
            <NavigateToFundTranferDetailPage />
            <NavigateToAddMoneyInitiateRoute />
            <NavigateToAddMoneyProcessRoute />
            <NavigateToAutoTransactionProcessingRoute />
            <NavigateToTransactionFailRoute />
            <NavigateToTransactionSuccessRoute />
            <NavigateToBuyStockRoute />
            <NavigateToBuyStockConfirmationRoute />
            <NavigateToBuyStocksRoute />
            <NavigateToKYCBlockedRoute />
            <NavigateToExploreStocksRoute />
            <NavigateToAlreadyExistsRoute />
            <NavigateToGettingStartedRoute />
            <NavigateToInvestmentAdvisorRoute />
            <NavigateToInvestmentsRoute />
            <NavigateToKYCSuccessRoute />
            <NavigateToMarketSummaryRoute />
            <NavigateToOrderListRoute />
            <NavigateToOrderSuccessRoute />
            <NavigateToSellStockRoute />
            <NavigateToSellStockConfirmationRoute />
            <NavigateToSellStocksRoute />
            <NavigateToStockDashboardRoute />
            <NavigateToStockDetailsRoute />
            <NavigateToTopGainersRoute />
            <NavigateToTopLoosersRoute />
            <NavigateToUserValidationRoute />
            <NavigateToUserVerificationRoute />
            <NavigateToWithdrawMoneyRoute />
            <NavigateToWithdrawMoneyConfirmRoute />
            <NavigateToWithdrawMoneyOtpRoute />
            <NavigateToKYCotpVerificationScreenRoute />
            <NavigateToAutoTransactionSuccessRoute />
          </React.Fragment>
        </ScrollToTop>
      </Switch>
    </Router>
  );
};

export default Routes;
