import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import PullToRefresh from "react-simple-pull-to-refresh";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import Layout from "../../Layout/Layout";
import "./MarketSummaryStyles.scss";
import BodyContainer from "../../../Components/BodyArea/Body";
import { checkAccountAvailability } from "../../../Redux/Actions/userValidationAction";
import TabComponent from "../../../Components/TabComponent/TabComponent";
import GainersTabComponent from "./SubComponents/GainersTabComponents";
import { setSelectedStock } from "../../../Redux/Actions/buyStockListingAction";
import {
  getMarketDepth,
  getSecurityStat,
  getSellStatus,
} from "../../../Redux/Actions/stockAction";
import { Images } from "../../../Theme/Images";

class MarketSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fail: true,
      marketSummaryDetails: [],
    };
  }

  componentDidMount() {
    this.setState({ marketSummaryDetails: this.props.marketSummaryDetails });
  }

  gainersViewAllHandle(value) {
    if (value === 0) {
      this.props.history.push("top-gainers");
    } else if (value === 1) {
      this.props.history.push("top-loosers");
    }
  }

  onViewPressed(item) {
    this.props.setSelectedStock(item);
    this.props.getSecurityStat();
    this.props.getMarketDepth();
    this.props.getSellStatus();
    setTimeout(() => {
      this.props.history.push("stock-details");
    }, 100);
  }

  onLeftIconClick = () => {
    this.props.history.push("dashboard");
  };

  render() {
    return (
      <Fragment>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft="Market summary"
          headerContentRight=""
          rightIconLink="/"
          leftIconLink="dashboard"
          handleLeftIconClick={() => this.onLeftIconClick()}
        >
          <BodyContainer>
            <PullToRefresh
              onRefresh={() => window.location.reload()}
              refreshingContent=" "
              pullingContent=" "
            >
              <header className="App-container" data-testid="ms-container">
                <div style={{ width: "100%" }}>
                  <div className="card card-3">
                    <TabComponent data={this.state.marketSummaryDetails} />
                  </div>

                  <div className="card card-3">
                    {this.props.topGainerFail ? (
                      <div className="ms-error-card">
                        <img
                          src={Images.errImages.ic_error}
                          className="ms-error-icon"
                          alt="logo"
                        />
                        <h4 className="ms-error-text">Sorry </h4>
                        <h4 className="ms-error-text">something went wrong</h4>
                      </div>
                    ) : (
                      <GainersTabComponent
                        handleTabChange={(value) => {
                          this.gainersViewAllHandle(value);
                        }}
                        topGainers={
                          this.props.topGainers
                            ? this.props.topGainers.sort(function (a, b) {
                                return (
                                  b.netChangeConverted - a.netChangeConverted
                                );
                              })
                            : []
                        }
                        topLoosers={
                          this.props.topLoosers
                            ? this.props.topLoosers.sort(function (a, b) {
                                return (
                                  a.netChangeConverted - b.netChangeConverted
                                );
                              })
                            : []
                        }
                        onViewPressed={(item) => this.onViewPressed(item)}
                      />
                    )}
                  </div>
                </div>
              </header>
            </PullToRefresh>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    topGainers: state.CommonReducer.topGainers,
    topLoosers: state.CommonReducer.topLoosers,
    topGainerFail: state.CommonReducer.topGainerFail,
    marketSummaryDetails: state.BuyStockReducer.marketSummaryDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    checkAccountAvailability: (data) =>
      dispatch(checkAccountAvailability(data)),
    setSelectedStock: (data) => dispatch(setSelectedStock(data)),
    getMarketDepth: (data) => dispatch(getMarketDepth(data)),
    getSecurityStat: (data) => dispatch(getSecurityStat(data)),
    getSellStatus: () => dispatch(getSellStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketSummary);
