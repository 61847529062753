import * as Constants from "./constants";
export function calculateStyles(length) {
  let width = 0;
  let paddingTop = 6;
  let fontSize = 0;
  width = length * 2 + 12;
  paddingTop = 6.5 - length / 3;
  fontSize = 2 - length / 14;
  let calWidth = JSON.stringify(width) + "%";
  let calPaddingTop = JSON.stringify(paddingTop) + "%";
  let calFontSize = JSON.stringify(fontSize) + "em";
  return {
    paddingTop: calPaddingTop,
    fontSize: calFontSize,
    width: calWidth,
  };
}

export const removeLeadingZeroesForInteger = (value) => {
  return parseInt(value, 10);
};

export const removeLeadingZeroesForFloat = (value) => {
  return parseFloat(value, 10);
};

export function calculateWidth(length) {
  let width = 6;
  width = length * 6;
  let calWidth = JSON.stringify(width) + "%";
  return calWidth;
}

export function fixDecimalPoints(str) {
  if (str) {
    if (str.length === 1) {
      return str + "0";
    } else {
      return str.substring(0, 2);
    }
  } else if (str === "" || !str) {
    return "00";
  }
}

export function cleanMobileNumber(mobileNumber) {
  if (mobileNumber) {
    if (mobileNumber.length === 10 && mobileNumber.startsWith("0")) {
      return mobileNumber.substring(1, 10);
    } else if (mobileNumber.length === 9 && !mobileNumber.startsWith("0")) {
      return mobileNumber;
    } else {
    }
  } else {
  }
}

export function displayMobileNumber(mobileNumber) {
  return mobileNumber.replace(Constants.DISPLAY_MOBILE_NUMBER, "$1 $2 $3");
}

export function colorAccordingToStatus(status) {
  if (
    status === "CANCELED" ||
    status === "P.CANCELLED" ||
    status === "REJECTED" ||
    status === "EXPIRED" ||
    status === "Q.CANCEL"
  ) {
    return "#FF0000 ";
  } else if (status === "FILLED") {
    return "#00DA6E";
  } else {
    return "#FFBF00";
  }
}

export function setOredrStatus(status) {
  let newStatus = status.toLowerCase();
  if (status === "P.CANCELLED") {
    return "Pending Cancelation";
  } else if (status === "Q.AMEND") {
    return "Amendment Queued";
  } else if (status === "P.CHANGE") {
    return "Pending Change";
  } else if (status === "P.FILLED") {
    return "Partially Filled";
  } else if (status === "CANCELED") {
    return "Cancelled";
  } else if (status === "REJECTED") {
    return "Rejected";
  } else if (status === "Q.CANCEL") {
    return "Cancellation Queued";
  } else {
    return newStatus[0].toUpperCase() + newStatus.slice(1);
  }
}

export function setAmountValidation(value) {
  if (value) {
    let amount = value.substr(0, value.indexOf("."));
    let decimal = value.split(".").pop();

    switch (decimal) {
      case "25":
        return [amount + ".00", amount + ".25", amount + ".50"];
      case "50":
        return [amount + ".25", amount + ".50", amount + ".75"];
      case "75":
        return [amount + ".50", amount + ".75", parseFloat(amount + 1) + ".00"];
      default:
        return [];
    }
  } else {
    return "00";
  }
}

export const genieLogoStyle = () => {
  return {
    height: 54,
    width: 32,
  };
};
  