import React, { Fragment } from "react";
import { Route, withRouter } from "react-router-dom";
import AccountSummary from "../Features/Modules/AccountSummary/AccountSummary";
import AddMoney from "../Features/Modules/AddMoney/AddMoney";
import AddMoneyInitiate from "../Features/Modules/AddMoney/AddMoneyInitiate";
import AddMoneyProcessing from "../Features/Modules/AddMoney/AddMoneyProcessing";
import AutoTransactionProcessing from "../Features/Modules/AddMoney/AutoTransactionProcessing";
import AutoTransactionSuccess from "../Features/Modules/AddMoney/AutoTransactionSuccess";
import FundTransferDetailPage from "../Features/Modules/AddMoney/FundTransferDetailPage";
import TransactionFail from "../Features/Modules/AddMoney/TransactionFail";
import TransactionSuccess from "../Features/Modules/AddMoney/TransactionSuccess";
import BuyStocks from "../Features/Modules/BuyStocks/BuyStock";
import BuyStockConfirmation from "../Features/Modules/BuyStocks/BuyStockConfirmation";
import BuyStocksList from "../Features/Modules/BuyStocks/BuysStocksList";
import KYCBlocked from "../Features/Modules/ErrorPages/KYCBlocked";
import ExploreStocks from "../Features/Modules/ExploreStocks/ExploreStocks";
import AlreadyExists from "../Features/Modules/ErrorPages/AlreadyExists";
import GettingStarted from "../Features/Modules/GettingStarted/GettingStarted";
import InvestmentAdvisor from "../Features/Modules/Investments/InvestmentAdvisor";
import Investments from "../Features/Modules/Investments/Investments";
import KycSuccess from "../Features/Modules/KycSuccess/KycSuccess";
import MarketSummary from "../Features/Modules/MarketSummary/MarketSummary";
import OrderList from "../Features/Modules/Orders/OredrList";
import OrderSuccess from "../Features/Modules/OrderSuccess/OrderSuccess";
import SellStock from "../Features/Modules/SellStocks/SellStock";
import SellStockConfirmation from "../Features/Modules/SellStocks/SellStockConfirmation";
import SellStockList from "../Features/Modules/SellStocks/SellStockList";
import StockDashboard from "../Features/Modules/StockDashboard/StockDashboard";
import StockDetails from "../Features/Modules/StockDetails/StockDetails";
import TopLoosers from "../Features/Modules/TopLoosers/TopLoosers";
import TopGainers from "../Features/Modules/TopGainers/TopGainers";
import UserValidation from "../Features/Modules/UserValidation/UserValidation";
import UserVerification from "../Features/Modules/UserVerification/UserVerification";
import WithdrawMoney from "../Features/Modules/WithdrawMoney/WithdrawMoney";
import WithdrawMoneyConfirm from "../Features/Modules/WithdrawMoney/WithdrawMoneyConfirm";
import WithdrawMoneyOtp from "../Features/Modules/WithdrawMoney/WithdrawMoneyOtp";
import KYCotpVerificationScreen from "../Features/OTP/KYCotpVerificationScreen";

const fragmentWrapper = (route, component) => {
  return (
    <Fragment>
      <Route path={route} exact component={component} />
    </Fragment>
  );
};

const navigateToFundTranferDetailPage = () => {
  return fragmentWrapper("/fundTransferDetails", FundTransferDetailPage);
};
const AccountSummaryRoute = () => {
  return fragmentWrapper("/account-summary", AccountSummary);
};
const AddMoneyRoute = () => {
  return fragmentWrapper("/add-money", AddMoney);
};
const AddMoneyInitiateRoute = () => {
  return fragmentWrapper("/add-money-initiate", AddMoneyInitiate);
};
const AddMoneyProcessRoute = () => {
  return fragmentWrapper("/add-money-process", AddMoneyProcessing);
};
const AutoTransactionProcessingRoute = () => {
  return fragmentWrapper(
    "/auto-transaction-process",
    AutoTransactionProcessing
  );
};
const AutoTransactionSuccessRoute = () => {
  return fragmentWrapper("/auto-transaction-success", AutoTransactionSuccess);
};
const TransactionFailRoute = () => {
  return fragmentWrapper("/transaction-fail", TransactionFail);
};
const TransactionSuccessRoute = () => {
  return fragmentWrapper("/transaction-success", TransactionSuccess);
};
const BuyStockRoute = () => {
  return fragmentWrapper("/buy-stock", BuyStocks);
};
const BuyStockConfirmationRoute = () => {
  return fragmentWrapper("/buy-stock-confirmation", BuyStockConfirmation);
};
const BuyStocksRoute = () => {
  return fragmentWrapper("/buy-stocks-list", BuyStocksList);
};
const KYCBlockedRoute = () => {
  return fragmentWrapper("/kyc-blocked", KYCBlocked);
};
const ExploreStocksRoute = () => {
  return fragmentWrapper("/explore-stocks", ExploreStocks);
};
const AlreadyExistsRoute = () => {
  return fragmentWrapper("/already-exists", AlreadyExists);
};
const GettingStartedRoute = () => {
  return fragmentWrapper("/", GettingStarted);
};
const InvestmentAdvisorRoute = () => {
  return fragmentWrapper("/investment-advisor", InvestmentAdvisor);
};
const InvestmentsRoute = () => {
  return fragmentWrapper("/investments", Investments);
};
const KYCSuccessRoute = () => {
  return fragmentWrapper("/kyc-success", KycSuccess);
};
const MarketSummaryRoute = () => {
  return fragmentWrapper("/market-summary", MarketSummary);
};
const OrderListRoute = () => {
  return fragmentWrapper("/order-list", OrderList);
};
const OrderSuccessRoute = () => {
  return fragmentWrapper("/order-success", OrderSuccess);
};

const SellStockRoute = () => {
  return fragmentWrapper("/sell-stock", SellStock);
};
const SellStockConfirmationRoute = () => {
  return fragmentWrapper("/sell-stock-confirmation", SellStockConfirmation);
};
const SellStocksRoute = () => {
  return fragmentWrapper("/sell-stocks-list", SellStockList);
};
const StockDashboardRoute = () => {
  return fragmentWrapper("/dashboard", StockDashboard);
};
const StockDetailsRoute = () => {
  return fragmentWrapper("/stock-details", StockDetails);
};
const TopGainersRoute = () => {
  return fragmentWrapper("/top-gainers", TopGainers);
};
const TopLoosersRoute = () => {
  return fragmentWrapper("/top-loosers", TopLoosers);
};
const UserValidationRoute = () => {
  return fragmentWrapper("/user-validation", UserValidation);
};
const UserVerificationRoute = () => {
  return fragmentWrapper("/user-verification", UserVerification);
};
const WithdrawMoneyRoute = () => {
  return fragmentWrapper("/withdraw-money", WithdrawMoney);
};
const WithdrawMoneyConfirmRoute = () => {
  return fragmentWrapper("/withdraw-money-confirm", WithdrawMoneyConfirm);
};
const WithdrawMoneyOtpRoute = () => {
  return fragmentWrapper("/withdraw-money-otp", WithdrawMoneyOtp);
};
const KYCotpVerificationScreenRoute = () => {
  return fragmentWrapper("/kycotpVerificationScreen", KYCotpVerificationScreen);
};

export const NavigateToFundTranferDetailPage = withRouter(
  navigateToFundTranferDetailPage
);
export const NavigateToAccountSummaryRoute = withRouter(AccountSummaryRoute);
export const NavigateToAddMoneyRoute = withRouter(AddMoneyRoute);
export const NavigateToAddMoneyInitiateRoute = withRouter(
  AddMoneyInitiateRoute
);
export const NavigateToAddMoneyProcessRoute = withRouter(AddMoneyProcessRoute);
export const NavigateToAutoTransactionProcessingRoute = withRouter(
  AutoTransactionProcessingRoute
);
export const NavigateToAutoTransactionSuccessRoute = withRouter(
  AutoTransactionSuccessRoute
);
export const NavigateToTransactionFailRoute = withRouter(TransactionFailRoute);
export const NavigateToTransactionSuccessRoute = withRouter(
  TransactionSuccessRoute
);

export const NavigateToBuyStockRoute = withRouter(BuyStockRoute);
export const NavigateToBuyStockConfirmationRoute = withRouter(
  BuyStockConfirmationRoute
);
export const NavigateToBuyStocksRoute = withRouter(BuyStocksRoute);
export const NavigateToKYCBlockedRoute = withRouter(KYCBlockedRoute);
export const NavigateToExploreStocksRoute = withRouter(ExploreStocksRoute);
export const NavigateToAlreadyExistsRoute = withRouter(AlreadyExistsRoute);
export const NavigateToGettingStartedRoute = withRouter(GettingStartedRoute);

export const NavigateToInvestmentAdvisorRoute = withRouter(
  InvestmentAdvisorRoute
);
export const NavigateToInvestmentsRoute = withRouter(InvestmentsRoute);
export const NavigateToKYCSuccessRoute = withRouter(KYCSuccessRoute);
export const NavigateToMarketSummaryRoute = withRouter(MarketSummaryRoute);
export const NavigateToOrderListRoute = withRouter(OrderListRoute);
export const NavigateToOrderSuccessRoute = withRouter(OrderSuccessRoute);

export const NavigateToSellStockRoute = withRouter(SellStockRoute);
export const NavigateToSellStockConfirmationRoute = withRouter(
  SellStockConfirmationRoute
);
export const NavigateToSellStocksRoute = withRouter(SellStocksRoute);
export const NavigateToStockDashboardRoute = withRouter(StockDashboardRoute);
export const NavigateToStockDetailsRoute = withRouter(StockDetailsRoute);
export const NavigateToTopGainersRoute = withRouter(TopGainersRoute);
export const NavigateToTopLoosersRoute = withRouter(TopLoosersRoute);
export const NavigateToUserValidationRoute = withRouter(UserValidationRoute);
export const NavigateToUserVerificationRoute = withRouter(
  UserVerificationRoute
);
export const NavigateToWithdrawMoneyRoute = withRouter(WithdrawMoneyRoute);
export const NavigateToWithdrawMoneyConfirmRoute = withRouter(
  WithdrawMoneyConfirmRoute
);
export const NavigateToWithdrawMoneyOtpRoute = withRouter(
  WithdrawMoneyOtpRoute
);
export const NavigateToKYCotpVerificationScreenRoute = withRouter(
  KYCotpVerificationScreenRoute
);
