const COLORS = {
  BLACK: "#333333",
  BROWNISH_GREY: "#767676",
  PALE_GREY: "#edeff5",
  WHITE: "#ffffff",
  BATTLESHIP_GREY: "#63717a",
  ERROR_RED: "#b00020",
  LIGHT_GREY: "#bacad3",
  MATERIAL_BLUE_INPUT_BUTTON: "#2598ED",
  GENIE_ORANGE: '#f15a22',
  button: {
    BACKGROUND_COLOR: "#000000",
    BACKGROUND_BORDER_COLOR: "",
    PRIMARY_COLOR: "#009BDF",
    SECONDARY_COLOR: "#FFFFFF",
    TEXT_PRIMARY_COLOR: "#FFFFFF",
    TEXT_SECONDARY_COLOR: "#009BDF",
    MATERIAL_BLUE_INPUT_BUTTON: "#2598ED",
    ORANGE_COLOR: "#f15a22",
  },
};

export default COLORS;
