import React from "react";
import Header from "../../Components/Header/Header";
import SearchBar from "../../Components/SearchBar/SearchBar";

const Layout = (props) => {
  const { showHeader } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "100%",
      }}
    >
      {showHeader ? <Header {...props} /> : <SearchBar {...props} />}
      {props.children}
    </div>
  );
};

export default Layout;

Layout.defaultProps = {
  showHeader: true,
};
