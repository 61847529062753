import { createReducer } from "@reduxjs/toolkit";
import * as types from "../Actions";

let initialState = {
  timerPopUp: {
    visibility: false,
    popUpType: "",
    title: "",
    decription: "",
    remainingTime: "",
    otpMobileNumber: "",
  },
  otpTimer: {
    hr: 0,
    min: 0,
    sec: 0,
  },   
};

const ComponentReducer = createReducer(initialState, {
  [types.TRIGGER_TIMER_POPUP](state, action) {
    return {
      ...state,
      timerPopUp: {
        visibility: true,
        popUpType: action.payload.popUpType,
        title: action.payload.title,
        decription: action.payload.decription,
        remainingTime: action.payload.remainingTime,
      },
    };
  },
  [types.CLEAR_TIMER_POPUP](state, action) {
    return {
      ...state,
      timerPopUp: {
        visibility: false,
        popUpType: "",
        title: "",
        decription: "",
        remainingTime: "",
      },
    };
  },
  [types.SET_REMAINING_TIME__FOR_OTP](state, action) {
    return {
      ...state,
      timerPopUp: {
        ...state.timerPopUp,
        remainingTime: action.payload,
      },
    };
  },
  [types.SET_OTP_COUNTDOWN](state, action) {
    return {
      ...state,
      otpTimer: {
        hr: action.payload.hr,
        min: action.payload.min,
        sec: action.payload.sec,
      },
    };
  },
  [types.STORE_OTP_MOBILE_NUMER](state, action) {
    return {
      ...state,
      otpMobileNumber: action.payload,
    };
  },
});

export default ComponentReducer;
