const values = {
  BUY_STOCKS_CALCULATION_VALIDATIONN: "50,000.00",
  SELL_STOCKS_CALCULATION_VALIDATION: "50,000.00",
  BUY_STOCKS_CALCULATION_VALIDATION_AMOUNT: window._env_.BUY_STOCKS_MAX_LIMIT,
  SELL_STOCKS_CALCULATION_VALIDATION_AMOUNT: window._env_.SELL_STOCKS_MAX_LIMIT,
  BUY_STOCKS_CALCULATION_VALIDATION_DECIMAL: 0,
  SELL_STOCKS_CALCULATION_VALIDATION_DECIMAL: 0,
};

export default values;
