import { put, call, delay } from "redux-saga/effects";
import * as types from "../Actions";
import {
  KycOTPRequestService,
  KycOTPValidateService,
  checkFreshUserEmailVerificationStateService,
  triggerResendEmailService
} from "../Services/addMoneyService";
import ErrorCodes from "../../Assets/ErrorCodes";
import * as ComponentActions from "../Actions/ComponentActions";
import * as commonAction from "../Actions/commonAction";
import Constants from "../../Assets/Constants";
import * as KycActions from "../Actions/KycActions";

export function* triggerKycOtpSaga(action) {
  try {
    yield delay(0);
    const accessToken = sessionStorage.getItem("token");
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(
      KycOTPRequestService,
      accessToken,
      action.payload.brokerName
    );
    if (response && response.data && response.data.STATUS) {
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      if (response.data && response.data.DATA && response.data.DATA.mobileNo) {
        yield put(
          ComponentActions.storeOTPmobileNumer(response.data.DATA.mobileNo)
        );
      }
      switch (response.data.STATUS) {
        case ErrorCodes.OTP.TX_SUCCESS:
          if (
            action.payload &&
            action.payload.flowtype &&
            action.payload.flowtype === "RESEND"
          ) {
            break;
          } else {
            action.payload.OTPnavigation();
            break;
          }
        case ErrorCodes.OTP.TX_OTP_BLOCKED:
          let payload = {
            topIcon: "",
            popUpType: Constants.popUp.KYC_OTP_BLOCKED,
            title: "Account creation blocked",
            decription:
              "Your account creation is temporarily blocked due to multiple invalid OTP attempts.",
            remainingTime: response.data.DATA.otpExpiryTimeInSeconds,
          };
          yield put(ComponentActions.triggerTimerPopUp(payload));
          if (
            action.payload &&
            action.payload.flowtype &&
            action.payload.flowtype === "RESEND" &&
            action.payload.navigateBack
          ) {
            action.payload.navigateBack();
          }
          break;
        case ErrorCodes.OTP.TX_OTP_SEND:
          let popUpPayload = {
            topIcon: "",
            popUpType: Constants.popUp.KYC_OTP_REQUESTED,
            title: "OTP already requested",
            decription: "We have already sent you an OTP which is still valid.",
            remainingTime: response.data.DATA.otpExpiryTimeInSeconds,
          };
          yield put(ComponentActions.triggerTimerPopUp(popUpPayload));
          if (
            action.payload &&
            action.payload.flowtype &&
            action.payload.flowtype === "RESEND" &&
            action.payload.navigateBack
          ) {
            action.payload.navigateBack();
          }
          break;
        case ErrorCodes.INTERNAL_SERVER_ERROR:
        case ErrorCodes.SERVICE_UNAVAILABLE:
        case ErrorCodes.REQUEST_TIMEOUT:
          yield put({ type: types.SHOW_ERROR_POPUP });
          break;
        default:
          break;
      }
    } else {
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
    }
  } catch (error) {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* verifyKycOtpSaga(action) {
  try {
    yield delay(0);
    const accessToken = sessionStorage.getItem("token");
    // yield put({
    //   type: types.FULL_SCREEN_LOADER,
    //   payload: { visibility: true, text: "" },
    // });
    let response = yield call(
      KycOTPValidateService,
      accessToken,
      action.payload.brokerCode,
      action.payload.OTP
    );
    if (response && response.data && response.data.STATUS) {
      // yield put({
      //   type: types.FULL_SCREEN_LOADER,
      //   payload: { visibility: false, text: "" },
      // });
      switch (response.data.STATUS) {
        case ErrorCodes.OTP.TX_SUCCESS:
          yield put(commonAction.commonKYCInitiation());
          break;
        case ErrorCodes.OTP.TX_OTP_EXPIRED:
          yield put(
            KycActions.storeVerifiedKycOTPStatus(ErrorCodes.OTP.TX_OTP_EXPIRED)
          );
          break;
        case ErrorCodes.OTP.TX_INVALID_OTP:
          yield put(
            KycActions.storeVerifiedKycOTPStatus(ErrorCodes.OTP.TX_INVALID_OTP)
          );
          break;
        case ErrorCodes.OTP.OTP_TRIES_EXCEEDED:
          yield put(
            KycActions.storeVerifiedKycOTPStatus(
              ErrorCodes.OTP.OTP_TRIES_EXCEEDED
            )
          );
          break;
        case ErrorCodes.INTERNAL_SERVER_ERROR:
        case ErrorCodes.SERVICE_UNAVAILABLE:
        case ErrorCodes.REQUEST_TIMEOUT:
          yield put({ type: types.SHOW_ERROR_POPUP });
          break;
        default:
          break;
      }
    } else {
      // yield put({
      //   type: types.FULL_SCREEN_LOADER,
      //   payload: { visibility: false, text: "" },
      // });
    }
  } catch (error) {
    // yield put({
    //   type: types.FULL_SCREEN_LOADER,
    //   payload: { visibility: false, text: "" },
    // });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}
   
export function* checkFreshUserEmailVerificationStateSaga(action) {
  try {
    yield delay(0);
    const accessToken = sessionStorage.getItem("token");
    let response = yield call(
      checkFreshUserEmailVerificationStateService,
      accessToken
    );
    if (response && response.data && response.data.STATUS) {
      switch (response.data.STATUS) {
        case ErrorCodes.EMAIL_VERIFICATION.TX_SUCCESS:
          yield put(
            KycActions.storeFreshUserEmailVerificationState(
              ErrorCodes.EMAIL_VERIFICATION.TX_SUCCESS
            )
          );
          break;
        case ErrorCodes.EMAIL_VERIFICATION.EMAIL_NOT_VERIFIED:
          yield put(
            KycActions.storeFreshUserEmailVerificationState(
              ErrorCodes.EMAIL_VERIFICATION.EMAIL_NOT_VERIFIED
            )
          );
          break;
        default:
          break;
      }
    } else {
    }
  } catch (error) {
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}

export function* triggerResendEmailSaga(action) {
  try {
    yield delay(0);
    const accessToken = sessionStorage.getItem("token");
    let response = yield call(triggerResendEmailService, accessToken);
    if (response && response.data && response.data.STATUS) {
      if( response.data.STATUS !== ErrorCodes.EMAIL_VERIFICATION.TX_SUCCESS){
        yield put({ type: types.SHOW_ERROR_POPUP });
      }
    } else {
    }
  } catch (error) {
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}
