import axios from "axios";

export default async function Api(BASE_URL, URL, method, token, params, body) {
  // console.log(
  //   "CALLING API ---------->  ",
  //   BASE_URL + URL,
  //   " METHOD:: ",
  //   method,
  //   " body :",
  //   body
  // );

  try {
    let header;
    let endpoint = `${BASE_URL}/${URL}`;
    header = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    return new Promise(async (resolve, reject) => {
      await axios({
        timeout: 20000,
        url: endpoint,
        method: method,
        params: params,
        data: body,
        headers: header,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          // console.log("api error ----------------> ", error);
          reject(error);
        });
    });
  } catch (error) {
    // console.log("service catch -------------> ", error);
  }
}
