import * as types from "../Actions";
import moment from "moment";

export const initailState = {
  buyStockList: [],
  selectedStock: {},
  marketDepth: {},
  totalAsk: "",
  totalBids: "",
  securityStat: [],
  sellStockList: [],
  sellStockPrice: "",
  sellStockPriceDecimal: "",
  sellNumberOfShares: "",
  sellStockDuration: {
    value: moment(),
    name: "Current trading day",
    tif: 0,
    tifDays: 1,
  },
  sellStatus: "",
  buyStockPrice: "",
  buyStockPriceDecimal: "",
  buyNumberOfShares: "",
  buyStockDuration: {
    value: moment(),
    name: "Current trading day",
    tif: 0,
    tifDays: 1,
  },
  buyCommission: "",
  sellCommission: "",
  marketStatus: "",
  orderListBuyStatus: [],
  buyOrderEditStatus: false,
  buyOrderEditItem: {},
  orderEditItemPrice: "",
  orderEditItemPriceDecimal: "",
  orderEditItemQty: "",
  orderEditItemDuration: "",
  buyOrderEditResponse: false,
  sellOrderEditResponse: false,
  bottomSheetStatus: false,
  tabSelected: 1,
  token: null,
  marketSummaryDetails: [],
  date: "",
  tradeStatus: "",
  selectedSecurityForSell: "",
  sellEditShareAmount: "",
};

export default function (state = initailState, action) {
  let response = action.response;
  let payload = action.payload;
  switch (action.type) {
    case types.GET_BUY_STOCK_LIST_SUCCESS:
      return { ...state, buyStockList: response.data.data };
    case types.GET_BUY_STOCK_LIST_FAIL:
      return { ...state, buyStockList: {} };
    case types.SELECTED_STOCK:
      return { ...state, selectedStock: payload };
    case types.GET_MARKET_DEPTH_SUCCESS:
      return {
        ...state,
        marketDepth: response.data.DATA,
        totalAsk: response.data.DATA.totalask,
        totalBids: response.data.DATA.totalbids,
      };
    case types.GET_MARKET_DEPTH_FAIL:
      return { ...state, marketDepth: {}, totalAsk: "", totalBids: "" };
    case types.GET_SECURITY_STAT_SUCCESS:
      return { ...state, securityStat: response.data.DATA };
    case types.GET_SECURITY_STAT_FAIL:
      return { ...state, securityStat: {} };
    case types.SET_SELL_STOCK_PRICE:
      return { ...state, sellStockPrice: payload };
    case types.SET_SELL_STOCK_PRICE_DECIMAL:
      return { ...state, sellStockPriceDecimal: payload };
    case types.SET_SELL_NUMBER_OF_SHARES:
      return { ...state, sellNumberOfShares: payload };
    case types.SET_SELL_STOCK_DURATION:
      return { ...state, sellStockDuration: payload };
    case types.SET_BUY_STOCK_PRICE:
      return {
        ...state,
        buyStockPrice: payload,
      };
    case types.SET_BUY_STOCK_PRICE_DECIMAL:
      return {
        ...state,
        buyStockPriceDecimal: payload,
      };
    case types.SET_BUY_NUMBER_OF_SHARES:
      return { ...state, buyNumberOfShares: payload };
    case types.SET_BUY_STOCK_DURATION:
      return { ...state, buyStockDuration: payload };
    case types.GET_SELL_STATUS_SUCCESS:
      return { ...state, sellStatus: response.data.DATA.status };
    case types.GET_SELL_STATUS_FAIL:
      return { ...state, sellStatus: "" };
    case types.GET_BUY_COMMISSION_SUCCESS:
      return { ...state, buyCommission: response.data.DATA.commision };
    case types.GET_BUY_COMMISSION_FAIL:
      return { ...state, buyCommission: "" };
    case types.GET_SELL_COMMISSION_SUCCESS:
      return { ...state, sellCommission: response.data.DATA.commision };
    case types.GET_SELL_COMMISSION_FAIL:
      return { ...state, sellCommission: "" };
    case types.GET_MARKET_STATUS_SUCCESS:
      return { ...state, marketStatus: response.data.DATA.status };
    case types.GET_MARKET_STATUS_FAIL:
      return { ...state, marketStatus: "" };
    case types.GET_ORDER_LIST_BUY_STATUS_SUCCESS:
      return { ...state, orderListBuyStatus: response.data.DATA };
    case types.GET_ORDER_LIST_BUY_STATUS_FAIL:
      return { ...state, orderListBuyStatus: "" };
    case types.SET_ORDER_EDIT_STATUS:
      return { ...state, buyOrderEditStatus: payload };
    case types.SET_ORDER_EDIT_ITEM:
      return { ...state, buyOrderEditItem: payload };
    case types.SET_SELECTED_OREDR_ITEM_PRICE:
      return { ...state, orderEditItemPrice: payload };
    case types.SET_SELECTED_OREDR_ITEM_QUANTITY:
      return { ...state, orderEditItemQty: payload };
    case types.SET_SELECTED_OREDR_ITEM_DURATION:
      return { ...state, orderEditItemDuration: payload };
    case types.SET_SELECTED_ORDER_ITEM_PRICE_DECIMAL:
      return { ...state, orderEditItemPriceDecimal: payload };
    case types.EDIT_BUY_ORDER_SUCCESS:
      return { ...state, buyOrderEditResponse: true };
    case types.EDIT_BUY_ORDER_FAIL:
      return { ...state, buyOrderEditResponse: false };
    case types.EDIT_SELL_ORDER_SUCCESS:
      return { ...state, sellOrderEditResponse: true };
    case types.EDIT_SELL_ORDER_FAIL:
      return { ...state, sellOrderEditResponse: false };
    case types.BUY_STOCK_CONFIRMATION_SUCCESS:
      return { ...state, buyOrderEditResponse: true };
    case types.BUY_STOCK_CONFIRMATION_FAIL:
      return { ...state, buyOrderEditResponse: false };
    case types.SELL_STOCK_CONFIRMATION_SUCCESS:
      return { ...state, sellOrderEditResponse: true };
    case types.SELL_STOCK_CONFIRMATION_FAIL:
      return { ...state, sellOrderEditResponse: false };
    case types.SET_BOTTOM_SHEET_STATUS:
      return { ...state, bottomSheetStatus: payload };
    case types.SET_SELECTED_TAB:
      return { ...state, tabSelected: payload };
    case types.GET_MARKET_SUMMARY_DETAILS:
      return { ...state, date: payload };
    case types.GET_MARKET_SUMMARY_DETAILS_SUCCESS:
      return { ...state, marketSummaryDetails: payload };
    case types.GET_MARKET_SUMMARY_DETAILS_FAIL:
      return { ...state, marketSummaryDetails: [] };
    case types.GET_SECURITY_TRADE_STATUS_SUCCESS:
      return { ...state, tradeStatus: payload.data.DATA.isnew };
    case types.GET_SECURITY_TRADE_STATUS_FAIL:
      return { ...state, tradeStatus: "" };
    case types.SET_SELECTED_SECURITY_FOR_SELL:
      return { ...state, selectedSecurityForSell: payload };
    case types.SAVE_EDIT_SELL_SHARE_AMOUNT:
      return { ...state, sellEditShareAmount: payload };
    default:
      return state;
  }
}
