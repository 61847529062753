import React, { Fragment, Component } from "react";
import { connect } from "react-redux";

import Layout from "../../Layout/Layout";
import BodyContainer from "../../../Components/BodyArea/Body";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import {
  setOtpValue,
  setOtpSendingCounter,
  setCircularLoadingFlag,
  setTimerValue,
  setButtonDisable,
  setSelectedWithdrawAmount,
  getOtpValidate,
  setOtpSend,
  postFundTransfer,
  getOtpRequest,
  customerBalanceWithdrawal,
} from "../../../Redux/Actions/withdrawAction";
import ButtonWithCircularProgress from "../../../Components/Buttons/ButtonWithCircularProgress";
import {
  showAlertDialog,
  hideAlertDialog,
} from "../../../Redux/Actions/commonAction";
import AlertDialog from "../../../Components/Alert/Alert";
import { Images } from "../../../Theme/Images";
import TextInputOutlined from "../../../Components/TextInput/TextInputOutlined";
import * as Constants from "./../../../Utils/constants";

class WithdrawMoneyOtp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidOtp: false,
      expiredOtp: false,
      invalidOtpCount: false,
      otpValue: "",
      withdrawalValue: "",
      decimalValue: "",
    };
  }

  componentDidMount() {
    const withdrawalValue = this.props.withdrawalAmount
      ? this.props.withdrawalAmount.split(".")[0]
      : "0";
    const decimalValue = this.props.withdrawalAmount
      ? this.props.withdrawalAmount.split(".")[1]
      : "00";
    this.setState({ withdrawalValue, decimalValue });
  }

  onLeftIconClick = () => {
    this.props.setOtpValue("");
    this.props.history.goBack();
  };

  timeoutActions = (time) => {
    this.props.setCircularLoadingFlag(false);
    this.props.setButtonDisable(false);
    setTimeout(() => {
      this.props.setCircularLoadingFlag(true);
      this.props.setButtonDisable(true);
    }, 100);
    this.props.setTimerValue(time);
  };

  onHandleOtpValue = (data) => {
    this.setState({ otpValue: data });
    this.props.setOtpValue(data);
    if (data.length === 6) {
      if (this.props.buttonDisable) {
        if (!this.state.invalidOtpCount) {
          this.props.getOtpValidate(() => {
            if (this.props.otpCounterStatus === "TX_OTP_EXPIRED") {
              this.setState({ expiredOtp: true });
            } else if (this.props.otpCounterStatus === "TX_INVALID_OTP") {
              this.setState({ invalidOtp: true });
            } else if (this.props.otpCounterStatus === "TX_OTP_BLOCKED") {
              this.timeoutActions(180);
              this.props.setOtpSendingCounter(0);
              this.setState({
                invalidOtpCount: true,
                invalidOtp: false,
                expiredOtp: false,
              });
            } else if (this.props.otpCounterStatus === "TX_SUCCESS") {
              this.setState({
                invalidOtp: false,
                expiredOtp: false,
                invalidOtpCount: false,
              });
              this.onValidOtp();
            } else {
            }
          });
        }
      } else {
        this.setState({
          expiredOtp: true,
          invalidOtp: false,
          invalidOtpCount: false,
        });
      }
    } else {
    }
  };

  buttonOnPress = () => {
    this.props.setCircularLoadingFlag(true);
    this.props.setButtonDisable(true);
    this.setState({
      otpValue: "",
      invalidOtp: false,
      expiredOtp: false,
      invalidOtpCount: false,
    });
    this.props.setOtpValue("");
    this.props.getOtpRequest(() => {
      if (this.props.otpCounterStatus === "TX_OTP_BLOCKED") {
        this.timeoutActions(180);
        this.props.setOtpSendingCounter(0);
        this.setState({ invalidOtpCount: true });
      } else if (this.props.otpCounterStatus === "TX_SUCCESS") {
        this.timeoutActions(120);
        this.props.setOtpSendingCounter(parseInt(this.props.otpCounter) + 1);
        this.setState({
          invalidOtpCount: false,
          invalidOtp: false,
          expiredOtp: false,
        });
      } else {
        this.props.setTimerValue(120);
      }
    });
  };

  onValidOtp = () => {
    this.props.setCircularLoadingFlag(false);
    this.props.setButtonDisable(false);
    this.props.setOtpSend(true);
    this.props.customerBalanceWithdrawal();
    // this.otpValidateSuccess();
  };

  onPrimaryPressed = () => {
    if (this.props.customerBalanceWithdrawalApiState) {
      this.props.hideAlertDialog();
      this.props.setSelectedWithdrawAmount("");
      this.props.setOtpSendingCounter(0);
      this.props.history.push("/dashboard");
    } else {
      this.props.customerBalanceWithdrawal();
      this.props.hideAlertDialog();
    }
  };

  otpValidateSuccess = () => {
    let alertObjSuccess = {
      visibility: true,
      dialogMessage: `You have successfully withdrawn LKR ${this.state.withdrawalValue}.${this.state.decimalValue} from your trading account. It will take 1-2 business days to release money from our end`,
      dialogTitle: "Successful",
      dialogPrimaryButton: "DONE",
      dialogIcon: Images.buyStocks.ic_correct,
    };
    this.props.showAlertDialog(alertObjSuccess);
  };

  fundTransferFailure = () => {
    let alertObjFailed = {
      visibility: true,
      dialogMessage: "Sorry, your withdrawal request failed. Please try again.",
      dialogTitle: "Failed",
      dialogPrimaryButton: "TRY AGAIN",
      dialogIcon: Images.withdrawMoney.ic_cancel,
    };
    this.props.showAlertDialog(alertObjFailed);
  };

  render() {
    return (
      <Fragment>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft=""
          headerContentRight=""
          rightIcon=""
          leftIconLink="dashboard"
          handleLeftIconClick={() => {
            this.onLeftIconClick();
          }}
        >
          <BodyContainer>
            <header
              className="App-container"
              data-testid="withdraw-money-otp-container"
            >
              <div
                style={{
                  width: "100%",
                  justifyItems: "center",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                <AlertDialog
                  onPrimaryPressed={() => {
                    this.onPrimaryPressed();
                  }}
                  onhandleClose={() => {
                    this.onPrimaryPressed();
                  }}
                />
                <div
                  style={{
                    justifyItems: "center",
                    textAlign: "center",
                    marginTop: "15%",
                    color: "#333333",
                  }}
                >
                  <h4
                    data-testid="verification-label"
                    style={{ fontSize: "1.5em" }}
                  >
                    Verification Code
                  </h4>
                </div>
                <div
                  style={{
                    justifyItems: "center",
                    textAlign: "center",
                    marginTop: "2%",
                  }}
                >
                  <h6
                    data-testid="paragraph=label"
                    style={{ color: "#767676", fontSize: "0.749em" }}
                  >
                    Enter the 6-digit code we've sent to +94{" "}
                    {this.props.mobileNumber}
                  </h6>
                </div>
                <div
                  style={{
                    width: "92%",
                    margin: 0,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "8%",
                  }}
                >
                  <TextInputOutlined
                    dataTestId="textInput-value"
                    label="Enter the code"
                    maxlength={6}
                    autoFocus={true}
                    onChange={this.onHandleOtpValue}
                    value={this.state.otpValue}
                  />
                </div>
                <div>
                  {this.state.invalidOtp ? (
                    <h6
                      data-testid="invalid-otp-error-label"
                      style={{
                        color: "red",
                        marginLeft: "10%",
                        textAlign: "center",
                        marginRight: "10%",
                        marginTop: "5%",
                      }}
                    >
                      {Constants.INVALID_OTP_ERROR}
                    </h6>
                  ) : null}
                </div>
                <div>
                  {this.state.expiredOtp ? (
                    <h6
                      data-testid="expired-otp-error-label"
                      style={{
                        color: "red",
                        marginLeft: "10%",
                        textAlign: "center",
                        marginRight: "10%",
                        marginTop: "5%",
                      }}
                    >
                      {Constants.EXPIRED_OTP_ERROR}
                    </h6>
                  ) : null}
                </div>
                <div>
                  {this.state.invalidOtpCount ? (
                    <h6
                      data-testid="max-otp-error-label"
                      style={{
                        color: "red",
                        marginLeft: "10%",
                        textAlign: "left",
                        marginRight: "10%",
                        marginTop: "5%",
                      }}
                    >
                      {Constants.MAXIMUM_OTP_ATTEMPT_COUNT_ERROR}
                    </h6>
                  ) : null}
                </div>
                <div
                  style={{
                    position: "fixed",
                    bottom: "3%",
                    left: 0,
                    right: 0,
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "90%",
                  }}
                >
                  <ButtonWithCircularProgress
                    onButtonClick={this.buttonOnPress}
                    circularLoadingFlag={this.props.circularLoadingFlag}
                    timerValue={this.props.timerValue}
                    buttonDisable={this.props.buttonDisable}
                    setOtpSend={this.props.setOtpSend}
                    setCircularLoadingFlag={this.props.setCircularLoadingFlag}
                    setButtonDisable={this.props.setButtonDisable}
                  >
                    Resend
                  </ButtonWithCircularProgress>
                </div>
              </div>
            </header>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mobileNumber: state.WithdrawReducer.mobileNumber,
    otpValue: state.WithdrawReducer.otpValue,
    otpCounter: state.WithdrawReducer.otpCounter,
    circularLoadingFlag: state.WithdrawReducer.circularLoadingFlag,
    timerValue: state.WithdrawReducer.timerValue,
    buttonDisable: state.WithdrawReducer.buttonDisable,
    withdrawalAmount: state.WithdrawReducer.withdrawalAmount,
    otpCounterStatus: state.WithdrawReducer.otpCounterStatus,
    otpSend: state.WithdrawReducer.otpSend,
    customerBalanceWithdrawalApiState:
      state.WithdrawReducer.customerBalanceWithdrawalApiState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOtpValue: (data) => dispatch(setOtpValue(data)),
    setOtpSendingCounter: (data) => dispatch(setOtpSendingCounter(data)),
    setCircularLoadingFlag: (data) => dispatch(setCircularLoadingFlag(data)),
    setTimerValue: (data) => dispatch(setTimerValue(data)),
    setButtonDisable: (data) => dispatch(setButtonDisable(data)),
    showAlertDialog: (data) => dispatch(showAlertDialog(data)),
    hideAlertDialog: () => dispatch(hideAlertDialog()),
    setSelectedWithdrawAmount: (data) =>
      dispatch(setSelectedWithdrawAmount(data)),
    getOtpValidate: (callback) => dispatch(getOtpValidate(callback)),
    setOtpSend: (data) => dispatch(setOtpSend(data)),
    postFundTransfer: (data) => dispatch(postFundTransfer(data)),
    getOtpRequest: (callback) => dispatch(getOtpRequest(callback)),
    customerBalanceWithdrawal: () => dispatch(customerBalanceWithdrawal()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WithdrawMoneyOtp);
