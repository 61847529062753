import React from "react";
import { connect } from "react-redux";
import PullToRefresh from "react-simple-pull-to-refresh";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import Layout from "../../Layout/Layout";
import "./OrderListStyles.scss";
import BodyContainer from "../../../Components/BodyArea/Body";
import AlertPopup from "../../../Components/AlertPopup";
import * as commonActions from "../../../Redux/Actions/commonAction";
import OrderListTabComponent from "./SubComponents/OrderListTabComponent";
import * as stockActions from "../../../Redux/Actions/stockAction";
import { fixDecimalPoints } from "../../../Utils/commonFunctions";
import { Images } from "../../../Theme/Images";

class OrderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: false,
      buyOrderList: [],
      sellOrderList: [],
      showHeader: true,
    };
  }

  componentDidMount() {
    this.props.getOrderListBuyStatus(() => {
      if (this.props.buyOrderList) {
        this.setState({
          buyOrderList: this.props.buyOrderList.filter(
            (order) => order.action === "BUY"
          ),
          sellOrderList: this.props.buyOrderList.filter(
            (order) => order.action === "SELL"
          ),
        });
      }
    });
  }

  hideAlert = () => {
    this.props.hideAlertDialog();
  };

  handleConfirmCancel = () => {
    this.props.getOrderListBuyStatus(() => {
      if (this.props.buyOrderList) {
        if (
          this.props.buyOrderList.find(
            (order) =>
              order.clientorderid === this.props.orderEditItem.clientorderid
          )
        ) {
          let item = this.props.buyOrderList.find(
            (item) =>
              item.clientorderid === this.props.orderEditItem.clientorderid
          );
          if (
            (item.orderStatus && item.orderStatus === "NEW") ||
            item.orderStatus === "P.FILLED" ||
            item.orderStatus === "QUEUED" ||
            item.orderStatus === "Q.AMEND"
          ) {
            this.props.cancelQueuedOrder(() => {
              this.props.getOrderListBuyStatus(() => {
                if (this.props.buyOrderList) {
                  this.setState({
                    buyOrderList: this.props.buyOrderList.filter(
                      (order) => order.action === "BUY"
                    ),
                    sellOrderList: this.props.buyOrderList.filter(
                      (order) => order.action === "SELL"
                    ),
                  });
                }
              });
            });
          } else {
            let alertObj = {
              visibility: true,
              dialogMessage:
                "You cannot make this change as the order or the part of the order has already been processed.",
              dialogTitle: "Failed",
              dialogPrimaryButton: "TRY AGAIN",
              dialogSecondaryButton: "",
              dialogIcon: Images.buyStocks.ic_close,
            };
            this.props.showAlertDialog(alertObj);
          }
        }
      }
    });
    this.hideAlert();
  };

  numberWithCommas = (x) => {
    let srt = x.substr(0, x.indexOf("."));
    const value = srt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  };

  handleEdit = (item) => {
    this.props.setOderEditItem(item);
    this.props.setOderEditStatus(true);
    this.props.setSelectedOrderItemDuration(item.orderplacedate);
    this.props.setSelectedOrderItemPrice(
      item.orderprice.substr(0, item.orderprice.indexOf("."))
    );
    this.props.setSelectedOrderItemPriceDecimal(
      fixDecimalPoints(item.orderprice.split(".").pop())
    );
    if (item.orderStatus === "P.FILLED") {
      this.props.setSelectedOrderItemQuantity(item.remainder);
    } else {
      this.props.setSelectedOrderItemQuantity(item.orderQuantity);
    }
  };

  onRefreshPage = () => {
    this.props.getOrderListBuyStatus(() => {
      if (this.props.buyOrderList) {
        this.setState({
          buyOrderList: this.props.buyOrderList.filter(
            (order) => order.action === "BUY"
          ),
          sellOrderList: this.props.buyOrderList.filter(
            (order) => order.action === "SELL"
          ),
        });
      }
    });
  };

  onLeftIconClick = () => {
    this.props.history.push("dashboard");
  };

  render() {
    return (
      <div>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft="Order List"
          headerContentRight=""
          leftIconLink="dashboard"
          showHeader={true}
          handleLeftIconClick={() => this.onLeftIconClick()}
        >
          <BodyContainer>
            <PullToRefresh
              onRefresh={() => this.onRefreshPage()}
              refreshingContent=" "
              pullingContent=" "
            >
              <header className="App-container">
                <div style={{ width: "100%" }}>
                  <OrderListTabComponent
                    buyOrder={this.state.buyOrderList}
                    sellOrder={this.state.sellOrderList}
                    handleEdit={(item) => this.handleEdit(item)}
                    handleConfirmCancel={this.handleConfirmCancel}
                    hideAlert={this.hideAlert}
                    tabSelected={this.props.tabSelected}
                  />
                  <AlertPopup />
                </div>
              </header>
            </PullToRefresh>
          </BodyContainer>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyList: state.CommonReducer.companyList,
    snpList: state.CommonReducer.snpList,
    loader: state.CommonReducer.showLoader,
    buyOrderList: state.BuyStockReducer.orderListBuyStatus,
    tabSelected: state.BuyStockReducer.tabSelected,
    orderEditItem: state.BuyStockReducer.buyOrderEditItem,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOderEditItem: (data) => dispatch(stockActions.setOderEditItem(data)),
    setOderEditStatus: (data) => dispatch(stockActions.setOderEditStatus(data)),
    setSelectedOrderItemQuantity: (data) =>
      dispatch(stockActions.setSelectedOrderItemQuantity(data)),
    setSelectedOrderItemPrice: (data) =>
      dispatch(stockActions.setSelectedOrderItemPrice(data)),
    setSelectedOrderItemDuration: (data) =>
      dispatch(stockActions.setSelectedOrderItemDuration(data)),
    setSelectedOrderItemPriceDecimal: (data) =>
      dispatch(stockActions.setSelectedOrderItemPriceDecimal(data)),
    hideAlertDialog: () => dispatch(commonActions.hideAlertDialog()),
    cancelQueuedOrder: (callback) =>
      dispatch(stockActions.cancelQueuedOrder(callback)),
    getOrderListBuyStatus: (callback) =>
      dispatch(stockActions.getOrderListBuyStatus(callback)),
    showAlertDialog: (data) => dispatch(commonActions.showAlertDialog(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
