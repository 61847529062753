import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import PullToRefresh from "react-simple-pull-to-refresh";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import Layout from "../../Layout/Layout";
import "./InvestmentsStyles.scss";
import BodyContainer from "../../../Components/BodyArea/Body";
import { Images } from "../../../Theme/Images";
import { setSelectedStock } from "../../../Redux/Actions/buyStockListingAction";
import { fixDecimalPoints } from "../../../Utils/commonFunctions";
import {
  getMarketDepth,
  getSecurityStat,
  getSellStatus,
} from "../../../Redux/Actions/stockAction";
import { Divider } from "@mui/material";

class Investments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: false,
      showHeader: true,
    };
  }

  onViewPressed(item) {
    this.props.setSelectedStock(item);
    this.props.getSecurityStat();
    this.props.getMarketDepth();
    this.props.getSellStatus();
    setTimeout(() => {
      this.props.history.push("stock-details");
    }, 100);
  }

  numberWithCommas = (x) => {
    let srt = x.substr(0, x.indexOf("."));
    const value = srt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  };

  numberAddCommas = (x) => {
    const value = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  };

  handleRightOnClick = () => {
    this.props.history.push("buy-stocks-list");
  };

  onLeftIconClick = () => {
    this.props.history.push("dashboard");
  };

  render() {
    return (
      <Fragment>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft="Investments"
          headerContentRight=""
          rightIcon={Images.addMoney.ic_addMoney}
          rightIconLink="/"
          handleRightIconClick={() => this.handleRightOnClick()}
          showHeader={this.state.showHeader}
          handleLeftIconClick={() => this.onLeftIconClick()}
        >
          <BodyContainer>
            <PullToRefresh
              onRefresh={() => window.location.reload()}
              refreshingContent=" "
              pullingContent=" "
            >
              <header className="App-container">
                <div style={{ width: "100%" }}>
                  <div>
                    {this.props.investments.map((item, index) => {
                      let boughtPrice = 0;
                      boughtPrice = (
                        parseFloat(item.totCost) / item.quantity
                      ).toFixed(2);
                      return (
                        <div key={index}>
                          <div
                            className="row"
                            onClick={() => this.onViewPressed(item)}
                          >
                            <div className="col-5">
                              <h2 className="inv-company-code">
                                {item.security}
                              </h2>
                              <h2 className="inv-company-name">
                                {item.companyName}
                              </h2>
                              <span className="inv-company-name">
                                {this.numberAddCommas(item.quantity)}
                              </span>
                              <span className="inv-company-name"> x </span>
                              <span className="inv-company-name">LKR </span>
                              <span className="inv-company-name">
                                {this.numberWithCommas(boughtPrice)}
                              </span>
                              <span className="inv-company-name">
                                .
                                {fixDecimalPoints(boughtPrice.split(".").pop())}
                              </span>
                            </div>
                            <div
                              className="col-7"
                              style={{ textAlign: "right" }}
                            >
                              <span className="lkr-text">LKR</span>
                              <span className="price-text">
                                {this.numberWithCommas(item.marketValue)}
                              </span>
                              <span className="in-decimal-text">
                                .
                                {fixDecimalPoints(
                                  item.marketValue.split(".").pop()
                                )}
                              </span>
                            </div>
                          </div>
                          <Divider variant="middle" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </header>
            </PullToRefresh>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    investments: state.CommonReducer.sellStockList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedStock: (data) => dispatch(setSelectedStock(data)),
    getMarketDepth: (data) => dispatch(getMarketDepth(data)),
    getSecurityStat: (data) => dispatch(getSecurityStat(data)),
    getSellStatus: () => dispatch(getSellStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Investments);
