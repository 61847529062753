import React, { Fragment, Component } from "react";
import { connect } from "react-redux";

import Layout from "../../Layout/Layout";
import BodyContainer from "../../../Components/BodyArea/Body";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import { TextInputStandard } from "../../../Components/TextInput";
import * as Utils from "../../../Utils/constants";
import {
  setSelectedWithdrawAmount,
  setCashAvailableForWithdrawal,
  setSelectedWithdrawAmountDecimal,
  setWithdrawalValue,
} from "../../../Redux/Actions/withdrawAction";
import * as Common from "../../../Utils/commonFunctions";
import * as Constants from "../../../Utils/constants";
import SimpleCard from "../../../Components/SimpleCard/SimpleCard";
import "./WithdrawMoneyStyles.scss";
import { calculateStyles } from "../../../Utils/commonFunctions";

class WithdrawMoney extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openBottomSheet: false,
      invalidPrice: false,
      invalidPriceDecimal: false,
      invalidExceededPrice: false,
      withdrawAmount: "",
    };
  }

  componentDidMount() {
    this.props.setSelectedWithdrawAmountDecimal("00");
  }

  numberWithCommas = (x) => {
    const value = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  };

  buttonOnPress = () => {
    const { withdrawalAmount, cashAvailableForWithdrawal } = this.props;
    let newWithdrawalAmount = withdrawalAmount.replace(/,/g, "");
    let newcashAvailableForWithdrawal = cashAvailableForWithdrawal.replace(
      /,/g,
      ""
    );

    const updatedWithdrawalAmount = parseFloat(
      Common.removeLeadingZeroesForFloat(newWithdrawalAmount)
    );
    if (updatedWithdrawalAmount <= 0) {
      this.setState({
        invalidPrice: true,
      });
      return;
    } else if (
      updatedWithdrawalAmount >= parseFloat(newcashAvailableForWithdrawal)
    ) {
      this.setState({
        invalidExceededPrice: true,
      });
      return;
    }
    this.props.setSelectedWithdrawAmount(
      this.numberWithCommas(updatedWithdrawalAmount.toFixed(2))
    );
    this.props.setCashAvailableForWithdrawal(
      this.numberWithCommas(
        parseFloat(newcashAvailableForWithdrawal).toFixed(2)
      )
    );
    this.props.history.push("withdraw-money-confirm");
  };

  onHandleWithdrawAmount = (data) => {
    const regex1 = Utils.REGEX_WITHDRAW_MONEY1;
    const regex2 = Utils.REGEX_WITHDRAW_MONEY2;
    if (data === "" || regex1.test(data) || regex2.test(data)) {
      this.setState({
        invalidPrice: false,
        invalidExceededPrice: false,
        withdrawAmount: data,
      });
    } else {
      this.setState({
        invalidPrice: true,
        invalidExceededPrice: false,
        withdrawAmount: this.state.withdrawAmount,
      });
    }
    let str = data;
    if (str.length === 1) {
      str = data;
    } else {
      str = data.replace(Utils.REGEX_REMOVE_LEADING_ZERO, "");
    }
    this.props.setWithdrawalValue(str);
    let totValue = str + "." + this.props.withdrawalAmountDecimal;
    this.props.setSelectedWithdrawAmount(totValue);
  };

  onHandleWithdrawalMoneyDecimal = (data) => {
    const regex = Utils.REGEX_SELL_BUY_FUNC;
    if (data === "" || regex.test(data)) {
      this.setState({
        invalidPrice: false,
        invalidExceededPrice: false,
        invalidPriceDecimal: false,
      });
    } else {
      this.setState({
        invalidPrice: true,
      });
    }
    this.props.setSelectedWithdrawAmountDecimal(data);
    let totValue = this.props.withdrawalValue + "." + data;
    this.props.setSelectedWithdrawAmount(totValue);
  };

  onLeftIconClick = () => {
    this.props.setSelectedWithdrawAmount("");
    this.props.setWithdrawalValue("");
    this.props.history.goBack();
  };

  onButtonDisable = () => {
    if (
      this.state.invalidPrice ||
      this.state.invalidExceededPrice ||
      this.props.withdrawalAmount === ""
    ) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <Fragment>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft={"Withdraw Money"}
          headerContentRight=""
          rightIcon=""
          leftIconLink="dashboard"
          handleLeftIconClick={() => this.onLeftIconClick()}
        >
          <BodyContainer>
            <header
              className="App-container"
              data-testid="withdraw-money-container"
            >
              <div
                style={{
                  width: "100%",
                  justifyItems: "center",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    marginTop: "10%",
                    left: 0,
                    right: 0,
                  }}
                >
                  <h3 className="title" data-testid="amount-label">
                    Amount (LKR)
                  </h3>
                </div>

                <div
                  className="inputDiv"
                  style={{ justifyContent: "center", height: "5%" }}
                >
                  <TextInputStandard
                    autoFocus
                    label="withdraw-price"
                    onChange={(data) => {
                      this.onHandleWithdrawAmount(data);
                    }}
                    placeholder="00"
                    maxlength="9"
                    width="100%"
                    value={
                      this.props.withdrawalValue
                        ? this.props.withdrawalValue
                        : ""
                    }
                    alignInput="right"
                  />
                  <h1
                    style={{
                      alignSelf: "center",
                      justifySelf: "center",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    .
                  </h1>
                  <TextInputStandard
                    autoFocus={!!this.props.withdrawalAmountDecimal}
                    label="price-decimal"
                    onChange={(data) => {
                      this.onHandleWithdrawalMoneyDecimal(data);
                    }}
                    placeholder="00"
                    maxlength="2"
                    value={
                      this.props.withdrawalAmountDecimal
                        ? this.props.withdrawalAmountDecimal
                        : ""
                    }
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    top: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  {this.props.cashAvailableForWithdrawal ? (
                    <h3
                      className="am-input-title"
                      data-testid="cash-available-amount"
                    >
                      Available to withdraw = LKR{" "}
                      {this.props.cashAvailableForWithdrawal}
                    </h3>
                  ) : null}
                  {this.state.invalidPrice ? (
                    <h6
                      style={{ color: "red" }}
                      data-testid="valid-amount-error"
                    >
                      {Constants.VALID_AMOUNT_REQUIRED_ERROR}
                    </h6>
                  ) : null}
                  {this.state.invalidExceededPrice ? (
                    <h6
                      style={{
                        color: "red",
                        marginLeft: "15%",
                        marginRight: "15%",
                      }}
                      data-testid="amount-less-error"
                    >
                      {
                        Constants.AMOUNT_NEEDS_TO_BE_LESS_THAN_WITHDRAWAL_AMOUNT_ERROR
                      }
                    </h6>
                  ) : null}
                </div>
                <div
                  style={{
                    position: "fixed",
                    bottom: "20%",
                    left: 0,
                    right: 0,
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  <SimpleCard
                    bankName={this.props.bankName}
                    accountNumber={this.props.accountNumber}
                    bankLogo={this.props.bankLogo}
                  />
                </div>
                <div
                  style={{ position: "fixed", bottom: "3%", left: 0, right: 0 }}
                >
                  <a
                    style={{
                      color: "white",
                    }}
                  >
                    <button
                      data-testid="button-container"
                      style={{
                        margin: 0,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "5%",
                      }}
                      className="btn-transaction remove-outline"
                      disabled={this.onButtonDisable()}
                      onClick={() => {
                        this.buttonOnPress();
                      }}
                    >
                      NEXT
                    </button>
                  </a>
                </div>
              </div>
            </header>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    withdrawalAmount: state.WithdrawReducer.withdrawalAmount,
    bankName: state.WithdrawReducer.bankName,
    accountNumber: state.WithdrawReducer.accountNumber,
    cashAvailableForWithdrawal:
      state.WithdrawReducer.cashAvailableForWithdrawal,
    withdrawalAmountDecimal: state.WithdrawReducer.withdrawalAmountDecimal,
    withdrawalValue: state.WithdrawReducer.withdrawalValue,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedWithdrawAmount: (data) =>
      dispatch(setSelectedWithdrawAmount(data)),
    setCashAvailableForWithdrawal: (data) =>
      dispatch(setCashAvailableForWithdrawal(data)),
    setSelectedWithdrawAmountDecimal: (data) =>
      dispatch(setSelectedWithdrawAmountDecimal(data)),
    setWithdrawalValue: (data) => dispatch(setWithdrawalValue(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawMoney);
