const ENDPOINTS = {
  BASE_URL: window._env_.REACT_APP_DEV_API_URL,
  //dashboard
  MANAGEMENT_SERVICE_DASHBOARD: "api/ezs/stocks-management-service/dashboard",
  MANAGEMENT_SERVICE_ACCOUNT_SUMMARY:
    "api/ezs/stocks-management-service/account_statement_summary",
  AUTH_SERVICE_INVESTEMENT_ADVICE:
    "api/auth/stocks-auth-service/getBrokerInvetmentAdvisor",

  //add money
  MANAGEMENT_SERVICE_PRE_VALIDATE_ADD_MONEY:
    "api/ezs/stocks-management-service/preValidateAddMoney",
  MANAGEMENT_SERVICE_CHECK_COMMON_PAYMET_STATUS:
    "api/ezs/stocks-management-service/checkCommonPaymentStatus",
  MANAGEMENT_SERVICE_TOP_UP_CUSTOMER:
    "api/ezs/stocks-management-service/topupCustomerAccount",
  MANAGEMENT_SERVICE_GET_DIALOG_FINANCE_AC:
    "api/ezs/stocks-management-service/getDialogFinanceAccountNo",

  //kyc OTP
  TRIGGER_KYC_OTP: "api/auth/stocks-auth-service/sendCustomerKycOtp",
  VALIDATE_KYC_OTP: "api/auth/stocks-auth-service/validateCustomerKycOtp",
  CHECK_FRESH_USER_EMAIL_VERIFICATION_STATE:
    "api/auth/stocks-auth-service/customerEmailVerify",
  TRIGGER_RESEND_EMAIL_API:
    "api/auth/stocks-auth-service/sendEmailVerification",
  GET_CUSTOMER_STATUS_API: "api/auth/stocks-auth-service/getCustomerStatus",
  TRIGGER_EDIT_SELL_SHARE_AMOUNT_API:
    "api/ezs/stocks-management-service/stock-amount",

  GET_MARKET_OPEN_API: "api/ezs/stocks-management-service/stock-app-details",
};

export default ENDPOINTS;
