import React from "react";
import { connect } from "react-redux";
import "./TransactionFailStyles.scss";
import { Images } from "../../../Theme/Images";

class TransactionFail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openBottomSheet: false,
      invaldPrice: false,
      invalidQty: false,
    };
  }

  buttonOnPress = () => {
    this.props.history.push("dashboard");
  };

  render() {
    return (
      <div className="add-money-processing-main">
        <div className="add-money-processing-title">{"Transaction failed"}</div>
        <img
          src={Images.addMoney.anm_fail}
          alt="img"
          className="add-money-processing-img"
        />
        <div className="add-money-processing-description">
          {"Transaction failed. Please try again"}
        </div>
        <div className="add-money-processing-btnView">
          <button
            className="add-btn-transaction"
            onClick={() => {
              this.buttonOnPress();
            }}
          >
            <div className="add-money-processing-btn-txt">{"TRY AGAIN"}</div>
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionFail);
