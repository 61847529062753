import axios from "axios";
axios.defaults.timeout = 20000;

const BASE_URL = window._env_.REACT_APP_DEV_API_URL;

export const getMarketDepthService = async (securityId) => {
  const token = sessionStorage.getItem("token");
  if (token) {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/api/ezs/stocks-management-service/marketdepth/${securityId}`;
    let res = await axios
      .get(REGISTER_API_ENDPOINT, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } else {
    return null
  }
};

export const getSecurityStatService = async (securityId) => {

  const token = sessionStorage.getItem("token");
  if (token) {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/api/ezs/stocks-management-service/securitystatistics/${securityId}`;
    let res = await axios
      .get(REGISTER_API_ENDPOINT, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } else {
    return null
  }
};

export const getSellStockListService = async (selected_Broker) => {
  const token = sessionStorage.getItem('token');

  if (token) {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/api/ezs/stocks-management-service/order/selllist/${selected_Broker}`;
    let res = await axios
      .get(REGISTER_API_ENDPOINT, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        }
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } else {
    return null
  }
};

export const getSellStockStatustService = async (req) => {

  const token = sessionStorage.getItem('token');

  if (token) {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/api/ezs/stocks-management-service/stockdetails/sellstatus/${req.brokerCode}/${req.securityId}`;
    let res = await axios
      .get(REGISTER_API_ENDPOINT, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        }
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } else {
    return null
  }
};

export const buyStockConfirmationService = async (req, selected_Broker) => {

  const token = sessionStorage.getItem('token');

  if (token) {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/api/ezs/stocks-management-service/order/buy/${selected_Broker}`;
    let res = await axios
      .post(REGISTER_API_ENDPOINT, req, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        }
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error.response;
      });

    return res;
  } else {
    return null
  }
};

export const sellStockConfirmationService = async (req, selected_Broker) => {

  const token = sessionStorage.getItem('token');

  if (token) {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/api/ezs/stocks-management-service/order/sell/${selected_Broker}`;
    let res = await axios
      .post(REGISTER_API_ENDPOINT, req, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        }
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } else {
    return null
  }
};

export const getMarketStatusService = async (accToken) => {

  const token = sessionStorage.getItem('token');

  if (token) {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/api/notification/stocks-kafka-service/marketStatus`;
    let res = await axios
      .get(REGISTER_API_ENDPOINT, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        }
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } else {
    return null
  }
};

export const getCommisionService = async (req) => {

  const token = sessionStorage.getItem('token');

  if (token) {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/api/ezs/stocks-management-service/commission/${req.brokerCode}/${req.totPrice}`;
    let res = await axios
      .get(REGISTER_API_ENDPOINT, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        }
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } else {
    return null
  }
};

export const getOrderListBuyStatusService = async (req) => {

  const token = sessionStorage.getItem('token');

  if (token) {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/api/ezs/stocks-management-service/buystatus/${req.brokerCode}`;
    let res = await axios
      .get(REGISTER_API_ENDPOINT, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        }
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } else {
    return null
  }
};

export const cancelQueuedOrderService = async (req) => {

  const token = sessionStorage.getItem('token');
  if (token) {
    const { body, brokerCode, action } = req;
    const orderType = (action === 1) ? "buy" : "sell";
    const REGISTER_API_ENDPOINT = `${BASE_URL}/api/ezs/stocks-management-service/order/${orderType}/cancel/${brokerCode}`;
    let res = await axios
      .post(REGISTER_API_ENDPOINT, body, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } else {
    return null
  }
};


export const editQueuedBuyOrderService = async (req, headers) => {

  const token = sessionStorage.getItem('token');

  if (token) {
    const { brokerCode } = headers;
    const REGISTER_API_ENDPOINT = `${BASE_URL}/api/ezs/stocks-management-service/order/buy/amend/${brokerCode}`;
    let res = await axios
      .post(REGISTER_API_ENDPOINT, req, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } else {
    return null
  }
};

export const editQueuedSellOrderService = async (req, headers) => {

  const token = sessionStorage.getItem('token');

  if (token) {
    const { brokerCode } = headers;
    const REGISTER_API_ENDPOINT = `${BASE_URL}/api/ezs/stocks-management-service/order/sell/amend/${brokerCode}`;
    let res = await axios
      .post(REGISTER_API_ENDPOINT, req, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } else {
    return null
  }
};

export const getMarketSummaryService = async (date) => {
  const token = sessionStorage.getItem('token');

  if (token) {
    const GET_MARKET_SUMMARY = `${BASE_URL}/api/notification/stocks-kafka-service/sector-index/${date}`;
    let res = await axios
      .get(GET_MARKET_SUMMARY, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } else {
    return null
  }
};

export const getTradeStatusService = async (securityId) => {
  const token = sessionStorage.getItem('token');

  if (token) {
    const GET_TRADE_STATUS = `${BASE_URL}/api/ezs/stocks-management-service/tradestatus/${securityId}`;
    let res = await axios
      .get(GET_TRADE_STATUS, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } else {
    return null
  }
};