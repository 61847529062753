export const REGEX_SELL_BUY_FUNC = /^[0-9\b]+$/;
export const REGEX_REMOVE_LEADING_ZERO = /^0+/ ;
export const REGEX_WITHDRAW_MONEY1 = /^[0-9]+(\.[0-9]{1,2})?$/;
export const REGEX_WITHDRAW_MONEY2 = /^\d{1,3}(,\d{3})*(\.\d{1,2})?$/;
export const VALID_AMOUNT_REQUIRED_ERROR = "Valid amount required";
export const AMOUNT_NEEDS_TO_BE_LESS_THAN_WITHDRAWAL_AMOUNT_ERROR = "Amount has to be less than the withdrawal amount";
export const REGEX_FOR_OTP = /\d{6}/;
export const MAXIMUM_OTP_ATTEMPT_COUNT_ERROR = "OTP has been checked maximum number of times. Try again after sometime.";
export const INVALID_OTP_ERROR = "Invalid OTP";
export const EXPIRED_OTP_ERROR = "The OTP you have entered has expired";
export const DISPLAY_MOBILE_NUMBER = /(\d{3})(\d{3})(\d{3})/;
export const FINPAL_REDIRECTION_URL = "https://apps.finpal.lk/";

//ADD_MONEY_INITIATE_SCREEN
export const ADD_MONEY_INITIATE_HEADER_TEXT = "Choose how you would like to perform the transaction";
export const ADD_MONEY_INITIATE_PAYMENT_METHOD_GENIE = "Using genie";
export const ADD_MONEY_INITIATE_PAYMENT_METHOD_GENIE_DESCRIPTION = "Transfer using payment options in genie";
export const ADD_MONEY_INITIATE_PAYMENT_METHOD_FUND_TRANSFER = "Fund transfer";
export const ADD_MONEY_INITIATE_PAYMENT_METHOD_FUND_TRANSFER_DESCRIPTION = "Transfer funds via a bank transfer (CEFT)";

//URL_ENDPOINTS
export const URL_ENDPOINT_ADD_MONEY = "/add-money";
export const URL_ENDPOINT_DASHBOARD = "/dashboard";
export const URL_ENDPOINT_FUND_TRANSFER_DETAILS_PAGE = "/fundTransferDetails";