import React, { Fragment } from "react";
import { connect } from "react-redux";
import ArrowLeftIcon from "../../Assets/Icons/arrow_left.svg";
import COLORS from "../../Assets/Colors";
import { withStyles } from "@mui/styles";
import Layout from "../Layout/Layout";
import BodyContainer from "../../Components/BodyArea/Body";
import * as KycActions from "../../Redux/Actions/KycActions";
import ErrorCodes from "../../Assets/ErrorCodes";
import LoadingTimerButton from "../../Components/LoadingTimerButton/LoadingTimerButton";
import { TextField } from "@mui/material";

const styles = () => ({
  main: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    paddingTop: "1.72em",
    marginBottom: "1.87em",
    paddingLeft: "1em",
    paddingRight: "1em",
  },
  mainHeader: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    paddingTop: "1em",
  },
  headerTxt: {
    textAlign: "center",
    color: COLORS.BLACK,
    fontSize: "1.6em",
    marginBottom: "0.375em",
    fontFamily: "Open Sans",
    fontWeight: "600",
  },
  decriptionTxt: {
    textAlign: "center",
    color: COLORS.BATTLESHIP_GREY,
    fontSize: ".8em",
    marginBottom: "1.875em",
    fontFamily: "Open Sans",
    fontWeight: "400",
  },
  buttonContainer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    paddingBottom: "2em",
  },
  inputFieldTxt: {
    width: "100%",
  },
  errorTxt: {
    fontWeight: "600",
    color: COLORS.ERROR_RED,
    fontSize: "0.85em",
    marginLeft: "1.31em",
    fontFamily: "Open Sans",
  },
  cssLabel: {
    color: COLORS.BLACK,
    fontFamily: "Open Sans",
    fontWeight: "200",
  },

  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `${COLORS.BLACK} !important`,
    },
    color: COLORS.BLACK,
  },

  cssFocused: {
    color: `${COLORS.BLACK} !important`,
    fontFamily: "Open Sans",
    fontSize: "1em",
  },

  notchedOutline: {
    borderWidth: "1px",
    borderColor: `${COLORS.BLACK} !important`,
  },
});

class KYCotpVerificationScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
    };
  }

  componentDidMount() {
    this.props.storeVerifiedKycOTPStatus("");
  }

  renderErrorMsgs = (verifiedKycOtpStatus) => {
    if (verifiedKycOtpStatus) {
      switch (verifiedKycOtpStatus) {
        case ErrorCodes.OTP.TX_OTP_EXPIRED:
          return "Please request for another OTP";
        case ErrorCodes.OTP.TX_INVALID_OTP:
          return "Please check and enter the correct OTP";
        case ErrorCodes.OTP.OTP_TRIES_EXCEEDED:
          return "Please try again in a few minutes";
        default:
          break;
      }
    } else {
      return;
    }
  };

  onOTPentered = (pin) => {
    pin = pin.trim();
    const { storeVerifiedKycOTPStatus, verifyKycOTP } = this.props;
    this.setState({ otp: pin });
    storeVerifiedKycOTPStatus("");
    if (pin) {
      if (pin.length === 6) {
        let payload = {
          brokerCode: "ARR",
          OTP: pin,
          validationSucessNavigation: "",
        };
        verifyKycOTP(payload);
      }
    }
  };

  onBackButtonPressed = () => {
    this.props.storeVerifiedKycOTPStatus("");
    this.props.history.goBack();
  };

  onResendButtonClicked = () => {
    let payload = {
      brokerName: this.props.selectedBroker,
      flowtype: "RESEND",
      navigateBack: () => {
        this.props.history.goBack();
      },
    };
    this.props.triggerKycOTP(payload);
  };

  render() {
    const { classes, verifiedKycOtpStatus, otpMobileNumber } = this.props;
    return (
      <Fragment>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft={""}
          handleLeftIconClick={() => {
            this.onBackButtonPressed();
          }}
        >
          <BodyContainer>
            <div className={classes.main}>
              <div className={classes.mainHeader}>
                <div className={classes.headerTxt}>{"Verification code"}</div>
                <div className={classes.decriptionTxt}>
                  {`Enter the 6-digit code we’ve sent to ${
                    otpMobileNumber
                      ? `+94 ${otpMobileNumber
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`
                      : ""
                  }`}
                </div>
                <div>
                  <TextField
                    id="standard-name"
                    label="Enter the code"
                    className={classes.inputFieldTxt}
                    value={this.state.otp}
                    onChange={(e) => this.onOTPentered(e.target.value)}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                    inputMode="numeric"
                    inputProps={{
                      maxLength: 6,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                  />
                </div>
                <div className={classes.errorTxt}>
                  {this.renderErrorMsgs(verifiedKycOtpStatus)}
                </div>
                <div className={classes.buttonContainer}>
                  <LoadingTimerButton
                    buttonTxt={"RESEND"}
                    onButtonClicked={() => this.onResendButtonClicked()}
                  />
                </div>
              </div>
            </div>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

export function mapDispatchToProps(dispatch) {
  return {
    verifyKycOTP: (payload) => dispatch(KycActions.verifyKycOTP(payload)),
    storeVerifiedKycOTPStatus: (payload) =>
      dispatch(KycActions.storeVerifiedKycOTPStatus(payload)),
    triggerKycOTP: (payload) => dispatch(KycActions.triggerKycOTP(payload)),
  };
}

function mapStateToProps(state) {
  return {
    verifiedKycOtpStatus: state.KYCreducer.verifiedKycOtpStatus,
    selectedBroker: state.UserValidationReducer.selectedBroker,
    otpMobileNumber: state.ComponentReducer.otpMobileNumber,
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(KYCotpVerificationScreen)
);
