import * as Types from "./index";

export const triggerKycOTP = (payload) => {
  return {
    type: Types.TRIGGER_KYC_OTP,
    payload,
  };
};

export const verifyKycOTP = (payload) => {
  return {
    type: Types.VERIFY_KYC_OTP,
    payload,
  };
};

export const storeVerifiedKycOTPStatus = (payload) => {
  return {
    type: Types.STORE_VERIFIED_KYC_OTP_STATUS,
    payload,
  };
};

export const setRemainingTimeForOTP = (payload) => {
  return {
    type: Types.SET_REMAINING_TIME__FOR_OTP,
    payload,
  };
}
export const setOTPCounter = (payload) => {
  return {
    type: Types.SET_OTP_COUNTDOWN,
    payload,
  };
}

// resend email
export const checkFreshUserEmailVerificationState = (payload) => {
  return {
    type: Types.CHECK_FRESH_USER_EMAIL_VERIFICATION_STATE,
    payload,
  };
}
export const storeFreshUserEmailVerificationState = (payload) => {
  return {
    type: Types.STORE_FRESH_USER_EMAIL_VERIFICATION_STATE,
    payload,
  };
}
export const triggerResendEmail = (payload) => {
  return {
    type: Types.TRIGGER_RESEND_EMAIL,
    payload,
  };
}
// 

