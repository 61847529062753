import { createReducer } from "@reduxjs/toolkit";
import * as types from "../Actions";

let initialState = {
  verifiedKycOtpStatus: "",
  emailVerificationStateForFreshUser: "",
};

const KYCreducer = createReducer(initialState, {
  [types.STORE_VERIFIED_KYC_OTP_STATUS](state, action) {
    return {
      ...state,
      verifiedKycOtpStatus: action.payload,
    };
  },
  [types.STORE_FRESH_USER_EMAIL_VERIFICATION_STATE](state, action) {
    return {
      ...state,
      emailVerificationStateForFreshUser: action.payload,
    };
  },
});

export default KYCreducer;
