import * as Types from "../Actions";

const initailState = {
  withdrawalAmount: "",
  withdrawalAmountDecimal: "",
  withdrawalValue: "",
  bankName: "",
  accountNumber: "",
  mobileNumber: "",
  otpCounter: 0,
  otpValue: "",
  circularLoadingFlag: false,
  timerValue: 0,
  buttonDisable: false,
  cashAvailableForWithdrawal: "",
  bankDetails: {},
  otpRequestDetails: {},
  otpVerifyDetails: {},
  otpCounterStatus: "",
  otpSend: true,
  fundTransferDetails: {},
  requestId: "",
  customerBalanceWithdrawalStatus: {},
  customerBalanceWithdrawalApiState: false,
};

const withdrawReducer = (state = initailState, action) => {
  switch (action.type) {
    case Types.SET_WITHDRAW_AMOUNT:
      return { ...state, withdrawalAmount: action.payload };
    case Types.SET_WITHDRAW_AMOUNT_DECIMAL:
      return { ...state, withdrawalAmountDecimal: action.payload };
    case Types.SET_WITHDRAW_VALUE:
      return { ...state, withdrawalValue: action.payload };

    case Types.SET_WITHDRAW_BANK_NAME:
      return { ...state, bankName: action.payload };

    case Types.SET_WITHDRAW_BANK_ACCOUNT_NUMBER:
      return { ...state, accountNumber: action.payload };

    case Types.SET_WITHDRAW_MOBILE_NUMBER:
      return { ...state, mobileNumber: action.payload };

    case Types.SET_OTP_SENDING_COUNTER:
      return { ...state, otpCounter: action.payload };

    case Types.SET_OTP_VALUE:
      return { ...state, otpValue: action.payload };

    case Types.SET_CIRCULAR_LOADING_FLAG:
      return { ...state, circularLoadingFlag: action.payload };

    case Types.SET_TIMER_VALUE_FOR_CIRCULAR_BAR:
      return { ...state, timerValue: action.payload };

    case Types.SET_BUTTON_DISABLE:
      return { ...state, buttonDisable: action.payload };

    case Types.SET_CASH_AVAILABLE_FOR_WITHDRAWAL:
      return { ...state, cashAvailableForWithdrawal: action.payload };

    case Types.GET_BANK_DETAILS_SUCCESS:
      return { ...state, bankDetails: action.payload };

    case Types.GET_BANK_DETAILS_FAIL:
      return { ...state, bankDetails: {} };

    case Types.SET_OTP_COUNTER_STATUS:
      return { ...state, otpCounterStatus: action.payload };

    case Types.SET_OTP_SEND:
      return { ...state, otpSend: action.payload };

    case Types.GET_OTP_REQUEST_SUCCESS:
      return { ...state, otpRequestDetails: action.payload };

    case Types.GET_OTP_REQUEST_FAIL:
      return { ...state, otpRequestDetails: {} };

    case Types.GET_OTP_VALIDATE_SUCCESS:
      return { ...state, otpVerifyDetails: action.payload };

    case Types.GET_OTP_VALIDATE_FAIL:
      return { ...state, otpVerifyDetails: {} };

    case Types.POST_FUND_TRANSFER_SUCCESS:
      return { ...state, otpVerifyDetails: action.payload.data };

    case Types.POST_FUND_TRANSFER_FAIL:
      return { ...state, otpVerifyDetails: {} };

    case Types.SET_REQUEST_ID:
      return { ...state, requestId: action.payload };

    case Types.CUSTOMER_BALANCE_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        customerBalanceWithdrawalStatus: action.payload,
        customerBalanceWithdrawalApiState: true,
      };

    case Types.CUSTOMER_BALANCE_WITHDRAWAL_FAIL:
      return {
        ...state,
        customerBalanceWithdrawalStatus: {},
        customerBalanceWithdrawalApiState: false,
      };

    default:
      return state;
  }
};

export default withdrawReducer;
