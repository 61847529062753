import * as Types from "./index";

export const checkAccountAvailability = () => {
  return {
    type: Types.GET_BUY_STOCK_LIST,
  };
};

export const setSelectedStock = (payload) => {
  return {
    type: Types.SELECTED_STOCK,
    payload,
  };
};
