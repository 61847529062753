import React from "react";
import CircularStatic from "./CircularStatic";

const CircularProgress = (props) => {
  const {
    timer,
    size,
    thickness,
    ...rest
  } = props;
  return (
    <div>
      <CircularStatic
        timerValue={timer}
        size={size}
        thickness={thickness}
        {...rest}
      />
    </div>
  );
};

export default CircularProgress;
