export const buyStockPrice = state => state.BuyStockReducer.buyStockPrice;
export const buyStockPriceDecimal = state => state.BuyStockReducer.buyStockPriceDecimal;
export const sellStockPrice = state => state.BuyStockReducer.sellStockPrice;
export const sellStockPriceDecimal = state => state.BuyStockReducer.sellStockPriceDecimal;
export const buyStockQuantity = state => state.BuyStockReducer.buyNumberOfShares;
export const sellStockQuantity = state => state.BuyStockReducer.sellNumberOfShares;
export const buyTif = state => state.BuyStockReducer.buyStockDuration.tif;
export const buyTifDays = state => state.BuyStockReducer.buyStockDuration.tifDays;
export const sellTif = state => state.BuyStockReducer.sellStockDuration.tif;
export const sellTifDays = state => state.BuyStockReducer.sellStockDuration.tifDays;
export const buyOrderEditItem = state => state.BuyStockReducer.buyOrderEditItem;
export const orderEditStatus = state => state.BuyStockReducer.buyOrderEditStatus;
export const orderEditPrice = state => state.BuyStockReducer.orderEditItemPrice;
export const orderEditPriceDecimal = state => state.BuyStockReducer.orderEditItemPriceDecimal;
export const orderEditQty = state => state.BuyStockReducer.orderEditItemQty;
export const orderItem = state => state.BuyStockReducer.buyOrderEditItem;
export const date = state => state.BuyStockReducer.date;