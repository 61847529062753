import React from "react";
import { connect } from "react-redux";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import "./AddMoneyStyles.scss";
import { TextInputStandard } from "../../../Components/TextInput/index";
import * as addMoneyActions from "../../../Redux/Actions/addMoneyAction";
import * as Utils from "../../../Utils/constants";
import Header from "../../../Components/Header/Header";

class AddMoney extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidPrice: false,
      invalidPriceDecimal: false,
      invalidCharacters: false,
      buttonDisabled: false,
      autoFocusVal: true,
    };
  }

  componentDidMount() {
    this.props.setAddmoneyAmountDecimal("00");
  }

  generateUniqueId() {
    let dateNow = Date.now();
    return dateNow;
  }

  numberWithCommas = (x) => {
    const value = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  };

  buttonOnPress(e) {
    this.setState({
      autoFocusVal: false,
    });
    if (isNaN(this.props.addMoneyAmount)) {
      this.setState({
        invaldPrice: true,
      });
      return;
    } else if (isNaN(this.props.addMoneyAmountDecimal)) {
      this.setState({
        invalidPriceDecimal: true,
      });
      return;
    } else {
      this.setState({
        invaldPrice: false,
        invalidPriceDecimal: false,
      });
    }
    let addMoneyPrice =
      this.props.addMoneyAmount + "." + this.props.addMoneyAmountDecimal;

    if (
      parseFloat(addMoneyPrice) <= 0 ||
      addMoneyPrice === "" ||
      this.props.addMoneyAmount === "" ||
      this.props.addMoneyAmountDecimal === "" ||
      this.props.addMoneyAmountDecimal.length !== 2
    ) {
      this.setState({
        invaldPrice: true,
      });
    } else {
      let generateReqId = this.generateUniqueId();
      let reqBody = {
        requestId: generateReqId,
        amount:
          this.props.addMoneyAmount + "." + this.props.addMoneyAmountDecimal,
      };
      this.props.preValidateAddMoney(reqBody, () => {
        try {
          let cds_account =
            this.props.accountSummary.accountNumber.split("/")[1];
          localStorage.setItem("cds_account", cds_account);
        } catch (error) {}
        let acc_token = sessionStorage.getItem("token");
        localStorage.setItem("requestId", generateReqId);
        localStorage.setItem("token", acc_token);
        localStorage.setItem(
          "amount",
          this.props.addMoneyAmount + "." + this.props.addMoneyAmountDecimal
        );
        this.props.setAddmoneyAmount("");
        window.ReactNativeWebView.postMessage(
          `https://apps.finpal.lk/api/tokenization?txnToken=${this.props.commonPaymentToken}&initiator=stocksPay`
        );
      });
    }
  }

  onHandleAddMoneyPrice = (data) => {
    const regex = Utils.REGEX_SELL_BUY_FUNC;
    if (data === "" || regex.test(data)) {
      this.setState({
        invaldPrice: false,
        invalidPriceDecimal: false,
        buttonDisabled: false,
        invalidCharacters: false,
      });
    } else {
      this.setState({
        invalidCharacters: true,
        buttonDisabled: true,
        invaldPrice: false,
        invalidPriceDecimal: false,
      });
    }
    let str = data;
    if (str.length === 1) {
      str = data;
    } else {
      str = data.replace(Utils.REGEX_REMOVE_LEADING_ZERO, "");
    }

    this.props.setAddmoneyAmount(str);
  };

  onHandleAddMoneyDecimal = (data) => {
    const regex = Utils.REGEX_SELL_BUY_FUNC;
    if (data === "" || regex.test(data)) {
      this.setState({
        invaldPrice: false,
        invalidPriceDecimal: false,
        buttonDisabled: false,
        invalidCharacters: false,
      });
    } else {
      this.setState({
        invalidCharacters: true,
        buttonDisabled: true,
        invaldPrice: false,
        invalidPriceDecimal: false,
      });
    }
    this.props.setAddmoneyAmountDecimal(data);
  };

  onLeftIconClick = () => {
    this.props.setAddmoneyAmount("");
    this.props.history.push("add-money-initiate");
  };

  containerTouched = (events) => {
    this.refs.textInput.blur();
    return false;
  };

  render() {
    return (
      <div className="add-money-main">
        <Header
          leftIcon={ArrowLeftIcon}
          headerContentLeft={"Add money"}
          leftIconLink="dashboard"
          handleLeftIconClick={() => this.onLeftIconClick()}
        />
        <div className="add-money-content">
          <div className="amount-label">{`Amount (LKR)`}</div>
          <div className="inputDiv">
            <TextInputStandard
              autoFocus={this.state.autoFocusVal}
              onChange={(data) => {
                this.onHandleAddMoneyPrice(data);
              }}
              placeholder="00"
              maxlength="9"
              width="100%"
              value={this.props.addMoneyAmount ? this.props.addMoneyAmount : ""}
              alignInput="right"    
            />
            <h1
              style={{
                alignSelf: "center",
                justifySelf: "center",
                marginTop: "0px",
                marginBottom: "0px",
              }}
            >
              .
            </h1>
            <TextInputStandard
              onChange={(data) => {
                this.onHandleAddMoneyDecimal(data);
              }}
              placeholder="00"
              maxlength="2"
              value={
                this.props.addMoneyAmountDecimal
                  ? this.props.addMoneyAmountDecimal
                  : ""
              }
            />
          </div>
          {this.state.invaldPrice || this.state.invalidPriceDecimal ? (
            <h6 style={{ color: "red" }}>{"Value required"}</h6>
          ) : null}
          {this.state.invalidCharacters ? (
            <h6 style={{ color: "red" }}>{"Valid amount required"}</h6>
          ) : null}
        </div>
        <div className="add-money-btn-view">
          <button
            className="add-btn-transaction"
            disabled={this.state.buttonDisabled}
            onClick={(e) => {
              this.buttonOnPress(e);
            }}
          >
            {"CONTINUE"}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addMoneyAmount: state.AddMoneyReducer.addMoneyAmount,
    addMoneyAmountDecimal: state.AddMoneyReducer.addMoneyAmountDecimal,
    commonPaymentToken: state.AddMoneyReducer.commonPaymentToken,
    accountSummary: state.DashboardReducer.accountSummary,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setAddmoneyAmount: (data) =>
      dispatch(addMoneyActions.setAddmoneyAmount(data)),
    setAddmoneyAmountDecimal: (data) =>
      dispatch(addMoneyActions.setAddmoneyAmountDecimal(data)),
    setAddmoneyUniqueId: (data) =>
      dispatch(addMoneyActions.setAddmoneyUniqueId(data)),
    preValidateAddMoney: (data, callback) =>
      dispatch(addMoneyActions.preValidateAddMoney(data, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMoney);
