import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Images } from "../../Theme/Images";
import {
  Dialog,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Radio,
  RadioGroup,
  FormControlLabel,
  Alert,
} from "@mui/material";
import { startBrowserBack, stopBrowserBack } from "../../Utils/helper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BottomSheet(props) {
  const {
    dropdownTitle,
    handleNavigation,
    bottomSheetStatus,
    handleOnClose,
    setSelectedOderDuration,
    dataArr,
    marketStatusOpen,
    handleIconClickOpen,
  } = props;
  
  const classes = useStyles();
  const [value, setValue] = React.useState();

  useEffect(() => {
    setValue(dataArr[marketStatusOpen ==="Close" ? 1 : 0] ? dataArr[marketStatusOpen ==="Close" ? 1 : 0].date : "");
  }, [dataArr]);
 
  const handleClose = () => {
    handleOnClose();
    startBrowserBack();
  };

  const handleRadioChange = (event, item) => {
   // console.log(event.target.value);
    //console.log(item);
    setValue(event.target.value);
    setSelectedOderDuration(item);
  };

  const handleOnReviewOrder = () => {
    handleNavigation();
  };

  useEffect(() => {
    if (bottomSheetStatus) {
      stopBrowserBack(handleClose);
    }
  }, [bottomSheetStatus]);

const handleClickOpen=()=>{
  handleIconClickOpen();
  startBrowserBack();
}


 
  return (
    <div>
      <Dialog
        fullScreen
        open={bottomSheetStatus}
        onClose={handleClose}
        TransitionComponent={Transition}
        classes={{ paper: classes.dialogPaper }}
      >
        <div className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              style={{ padding: "12px 12px", marginTop: "-12px"}}
              edge="start"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
           
           <div >
            
          <Typography variant="h8" className={classes.subTitle1}  >  
            Market status :  
            <div className={classes.subTitle1}>
              {marketStatusOpen ==="Open" ? (
                <div className={classes.subTitle2}>
                  {'Open'}
                </div>
              ) : (<div className={classes.subTitle3}>
                {'Closed'}
              </div>)}
           </div>
          </Typography>
            
          </div>
          </Toolbar>
          <Toolbar>
           
            <Typography variant="h6" className={classes.title} style={{marginLeft: "39px !important",  whiteSpace: "pre-line",
          fontWeight: "bold"}}>
              {dropdownTitle}
            </Typography>
          </Toolbar>
          <Typography variant="h6" className={classes.subTitle} >
            order will remain active for ;
          </Typography>
        </div>
               
        <div >
        <div >
        <div hidden={marketStatusOpen ==="Open"} className={classes.infoIconStyle} >
              <img onClick={handleClickOpen} alt="logo" 
                src={Images.dashboard.ic_info} >
              </img>
             </div>
        </div>
           
              
              </div>
   
        <div>
          {dataArr.map((item, idx) => {
            return (
              <div>
                 <div style={{ marginLeft: "20px"}} key={idx}>
                 <RadioGroup
                   row 
                   aria-label="position" 
                   name="quiz" 
                   value={value}  onChange={(event) => handleRadioChange(event, item)}
                   
                 >
                   <FormControlLabel
                     style={{
                       margin: "5px 0px",
                     }}
                     value={item.date} 
                     control={
                       <Radio disabled={(marketStatusOpen ==="Close"&& idx===0 )} 
                       name="radioValues"  
                         style={{ paddingLeft: "0px", color: "#2598ed" }}
                         className="form-check-input" 
                       />
                     }
                     //checked={marketStatusOpen ==="Close"? idx===1 :idx===0} checked={item.date===value}
                     label={item.name}
                     labelPlacement="end"
                   />
                 </RadioGroup>
                 <Divider />
               </div>
              
              </div>
            );
          })}
          <div
            className="row no-padding"
            style={{ position: "sticky", bottom: 0, left: 0, right: 0 }}
          >
            <div className="col-12">
              <div style={{ color: "white" }}>
                <button 
                  className="btn-transaction "
                  style={{ backgroundColor: "black" }}
                  onClick={handleOnReviewOrder}
                >
                  REVIEW ORDER
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}


const useStyles = makeStyles((theme) => ({
  appBar: {
    outline: "none",
    backgroundColor: "#ffffff !important",
  },
  subTitle: {
    fontFamily: "Open Sans",
    fontWeight: "normal",
    fontSize: "small !important",
    marginLeft: "56px !important",
    width: "100%",
    color: "#333333",
  },
  subTitle1: {
    fontFamily: "Open Sans",
    fontWeight: "normal",
    fontSize: "0.874em",
    marginLeft: "5px !important",
    width: "100%",
    color: "#333333",
  },
  subTitle2: {
    fontFamily: "Open Sans",
    fontWeight: "normal",
    fontSize: "0.874em",
    marginLeft: "105px !important",
    width: "100%",
    color: "#49bd64",
    marginTop: "-18px !important",
  },
  subTitle3: {
    fontFamily: "Open Sans",
    fontWeight: "normal",
    fontSize: "0.874em",
    marginLeft: "105px !important",
    width: "100%",
    color: "red",
    marginTop: "-18px !important",
  },
  infoIconStyle: {
    marginLeft: "340px !important",
    marginTop: "11px !important",
    position: "absolute !important"
  },
  title: {
    fontFamily: "Open Sans",
    fontWeight: "bold",
    fontSize: "1.498em",
    marginLeft: "4px",
    width: "50%",
    color: "#333333",
    marginTop: "30px",
    marginBottom: "10px",
    lineHeight: "32px",
    marginLeft: "38px !important",
  },
  dialogPaper: {
    minHeight: "60vh !important",
    maxHeight: "60vh !important",
    position: "fixed !important",
    bottom: 0,
    borderTopLeftRadius: "16px !important",
    borderTopRightRadius: "16px !important",
    display: "flex !important",
    flexDirection: "column !important",
    paddingTop: "1em",
    overflow: "none !important",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "90%",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000000",
    },
    "& .MuiOutlinedInput-root.Mui-focused .focusedLabel": {
      color: "#000000",
    },
    "& label.Mui-focused": {
      color: "#000000",
    },
  },
  radio: {
    "&$checked": {
      color: "red",
    },
  },
  checked: {},
}));