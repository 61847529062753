import * as types from "../Actions";

export const initailState = {
  addMoneyAmount: "",
  addMoneyAmountDecimal: "",
  addMoneyUniqueId: "",
  commonPaymentStatus: {},
  atradAddMoney: {},
  commonPaymentToken: "",
  addMoneyInitiateState: false,
  addMoneyFundTranferDetails: "",
};

export default function (state = initailState, action) {
  switch (action.type) {
    case types.SET_ADD_MONEY_AMOUNT:
      return { ...state, addMoneyAmount: action.payload };
    case types.SET_APP_MONEY_AMOUNT_DECIMAL:
      return { ...state, addMoneyAmountDecimal: action.payload };
    case types.SET_ADD_MONEY_UNIQUE_ID:
      return { ...state, addMoneyUniqueId: action.payload };
    case types.COMMON_PAYMENT_MODULE_STATE_SUCCESS:
      return { ...state, commonPaymentStatus: action.payload.data.DATA };
    case types.COMMON_PAYMENT_MODULE_STATE_FAIL:
      return { ...state, commonPaymentStatus: {} };
    case types.PRE_VALIDATE_ADD_MONEY_SUCCESS:
      return { ...state, commonPaymentToken: action.payload.data.DATA.data };
    case types.PRE_VALIDATE_ADD_MONEY_FAIL:
      return { ...state, commonPaymentToken: "" };
    case types.ATRAD_ADD_MONEY_SUCCESS:
      return { ...state, atradAddMoney: action.payload.data.DATA };
    case types.ATRAD_ADD_MONEY_FAIL:
      return { ...state, atradAddMoney: {} };
    case types.INITIATE_ADD_MONEY:
      return { ...state, addMoneyInitiateState: action.payload };
    case types.STORE_FUND_TRANFER_INFO:
      return { ...state, addMoneyFundTranferDetails: action.payload };
    default:
      return state;
  }
}
