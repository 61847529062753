import React, { Component,useState } from "react";
import { connect } from "react-redux";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import "./BuyStockStyles.scss";
import { Divider } from "@mui/material";
import { Images } from "../../../Theme/Images";
import moment from "moment";
import { TextInputStandard } from "../../../Components/TextInput/index";
import BottomSheet from "../../../Components/BottomSheet/BottomSheet";
import * as commonFunctions from "../../../Utils/commonFunctions";
import * as stockActions from "../../../Redux/Actions/stockAction";
import * as commonActions from "../../../Redux/Actions/commonAction";
import * as Utils from "../../../Utils/constants";
import values from "../../../Assets/ConfigurableValues";
import Header from "../../../Components/Header/Header";
import {
  getMarketStatus,
 
} from "../../../Redux/Actions/stockAction";

const BASE_URL = window._env_.REACT_APP_DEV_API_URL;
const BUY_STOCKS_CALCULATION_VALIDATION_AMOUNT =
  window._env_.REACT_APP_BUY_STOCKS_MAX_LIMIT;
let eventSource = undefined;

const CONSIDERD_VALUE_FOR_INCREAMENT = 100;
const INCREAMENT_BELOW_HUNDRAD = 10;
const INCREAMENT_ABOVE_HUNDRAD = 25;

class BuyStock extends Component {
  constructor(props) {
    super(props);
    const {
      orderSelectedItemPrice,
      buyStockPrice,
      orderSelectedItemPriceDecimal,
      buyStockPriceDecimal,
      orderSelectedItemQty,
      noOfShares,
    } = props;

    this.state = {
      buyStockPrice: orderSelectedItemPrice
        ? orderSelectedItemPrice.toString()
        : buyStockPrice
        ? buyStockPrice
        : "",
      buyStockPriceDecimal: orderSelectedItemPriceDecimal
        ? commonFunctions.fixDecimalPoints(orderSelectedItemPriceDecimal)
        : buyStockPriceDecimal
        ? buyStockPriceDecimal
        : "",
      noOfShares: orderSelectedItemQty
        ? orderSelectedItemQty
        : noOfShares
        ? noOfShares
        : "",
      invaldPrice: false,
      invalidQty: false,
      currentPrice: "",
      lastTradedPrice: "",
      securityValue: "",
      marketOpenDays: [],
      priceOnFocus: false,
      amountExceedError: false,
      validDecimalEnteredErrorBelowHundrad: false,
      validDecimalEnteredErrorAboveHundrad: false,
      noValueValidation: false,
      marketStatus: "",
      infoIconStatus: false,
    };
  }

  componentDidMount() {
   
    this.props.getMarketStatus();
    this.handleEventSource();
  }

  componentWillUnmount() {
    eventSource.close();
  }

  handleEventSource() {
    eventSource = new EventSource(
      `${BASE_URL}/api/notification/stocks-kafka-service/event/stream/watch-data`
    );
    eventSource.addEventListener("watchData", (event) =>
      this.handleWatchData(event)
    );
    eventSource.onopen = () => this.handleOpen();
    eventSource.onerror = (event) => this.handleError(event);
  }

  onLeftIconClick = () => {
    this.props.setBuyStockPriceDecimal("");
    this.props.setBuyNumberOfShares("");
    this.props.setSelectedOrderItemQuantity("");
    this.props.setBuyStockPrice("");
    this.props.setSelectedOrderItemPrice("");
    this.props.setSelectedOrderItemPriceDecimal("");
    this.props.history.goBack();
  };

  handleWatchData = (event) => {
    const { buyOrderEditItem, buyOrderEditStatus, selectedStock } = this.props;

    let watchData = JSON.parse(event.data);
    let securityValue = watchData.security;
    let currentPrice = watchData.askprice;
    let lastTradedPrice = watchData.tradeprice;

    if (buyOrderEditStatus) {
      if (buyOrderEditItem && buyOrderEditItem.securitycode !== "") {
        if (buyOrderEditItem.securitycode === securityValue) {
          this.setState({ securityValue, currentPrice, lastTradedPrice });
        }
      }
    } else {
      if (selectedStock && selectedStock.security !== "") {
        if (selectedStock.security === securityValue) {
          this.setState({ securityValue, currentPrice, lastTradedPrice });
        }
      }
    }
  };

  handleOpen = () => {};

  handleError = (event) => {
    if (event.target.readyState === EventSource.CLOSED) {
    }
    eventSource.close();
  };

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  buttonOnPress() {
    const { buyOrderEditStatus, getBuyCommission } = this.props;

    const { buyStockPrice, buyStockPriceDecimal, noOfShares } = this.state;
    if (!buyStockPrice && !buyStockPriceDecimal) {
      this.setState({
        invaldPrice: true,
      });
      return;
    } else if (!buyStockPrice || !buyStockPriceDecimal) {
      this.setState({
        noValueValidation: true,
      });
      return;
    } else if (noOfShares && (buyStockPrice || buyStockPriceDecimal)) {
      let buyStockPriceDecimal_ =
        buyStockPriceDecimal && buyStockPriceDecimal !== 0
          ? buyStockPriceDecimal
          : 0.0;
      let amnt =
        Number(noOfShares) *
        (Number(buyStockPrice) + Number(buyStockPriceDecimal_) * 0.01);
      if (
        BUY_STOCKS_CALCULATION_VALIDATION_AMOUNT &&
        BUY_STOCKS_CALCULATION_VALIDATION_AMOUNT > 0 &&
        amnt > BUY_STOCKS_CALCULATION_VALIDATION_AMOUNT
      ) {
        this.setState({
          amountExceedError: true,
        });
        return;
      }
    } else {
      this.setState({
        invaldPrice: false,
      });
    }

    if (buyOrderEditStatus) {
      if (parseFloat(noOfShares) <= 0 || noOfShares === "") {
        this.setState({
          invalidQty: true,
        });
      } else {
        getBuyCommission(() => {
          this.props.history.push("buy-stock-confirmation");
        });
      }
    } else {
      if (parseFloat(noOfShares) <= 0 || noOfShares === "") {
        this.setState({
          invalidQty: true,
        });
      } else {
        this.setBottomSheetData();
        getBuyCommission();
      }
    }
  }

  setBottomSheetData = () => {
    this.props.getMarketStatus();
    const { setBottomSheetStatus, bottomSheetStatus, getMarketOpenDays,getMarketStatus} =
      this.props;
    getMarketOpenDays(() => {
      if (
        this.props.marketOpenDays &&
        this.props.marketOpenDays.DATA !== null
      ) {
        this.setState({ marketOpenDays: this.props.marketOpenDays.DATA });
        setBottomSheetStatus(!bottomSheetStatus);
      }
    });

    if(this.props.marketStatus ==="Open"){
      this.props.setBuyStockDuration({
        value: moment(),
        name: "Current trading day",
        tif: 0,
        tifDays: 1,
      });
    }else{
      this.props.setBuyStockDuration({
        value: moment(),
        name: "Next trading day",
        tif: 6,
        tifDays: 1,
      });
    }
   
  };

  handleOnClose = () => {
    this.props.setBottomSheetStatus(false);
  };

  handleIconClickOpen = () => {
    //console.log("Test");
   this.props.setBottomSheetStatus(false);
    this.setState({
      infoIconStatus: true,
    });
   // console.log(this.state.infoIconStatus);
  };

   closePopup=()=>{
    this.props.setBottomSheetStatus(true);
    this.setState({
      infoIconStatus: false,
    });
  }

  onHandleNoOfShare = (data) => {
    const { setBuyNumberOfShares, setSelectedOrderItemQuantity } = this.props;
    const regex = Utils.REGEX_SELL_BUY_FUNC;
    let str = data.replace(Utils.REGEX_REMOVE_LEADING_ZERO, "");

    this.setState({
      noOfShares: data,
    });

    if (data === "" || regex.test(data)) {
      this.setState({
        invalidQty: false,
        amountExceedError: false,
      });
    } else {
      this.setState({
        invalidQty: true,
        amountExceedError: false,
      });
    }
    setBuyNumberOfShares(str);
    setSelectedOrderItemQuantity(str);
  };

  onHandleBuyStockPrice = (data) => {
    const regex = Utils.REGEX_SELL_BUY_FUNC;
    const { buyStockPriceDecimal } = this.state;

    this.setState({
      buyStockPrice: data,
    });

    if (data) {
      if (regex.test(data)) {
        this.setState({
          invaldPrice: false,
          noValueValidation: false,
          amountExceedError: false,
        });
      } else {
        this.setState({
          invaldPrice: true,
          noValueValidation: false,
          amountExceedError: false,
        });
      }
    } else {
      if (!buyStockPriceDecimal && !data) {
        this.setState({
          noValueValidation: false,
          invaldPrice: true,
          amountExceedError: false,
        });
      } else {
        this.setState({
          noValueValidation: true,
          invaldPrice: false,
          amountExceedError: false,
        });
      }
    }

    this.props.setBuyStockPrice(data);
    this.props.setSelectedOrderItemPrice(data);

    this.setState({
      validDecimalEnteredErrorBelowHundrad: false,
      validDecimalEnteredErrorAboveHundrad: false,
    });

    if (buyStockPriceDecimal && data) {
      if (
        parseInt(data) < CONSIDERD_VALUE_FOR_INCREAMENT &&
        parseInt(buyStockPriceDecimal) % INCREAMENT_BELOW_HUNDRAD !== 0
      ) {
        this.setState({ validDecimalEnteredErrorBelowHundrad: true });
      } else if (
        parseInt(data) >= CONSIDERD_VALUE_FOR_INCREAMENT &&
        parseInt(buyStockPriceDecimal) % INCREAMENT_ABOVE_HUNDRAD !== 0
      ) {
        this.setState({ validDecimalEnteredErrorAboveHundrad: true });
      } else {
        this.setState({
          validDecimalEnteredErrorBelowHundrad: false,
          validDecimalEnteredErrorAboveHundrad: false,
        });
      }
    }
  };

  onHandleBuyStockPriceDecimal = (data) => {
    const { buyStockPrice } = this.state;
    const { setBuyStockPriceDecimal, setSelectedOrderItemPriceDecimal } =
      this.props;

    const regex = Utils.REGEX_SELL_BUY_FUNC;

    this.setState({
      buyStockPriceDecimal: data,
    });

    if (data) {
      if (regex.test(data)) {
        this.setState({
          invaldPrice: false,
          noValueValidation: false,
          amountExceedError: false,
        });
      } else {
        this.setState({
          invaldPrice: true,
          noValueValidation: false,
          amountExceedError: false,
        });
      }
    } else {
      if (!buyStockPrice && !data) {
        this.setState({
          noValueValidation: false,
          invaldPrice: true,
          amountExceedError: false,
        });
      } else {
        this.setState({
          noValueValidation: true,
          invaldPrice: false,
          amountExceedError: false,
        });
      }
    }
    setBuyStockPriceDecimal(data);
    setSelectedOrderItemPriceDecimal(data);

    this.setState({
      validDecimalEnteredErrorBelowHundrad: false,
      validDecimalEnteredErrorAboveHundrad: false,
    });

    if (buyStockPrice && data) {
      if (
        parseInt(buyStockPrice) < CONSIDERD_VALUE_FOR_INCREAMENT &&
        parseInt(data) % INCREAMENT_BELOW_HUNDRAD !== 0
      ) {
        this.setState({ validDecimalEnteredErrorBelowHundrad: true });
      } else if (
        parseInt(buyStockPrice) >= CONSIDERD_VALUE_FOR_INCREAMENT &&
        parseInt(data) % INCREAMENT_ABOVE_HUNDRAD !== 0
      ) {
        this.setState({ validDecimalEnteredErrorAboveHundrad: true });
      } else {
        this.setState({
          validDecimalEnteredErrorBelowHundrad: false,
          validDecimalEnteredErrorAboveHundrad: false,
        });
      }
    }
  };

  handleBottomSheetNavigation = () => {
    this.props.history.push("buy-stock-confirmation");
  };

  handleOrderDuration = (data) => {
    this.props.setBuyStockDuration(data);
  };

  onBlurBuyStockPriceDecimal = () => {
    this.setState({
      priceOnFocus: false,
    });
    if (this.props.buyStockPriceDecimal === "") {
      // this.props.setBuyStockPriceDecimal("00");
    }
  };

  onFocusBuyStockPriceDecimal = () => {
    this.setState({
      priceOnFocus: true,
    });
  };

  onBlurBuyStockPrice = () => {
    this.setState({
      priceOnFocus: false,
    });
  };

  getRow = (valueOne, valueTwo, valueThree) => {
    return (
      <div className="bs-row-2">
        <div style={{ width: "49%" }}>
          <span className="bs_card_Text">{valueOne}</span>
        </div>
        <div style={{ width: "2%" }} className="bs_colan">
          :
        </div>
        <div style={{ width: "49%", textAlign: "end" }}>
          <span className="bs_lkrText">{"LKR"}</span>
          <span className="bs_priceText">
            {Number(parseInt(valueTwo)).toLocaleString()}
          </span>
          <span className="bs_decimalText">.{valueThree}</span>
        </div>
      </div>
    );
  };

  styles = {
    dashedBorder: {
      marginLeft: "16px",
      marginRight: "16px",
      borderBottomWidth: "1px",
      borderBottomColor: "#dee0e9",
      borderBottomStyle: "dashed",
    },
  };

  renderCurrentPriceText = () => {
    const { currentPrice } = this.state;
    const { selectedStock } = this.props;

    if (selectedStock) {
      if (!currentPrice || currentPrice === "") {
        if (selectedStock.askPrice) {
          if (selectedStock.askPrice === "") {
            return "0";
          } else {
            return selectedStock.askPrice.substr(
              0,
              selectedStock.askPrice.indexOf(".")
            );
          }
        } else {
          return "0";
        }
      } else {
        if (currentPrice) {
          return currentPrice.substr(0, currentPrice.indexOf("."));
        }
      }
    } else {
      return "0";
    }
  };

  renderEditCurrentPriceText = () => {
    const { currentPrice } = this.state;
    const { buyOrderEditItem } = this.props;

    if (buyOrderEditItem) {
      if (!currentPrice || currentPrice === "") {
        if (buyOrderEditItem.askprice) {
          if (buyOrderEditItem.askprice === "") {
            return "0";
          } else {
            return buyOrderEditItem.askprice.substr(
              0,
              buyOrderEditItem.askprice.indexOf(".")
            );
          }
        } else {
          return "0";
        }
      } else {
        if (currentPrice) {
          return currentPrice.substr(0, currentPrice.indexOf("."));
        }
      }
    } else {
      return "0";
    }
  };

  renderLastTradedPriceText = () => {
    const { lastTradedPrice, currentPrice } = this.state;
    const { selectedStock } = this.props;

    if (selectedStock) {
      if (!lastTradedPrice || lastTradedPrice === "") {
        if (selectedStock.tradePrice) {
          if (selectedStock.tradePrice === "") {
            return "0";
          } else {
            return selectedStock.tradePrice.substr(
              0,
              selectedStock.tradePrice.indexOf(".")
            );
          }
        } else {
          return "0";
        }
      } else {
        if (lastTradedPrice && currentPrice) {
          return lastTradedPrice.substr(0, currentPrice.indexOf("."));
        }
      }
    } else {
      return "0";
    }
  };

  renderEditLastTradedPriceText = () => {
    const { lastTradedPrice, currentPrice } = this.state;
    const { buyOrderEditItem } = this.props;

    if (buyOrderEditItem) {
      if (!lastTradedPrice || lastTradedPrice === "") {
        if (buyOrderEditItem.tradeprice) {
          if (buyOrderEditItem.tradeprice === "") {
            return "0";
          } else {
            return buyOrderEditItem.tradeprice.substr(
              0,
              buyOrderEditItem.tradeprice.indexOf(".")
            );
          }   
        } else {
          return "0";
        }
      } else {
        if (lastTradedPrice && currentPrice) {
          return lastTradedPrice.substr(0, currentPrice.indexOf("."));
        }
      }
    } else {
      return "0";
    }
  };

  renderCurrentPriceDecimal = () => {
    const { currentPrice } = this.state;
    const { selectedStock } = this.props;

    if (selectedStock) {
      if (!currentPrice || currentPrice === "") {
        if (selectedStock.askPrice) {
          if (selectedStock.askPrice === "") {
            return "00";
          } else {
            return commonFunctions.fixDecimalPoints(
              selectedStock.askPrice.split(".").pop()
            );
          }
        } else {
          return "00";
        }
      } else {
        return commonFunctions.fixDecimalPoints(currentPrice.split(".").pop());
      }
    } else {
      return "00";
    }
  };

  renderEditCurrentPriceDecimal = () => {
    const { currentPrice } = this.state;
    const { buyOrderEditItem } = this.props;

    if (buyOrderEditItem) {
      if (!currentPrice || currentPrice === "") {
        if (buyOrderEditItem.askprice) {
          if (buyOrderEditItem.askprice === "") {
            return "00";
          } else {
            return commonFunctions.fixDecimalPoints(
              buyOrderEditItem.askprice.split(".").pop()
            );
          }
        } else {
          return "00";
        }
      } else {
        return commonFunctions.fixDecimalPoints(currentPrice.split(".").pop());
      }
    } else {
      return "00";
    }
  };

  renderLastTradedPriceDecimal = () => {
    const { lastTradedPrice } = this.state;
    const { selectedStock } = this.props;

    if (selectedStock) {
      if (!lastTradedPrice || lastTradedPrice === "") {
        if (selectedStock.tradePrice) {
          if (selectedStock.tradePrice === "") {
            return "00";
          } else {
            return commonFunctions.fixDecimalPoints(
              selectedStock.tradePrice.split(".").pop()
            );
          }
        } else {
          return "00";
        }
      } else {
        return commonFunctions.fixDecimalPoints(
          lastTradedPrice.split(".").pop()
        );
      }
    } else {
      return "00";
    }
  };

  renderEditLastTradedPricDecimal = () => {
    const { lastTradedPrice } = this.state;
    const { buyOrderEditItem } = this.props;

    if (buyOrderEditItem) {
      if (!lastTradedPrice || lastTradedPrice === "") {
        if (buyOrderEditItem.tradeprice) {
          if (buyOrderEditItem.tradeprice === "") {
            return "00";
          } else {
            return commonFunctions.fixDecimalPoints(
              buyOrderEditItem.tradeprice.split(".").pop()
            );
          }
        } else {
          return "00";
        }
      } else {
        return commonFunctions.fixDecimalPoints(
          lastTradedPrice.split(".").pop()
        );
      }
    } else {
      return "00";
    }
  };

  renderStockDetailsComponent = () => {
    const {
      buyOrderEditStatus,
      orderSelectedItemPriceDecimal,
      orderSelectedItemQty,
      orderSelectedItemPrice,
      buyStockPrice,
      buyStockPriceDecimal,
    } = this.props;

    const {
      validDecimalEnteredErrorBelowHundrad,
      validDecimalEnteredErrorAboveHundrad,
      noValueValidation,
    } = this.state;

    return (
      <div className="stockDetailsContainer">
        {this.getRow(
          "Current price",
          buyOrderEditStatus
            ? this.renderEditCurrentPriceText()
            : this.renderCurrentPriceText(),
          buyOrderEditStatus
            ? this.renderEditCurrentPriceDecimal()
            : this.renderCurrentPriceDecimal()
        )}
        <Divider variant="middle" className="dividerStyle" />
        {this.getRow(
          "Last traded price",
          buyOrderEditStatus
            ? this.renderEditLastTradedPriceText()
            : this.renderLastTradedPriceText(),
          buyOrderEditStatus
            ? this.renderEditLastTradedPricDecimal()
            : this.renderLastTradedPriceDecimal()
        )}
        <Divider variant="middle" className="dividerStyle" />
        {buyOrderEditStatus
          ? this.getRow(
              "Value of shares",
              !validDecimalEnteredErrorBelowHundrad &&
                !validDecimalEnteredErrorAboveHundrad &&
                !noValueValidation &&
                orderSelectedItemPriceDecimal &&
                orderSelectedItemQty &&
                orderSelectedItemPrice
                ? orderSelectedItemPriceDecimal &&
                  orderSelectedItemPriceDecimal !== 0
                  ? Math.floor(
                      Number(orderSelectedItemQty) *
                        (Number(orderSelectedItemPrice) +
                          Number(orderSelectedItemPriceDecimal) * 0.01)
                    )
                      .toString()
                      .split(".")[0]
                  : Math.floor(
                      Number(orderSelectedItemQty) *
                        (Number(orderSelectedItemPrice) + 0.0)
                    )
                      .toString()
                      .split(".")[0]
                : "00.00",
              !validDecimalEnteredErrorBelowHundrad &&
                !validDecimalEnteredErrorAboveHundrad &&
                !noValueValidation &&
                orderSelectedItemPriceDecimal &&
                orderSelectedItemQty &&
                orderSelectedItemPrice
                ? orderSelectedItemPriceDecimal &&
                  orderSelectedItemPriceDecimal !== 0
                  ? (
                      Number(orderSelectedItemQty) *
                      (Number(orderSelectedItemPrice) +
                        Number(orderSelectedItemPriceDecimal) * 0.01)
                    )
                      .toFixed(2)
                      .toString()
                      .split(".")[1]
                  : "00"
                : "00"
            )
          : this.getRow(
              "Value of shares",
              !validDecimalEnteredErrorBelowHundrad &&
                !validDecimalEnteredErrorAboveHundrad &&
                !noValueValidation &&
                buyStockPriceDecimal &&
                orderSelectedItemQty &&
                buyStockPrice
                ? buyStockPriceDecimal && buyStockPriceDecimal !== 0
                  ? Math.floor(
                      Number(orderSelectedItemQty) *
                        (Number(buyStockPrice) +
                          Number(buyStockPriceDecimal) * 0.01)
                    )
                      .toString()
                      .split(".")[0]
                  : Math.floor(
                      Number(orderSelectedItemQty) *
                        (Number(buyStockPrice) + 0.0)
                    )
                      .toString()
                      .split(".")[0]
                : "00.00",
              !validDecimalEnteredErrorBelowHundrad &&
                !validDecimalEnteredErrorAboveHundrad &&
                !noValueValidation &&
                buyStockPriceDecimal &&
                buyStockPrice &&
                orderSelectedItemQty
                ? buyStockPriceDecimal && buyStockPriceDecimal !== 0
                  ? (
                      Number(orderSelectedItemQty) *
                      (Number(buyStockPrice) +
                        Number(buyStockPriceDecimal) * 0.01)
                    )
                      .toFixed(2)
                      .toString()
                      .split(".")[1]
                  : "00"
                : "00"
            )}
      </div>
    );
  };

  renderAvailableToInvestCard = () => {
    const { availableToInvest } = this.props;
    return (
      <div className="bsc-card bsc-card-3" style={{ marginTop: "20px" }}>
        <div className="bsc-row-2">
          <div className="bsc-space-11">
            <div className="bsc-flex">
              <img
                src={Images.buyStocks.img_availableToInvest}
                className="bsc-icon-available"
                alt="logo"
              />
              <div className="bsc-ml-16">
                <div className="bsc-row-2 no-padding">
                  <h2 className="bsc-header-text-card">
                    {"Available to invest"}
                  </h2>
                </div>
                <div
                  className="bsc-row-2 no-padding"
                  style={{ marginTop: "4px" }}
                >
                  <h4 className="bsc-available-invest-lkr">{"LKR"}</h4>
                  <h4 className="bsc-available-invest-price">
                    {availableToInvest && parseFloat(availableToInvest) > 0
                      ? this.numberWithCommas(
                          parseFloat(availableToInvest).toFixed(2).split(".")[0]
                        )
                      : "00"}
                  </h4>
                  <h4 className="bsc-available-invest-decimal">
                    .
                    {availableToInvest && parseFloat(availableToInvest) > 0
                      ? this.numberWithCommas(
                          parseFloat(availableToInvest).toFixed(2).split(".")[1]
                        )
                      : "00"}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="bsc-space-1"></div>
        </div>
      </div>
    );
  };

  validationInfoContainer = () => {
    const { amountExceedError } = this.state;
    return (
      <div>
        {BUY_STOCKS_CALCULATION_VALIDATION_AMOUNT &&
        BUY_STOCKS_CALCULATION_VALIDATION_AMOUNT > 0 ? (
          <>
            <div style={this.styles.dashedBorder} />
            <div className="warningDivOne">
              <div>
                <img
                  src={
                    amountExceedError
                      ? Images.buyNsell.warningRed
                      : Images.buyNsell.warningBlack
                  }
                  className="warningIcon"
                  alt="logo"
                />
              </div>
              <div
                className={
                  amountExceedError ? "warningError" : "stockInfoTxtInBlack"
                }
              >
                {"You can only buy shares worth Rs"}
                <div
                  className={
                    amountExceedError ? "amountBold" : "amountBoldBlack"
                  }
                >
                  &nbsp;
                  {Number(
                    BUY_STOCKS_CALCULATION_VALIDATION_AMOUNT
                  ).toLocaleString()}
                </div>
                {`.${
                  values.BUY_STOCKS_CALCULATION_VALIDATION_DECIMAL === 0
                    ? "00"
                    : values.BUY_STOCKS_CALCULATION_VALIDATION_DECIMAL
                } at a time`}
              </div>
            </div>
            <div className="warningDivTwo">
              <div>
                <img
                  src={Images.buyNsell.warningBlack}
                  className="warningIcon"
                  alt="logo"
                />
              </div>

              <div className={"stockInfoTxtInBlack"}>
                {"You can perform multiples of Rs"}
                <div className={"amountBoldBlack"}>
                  &nbsp;
                  {Number(
                    BUY_STOCKS_CALCULATION_VALIDATION_AMOUNT
                  ).toLocaleString()}
                </div>
                {`.${
                  values.BUY_STOCKS_CALCULATION_VALIDATION_DECIMAL === 0
                    ? "00"
                    : values.BUY_STOCKS_CALCULATION_VALIDATION_DECIMAL
                } transaction`}
              </div>
            </div>
            <div style={this.styles.dashedBorder} />
          </>
        ) : null}
      </div>
    );
  };

  focusValidationContainer = () => {
    return (
      <div className="focusValidationContainer">
        <h6 className="bs-info-text">
          {`If the price entered is less than 100, the cents value
                    should be stated in increments of 10 cents (ex: 54.10 or
                    54.20 or 54.30)`}
        </h6>

        <h6 className="bs-info-text">
          {`If the price entered is 100 or more, the cents value should
                    be stated in increments of 25 cents (ex: 112.00 or 112.25 or
                    112.50)`}
        </h6>
      </div>
    );
  };

  render() {
    const {
      invalidQty,
      invaldPrice,
      amountExceedError,
      validDecimalEnteredErrorBelowHundrad,
      validDecimalEnteredErrorAboveHundrad,
      noValueValidation,
      infoIconStatus,
    } = this.state;
    const {
      bottomSheetStatus,
      buyOrderEditStatus,
      buyOrderEditItem,
      selectedStock,
    } = this.props;

    return (
      <div className="buy-main">
        <Header
          leftIcon={ArrowLeftIcon}
          headerContentLeft={`Buy ${
            buyOrderEditStatus
              ? buyOrderEditItem.securitycode
              : selectedStock.security
          }`}
          leftIconLink="buy-stocks-list"
          handleLeftIconClick={() => this.onLeftIconClick()}
        />
        <div className="buy-bodyContainer">
          <div className="buy-innerContent">
            <div className="buy-innerContent">
              <div className="priceConatainer">
                <div className="bs-input-title">
                  {"Your price per share (LKR)"}
                </div>
                <div className="txtInputView">
                  <TextInputStandard
                    autoFocus={false}
                    label="stock-price"
                    onChange={(data) => {
                      this.onHandleBuyStockPrice(data);
                    }}
                    placeholder="00"
                    maxlength="9"
                    value={this.state.buyStockPrice}
                    fontSize="2em"
                    alignInput="right"
                    handleFocus={() => this.onFocusBuyStockPriceDecimal()}
                    handleBlur={() => this.onBlurBuyStockPrice()}
                  />
                  <h1
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    {"."}
                  </h1>

                  <TextInputStandard
                    label="price-decimal"
                    onChange={(data) => {
                      this.onHandleBuyStockPriceDecimal(data);
                    }}
                    placeholder="00"
                    maxlength="2"
                    value={this.state.buyStockPriceDecimal}
                    alignInput="left"
                    fontSize="2em"
                    handleBlur={() => this.onBlurBuyStockPriceDecimal()}
                    handleFocus={() => this.onFocusBuyStockPriceDecimal()}
                  />
                </div>

                {invaldPrice ? (
                  <h6 className="bs-error-value-req">{"Value required"}</h6>
                ) : null}
                {noValueValidation ? (
                  <h6 className="ss-error-value-req">
                    {"Invalid price entered"}
                  </h6>
                ) : null}
                {validDecimalEnteredErrorBelowHundrad ? (
                  <h6 className="bs-error-value-decimal-validation">
                    {
                      "Cents value should be in increments of 10 cents ( for values less than Rs.100)"
                    }
                  </h6>
                ) : null}
                {validDecimalEnteredErrorAboveHundrad ? (
                  <h6 className="bs-error-value-decimal-validation">
                    {
                      "Cents value should be in increments of 25 cents ( for values more than Rs.100)"
                    }
                  </h6>
                ) : null}
                {this.state.priceOnFocus
                  ? this.focusValidationContainer()
                  : null}

                <div style={this.styles.dashedBorder} />
              </div>

              <div className="sharesContainer">
                <h3 className="bs-input-title">{"Number of shares"}</h3>

                <TextInputStandard
                  onChange={(data) => {
                    this.onHandleNoOfShare(data);
                  }}
                  value={this.state.noOfShares}
                  width="100%"
                  maxlength="6"
                  marginStart="0%"
                  alignInput="center"
                  handleBlur={() => this.onBlurBuyStockPriceDecimal()}
                />
                {invalidQty ? (
                  <h6 className="bs-error-value-req">{"Value required"}</h6>
                ) : null}
                {amountExceedError ? (
                  <h6 className="bs-error-value-req-withPadding">
                    {
                      "Please reduce the number of shares or the price per share"
                    }
                  </h6>
                ) : null}
              </div>

              {this.validationInfoContainer()}
            </div>
            
            <BottomSheet
              bottomSheetStatus={bottomSheetStatus}
              handleOnClose={this.handleOnClose}
              handleIconClickOpen={this.handleIconClickOpen}
              marketStatusOpen={this.props.marketStatus}
              dropdownTitle={"Order duration \n (Time in force)"}
              handleNavigation={this.handleBottomSheetNavigation}
              setSelectedOderDuration={(data) => {
                this.handleOrderDuration(data);
              }}
              dataArr={this.state.marketOpenDays}
            />
          </div>
          {this.renderStockDetailsComponent()}
          {this.renderAvailableToInvestCard()}
        </div>

           <div>
                {this.state.infoIconStatus?
               <div className="main">
                  <div className="popup" role="dialog">
                  <div className="MuiDialogContent-root makeStyles-centerView css-ypiqx9-MuiDialogContent-root">
                       <img className="makeStyles-icon" style={{marginLeft: "44%"}}
                       src={Images.buyStocks.ic_marketClose}
                        alt="logo"/>
                    </div>
                      <p className="popup-header2">
                    Market is currently closed
                    </p>
                    
                    <div className="MuiDialogContent-root css-ypiqx9-MuiDialogContent-root">
                      <p className="popup-detail"> Will be applicable for orders placed during active market hours for same day fulfillment.Please use the next trading day option when placing orders after trading hours to be 
                        fulfilled immediately on the next market opening.</p>
                    </div>
                    {/* <hr className="MuiDivider-root MuiDivider-fullWidth css-9mgopn-MuiDivider-root"></hr> */}
                    
                    <p  onClick={this.closePopup} className="close-text2">
                    CLOSE</p>
                   </div>
                  </div> :""}
              </div> 

        <div className="buy-buttonContainer">
          
          <button
            className="bs-btn-transaction"
            disabled={
              (invalidQty && invaldPrice) ||
              noValueValidation ||
              validDecimalEnteredErrorBelowHundrad ||
              validDecimalEnteredErrorAboveHundrad
            }
            onClick={() => {
              this.buttonOnPress();
            }}
          >
            {"NEXT"}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedStock: state.BuyStockReducer.selectedStock,
    availableToInvest: state.DashboardReducer.dashboard.availableToInvest,
    buyStockPrice: state.BuyStockReducer.buyStockPrice,
    buyStockPriceDecimal: state.BuyStockReducer.buyStockPriceDecimal,
    noOfShares: state.BuyStockReducer.buyNumberOfShares,
    buyOrderEditStatus: state.BuyStockReducer.buyOrderEditStatus,
    buyOrderEditItem: state.BuyStockReducer.buyOrderEditItem,
    orderSelectedItemPrice: state.BuyStockReducer.orderEditItemPrice,
    orderSelectedItemQty: state.BuyStockReducer.orderEditItemQty,
    orderSelectedItemPriceDecimal:
      state.BuyStockReducer.orderEditItemPriceDecimal,
    bottomSheetStatus: state.BuyStockReducer.bottomSheetStatus,
    securityStat: state.BuyStockReducer.securityStat,
    marketOpenDays: state.CommonReducer.marketOpenDays,
    marketStatus: state.BuyStockReducer.marketStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setBuyStockPrice: (data) => dispatch(stockActions.setBuyStockPrice(data)),
    setBuyNumberOfShares: (data) =>
      dispatch(stockActions.setBuyNumberOfShares(data)),
    setBuyStockDuration: (data) =>
      dispatch(stockActions.setBuyStockDuration(data)),
    getBuyCommission: (callback) =>
      dispatch(stockActions.getBuyCommission(callback)),
    setSelectedOrderItemQuantity: (data) =>
      dispatch(stockActions.setSelectedOrderItemQuantity(data)),
    setSelectedOrderItemPrice: (data) =>
      dispatch(stockActions.setSelectedOrderItemPrice(data)),
    setSelectedOrderItemDuration: (data) =>
      dispatch(stockActions.setSelectedOrderItemDuration(data)),
    setBuyStockPriceDecimal: (data) =>
      dispatch(stockActions.setBuyStockPriceDecimal(data)),
    setSelectedOrderItemPriceDecimal: (data) =>
      dispatch(stockActions.setSelectedOrderItemPriceDecimal(data)),
    setBottomSheetStatus: (data) =>
      dispatch(stockActions.setBottomSheetStatus(data)),
    getMarketOpenDays: (callback) =>
      dispatch(commonActions.getMarketOpenDays(callback)),
      getMarketStatus: () => dispatch(getMarketStatus()),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(BuyStock);
