import axios from "axios";
axios.defaults.timeout = 20000;

const BASE_URL = window._env_.REACT_APP_DEV_API_URL;

export const getBankDetails = async (accToken) => {
  const token = sessionStorage.getItem("token");

  if (token) {
    let BANK_DETAIL_API_ENDPOINT = `${BASE_URL}/api/auth/stocks-auth-service/customerBankInfo`;
    let res = await axios
      .get(BANK_DETAIL_API_ENDPOINT, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return res;
  } else {
    return null;
  }
};

export const getOtpRequest = async (req, accToken) => {
  const token = sessionStorage.getItem("token");

  if (token) {
    let GET_OTP_REQUEST_API_ENDPOINT = `${BASE_URL}/api/ezs/stocks-management-service/sendCxOtp/${req.brokerCode}/${req.amount}`;
    let res = await axios
      .get(GET_OTP_REQUEST_API_ENDPOINT, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return res;
  } else {
    return null;
  }
};

export const getOtpValidate = async (req, accToken) => {
  const token = sessionStorage.getItem("token");

  if (token) {
    let GET_OTP_VALIDATE_API_ENDPOINT = `${BASE_URL}/api/ezs/stocks-management-service/validateOtp/${req.brokerCode}/${req.otp}`;
    let res = await axios
      .get(GET_OTP_VALIDATE_API_ENDPOINT, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return res;
  } else {
    return null;
  }
};

export const postFundTransfer = async (req, accToken) => {
  const token = sessionStorage.getItem("token");

  if (token) {
    let POST_FUND_TRANSFER_API_ENDPOINT = `${BASE_URL}/api/stocks-management-service/fund/transfer`;
    let res = await axios
      .post(POST_FUND_TRANSFER_API_ENDPOINT, req, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return res;
  } else {
    return null;
  }
};

export const customerBalanceWithdrawalService = async (req) => {
  const token = sessionStorage.getItem("token");

  if (token) {
    let POST_FUND_TRANSFER_API_ENDPOINT = `${BASE_URL}/api/ezs/stocks-management-service/withdrawal-details`;
    let res = await axios
      .post(POST_FUND_TRANSFER_API_ENDPOINT, req, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return res;
  } else {
    return null;
  }
};
