import React from "react";
import styles from "./Header.module.scss";
import PropTypes from "prop-types";
import { ButtonWithIcon } from "../Buttons/index";
import { Images } from "../../Theme/Images";
export default function Header(props) {
  const [showHeader, setValue] = React.useState(true);
  const {
    leftIcon,
    leftIconLink,
    handleLeftIconClick,
    headerContentLeft,
    headerContentRight,
    rightIcon,
    handleRightIconClick,
    rightButton,
    sellStockEnable,
    handleBuyPressed,
    handleSellPressed,
    rightIconStyle,
  } = props;

  const rightIconOnClick = () => {
    setValue(!showHeader);
    if (handleRightIconClick && typeof handleRightIconClick === "function") {
      handleRightIconClick(showHeader);
    }
  };

  const onBuyPressed = () => {
    handleBuyPressed();
  };

  const onSellPressed = () => {
    handleSellPressed();
  };

  if (window.location.pathname === "/" && window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage("Home");
  } else if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage("NotHome");
  }

  const onLeftIconClicked = () => {
    if (leftIconLink && leftIconLink === "exitWebView") {
      return window.ReactNativeWebView.postMessage("Exit");
    } else if (
      handleLeftIconClick &&
      typeof handleLeftIconClick === "function"
    ) {
      return handleLeftIconClick();
    }
  };

  return (
    <div>
      <header className={styles.header_container}>
        <div
          className={styles.header_leftIconBox}
          onClick={() => {
            onLeftIconClicked();
          }}
        >
          {leftIcon ? (
            <img
              className={styles.header_leftIconBox_icon}
              src={leftIcon}
              alt="left-icon"
            />
          ) : null}
        </div>

        <div className={styles.header_headerTextBox}>
          <span className={styles.header_headerTextBox_textStyle}>
            {headerContentLeft}
          </span>
          <span className={styles.header_headerTextBox_textStyle}>
            {headerContentRight}
          </span>
        </div>

        <div className={styles.header_rightButtonBox}>
          {rightIcon ? (
            <img
              className={
                rightIconStyle
                  ? rightIconStyle
                  : styles.header_rightButtonBox_icon
              }
              src={rightIcon}
              onClick={rightIconOnClick}
              alt="right-icon"
            />
          ) : rightButton ? (
            <div className={styles.header_rightButtonBox_rightBUttonSubView}>
              <ButtonWithIcon
                title="Buy"
                icon={Images.buyStocks.ic_plus_new}
                buttonWidth="5.5em"
                onPress={onBuyPressed}
              />
              {sellStockEnable && sellStockEnable === "enable" ? (
                <div className={styles.header_rightButtonBox_sellBTN}>
                  <ButtonWithIcon
                    title="Sell"
                    icon={Images.sellStocks.ic_minus}
                    buttonWidth="5.5em"
                    onPress={onSellPressed}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </header>
    </div>
  );
}

Header.propTypes = {
  handleOnClick: PropTypes.func,
  handleRightIconClick: PropTypes.func,
  handleLeftIconClick: PropTypes.func,
};

Header.defaultProps = {
  rightButton: false,
  sellStockEnable: false,
};
