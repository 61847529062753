import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import Layout from "../../Layout/Layout";
import "./AddMoneyStyles.scss";
import BodyContainer from "../../../Components/BodyArea/Body";
import * as actions from "../../../Redux/Actions/addMoneyAction";
import { withStyles } from "@mui/styles";
import COLORS from "../../../Assets/Colors";
import PrimaryButton from "../../../Assets/Widgets/PrimaryButton";
import { Images } from "../../../Theme/Images";

const styles = () => ({
  main: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    paddingTop: "3.12em",
    marginBottom: "1.87em",
    paddingLeft: "1em",
    paddingRight: "1em",
  },
  mainHeader: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  headerLogo: {
    width: "6.25em",
    height: "6.25em",
    alignSelf: "center",
    marginBottom: "1em",
  },
  heading: {
    textAlign: "center",
    color: COLORS.BLACK,
    fontSize: "1em",
    marginBottom: "1em",
    fontWeight: "600",
  },
  contentBox: {
    padding: "1em",
    boxShadow: "0 10px 20px #e0e8ea",
    borderRadius: "1em",
    backgroundColor: COLORS.WHITE,
    marginBottom: "1.8em",
  },
  bankHeading: {
    textAlign: "center",
    color: COLORS.BROWNISH_GREY,
    fontSize: "0.75em",
    marginBottom: "0.31em",
    fontWeight: "600",
  },
  bankTxt: {
    textAlign: "center",
    color: COLORS.BLACK,
    fontSize: "1.25em",
    marginBottom: "0.625em",
    fontWeight: "400",
  },
  breakLine: {
    width: "100%",
    height: "1px",
    backgroundColor: COLORS.PALE_GREY,
    marginBottom: "0.625em",
  },
  ACHeading: {
    textAlign: "center",
    color: COLORS.BROWNISH_GREY,
    fontSize: "0.75em",
    marginBottom: "0.31em",
    fontWeight: "600",
  },
  ACTxt: {
    textAlign: "center",
    color: COLORS.BLACK,
    fontSize: "1.25em",
    marginBottom: "1em",
    fontWeight: "400",
  },
  remarksText: {
    textAlign: "center",
    color: COLORS.BROWNISH_GREY,
    fontSize: "1.25em",
  },
  bottomText: {
    textAlign: "center",
    color: COLORS.BROWNISH_GREY,
    fontSize: "0.87em",
    marginBottom: "3em",
    fontWeight: "400",
  },
  buttonContainer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
  },
  infoLogo: {
    width: "1.12em",
    height: "1.12em",
    marginRight: "0.3em",
  },
  bottomTextView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: "20px",
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
  },
});

class FundTransferDetailPage extends Component {
  componentDidMount() {
    const { getFundTranferInfoDetails } = this.props;
    setTimeout(() => {
      getFundTranferInfoDetails();
    }, 500);
  }

  onOKButtonPressed = () => {
    this.props.history.push("dashboard");
  };

  renderSeparatedAccountNumber = (ac) => {
    return ac.toString().match(new RegExp(".{1,4}", "g")).join(" ");
  };

  render() {
    const { classes, addMoneyFundTranferDetails } = this.props;
    return (
      <Fragment>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft={"Fund transfer"}
          handleLeftIconClick={() => {
            this.props.history.push("add-money-initiate");
          }}
        >
          <BodyContainer>
            <div className={classes.main}>
              <div className={classes.mainHeader}>
                <img
                  src={Images.addMoney.fundTransferDetails}
                  className={classes.headerLogo}
                  alt="logo"
                />
                <div className={classes.heading}>
                  {"Transfer funds to the below account"}
                </div>
                <div className={classes.contentBox}>
                  <div className={classes.bankHeading}>{"Account name"}</div>
                  {addMoneyFundTranferDetails &&
                  addMoneyFundTranferDetails.brokerName ? (
                    <div className={classes.bankTxt}>
                      {addMoneyFundTranferDetails.brokerName}
                    </div>
                  ) : null}
                  <div className={classes.breakLine} />
                  <div className={classes.bankHeading}>{"Bank & branch"}</div>
                  {addMoneyFundTranferDetails &&
                  addMoneyFundTranferDetails.finacleBankDetails ? (
                    <div className={classes.bankTxt}>
                      {addMoneyFundTranferDetails.finacleBankDetails}
                    </div>
                  ) : null}
                  <div className={classes.breakLine} />
                  <div className={classes.ACHeading}>{"Account number"}</div>
                  {addMoneyFundTranferDetails &&
                  addMoneyFundTranferDetails.finacleCxAccountNo ? (
                    <div className={classes.ACTxt}>
                      {this.renderSeparatedAccountNumber(
                        addMoneyFundTranferDetails.finacleCxAccountNo
                      )}
                    </div>
                  ) : null}
                  {/* <div className={classes.breakLine} />
                  <div className={classes.ACHeading}>{"Remarks"}</div>
                  <div className={classes.remarksText}>
                    {"[Put Your registered mobile number]"}
                  </div> */}
                </div>
                <div className={classes.bottomTextView}>
                  {/* <img
                    src={Images.common.info}
                    className={classes.infoLogo}
                    alt="logo"
                  /> */}
                  <div className={classes.bottomText}>
                    {
                      "Your buying power will be increased once the transfer is completed"
                    }
                  </div>
                </div>
              </div>
              <div className={classes.buttonContainer}>
                <PrimaryButton
                  buttonText={"CLOSE"}
                  onPressed={() => this.onOKButtonPressed()}
                />
              </div>
            </div>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addMoneyFundTranferDetails:
      state.AddMoneyReducer.addMoneyFundTranferDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getFundTranferInfoDetails: (payload) =>
      dispatch(actions.getFundTranferInfoDetails(payload)),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(FundTransferDetailPage)
);
