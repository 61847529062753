import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import styles from "./GainersTabComponent.module.scss";
import { AppBar, Divider, Tab, Tabs } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, onViewPressed, onViewAllPressed, ...other } =
    props;


  const createGainersLosersList = (item, idx) => {
    return (
      <div
        style={{ width: "100%", display: "flex",  marginBottom:'1em' }}
        onClick={() => onViewPressed(item)}
      >
        <div style={{ width: "50%" }}>
          <h2 className={styles.gtSecurityStyle}>{item.security}</h2>
          <h4 className={styles.gtCompanyStyle}>{item.companyName}</h4>
        </div>
        <div
          style={{
            width: "50%",
            textAlign: "right",
            marginTop: "13px",
          }}
        >
          <span className={styles.gtLkrText}>LKR </span>
          <span className={styles.gtPriceText}>
            {numberWithCommas(item.tradePrice.split(".")[0])}
          </span>
          <span className={styles.gtDecimalText}>
            .{item.tradePrice.split(".").pop()}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children[0] ? (
            children.slice(0, 5).map((item, idx) => {
              if (idx === 4) {
                return (
                  <div key={idx}>
                    {createGainersLosersList(item, idx)}
                    <Divider key={idx}/>
                  </div>
                );
              } else {
                return (
                  <div key={idx}>
                    {createGainersLosersList(item, idx)}
                    <Divider key={idx}/>
                  </div>
                );
              }
            })
          ) : (
            <h2 className={styles.gtNoRecordStyle}>No Records Found</h2>
          )}
          {index === 0 && children.length > 5 ? (
            <div onClick={() => onViewAllPressed(index)}>
              <span className={styles.viewAllText}>VIEW ALL</span>
            </div>
          ) : null}
          {index === 1 && children.length > 5 ? (
            <div onClick={() => onViewAllPressed(index)}>
              <span className={styles.viewAllText}>VIEW ALL</span>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.array,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    color: "black",
    fontWeight: "normal",
    fontSize: "16sp",
    outline: "none !important",
    width: "50%",
    textTransform: "none",
  },
  indicator: {
    backgroundColor: "#2598ed",
  },
  appBarStyles: {
    backgroundColor: "#ffffff !important",
    color: "#333333 !important",
  },
}));

export default function GainerTabComponent(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { topLoosers, topGainers, onViewPressed, handleTabChange } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleViewAll = (value) => {
    handleTabChange(value);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={0} className={classes.appBarStyles}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          classes={{
            indicator: classes.indicator,
          }}
        >
          <Tab
            className={classes.header}
            label="Top gainers"
            {...a11yProps(0)}
          />
          <Tab
            className={classes.header}
            label="Top losers"
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <Divider style={{ marginBottom: "3%" }} />
      <TabPanel
        value={value}
        index={0}
        children={topGainers}
        onViewPressed={(item) => onViewPressed(item)}
        onViewAllPressed={() => handleViewAll(value)}
      />
      <TabPanel
        value={value}
        index={1}
        children={topLoosers}
        onViewPressed={(item) => onViewPressed(item)}
        onViewAllPressed={() => handleViewAll(value)}
      />
    </div>
  );
}
