import React from "react";
import { connect } from "react-redux";
import CircularLoading from "../../Features/Common/CirculerLoading";
import ErrorPopUp from "../../Components/Alert/ErrorPopUp";

function BodyContainer(props) {
  const appStyle = {
    flex: 1,
    position: "relative",
    top: "15px",
    marginBottom: "0%",
    overflow: "scroll",
    overflowX: "hidden",
  };

  const loaderStyles = {
    textAlign: "center",
    paddingTop: "70%",
  };

  return (
    <>
      {props.loader ? (
        <div style={loaderStyles} data-testid="loader">
          <CircularLoading />
        </div>
      ) : (
        <div style={appStyle} className="scroll-behavior: smooth">
          <ErrorPopUp />
          {props.children}
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    dashboardData: state.DashboardReducer.dashboard,
    loader: state.CommonReducer.showLoader,
  };
};

export default connect(mapStateToProps)(BodyContainer);
