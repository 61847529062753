import ENDPOINTS from "./Endpoints";
import Api from "./Service";
import UTILS from "./Utils";

export function preValidateAddMoneyService(accessToken, brokerCode, body) {
  return Api(
    ENDPOINTS.BASE_URL,
    `${ENDPOINTS.MANAGEMENT_SERVICE_PRE_VALIDATE_ADD_MONEY}/${brokerCode}`,
    UTILS.METHODS.POST,
    accessToken,
    null,
    body
  );
}
export function commonPaymentModuleStatusService(accessToken, requestId) {
  return Api(
    ENDPOINTS.BASE_URL,
    `${ENDPOINTS.MANAGEMENT_SERVICE_CHECK_COMMON_PAYMET_STATUS}/${requestId}`,
    UTILS.METHODS.GET,
    accessToken,
    null,
    null
  );
}
export function atradAddMoneyService(accessToken, brokerCode, requestId) {
  return Api(
    ENDPOINTS.BASE_URL,
    `${ENDPOINTS.MANAGEMENT_SERVICE_TOP_UP_CUSTOMER}/${brokerCode}/${requestId}`,
    UTILS.METHODS.GET,
    accessToken,
    null,
    null
  );
}
export function getFundTranferDetailsService(accessToken, brokerCode) {
  return Api(
    ENDPOINTS.BASE_URL,
    `${ENDPOINTS.MANAGEMENT_SERVICE_GET_DIALOG_FINANCE_AC}/${brokerCode}`,
    UTILS.METHODS.GET,
    accessToken,
    null,
    null
  );
}

//kyc OTP
export function KycOTPRequestService(accessToken, brokerCode) {
  return Api(
    ENDPOINTS.BASE_URL,
    `${ENDPOINTS.TRIGGER_KYC_OTP}/${brokerCode}`,
    UTILS.METHODS.GET,
    accessToken,
    null,
    null
  );
}

export function KycOTPValidateService(accessToken, brokerCode, OTP) {
  return Api(
    ENDPOINTS.BASE_URL,
    `${ENDPOINTS.VALIDATE_KYC_OTP}/${brokerCode}/${OTP}`,
    UTILS.METHODS.GET,
    accessToken,
    null,
    null
  );
}

export function checkFreshUserEmailVerificationStateService(accessToken) {
  return Api(
    ENDPOINTS.BASE_URL,
    ENDPOINTS.CHECK_FRESH_USER_EMAIL_VERIFICATION_STATE,
    UTILS.METHODS.GET,
    accessToken,
    null,
    null
  );
}

export function triggerResendEmailService(accessToken) {
  return Api(
    ENDPOINTS.BASE_URL,
    ENDPOINTS.TRIGGER_RESEND_EMAIL_API,
    UTILS.METHODS.GET,
    accessToken,
    null,
    null
  );
}

export function getCustomerStatusService(accessToken) {
  return Api(
    ENDPOINTS.BASE_URL,
    ENDPOINTS.GET_CUSTOMER_STATUS_API,
    UTILS.METHODS.GET,
    accessToken,
    null,
    null
  );
}

export function triggerEditSellShareAmountService(
  accessToken,
  brokerName,
  securityCode
) {
  return Api(
    ENDPOINTS.BASE_URL,
    `${ENDPOINTS.TRIGGER_EDIT_SELL_SHARE_AMOUNT_API}/${brokerName}/${securityCode}`,
    UTILS.METHODS.GET,
    accessToken,
    null,
    null
  );
}
