import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import Layout from "../../Layout/Layout";
import "./BuyStockConfirmationStyles.scss";
import BodyContainer from "../../../Components/BodyArea/Body";
import { Images } from "../../../Theme/Images";
import moment from "moment";
import * as commonActions from "../../../Redux/Actions/commonAction";
import AlertDialog from "../../../Components/Alert/Alert";
import * as stockActions from "../../../Redux/Actions/stockAction";
import * as dashboardActions from "../../../Redux/Actions/dashboardAction";
import Constants from "../../../Assets/Constants";

class BuyStockConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "0",
      sharePrice: "0",
      grandTotal: "0",
      marketCloseStatus: false,
      insufficienfFundStatus: false,
      commision: parseFloat(this.props.commision).toFixed(2),
      buttonDisabled: false,
    };
  }

  componentDidMount() {
    const { getMarketOpenTime } = this.props;
    getMarketOpenTime(Constants.COMMON_API.MARKET_OPENING_HOURS);
    this.props.getMarketStatus();
    this.setState({
      commision: parseFloat(this.props.commision).toFixed(2),
    });

    this.props.setBottomSheetStatus(false);
    let stockPrice =
      this.props.buyStockPrice + "." + this.props.buyStockPriceDecimal;
    let editPrice =
      this.props.orderEditItemPrice +
      "." +
      this.props.orderEditItemPriceDecimal;
    if (this.props.buyOrderEditStatus) {
      this.setState({
        sharePrice: (
          parseFloat(editPrice) * parseFloat(this.props.orderEditItemQty)
        ).toFixed(2),
        grandTotal: (
          parseFloat(editPrice) * parseFloat(this.props.orderEditItemQty) +
          parseFloat(this.state.commision)
        ).toFixed(2),
      });
    } else {
      this.props.getTradeStatus(this.props.selectedStock.security);
      this.setState({
        sharePrice: (
          parseFloat(stockPrice) * parseFloat(this.props.buyNumberOfShares)
        ).toFixed(2),
        grandTotal: (
          parseFloat(stockPrice) * parseFloat(this.props.buyNumberOfShares) +
          parseFloat(this.state.commision)
        ).toFixed(2),
      });
    }
  }

  numberWithCommas = (x) => {
    let srt = x.substr(0, x.indexOf("."));
    const value = srt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  };

  numberAddCommas = (x) => {
    const value = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  };

  buttonOnPress = () => {
    const { marketOpeningHrs } = this.props;
    this.setState({
      buttonDisabled: true,
    });
    this.props.getMarketStatus();
    if (this.props.buyOrderEditStatus) {
      this.props.getOrderListBuyStatus(() => {
        if (this.props.buyOrderList) {
          if (
            this.props.buyOrderList.find(
              (order) =>
                order.clientorderid ===
                this.props.buyOrderEditItem.clientorderid
            )
          ) {
            let item = this.props.buyOrderList.find(
              (item) =>
                item.clientorderid === this.props.buyOrderEditItem.clientorderid
            );
            if (
              (item.orderStatus && item.orderStatus === "NEW") ||
              item.orderStatus === "P.FILLED" ||
              item.orderStatus === "QUEUED" ||
              item.orderStatus === "Q.AMEND"
            ) {
              if (
                this.props.buyOrderEditItem.timeinforce === "DAY" &&
                this.props.marketStatus === "Close"
              ) {
                this.setState({
                  marketCloseStatus: true,
                  buttonDisabled: false,
                });
                let alertObj = {
                  visibility: true,
                  dialogMessage: `During Open Auction call ${marketOpeningHrs} the system accepts orders. These orders can be amended and cancelled during this session. However, no trades take place during this stage`,
                  dialogTitle: "Market is currently closed",
                  dialogPrimaryButton: "CHANGE ORDER DURATION",
                  dialogSecondaryButton: "CLOSE",
                  dialogIcon: Images.buyStocks.ic_marketClose,
                };
                this.props.showAlertDialog(alertObj);
              } else {
                this.props.editBuyOrder();
              }
            } else {
              //
              let alertObj = {
                visibility: true,
                dialogMessage:
                  "You cannot make this change as the order or the part of the order has already been processed.",
                dialogTitle: "Failed",
                dialogPrimaryButton: "TRY AGAIN",
                dialogSecondaryButton: "",
                dialogIcon: Images.buyStocks.ic_close,
              };
              this.props.showAlertDialog(alertObj);
              this.setState({
                buttonDisabled: false,
              });
            }
          }
        }
      });
    } else {
      if (this.props.buyTif === 0 && this.props.marketStatus === "Close") {
        this.setState({
          marketCloseStatus: true,
          buttonDisabled: false,
        });
        let alertObj = {
          visibility: true,
          dialogMessage: `During Open Auction call ${marketOpeningHrs} the system accepts orders. These orders can be amended and cancelled during this session. However, no trades take place during this stage`,
          dialogTitle: "Market is currently closed",
          dialogPrimaryButton: "CHANGE ORDER DURATION",
          dialogSecondaryButton: "CLOSE",
          dialogIcon: Images.buyStocks.ic_marketClose,
        };

        this.props.showAlertDialog(alertObj);
      } else if (
        this.props.buyTif === 0 &&
        this.props.tradeStatus.toLowerCase() !== "true"
      ) {
        this.setState({
          marketCloseStatus: true,
          buttonDisabled: false,
        });
        let alertObj = {
          visibility: true,
          dialogMessage:
            "This security is not open for trading. Do you want to queue the order by changing the order duration?",
          dialogTitle: "Security is currently closed",
          dialogPrimaryButton: "CHANGE ORDER DURATION",
          dialogSecondaryButton: "CLOSE",
          dialogIcon: Images.buyStocks.ic_marketClose,
        };
        this.props.showAlertDialog(alertObj);
      } else if (
        parseFloat(this.props.availableToInvest) <
        parseFloat(this.state.grandTotal)
      ) {
        this.setState({
          insufficienfFundStatus: true,
          buttonDisabled: false,
        });
        let alertObj = {
          visibility: true,
          dialogMessage:
            "This transaction cannot be sent because there are insufficient funds available",
          dialogTitle: "Insufficient funds",
          dialogPrimaryButton: "ADD MONEY",
          dialogSecondaryButton: "EDIT ORDER",
          dialogIcon: Images.buyStocks.ic_danger,
        };
        this.props.showAlertDialog(alertObj);
      } else {
        this.props.buyStockConfirmation();
        this.setState({
          buttonDisabled: false,
        });
      }
    }
  };

  onPrimaryPressed = () => {
    this.setState({
      buttonDisabled: false,
    });
    if (this.state.marketCloseStatus) {
      this.props.setBottomSheetStatus(true);
      this.props.hideAlertDialog();
      this.props.history.push("buy-stock");
    } else if (this.state.insufficienfFundStatus) {
      this.props.hideAlertDialog();
      this.props.history.push("add-money");
    } else if (this.props.buyOrderEditResponse) {
      this.props.hideAlertDialog();
      this.props.setBuyNumberOfShares("");
      this.props.setSelectedOrderItemQuantity("");
      this.props.setBuyStockPrice("");
      this.props.setSelectedOrderItemPrice("");
      this.props.setBuyStockPriceDecimal("");
      this.props.setSelectedOrderItemPriceDecimal("");
      this.props.setOderEditStatus(false);
      this.props.setSelectedTab(1);
      this.props.history.push("order-list");
    } else {
      this.props.hideAlertDialog();
    }
  };

  onSecondaryPressed = () => {
    this.props.hideAlertDialog();
  };

  onLeftIconClick = () => {
    this.props.history.goBack();
  };

  getRowValuesOneStyle = (firstValue, SecondValue) => {
    return (
      <div>
        <div className="rowValueMainView" />
        <div className="bsc-row">
          <div className="bsc-space-5">
            <span className="bsc-header-text-card">{firstValue}</span>
          </div>
          <div className="bsc-space-1">
            <span className="bsc-header-text-card">:</span>
          </div>
          <div className="bsc-space-6" style={{ textAlign: "end" }}>
            <span className="bsc_header_lkrText">{SecondValue}</span>
          </div>
        </div>
      </div>
    );
  };

  getRowValuesThreeStyles = (firstValue, secondValue, thirdValue) => {
    return (
      <div>
        <div className="rowValueMainView" />
        <div className="bsc-row">
          <div className="bsc-space-5">
            <span className="bsc-header-text-card">{firstValue}</span>
          </div>
          <div className="bsc-space-1">
            <span className="bsc-header-text-card">:</span>
          </div>
          <div className="bsc-space-6" style={{ textAlign: "end" }}>
            <span className="bsc_header_lkrText">{"LKR"}</span>
            <span className="bsc_header_priceText">{secondValue}</span>
            <span className="bsc_header_decimalText">.{thirdValue}</span>
          </div>
        </div>
      </div>
    );
  };

  availableToInvestContainer = () => {
    return (
      <div className="bsc-card bsc-card-3" style={{ marginTop: "100px" }}>
        <div className="bsc-row-2">
          <div className="bsc-space-11">
            <div className="bsc-flex">
              <img
                src={Images.buyStocks.img_availableToInvest}
                className="bsc-icon-available"
                alt="logo"
              />
              <div className="bsc-ml-16">
                <div className="bsc-row-2 no-padding">
                  <h2 className="bsc-header-text-card">
                    {"Available to invest"}
                  </h2>
                </div>
                <div
                  className="bsc-row-2 no-padding"
                  style={{ marginTop: "4px" }}
                >
                  <h4 className="bsc-available-invest-lkr">{"LKR"}</h4>
                  <h4 className="bsc-available-invest-price">
                    {this.props.availableToInvest &&
                    parseFloat(this.props.availableToInvest) > 0
                      ? this.numberAddCommas(
                          parseFloat(this.props.availableToInvest)
                            .toFixed(2)
                            .split(".")[0]
                        )
                      : "0"}
                  </h4>
                  <h4 className="bsc-available-invest-decimal">
                    .
                    {this.props.availableToInvest &&
                    parseFloat(this.props.availableToInvest) > 0
                      ? this.numberAddCommas(
                          parseFloat(this.props.availableToInvest)
                            .toFixed(2)
                            .split(".")[1]
                        )
                      : "00"}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <Fragment>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft={`Buy ${
            this.props.buyOrderEditStatus
              ? this.props.buyOrderEditItem.securitycode
              : this.props.selectedStock.security
          }`}
          headerContentRight=""
          rightIcon=""
          leftIconLink="buy-stock"
          handleLeftIconClick={() => this.onLeftIconClick()}
        >
          <BodyContainer>
            <header className="App-container" data-testid="bsc-container">
              <div className="content">
                <AlertDialog
                  onPrimaryPressed={() => {
                    this.onPrimaryPressed();
                  }}
                  onSecondaryPressed={() => {
                    this.onSecondaryPressed();
                  }}
                />

                <div className="noOfShares">
                  <h3 className="bsc-input-title">{"Number of shares"}</h3>
                  <h2 className="ssc-share-count">
                    {this.props.buyOrderEditStatus
                      ? this.props.orderEditItemQty
                      : this.props.buyNumberOfShares}
                  </h2>
                </div>
                {this.getRowValuesOneStyle("Order type", "Limit")}
                {this.getRowValuesThreeStyles(
                  "Price per share",
                  this.props.buyOrderEditStatus
                    ? this.numberAddCommas(this.props.orderEditItemPrice)
                    : this.numberAddCommas(this.props.buyStockPrice),
                  this.props.buyOrderEditStatus
                    ? this.props.orderEditItemPriceDecimal
                    : this.props.buyStockPriceDecimal
                )}
                {this.getRowValuesOneStyle(
                  "Number of shares",
                  this.props.buyOrderEditStatus
                    ? this.props.orderEditItemQty
                    : this.props.buyNumberOfShares
                )}
                {this.getRowValuesOneStyle(
                  "Time in force (TIF)",
                  this.props.buyOrderEditStatus
                    ? this.props.buyOrderEditItem.timeinforce === "DAY"
                      ? "Day"
                      : "Good till day (GTD)"
                    : this.props.buyTif === 0
                    ? "Day"
                    : "Good till day (GTD)"
                )}
                {this.getRowValuesOneStyle(
                  "Order expires by",
                  this.props.buyOrderEditStatus
                    ? moment(
                      this.props.buyOrderEditItem.tifremainingdays,
                      "YYYY-MM-DD"
                    ).format("YYYY MMM DD")
                    : this.props.buyStockDuration.name
                )}

                {this.getRowValuesThreeStyles(
                  "Shares value (LKR)",
                  this.state.sharePrice
                    ? this.numberWithCommas(this.state.sharePrice)
                    : "0",
                  this.state.sharePrice
                    ? this.state.sharePrice.split(".").pop()
                    : "00"
                )}
                {this.getRowValuesThreeStyles(
                  "Commission (LKR)",
                  this.state.commision
                    ? this.numberWithCommas(this.state.commision)
                    : "0",
                  this.state.commision
                    ? this.state.commision.split(".").pop()
                    : "00"
                )}
                {this.getRowValuesThreeStyles(
                  "Grand total (LKR)",
                  this.state.grandTotal
                    ? this.numberWithCommas(this.state.grandTotal)
                    : "0",
                  this.state.grandTotal
                    ? this.state.grandTotal.split(".").pop()
                    : "00"
                )}
                {this.availableToInvestContainer()}

                <div className="buttonContainer">
                  <button
                    className="buttonStyle"
                    disabled={this.state.buttonDisabled}
                    onClick={() => {
                      this.buttonOnPress();
                    }}
                  >
                    {"CONFIRM"}
                  </button>
                </div>
              </div>
            </header>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedStock: state.BuyStockReducer.selectedStock,
    buyStockPrice: state.BuyStockReducer.buyStockPrice,
    buyStockPriceDecimal: state.BuyStockReducer.buyStockPriceDecimal,
    buyNumberOfShares: state.BuyStockReducer.buyNumberOfShares,
    buyStockDuration: state.BuyStockReducer.buyStockDuration,
    availableToInvest: state.DashboardReducer.dashboard.availableToInvest,
    commision: state.BuyStockReducer.buyCommission,
    marketStatus: state.BuyStockReducer.marketStatus,
    buyTif: state.BuyStockReducer.buyStockDuration.tif,
    buyOrderEditStatus: state.BuyStockReducer.buyOrderEditStatus,
    orderEditItemPrice: state.BuyStockReducer.orderEditItemPrice,
    orderEditItemPriceDecimal: state.BuyStockReducer.orderEditItemPriceDecimal,
    orderEditItemQty: state.BuyStockReducer.orderEditItemQty,
    buyOrderEditItem: state.BuyStockReducer.buyOrderEditItem,
    buyOrderEditResponse: state.BuyStockReducer.buyOrderEditResponse,
    tradeStatus: state.BuyStockReducer.tradeStatus,
    buyOrderList: state.BuyStockReducer.orderListBuyStatus,
    marketOpeningHrs: state.DashboardReducer.marketOpeningHrs,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMarketStatus: () => dispatch(stockActions.getMarketStatus()),
    showAlertDialog: (data) => dispatch(commonActions.showAlertDialog(data)),
    hideAlertDialog: () => dispatch(commonActions.hideAlertDialog()),
    buyStockConfirmation: () => dispatch(stockActions.buyStockConfirmation()),
    editBuyOrder: () => dispatch(stockActions.editBuyOrder()),
    setBuyStockPrice: (data) => dispatch(stockActions.setBuyStockPrice(data)),
    setBuyNumberOfShares: (data) =>
      dispatch(stockActions.setBuyNumberOfShares(data)),
    setSelectedOrderItemQuantity: (data) =>
      dispatch(stockActions.setSelectedOrderItemQuantity(data)),
    setSelectedOrderItemPrice: (data) =>
      dispatch(stockActions.setSelectedOrderItemPrice(data)),
    setBuyStockPriceDecimal: (data) =>
      dispatch(stockActions.setBuyStockPriceDecimal(data)),
    setSelectedOrderItemPriceDecimal: (data) =>
      dispatch(stockActions.setSelectedOrderItemPriceDecimal(data)),
    setOderEditStatus: (data) => dispatch(stockActions.setOderEditStatus(data)),
    setBottomSheetStatus: (data) =>
      dispatch(stockActions.setBottomSheetStatus(data)),
    setSelectedTab: (data) => dispatch(stockActions.setSelectedTab(data)),
    getTradeStatus: (data) => dispatch(stockActions.getTradeStatus(data)),
    getOrderListBuyStatus: (callback) =>
      dispatch(stockActions.getOrderListBuyStatus(callback)),
    getMarketOpenTime: (payload) =>
      dispatch(dashboardActions.getMarketOpenTime(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyStockConfirmation);
