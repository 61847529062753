import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import "./OrderListTabComponentStyles.scss";
import { Images } from "../../../../Theme/Images";
import EditOrderDropdown from "./EditOrderDropdown";
import AlertDialog from "../../../../Components/Alert/Alert";
import * as commonFunctions from "../../../../Utils/commonFunctions";
import { setSelectedTab } from "../../../../Redux/Actions/stockAction";
import { startBrowserBack, stopBrowserBack } from "../../../../Utils/helper";
import { AppBar, Tab, Tabs } from "@mui/material";
import moment from "moment";

function TabPanel(props) {
  const { items, value, index, handleOnEdit } = props;
  const [open, setOpen] = React.useState(false);
  const [navigation, setNavigation] = React.useState("");

  const toggleDropdown = (item) => {
    if (value === 1) {
      setNavigation("Buy");
    } else {
      setNavigation("Sell");
    }
    handleOnEdit(item);
    setOpen(true);
    stopBrowserBack(toggle);
  };

  const toggle = () => {
    setOpen(false);
    startBrowserBack();
  };

  const numberWithCommas = (x) => {
    let srt = x.substr(0, x.indexOf("."));
    const value = srt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  };

  const renderDateComponent = (item) => {
    if (
      item.orderStatus &&
      (item.orderStatus === "NEW" ||
        item.orderStatus === "PENDING" ||
        item.orderStatus === "P.FILLED" ||
        item.orderStatus === "P.CHANGE" ||
        item.orderStatus === "QUEUED" ||
        item.orderStatus === "Q.AMEND")
    ) {
      return (
        <>
          <div className="order-main-item-date-view">
            <div className="order-main-item-date-view-title">
              {"Order Placed :"}
            </div>
            <div className="order-main-item-date-view-date">
              {item.orderplacedate
                ? moment(item.orderplacedate).format("MMMM DD , YYYY")
                : null}
            </div>
          </div>
          <div className="order-main-item-date-view">
            <div className="order-main-item-date-view-title">
              {"Order Expiry :"}
            </div>
            <div className="order-main-item-date-view-date">
              {item.tifremainingdays
                ? moment(item.tifremainingdays, 'YYYY-MM-DD').format("MMMM DD , YYYY")
                : null}
            </div>
          </div>
        </>
      );
    } else return;
  };

  return (
    <div>
      {value === index && (
        <>
          {items[0] ? (
            items.map((item, index) => {
              return (
                <div className="order-main">
                  {index && index !== 0 ? <div className="divider" /> : null}
                  <div className="order-main-item">
                    <div className="order-main-item-initial-view">
                      <div className="order-main-item-detail-view">
                        <h2 className="order-main-item-company-code">
                          {item.securitycode}
                        </h2>
                        <h2 className="order-main-item-company-name">
                          {item.companyName}
                        </h2>
                      </div>

                      <div className="order-main-item-amnt-view">
                        <div className="order-main-item-amnt-view-content">
                          <span className="order-priceTxt">{"LKR"}</span>
                          <span className="order-value">
                            {numberWithCommas(item.orderprice)}
                          </span>
                          <span className="order-decimal">
                            .
                            {item.orderprice
                              ? commonFunctions.fixDecimalPoints(
                                  item.orderprice.split(".").pop()
                                )
                              : "00"}
                          </span>
                          <span className="order-quantity-x">{"x"}</span>
                          <span className="order-quantity">
                            {item.orderQuantity}
                          </span>
                        </div>
                        <h5
                          className="order-status"
                          style={{
                            color: commonFunctions.colorAccordingToStatus(
                              item.orderStatus
                            ),
                          }}
                        >
                          {item.orderStatus
                            ? commonFunctions.setOredrStatus(item.orderStatus)
                            : ""}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="order-middle-txt">
                    {value === 1 ? "Buy" : "Sell"} ({item.timeinforce})
                  </div>
                  <div className="order-date-view">
                    {renderDateComponent(item)}
                  </div>
                  <div>
                    {item.orderStatus &&
                    (item.orderStatus === "NEW" ||
                      item.orderStatus === "P.FILLED" ||
                      item.orderStatus === "QUEUED" ||
                      item.orderStatus === "Q.AMEND") ? (
                      <div
                        className="order-main-item-edit-text"
                        onClick={() => toggleDropdown(item)}
                      >
                        {"EDIT"}
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })
          ) : (
            <h2
              className="color-black font-size-14"
              style={{ textAlign: "center", marginTop: "40%" }}
            >
              {"No Records Found"}
            </h2>
          )}
        </>
      )}

      {open && (
        <div style={{ width: "100%", marginTop: "5%" }}>
          <EditOrderDropdown
            toggle={toggle}
            open={open}
            navigateFrom={navigation}
          />
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  items: PropTypes.array,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  handleOnEdit: PropTypes.func,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    color: "black",
    fontWeight: "normal",
    fontSize: "16sp",
    outline: "none !important",
    width: "30%",
  },
  indicator: {
    backgroundColor: "#ffffff !important",
  },
  appBarStyles: {
    backgroundColor: "#ffffff !important",
    color: "#333333 !important",
  },
}));

export default function OrderListTabComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    buyOrder,
    sellOrder,
    handleEdit,
    handleConfirmCancel,
    hideAlert,
    tabSelected,
  } = props;
  const [value, setValue] = React.useState();

  useEffect(() => {
    setValue(tabSelected);
  }, "");

  const handleChange = (event, newValue) => {
    dispatch(setSelectedTab(newValue));
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <div className="order-headerStyle">
        <AppBar
          position="static"
          elevation={0}
          className={classes.appBarStyles}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            classes={{
              indicator: classes.indicator,
            }}
          >
            <Tab
              className={classes.header}
              {...a11yProps(0)}
              label={
                <React.Fragment>
                  <div
                    className="row "
                    style={{
                      backgroundColor: value === 0 ? "#2598ed" : "white",
                      height: "20px",
                      alignContent: "center",
                      borderRadius: "20px",
                    }}
                  >
                    <img
                      alt="img"
                      src={
                        value === 0
                          ? Images.orderList.ic_sellOrder
                          : Images.orderList.ic_sellOrderGray
                      }
                    />
                    <span
                      style={{
                        color: value === 0 ? "white" : "#accee0",
                        marginLeft: "4px",
                        textTransform: "none",
                      }}
                    >
                      Sell
                    </span>
                  </div>
                </React.Fragment>
              }
            />

            <Tab
              className={classes.header}
              {...a11yProps(1)}
              label={
                <React.Fragment>
                  <div
                    className="row "
                    style={{
                      backgroundColor: value === 1 ? "#2598ed" : "white",
                      height: "20px",
                      alignContent: "center",
                      borderRadius: "20px",
                    }}
                  >
                    <img
                      alt="img"
                      src={
                        value === 1
                          ? Images.orderList.ic_buyOrder
                          : Images.orderList.ic_buyOrderGray
                      }
                    />
                    <span
                      style={{
                        color: value === 1 ? "white" : "#accee0",
                        marginLeft: "4px",
                        textTransform: "none",
                      }}
                    >
                      Buy
                    </span>
                  </div>
                </React.Fragment>
              }
            />
          </Tabs>
        </AppBar>
      </div>
      <div className="order-tab-main">
        <TabPanel
          value={value}
          index={0}
          items={sellOrder}
          handleOnEdit={handleEdit}
        />
        <TabPanel
          value={value}
          index={1}
          items={buyOrder}
          handleOnEdit={handleEdit}
        />
      </div>
      <AlertDialog
        onPrimaryPressed={handleConfirmCancel}
        onSecondaryPressed={hideAlert}
      />
    </div>
  );
}

OrderListTabComponent.defaultProps = {
  handleEdit: () => {},
  tabSelected: 1,
};
