import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import Layout from "../../Layout/Layout";
import "./StockDetailsStyles.scss";
import BodyContainer from "../../../Components/BodyArea/Body";
import { fixDecimalPoints } from "../../../Utils/commonFunctions";
import {
  showErrorPopup,
  marketDepthFail,
  securityStatFail,
} from "../../../Redux/Actions/commonAction";
import {
  getMarketDepth,
  getSecurityStat,
} from "../../../Redux/Actions/stockAction";
import { Images } from "../../../Theme/Images";
import { setSelectedStock } from "../../../Redux/Actions/buyStockListingAction";
import { Divider } from "@mui/material";

class StockDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "0",
      totalBids: "",
      totalAsk: "",
    };
  }

  componentDidMount() {
    if (this.props.marketDepthFail && this.props.securityStatFail) {
      this.props.showErrorPopup();
      this.props.marketDepthFailAction(false);
      this.props.securityStatFailAcrion(false);
    }

    this.stockDetails = setInterval(() => {
      this.props.getMarketDepth();
      this.props.getSecurityStat();
      if (this.props.marketDepthFail && this.props.securityStatFail) {
        this.props.marketDepthFailAction(false);
        this.props.securityStatFailAcrion(false);
        this.props.showErrorPopup();
      }
    }, 10000);
  }

  setSelectedStockForSellFunc() {
    const filteredStock = this.props.sellStockList.filter((listItem) => {
      if (listItem.security === this.props.selectedStock.security) {
        return listItem;
      }
      return null;
    });
    if (filteredStock[0]) {
      this.props.setSelectedStock(filteredStock[0]);
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    clearInterval(this.stockDetails);
  }

  numberWithCommas = (x) => {
    const value = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  };

  onClickBuyStocks = () => {
    this.props.history.push("buy-stock");
  };

  onClickSellStocks() {
    const condition = this.setSelectedStockForSellFunc();
    if (condition) {
      this.props.history.push("sell-stock");
    }
  }

  onLeftIconClick = () => {
    this.props.history.goBack();
  };

  getRowWithThreeStyleDivider = (firstValue, secondValue, thirdValue) => {
    return (
      <div>
        <Divider style={{ marginTop: "11px", marginBottom: "11px" }} />
        <div className="row no-padding">
          <div style={{ width: "45%" }}>
            <span className="sd_card_Text">{firstValue}</span>
          </div>
          <div className="sd-colen">:</div>
          <div style={{ textAlign: "end", width: "50%" }}>
            <span className="sd_lkrText">LKR</span>
            <span className="sd_priceText">{secondValue}</span>
            <span className="sd_decimalText">.{thirdValue}</span>
          </div>
        </div>
      </div>
    );
  };

  getRowWithTwoStyleDivider = (firstValue, secondValue) => {
    return (
      <div>
        <Divider style={{ marginTop: "11px", marginBottom: "11px" }} />
        <div className="row no-padding">
          <div style={{ width: "45%" }}>
            <span className="sd_card_Text">{firstValue}</span>
          </div>
          <div className="sd-colen">:</div>
          <div style={{ textAlign: "end", width: "50%" }}>
            <span className="sd_priceText" data-testid="total-volume">
              {secondValue}
            </span>
          </div>
        </div>
      </div>
    );
  };

  getBidAndAskRow = (
    bidSplits,
    bidQuantity,
    bidPrice,
    askSplits,
    askQuantity,
    askPrice,
    finalRecord
  ) => {
    return (
      <div className="row no-padding">
        <div className="sd-tableRow">
          <div className="sd-flex-row">
            <div
              className={`sd-table-cell-value sd-border-left-style-color ${
                finalRecord ? "sd-table-cell-value-final" : ""
              }`}
              style={{ marginLeft: "15px" }}
            >
              <span className="sd-table-cell-price-text">{bidSplits}</span>
            </div>

            <div
              className={`sd-table-cell-value ${
                finalRecord ? "sd-table-cell-value-final" : ""
              }`}
            >
              <span className="sd-table-cell-price-text">{bidQuantity}</span>
            </div>

            <div
              className={`sd-table-cell-value sd-border-right-style-color ${
                finalRecord ? "sd-table-cell-value-final" : ""
              }`}
            >
              <span className="sd-table-cell-price-text">{bidPrice}</span>
            </div>
          </div>
        </div>
        <div className="sd-tableRow">
          <div className="sd-flex-row">
            <div
              className={`sd-table-cell-value sd-border-left-style-color ${
                finalRecord ? "sd-table-cell-value-final" : ""
              }`}
            >
              <span className="sd-table-cell-price-text">{askSplits}</span>
            </div>

            <div
              className={`sd-table-cell-value ${
                finalRecord ? "sd-table-cell-value-final" : ""
              }`}
            >
              <span className="sd-table-cell-price-text">{askQuantity}</span>
            </div>

            <div
              className={`sd-table-cell-value sd-border-right-style-color ${
                finalRecord ? "sd-table-cell-value-final" : ""
              }`}
              style={{ marginRight: "15px" }}
            >
              <span className="sd-table-cell-price-text">{askPrice}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft={
            this.props.selectedStock.security
              ? this.props.selectedStock.security
              : ""
          }
          headerContentRight=""
          rightIcon=""
          rightIconLink=""
          leftIconLink="buy-stocks-list"
          rightButton={true}
          sellStockEnable={this.props.sellStockStatus}
          handleBuyPressed={() => this.onClickBuyStocks()}
          handleSellPressed={() => this.onClickSellStocks()}
          handleLeftIconClick={() => this.onLeftIconClick()}
        >
          <BodyContainer>
            <header className="App-container" data-testid="sd-container">
              {this.props.securityStatFail && this.props.marketDepthFail ? (
                <div></div>
              ) : (
                <div style={{ width: "100%" }}>
                  {this.props.selectedStock.companyName ? (
                    <div className="header-text-top">
                      <div className="header-text">
                        {this.props.selectedStock.companyName}
                      </div>
                    </div>
                  ) : null}
                  {this.props.securityStatFail ? (
                    <div className="card card-3">
                      <div className="App-error-card">
                        <img
                          src={Images.errImages.ic_error}
                          className="App-error-icon"
                          alt="logo"
                        />
                        <h4 className="App-error-text">Sorry </h4>
                        <h4 className="App-error-text">something went wrong</h4>
                      </div>
                    </div>
                  ) : (
                    <div className="sd-card sd-card-3 ">
                      <span className="sd-header-text-card">
                        Price per share
                      </span>
                      <div className="sd-mb-16">
                        <span className="sd_header_lkrText">LKR</span>
                        <span className="sd_header_priceText">
                          {this.props.securityStat.lasttradeprice
                            ? this.props.securityStat.lasttradeprice.substr(
                                0,
                                this.props.securityStat.lasttradeprice.indexOf(
                                  "."
                                )
                              )
                            : "0"}
                        </span>
                        <span className="sd_header_decimalText">
                          .
                          {this.props.securityStat.lasttradeprice
                            ? fixDecimalPoints(
                                this.props.securityStat.lasttradeprice
                                  .split(".")
                                  .pop()
                              )
                            : "00"}
                        </span>
                      </div>

                      {this.getRowWithThreeStyleDivider(
                        "Closing price",
                        this.props.securityStat.closingprice
                          ? this.props.securityStat.closingprice.substr(
                              0,
                              this.props.securityStat.closingprice.indexOf(".")
                            )
                          : "0",
                        this.props.securityStat.closingprice
                          ? fixDecimalPoints(
                              this.props.securityStat.closingprice
                                .split(".")
                                .pop()
                            )
                          : "00"
                      )}

                      {this.getRowWithThreeStyleDivider(
                        "Previous closing price",
                        this.props.securityStat.previousclose
                          ? this.props.securityStat.previousclose.substr(
                              0,
                              this.props.securityStat.previousclose.indexOf(".")
                            )
                          : "0",
                        this.props.securityStat.previousclose
                          ? fixDecimalPoints(
                              this.props.securityStat.previousclose
                                .split(".")
                                .pop()
                            )
                          : "00"
                      )}

                      {this.getRowWithThreeStyleDivider(
                        "Turnover",
                        this.props.securityStat.totalturnover
                          ? this.props.securityStat.totalturnover.substr(
                              0,
                              this.props.securityStat.totalturnover.indexOf(".")
                            )
                          : "0",
                        this.props.securityStat.totalturnover
                          ? fixDecimalPoints(
                              this.props.securityStat.totalturnover
                                .split(".")
                                .pop()
                            )
                          : "00"
                      )}

                      {this.getRowWithTwoStyleDivider(
                        "Share volume",
                        this.props.securityStat.totalvolume
                          ? this.numberWithCommas(
                              this.props.securityStat.totalvolume
                            )
                          : "0"
                      )}

                      {this.getRowWithTwoStyleDivider(
                        "Trade volume",
                        this.props.securityStat.totalnotrades
                          ? this.numberWithCommas(
                              this.props.securityStat.totalnotrades
                            )
                          : "0"
                      )}

                      <Divider
                        style={{ marginTop: "11px", marginBottom: "11px" }}
                      />
                      <div className="row no-padding">
                        <div style={{ width: "45%" }}>
                          <span className="sd_card_Text">
                            Day’s price range
                          </span>
                        </div>
                        <span className="sd-colen">:</span>
                        <div style={{ textAlign: "end", width: "50%" }}>
                          <span className="sd_lkrText">LKR</span>
                          <span className="sd_priceText">
                            {this.props.securityStat.highpricetrade
                              ? this.props.securityStat.highpricetrade.substr(
                                  0,
                                  this.props.securityStat.highpricetrade.indexOf(
                                    "."
                                  )
                                )
                              : "0"}
                          </span>
                          <span className="sd_decimalText">
                            .
                            {this.props.securityStat.highpricetrade
                              ? fixDecimalPoints(
                                  this.props.securityStat.highpricetrade
                                    .split(".")
                                    .pop()
                                )
                              : "00"}
                          </span>
                          <span className="sd-colen"> -</span>
                          <span className="sd_lkrText">LKR</span>
                          <span className="sd_priceText">
                            {this.props.securityStat.lowpricetrade
                              ? this.props.securityStat.lowpricetrade.substr(
                                  0,
                                  this.props.securityStat.lowpricetrade.indexOf(
                                    "."
                                  )
                                )
                              : "0"}
                          </span>
                          <span className="sd_decimalText">
                            .
                            {this.props.securityStat.lowpricetrade
                              ? fixDecimalPoints(
                                  this.props.securityStat.lowpricetrade
                                    .split(".")
                                    .pop()
                                )
                              : "00"}
                          </span>
                        </div>
                      </div>

                      {this.props.securityStat.marketcap
                        ? this.getRowWithThreeStyleDivider(
                            "Market capitalisation",
                            this.props.securityStat.marketcap
                              ? this.props.securityStat.marketcap.substr(
                                  0,
                                  this.props.securityStat.marketcap.indexOf(".")
                                )
                              : "0",

                            this.props.securityStat.marketcap
                              ? fixDecimalPoints(
                                  this.props.securityStat.marketcap
                                    .split(".")
                                    .pop()
                                )
                              : "00"
                          )
                        : this.getRowWithTwoStyleDivider(
                            "Market capitalisation",
                            "Not Available"
                          )}
                    </div>
                  )}
                  {this.props.marketDepthFail ? (
                    <div className="card card-3">
                      <div className="App-error-card">
                        <img
                          src={Images.errImages.ic_error}
                          className="App-error-icon"
                          alt="logo"
                        />
                        <h4 className="App-error-text">Sorry </h4>
                        <h4 className="App-error-text">something went wrong</h4>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="tb-ta-card tb-ta-card-3"
                      style={{ marginTop: "30px" }}
                    >
                      <div className="sd-row">
                        <div className="sd-tb-left">
                          <h6 className="sd-tb-header-title">Total bids</h6>
                          <h6 className="sd-tb-header-value">
                            {this.numberWithCommas(this.props.totalBids)}
                          </h6>
                        </div>
                        <div className="sd-ta-right">
                          <h6 className="sd-ta-header-title">Total asks</h6>
                          <h6 className="sd-ta-header-value">
                            {this.numberWithCommas(this.props.totalAsk)}
                          </h6>
                        </div>
                      </div>

                      <div className="row no-padding">
                        <div className="sd-tableRow">
                          <div className="sd-flex-row">
                            <div
                              className="sd-table-cell sd-border-left-style-color"
                              style={{ marginLeft: "15px" }}
                            >
                              <span className="sd-table-subheader">SPLITS</span>
                            </div>

                            <div className="sd-table-cell">
                              <span className="sd-table-subheader">QTY.</span>
                            </div>

                            <div className="sd-table-cell sd-border-right-style-color">
                              <span className="sd-table-subheader">
                                PRICE(LKR)
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="sd-tableRow">
                          <div className="sd-flex-row">
                            <div className="sd-table-cell sd-border-left-style-color">
                              <span className="sd-table-subheader">SPLITS</span>
                            </div>

                            <div className="sd-table-cell">
                              <span className="sd-table-subheader">QTY.</span>
                            </div>

                            <div
                              className="sd-table-cell sd-border-right-style-color"
                              style={{ marginRight: "15px" }}
                            >
                              <span className="sd-table-subheader">
                                PRICE(LKR)
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {this.getBidAndAskRow(
                        this.props.bidArray && this.props.bidArray[0]
                          ? this.props.bidArray[0].splits
                          : null,

                        this.props.bidArray && this.props.bidArray[0]
                          ? this.props.bidArray[0].qty
                          : null,

                        this.props.bidArray && this.props.bidArray[0]
                          ? this.props.bidArray[0].price
                          : null,

                        this.props.askArray && this.props.askArray[0]
                          ? this.props.askArray[0].splits
                          : null,

                        this.props.askArray && this.props.askArray[0]
                          ? this.props.askArray[0].qty
                          : null,

                        this.props.askArray && this.props.askArray[0]
                          ? this.props.askArray[0].price
                          : null,
                        false
                      )}

                      {this.getBidAndAskRow(
                        this.props.bidArray && this.props.bidArray[1]
                          ? this.props.bidArray[1].splits
                          : null,

                        this.props.bidArray && this.props.bidArray[1]
                          ? this.props.bidArray[1].qty
                          : null,

                        this.props.bidArray && this.props.bidArray[1]
                          ? this.props.bidArray[1].price
                          : null,

                        this.props.askArray && this.props.askArray[1]
                          ? this.props.askArray[1].splits
                          : null,

                        this.props.askArray && this.props.askArray[1]
                          ? this.props.askArray[1].qty
                          : null,

                        this.props.askArray && this.props.askArray[1]
                          ? this.props.askArray[1].price
                          : null,
                        false
                      )}

                      {this.getBidAndAskRow(
                        this.props.bidArray && this.props.bidArray[2]
                          ? this.props.bidArray[2].splits
                          : null,

                        this.props.bidArray && this.props.bidArray[2]
                          ? this.props.bidArray[2].qty
                          : null,

                        this.props.bidArray && this.props.bidArray[2]
                          ? this.props.bidArray[2].price
                          : null,

                        this.props.askArray && this.props.askArray[2]
                          ? this.props.askArray[2].splits
                          : null,

                        this.props.askArray && this.props.askArray[2]
                          ? this.props.askArray[2].qty
                          : null,

                        this.props.askArray && this.props.askArray[2]
                          ? this.props.askArray[2].price
                          : null,
                        false
                      )}

                      {this.getBidAndAskRow(
                        this.props.bidArray && this.props.bidArray[3]
                          ? this.props.bidArray[3].splits
                          : null,

                        this.props.bidArray && this.props.bidArray[3]
                          ? this.props.bidArray[3].qty
                          : null,

                        this.props.bidArray && this.props.bidArray[3]
                          ? this.props.bidArray[3].price
                          : null,

                        this.props.askArray && this.props.askArray[3]
                          ? this.props.askArray[3].splits
                          : null,

                        this.props.askArray && this.props.askArray[3]
                          ? this.props.askArray[3].qty
                          : null,

                        this.props.askArray && this.props.askArray[3]
                          ? this.props.askArray[3].price
                          : null,
                        true
                      )}
                    </div>
                  )}
                </div>
              )}
            </header>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    marketDepth: state.BuyStockReducer.marketDepth,
    securityStat: state.BuyStockReducer.securityStat,
    selectedStock: state.BuyStockReducer.selectedStock,
    askArray: state.BuyStockReducer.marketDepth.ask,
    bidArray: state.BuyStockReducer.marketDepth.bid,
    totalBids: state.BuyStockReducer.totalBids,
    totalAsk: state.BuyStockReducer.totalAsk,
    sellStockStatus: state.BuyStockReducer.sellStatus,
    securityStatFail: state.CommonReducer.securityStatFail,
    marketDepthFail: state.CommonReducer.marketDepthFail,
    sellStockList: state.CommonReducer.sellStockList,
    selectedSecurityForSell: state.BuyStockReducer.selectedSecurityForSell,
    ccccc: state.BuyStockReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMarketDepth: () => dispatch(getMarketDepth()),
    getSecurityStat: () => dispatch(getSecurityStat()),
    showErrorPopup: () => dispatch(showErrorPopup()),
    marketDepthFailAction: (data) => dispatch(marketDepthFail(data)),
    securityStatFailAcrion: (data) => dispatch(securityStatFail(data)),
    setSelectedStock: (data) => dispatch(setSelectedStock(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StockDetails);
