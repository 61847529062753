import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import PullToRefresh from "react-simple-pull-to-refresh";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import Layout from "../../Layout/Layout";
import "./InvestmentAdvisorStyles.scss";
import BodyContainer from "../../../Components/BodyArea/Body";
import { Divider } from "@mui/material";

class InvestmentAdvisor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHeader: true,
    };
  }

  hideAlert = () => {
    this.props.hideAlertDialog();
  };

  numberWithSpace = (x) => {
    if (x.length === 9) {
      x = "0" + x;
    }
    let cleaned = ("" + x).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{4})(\d{3})(\d{3})$/);
    if (match) {
      return match[1] + " " + match[2] + " " + match[3];
    }
    return null;
  };

  onLeftIconClick = () => {
    this.props.history.push("dashboard");
  };

  render() {
    return (
      <Fragment>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft="Investment advisor"
          leftIconLink="dashboard"
          showHeader={true}
          handleLeftIconClick={() => this.onLeftIconClick()}
        >
          <BodyContainer>
            <PullToRefresh refreshingContent=" " pullingContent=" ">
              <header className="App-container">
                <div style={{ width: "100%" }}>
                  <div className="ia-top-row">
                    <h3 className="ia-header-text">
                      Contact the following person for any investment problems
                      you may have
                    </h3>
                  </div>

                  <div className="card card-3 ia-card">
                    <h3 className="ia-card-header-text">Name</h3>
                    <h3 className="ia-card-text">
                      {this.props.investmentAdvisor
                        ? this.props.investmentAdvisor.name
                        : ""}
                    </h3>
                    <Divider
                      variant="middle"
                      style={{ marginTop: "4%", marginBottom: "4%" }}
                    />
                    <h3 className="ia-card-header-text">Telephone number</h3>
                    <h3 className="ia-card-text">
                      {this.numberWithSpace(
                        this.props.investmentAdvisor
                          ? this.props.investmentAdvisor.mobileNo
                          : ""
                      )}
                    </h3>
                    <Divider
                      variant="middle"
                      style={{ marginTop: "4%", marginBottom: "4%" }}
                    />
                    <h3 className="ia-card-header-text">Email</h3>
                    <h3 className="ia-card-text">
                      {this.props.investmentAdvisor
                        ? this.props.investmentAdvisor.email
                        : ""}
                    </h3>
                  </div>
                </div>
              </header>
            </PullToRefresh>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.CommonReducer.showLoader,
    investmentAdvisor: state.DashboardReducer.investmentAdvisor,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentAdvisor);
