import * as Types from "./index";

export const checkAccountAvailability = (payload) => {
  return {
    type: Types.CHECK_ACCOUNT_AVAILABILITY,
    payload,
  };
};

export const getBrokerList = () => {
  return {
    type: Types.GET_BROKER_LIST,
  };
};

export const setSelectedBroker = (payload) => {
  return {
    type: Types.SET_SELECTED_BROKER,
    payload,
  };
};
