import React from "react";
import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";

export default function TextInputStandard(props) {
  const [inputValue, setValue] = React.useState("");
  const {
    onChange,
    placeholder,
    maxlength,
    value,
    width,
    label,
    autoFocus,
    alignInput,
    fontSize,
    handleBlur,
    handleFocus,
  } = props;

  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        width: `${width} !important`,
        fontSize: `${fontSize} !important`,
        alignSelf: "center !important",
      },
      "& .MuiInput-underline:before": {
        content: "none !important",
      },
    },
    inputField: {
      borderBottom: "none !important",
    },
    input: {
      color: "black !important",
    },
    centerAdornment: {},
    noBorder: {
      border: "none !important",
    },
  }));

  const classes = useStyles();

  const handleOnChange = (event) => {
    onChange(event.target.value.substring(0, parseInt(maxlength)));
    setValue(event.target.value.substring(0, parseInt(maxlength)));
  };

  const handleOnBlur = () => {
    handleBlur();
  };

  const handleOnFocus = () => {
    handleFocus();
  };

  const MAX_LENGTH = parseInt(maxlength);

  return (
    <TextField
      data-testid="textInput-container"
      type="number"
      autoFocus={autoFocus}
      onChange={handleOnChange}
      placeholder={placeholder}
      value={inputValue ? inputValue : value}
      onBlur={() => handleOnBlur()}
      onFocus={() => handleOnFocus()}
      className={classes.root}
      margin="dense"
      inputMode="numeric"
      inputProps={{
        "aria-label": label,
        classes: {
          input: classes.input,
        },
        style: { textAlign: alignInput, padding: 0 },
        inputMode: "numeric",
        pattern: "[0-9]*",
        maxLength: MAX_LENGTH,
      }}
      InputProps={{
        classes: { notchedOutline: classes.noBorder },
        style: { padding: "0px !important" },
      }}
    />
  );
}

TextInputStandard.defaultProps = {
  placeholder: "00",
  maxlength: 12,
  autoFocus: false,
  alignInput: "left",
  fontSize: "2em",
  handleBlur: () => {},
  handleFocus: () => {},
};
