import { Typography } from "@mui/material";
import React from "react";

const VerticalTextPair = ({
  topValue,
  topStyle,
  bottomValue,
  bottomStyle,
  variant,
}) => {
  return (
    <React.Fragment>
      <Typography variant={variant} style={topStyle}>
        {topValue}
      </Typography>
      <Typography variant={variant} style={bottomStyle}>
        {bottomValue}
      </Typography>
    </React.Fragment>
  );
};

export default VerticalTextPair;
