import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import Layout from "../../Layout/Layout";
import "./SellStockConfirmationStyles.scss";
import BodyContainer from "../../../Components/BodyArea/Body";
import moment from "moment";
import {
  showAlertDialog,
  hideAlertDialog,
} from "../../../Redux/Actions/commonAction";
import AlertDialog from "../../../Components/Alert/Alert";
import { Images } from "../../../Theme/Images";
import {
  getMarketStatus,
  sellStockConfirmation,
  editSellOrder,
  setSellStockPrice,
  SetSellNumberOfShares,
  setSelectedOrderItemQuantity,
  setSelectedOrderItemPrice,
  setSelectedOrderItemPriceDecimal,
  setSellStockPriceDecimal,
  setOderEditStatus,
  setBottomSheetStatus,
  setSelectedTab,
  getTradeStatus,
  getOrderListBuyStatus,
} from "../../../Redux/Actions/stockAction";
import * as dashboardActions from "../../../Redux/Actions/dashboardAction";
import Constants from "../../../Assets/Constants";

class SellStockConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "0",
      sharePrice: "0",
      grandTotal: "0",
      commision: parseFloat(this.props.commision).toFixed(2),
      marketCloseStatus: false,
    };
  }

  componentDidMount() {
    const { getMarketOpenTime } = this.props;
    getMarketOpenTime(Constants.COMMON_API.MARKET_OPENING_HOURS);
    this.props.getMarketStatus();
    this.props.setBottomSheetStatus(false);
    let stockPrice =
      this.props.sellStockPrice + "." + this.props.sellStockPriceDecimal;

    let editPrice =
      this.props.orderEditItemPrice +
      "." +
      this.props.orderEditItemPriceDecimal;
    if (this.props.orderEditStatus) {
      this.setState({
        sharePrice: (
          parseFloat(editPrice) * parseFloat(this.props.orderEditItemQty)
        ).toFixed(2),
        grandTotal: (
          parseFloat(editPrice) * parseFloat(this.props.orderEditItemQty) -
          parseFloat(this.state.commision)
        ).toFixed(2),
      });
    } else {
      this.props.getTradeStatus(this.props.selectedStock.security);
      this.setState({
        sharePrice: (
          parseFloat(stockPrice) * parseFloat(this.props.sellNumberOfShares)
        ).toFixed(2),
        grandTotal: (
          parseFloat(stockPrice) * parseFloat(this.props.sellNumberOfShares) -
          parseFloat(this.state.commision)
        ).toFixed(2),
      });
    }
  }

  numberWithCommas = (x) => {
    let srt = x.substr(0, x.indexOf("."));
    const value = srt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  };
  numberAddCommas = (x) => {
    const value = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  };

  onPrimaryPressed = () => {
    if (this.state.marketCloseStatus) {
      this.props.setBottomSheetStatus(true);
      this.props.hideAlertDialog();
      this.props.history.push("sell-stock");
    } else if (this.props.orderEditResponse) {
      this.props.hideAlertDialog();
      this.props.SetSellNumberOfShares("");
      this.props.setSelectedOrderItemQuantity("");
      this.props.setSellStockPrice("");
      this.props.setSelectedOrderItemPrice("");
      this.props.setSelectedOrderItemPriceDecimal("");
      this.props.setSellStockPriceDecimal("");
      this.props.setOderEditStatus(false);
      this.props.setSelectedTab(0);
      this.props.history.push("order-list");
    } else {
      this.props.hideAlertDialog();
    }
  };

  buttonOnPress = () => {
    const { marketOpeningHrs } = this.props;
    this.props.getMarketStatus();
    if (this.props.orderEditStatus) {
      this.props.getOrderListBuyStatus(() => {
        if (this.props.buyOrderList) {
          if (
            this.props.buyOrderList.find(
              (order) =>
                order.clientorderid === this.props.orderEditItem.clientorderid
            )
          ) {
            let item = this.props.buyOrderList.find(
              (item) =>
                item.clientorderid === this.props.orderEditItem.clientorderid
            );
            if (
              (item.orderStatus && item.orderStatus === "NEW") ||
              item.orderStatus === "P.FILLED" ||
              item.orderStatus === "QUEUED" ||
              item.orderStatus === "Q.AMEND"
            ) {
              ///let
              if (
                this.props.orderEditItem.timeinforce === "DAY" &&
                this.props.marketStatus === "Close"
              ) {
                this.setState({
                  marketCloseStatus: true,
                });
                let alertObj = {
                  visibility: true,
                  dialogMessage: `During Open Auction call ${marketOpeningHrs} the system accepts orders. These orders can be amended and cancelled during this session. However, no trades take place during this stage`,
                  dialogTitle: "Market is currently closed",
                  dialogPrimaryButton: "CHANGE ORDER DURATION",
                  dialogSecondaryButton: "CLOSE",
                  dialogIcon: Images.buyStocks.ic_marketClose,
                };
                this.props.showAlertDialog(alertObj);
              } else {
                this.props.editSellOrder();
              }
            } else {
              //
              let alertObj = {
                visibility: true,
                dialogMessage:
                  "You cannot make this change as the order or the part of the order has already been processed.",
                dialogTitle: "Failed",
                dialogPrimaryButton: "TRY AGAIN",
                dialogSecondaryButton: "",
                dialogIcon: Images.buyStocks.ic_close,
              };
              this.props.showAlertDialog(alertObj);
            }
          }
        }
      });
    } else {
      if (this.props.sellTif === 0 && this.props.marketStatus === "Close") {
        this.setState({
          marketCloseStatus: true,
        });
        let alertObj = {
          visibility: true,
          dialogMessage: `During Open Auction call ${marketOpeningHrs} the system accepts orders. These orders can be amended and cancelled during this session. However, no trades take place during this stage`,
          dialogTitle: "Market is currently closed",
          dialogPrimaryButton: "CHANGE ORDER DURATION",
          dialogSecondaryButton: "CLOSE",
          dialogIcon: Images.buyStocks.ic_marketClose,
        };
        this.props.showAlertDialog(alertObj);
      } else if (
        this.props.sellTif === 0 &&
        this.props.tradeStatus.toLowerCase() !== "true"
      ) {
        this.setState({
          marketCloseStatus: true,
        });
        let alertObj = {
          visibility: true,
          dialogMessage:
            "This security is not open for trading. Do you want to queue the order by changing the order duration?",
          dialogTitle: "Security is currently closed",
          dialogPrimaryButton: "CHANGE ORDER DURATION",
          dialogSecondaryButton: "CLOSE",
          dialogIcon: Images.buyStocks.ic_marketClose,
        };
        this.props.showAlertDialog(alertObj);
      } else {
        this.props.sellStockConfirmation();
      }
    }
  };

  onSecondaryPressed = () => {
    this.props.hideAlertDialog();
  };

  onLeftIconClick = () => {
    this.props.history.goBack();
  };

  getRowValuesOneStyle = (firstValue, SecondValue) => {
    return (
      <div>
        <div
          style={{
            marginLeft: "16px", 
            marginRight: "16px",
            borderBottomWidth: "1px",
            borderBottomColor: "gray",
            borderBottomStyle: "dashed",
          }}
        ></div>
        <div className="ssc-row">
          <div className="ssc-space-5">
            <span className="ssc-header-text-card">{firstValue}</span>
          </div>
          <div className="ssc-space-1">
            <span className="ssc-header-text-card">:</span>
          </div>
          <div className="ssc-space-6" style={{ textAlign: "end" }}>
            <span className="ssc_header_lkrText">{SecondValue}</span>
          </div>
        </div>
      </div>
    );
  };

  getRowValuesThreeStyles = (firstValue, secondValue, thirdValue) => {
    return (
      <div>
        <div
          style={{
            marginLeft: "16px",
            marginRight: "16px",
            borderBottomWidth: "1px",
            borderBottomColor: "gray",
            borderBottomStyle: "dashed",
          }}
        ></div>
        <div className="ssc-row">
          <div className="ssc-space-5">
            <span className="ssc-header-text-card">{firstValue}</span>
          </div>
          <div className="ssc-space-1">
            <span className="ssc-header-text-card">:</span>
          </div>
          <div className="ssc-space-6" style={{ textAlign: "end" }}>
            <span className="ssc_header_lkrText">LKR</span>
            <span className="ssc_header_priceText">{secondValue}</span>
            <span className="ssc_header_decimalText">.{thirdValue}</span>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft={`Sell ${
            this.props.orderEditStatus
              ? this.props.orderEditItem.securitycode
              : this.props.selectedStock.security
          }`}
          headerContentRight=""
          rightIcon=""
          leftIconLink="sell-stock"
          handleLeftIconClick={() => this.onLeftIconClick()}
        >
          <BodyContainer>
            <header className="App-container" data-testid="ssc-container">
              <div style={{ width: "100%" }}>
                <AlertDialog
                  onPrimaryPressed={() => {
                    this.onPrimaryPressed();
                  }}
                  onSecondaryPressed={() => {
                    this.onSecondaryPressed();
                  }}
                />

                <div
                  style={{
                    width: "100%",
                    alignContent: "center",
                    textAlign: "center",
                    marginTop: "30px",
                  }}
                >
                  <h3 className="ssc-input-title">Number of shares</h3>
                  <h2 className="ssc-share-count">
                    {this.props.orderEditStatus
                      ? this.props.orderEditItemQty
                      : this.props.sellNumberOfShares}
                  </h2>
                </div>

                {this.getRowValuesOneStyle("Order type", "Limit")}

                {this.getRowValuesThreeStyles(
                  "Price per share",
                  this.props.orderEditStatus
                    ? this.numberAddCommas(this.props.orderEditItemPrice)
                    : this.numberAddCommas(this.props.sellStockPrice),
                  this.props.orderEditStatus
                    ? this.props.orderEditItemPriceDecimal
                    : this.props.sellStockPriceDecimal
                )}

                {this.getRowValuesOneStyle(
                  "Number of shares",
                  this.props.orderEditStatus
                    ? this.props.orderEditItemQty
                    : this.props.sellNumberOfShares
                )}

                {this.getRowValuesOneStyle(
                  "Time in force (TIF)",
                  this.props.orderEditStatus
                    ? this.props.orderEditItem.timeinforce === "DAY"
                      ? "Day"
                      : " Good till day (GTD)"
                    : this.props.sellTif === 0
                    ? "Day"
                    : " Good till day (GTD)"
                )}

                {this.getRowValuesOneStyle(
                  "Order expires by",
                  this.props.orderEditStatus
                     ? moment(
                      this.props.orderEditItem.tifremainingdays,
                      "YYYY-MM-DD"
                    ).format("YYYY MMM DD")
                    : this.props.sellStockDuration.name
                )}

                {this.getRowValuesThreeStyles(
                  "Shares value (LKR)",
                  this.state.sharePrice
                    ? this.numberWithCommas(this.state.sharePrice)
                    : "0",
                  this.state.sharePrice
                    ? this.state.sharePrice.split(".").pop()
                    : "00"
                )}

                {this.getRowValuesThreeStyles(
                  "Commission (LKR)",
                  this.state.commision
                    ? this.numberWithCommas(this.state.commision)
                    : "0",
                  this.state.commision
                    ? this.state.commision.split(".").pop()
                    : "00"
                )}

                {this.getRowValuesThreeStyles(
                  "Grand total (LKR)",
                  this.state.grandTotal
                    ? this.numberWithCommas(this.state.grandTotal)
                    : "0",
                  this.state.grandTotal
                    ? this.state.grandTotal.split(".").pop()
                    : "00"
                )}

                <div
                  className="row no-padding"
                  style={{ marginTop: "196px", left: 0, right: 0 }}
                >
                  <div className="col-12">
                    <button
                      className="btn-transaction remove-outline"
                      onClick={() => {
                        this.buttonOnPress();
                      }}
                    >
                      CONFIRM
                    </button>
                  </div>
                </div>
              </div>
            </header>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedStock: state.BuyStockReducer.selectedStock,
    sellStockPrice: state.BuyStockReducer.sellStockPrice,
    sellStockPriceDecimal: state.BuyStockReducer.sellStockPriceDecimal,
    sellNumberOfShares: state.BuyStockReducer.sellNumberOfShares,
    sellStockDuration: state.BuyStockReducer.sellStockDuration,
    commision: state.BuyStockReducer.sellCommission,
    marketStatus: state.BuyStockReducer.marketStatus,
    sellTif: state.BuyStockReducer.sellStockDuration.tif,
    orderEditStatus: state.BuyStockReducer.buyOrderEditStatus,
    orderEditItemPrice: state.BuyStockReducer.orderEditItemPrice,
    orderEditItemPriceDecimal: state.BuyStockReducer.orderEditItemPriceDecimal,
    orderEditItemQty: state.BuyStockReducer.orderEditItemQty,
    orderEditItem: state.BuyStockReducer.buyOrderEditItem,
    orderEditResponse: state.BuyStockReducer.sellOrderEditResponse,
    tradeStatus: state.BuyStockReducer.tradeStatus,
    buyOrderList: state.BuyStockReducer.orderListBuyStatus,
    marketOpeningHrs: state.DashboardReducer.marketOpeningHrs,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    showAlertDialog: (data) => dispatch(showAlertDialog(data)),
    getMarketStatus: () => dispatch(getMarketStatus()),
    hideAlertDialog: () => dispatch(hideAlertDialog()),
    sellStockConfirmation: () => dispatch(sellStockConfirmation()),
    editSellOrder: () => dispatch(editSellOrder()),
    setSellStockPrice: (data) => dispatch(setSellStockPrice(data)),
    SetSellNumberOfShares: (data) => dispatch(SetSellNumberOfShares(data)),
    setSelectedOrderItemQuantity: (data) =>
      dispatch(setSelectedOrderItemQuantity(data)),
    setSelectedOrderItemPrice: (data) =>
      dispatch(setSelectedOrderItemPrice(data)),
    setSellStockPriceDecimal: (data) =>
      dispatch(setSellStockPriceDecimal(data)),
    setSelectedOrderItemPriceDecimal: (data) =>
      dispatch(setSelectedOrderItemPriceDecimal(data)),
    setOderEditStatus: (data) => dispatch(setOderEditStatus(data)),
    setBottomSheetStatus: (data) => dispatch(setBottomSheetStatus(data)),
    setSelectedTab: (data) => dispatch(setSelectedTab(data)),
    getTradeStatus: (data) => dispatch(getTradeStatus(data)),
    getOrderListBuyStatus: (callback) =>
      dispatch(getOrderListBuyStatus(callback)),
    getMarketOpenTime: (payload) =>
      dispatch(dashboardActions.getMarketOpenTime(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SellStockConfirmation);
