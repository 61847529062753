const ErrorCodes = {
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
  SERVICE_UNAVAILABLE: "SERVICE_UNAVAILABLE",
  REQUEST_TIMEOUT: "REQUEST_TIMEOUT",

  OTP: {
    TX_OTP_BLOCKED: "TX_OTP_BLOCKED",
    TX_OTP_SEND: "TX_OTP_SEND",
    TX_SUCCESS: "TX_SUCCESS",
    TX_OTP_EXPIRED: "TX_OTP_EXPIRED",
    TX_INVALID_OTP: "TX_INVALID_OTP",
    OTP_TRIES_EXCEEDED: "OTP_TRIES_EXCEEDED",
  },

  EMAIL_VERIFICATION: {
    TX_SUCCESS: "TX_SUCCESS",
    EMAIL_NOT_VERIFIED: "EMAIL_NOT_VERIFIED",
  },
};

export default ErrorCodes;
