import * as types from "../Actions";

export const initailState = {
  accountAvailable: "no",
  brokerList: [],
  selectedBroker: "ARR",
  // selectedBroker: "ASI",
  token: null,
};

export default function (state = initailState, action) {
  let response = action.response;
  switch (action.type) {
    case types.CHECK_ACCOUNT_AVAILABILITY:
      return { ...state, accountAvailable: action.payload };
    case types.SET_SELECTED_BROKER:
      return { ...state, selectedBroker: action.payload };
    case types.GET_BROKER_LIST_SUCCESS:
      return { ...state, brokerList: response.data.DATA };
    case types.GET_BROKER_LIST_FAIL:
      return { ...state, brokerList: [] };
    default:
      return state;
  }
}
