import axios from "axios";
axios.defaults.timeout = 20000;

const BASE_URL = window._env_.REACT_APP_DEV_API_URL;

export const getCompanyListService = async (access_Token) => {
  const token = sessionStorage.getItem("token");

  if (token) {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/api/notification/stocks-kafka-service/watchdata`;
    let res = await axios
      .get(REGISTER_API_ENDPOINT, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      }); 

    return res;
  } else {
    return null;
  }
};

export const getSnpListService = async (access_Token) => {
  const token = sessionStorage.getItem("token");

  if (token) {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/api/ezs/stocks-management-service/snp`;
    let res = await axios
      .get(REGISTER_API_ENDPOINT, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } else {
    return null;
  }
};

export const getTopGainerLoosersService = async () => {
  const token = sessionStorage.getItem("token");
  if (token) {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/api/ezs/stocks-management-service/market_summary/getTopList`;
    let res = await axios
      .get(REGISTER_API_ENDPOINT, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } else {
    return null;
  }
};

export const getMarketOpenDaysService = async (access_Token) => {
  const token = sessionStorage.getItem("token");
  if (token) {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/api/ezs/stocks-management-service/marketopendays`;
    let res = await axios
      .get(REGISTER_API_ENDPOINT, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } else {
    return null;
  }
};

export const cdsAccountValidationService = async (selected_broker, token) => {
  // const token = sessionStorage.getItem('token');
  if (token) {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/api/ezs/stocks-management-service/validate/${selected_broker}`;
    let res = await axios
      .get(REGISTER_API_ENDPOINT, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } else {
    return null;
  }
};

export const kycInitiationService = async () => {
  const token = sessionStorage.getItem("token");
  if (token) {
    const REGISTER_API_ENDPOINT = `${BASE_URL}/api/auth/stocks-auth-service/commonKyc/initRequest`;
    let res = await axios
      .get(REGISTER_API_ENDPOINT, {
        headers: {
          Authorization: "Bearer " + token + " ",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } else {
    return null;
  }
};
