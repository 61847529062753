import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import Layout from "../../Layout/Layout";
import BodyContainer from "../../../Components/BodyArea/Body";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import {
  setSelectedMobileNumber,
  setOtpSendingCounter,
  setCircularLoadingFlag,
  setTimerValue,
  setButtonDisable,
  setSelectedWithdrawAmount,
  getOtpRequest,
  setWithdrawalValue,
} from "./../../../Redux/Actions/withdrawAction";
import * as Constants from "./../../../Utils/constants";
import * as CommonFunc from "./../../../Utils/commonFunctions";
import "./WithdrawMoneyConfimStyles.scss";
import { Divider, List, ListItem, ListItemText } from "@mui/material";

class WithdrawMoneyConfirm extends Component {
  state = { invalidOtpStatus: false };

  onLeftIconClick = () => {
    this.props.history.goBack();
    this.props.setSelectedWithdrawAmount("");
    this.props.setWithdrawalValue("");
  };

  buttonOnPress = () => {
    //Check otp status is not blocked, otherwise send to next page
    this.props.getOtpRequest(() => {
      this.otpResponseValidate();
    });
  };

  otpResponseValidate() {
    if (this.props.otpCounterStatus === "TX_OTP_BLOCKED") {
      this.setState({ invalidOtpStatus: true });
      this.props.setOtpSendingCounter(0);
    } else if (this.props.otpCounterStatus === "TX_SUCCESS") {
      this.setState({ invalidOtpStatus: false });
      const mobileNumber = CommonFunc.cleanMobileNumber(
        this.props.mobileNumber
      );
      const cleanedMobileNumber = CommonFunc.displayMobileNumber(mobileNumber);
      this.props.setSelectedMobileNumber(cleanedMobileNumber);
      this.props.setOtpSendingCounter(parseInt(this.props.otpCounter) + 1);
      this.props.setCircularLoadingFlag(true);
      this.props.setButtonDisable(true);
      this.props.setTimerValue(120);
      this.props.history.push("/withdraw-money-otp");
    } else {
    }
  }

  render() {
    return (
      <Fragment>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft={"Withdraw Money"}
          headerContentRight=""
          rightIcon=""
          leftIconLink="dashboard"
          handleLeftIconClick={() => {
            this.onLeftIconClick();
          }}
        >
          <BodyContainer>
            <header
              className="App-container"
              data-testid="withdraw-money-confirm-container"
            >
              <div style={{ width: "100%" }}>
                <div>
                  <div
                    style={{
                      width: "100%",
                      justifyItems: "center",
                      textAlign: "center",
                      marginTop: "10%",
                    }}
                  >
                    <h3 data-testid="amount-label" className="am-input-title">
                      Amount (LKR)
                    </h3>
                    <label
                      data-testid="input-label-container"
                      style={{
                        width: "100%",
                        padding: "15px 0 7px",
                        margin: "0",
                        boxSizing: "content-box",
                        fontSize: "2em",
                      }}
                    >
                      {this.props.withdrawalAmount ? (
                        <>
                          <span className="withdraw_amountText">
                            {this.props.withdrawalAmount.split(".")[0]}
                          </span>
                          .
                          <span className="withdraw_decimalText">
                            {this.props.withdrawalAmount.split(".")[1]}
                          </span>
                        </>
                      ) : (
                        "0"
                      )}
                    </label>
                  </div>
                </div>
                <div style={{ marginTop: "10%" }}>
                  <List style={{ width: "100%" }}>
                    <Divider
                      style={{
                        width: "90%",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      variant="inset"
                      component="li"
                    />
                    <ListItem
                      style={{
                        width: "95%",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <ListItemText
                        data-testid="deposit-label"
                        className="am-input-title"
                        style={{ width: "45%", color: "#333333" }}
                        primary="Deposit to"
                      />
                      <ListItemText
                        className="am-input-title"
                        style={{ width: "10%", color: "#333333" }}
                        primary=":"
                      />
                      <ListItemText
                        data-testid="bank-value-label"
                        className="am-input-title"
                        style={{
                          width: "45%",
                          textAlign: "right",
                          color: "#333333",
                        }}
                        primary={this.props.bankName}
                      />
                    </ListItem>
                    <Divider
                      style={{
                        width: "90%",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      variant="inset"
                      component="li"
                    />
                    <ListItem
                      style={{
                        width: "95%",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <ListItemText
                        data-testid="accountNum-label"
                        className="am-input-title"
                        style={{ width: "45%", color: "#333333" }}
                        primary="Account number"
                      />
                      <ListItemText
                        className="am-input-title"
                        style={{ width: "10%", color: "#333333" }}
                        primary=":"
                      />
                      <ListItemText
                        data-testid="account-value-label"
                        className="am-input-title"
                        style={{
                          width: "45%",
                          textAlign: "right",
                          color: "#333333",
                        }}
                        primary={this.props.accountNumber}
                      />
                    </ListItem>
                  </List>
                </div>
                <div>
                  {this.state.invalidOtpStatus ? (
                    <h6
                      style={{
                        color: "red",
                        marginLeft: "10%",
                        textAlign: "left",
                        marginRight: "10%",
                      }}
                    >
                      {Constants.MAXIMUM_OTP_ATTEMPT_COUNT_ERROR}
                    </h6>
                  ) : null}
                </div>
                <div
                  className="row no-padding"
                  style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
                >
                  <div className="col-12">
                    <a style={{ color: "white" }}>
                      <button
                        data-testid="button-container"
                        style={{
                          margin: 0,
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginBottom: "5%",
                        }}
                        className="btn-transaction remove-outline"
                        onClick={() => {
                          this.buttonOnPress();
                        }}
                      >
                        CONFIRM
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </header>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    withdrawalAmount: state.WithdrawReducer.withdrawalAmount,
    bankName: state.WithdrawReducer.bankName,
    accountNumber: state.WithdrawReducer.accountNumber,
    otpCounter: state.WithdrawReducer.otpCounter,
    otpCounterStatus: state.WithdrawReducer.otpCounterStatus,
    mobileNumber: state.WithdrawReducer.bankDetails.mobileNumber,
    otpRequestDetails: state.WithdrawReducer.otpRequestDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedMobileNumber: (data) => dispatch(setSelectedMobileNumber(data)),
    setOtpSendingCounter: (data) => dispatch(setOtpSendingCounter(data)),
    setCircularLoadingFlag: (data) => dispatch(setCircularLoadingFlag(data)),
    setTimerValue: (data) => dispatch(setTimerValue(data)),
    setButtonDisable: (data) => dispatch(setButtonDisable(data)),
    setSelectedWithdrawAmount: (data) =>
      dispatch(setSelectedWithdrawAmount(data)),
    getOtpRequest: (callback) => dispatch(getOtpRequest(callback)),
    setWithdrawalValue: (data) => dispatch(setWithdrawalValue(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithdrawMoneyConfirm);
