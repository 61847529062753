import { combineReducers } from "redux";

import UserValidationReducer from "./userValidationReducer";
import DashboardReducer from "./dashboardReducer";
import BuyStockReducer from "./stockReducer";
import CommonReducer from "./commonReducer";
import WithdrawReducer from "./withdrawReducer";
import AddMoneyReducer from "./addMoneyReducer";
import ComponentReducer from "./ComponentReducer";
import KYCreducer from "./KYCreducer";

const rootReducer = combineReducers({
  UserValidationReducer,
  DashboardReducer,
  BuyStockReducer,
  CommonReducer,
  WithdrawReducer,
  AddMoneyReducer,
  ComponentReducer,
  KYCreducer,
});
export default rootReducer;
