import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Divider,
  DialogContent,
} from "@mui/material";
import "./EditOrderDropdownStyles.scss";
import { Images } from "../../../../Theme/Images";
import { showAlertDialog } from "../../../../Redux/Actions/commonAction";
import { Link } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditOrderDropdown = (props) => {
  const selectedItem = useSelector(
    (state) => state.BuyStockReducer.buyOrderEditItem
  );
  const dispatch = useDispatch();

  const classes = useStyles();
  const { toggle, open, navigateFrom } = props;

  let alertObj = {
    visibility: true,
    dialogMessage: `Are you sure you want to cancel the ${
      selectedItem ? selectedItem.securitycode : null
    }(${navigateFrom}) order?`,
    dialogTitle: `Cancel ${
      selectedItem ? selectedItem.securitycode : null
    }(${navigateFrom}) Order`,
    dialogPrimaryButton: "YES",
    dialogSecondaryButton: "NO",
    dialogIcon: Images.buyStocks.ic_danger,
  };

  const showAlert = useCallback(() => {
    toggle();
    dispatch(showAlertDialog(alertObj));
  }, [dispatch, toggle]);

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={toggle}
        TransitionComponent={Transition}
        classes={{ paper: classes.dialogPaper }}
      >
        <div className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              edge="start"
              color="default"
              onClick={toggle}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {"Edit Order"}
            </Typography>
          </Toolbar>
        </div>
        <DialogContent className={classes.content}>
          <Link to={navigateFrom === "Sell" ? "sell-stock" : "buy-stock"}>
            <div className="card dropdown-card">
              <div className="row no-padding">
                <div className="col-3 no-padding">
                  <img
                    src={Images.orderList.ic_edit_order}
                    className="App-list-icon"
                    alt="logo"
                  />
                </div>
                <div className="col-7 no-padding">
                  <div className="row no-padding">
                    <h2 className="dropdown-heading-text">Edit order</h2>
                  </div>
                  <div className="row no-padding">
                    <h4 className="dropdown-status-text">
                      Edit share price & quantity
                    </h4>
                  </div>
                </div>
                <div className="col-2 text-right">
                  <img
                    src={Images.dashboard.ic_arrow_right}
                    className="arrow-icon"
                    alt="logo"
                  />
                </div>
              </div>
            </div>
          </Link>
          <Divider className={classes.divider} />
          <div className="card dropdown-card" onClick={() => showAlert()}>
            <div className="row no-padding">
              <div className="col-3 no-padding">
                <img
                  src={Images.orderList.ic_cancel_order}
                  className="App-list-icon"
                  alt="logo"
                />
              </div>
              <div className="col-7 no-padding">
                <div className="row no-padding">
                  <h2 className="dropdown-heading-text">Cancel order</h2>
                </div>
                <div className="row no-padding">
                  <h4 className="dropdown-status-text">Cancel your order</h4>
                </div>
              </div>
              <div className="col-2 text-right">
                <img
                  src={Images.dashboard.ic_arrow_right}
                  className="arrow-icon"
                  alt="logo"
                />
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#ffffff !important",
    color: "#333333 !important",
  },
  title: {
    marginLeft: `theme.spacing(2)`,
    width: "90% !important",
    color: "#000000 !important",
    fontSize: "1.3em !important",
  },
  dialogPaper: {
    maxHeight: "30vh !important",
    position: "fixed !important",
    bottom: "0px !important",
    borderTopLeftRadius: "16px !important",
    borderTopRightRadius: "16px !important",
  },
  content: {},
  divider: {
    margin: "0 15px",
  },
}));

export default EditOrderDropdown;
