import React from "react";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { hideErrorPopup } from "../../Redux/Actions/commonAction";
import { Images } from "../../Theme/Images";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Slide,
  Divider,
  DialogActions,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialogContent-root": {
      padding: "0px !important",
    },
  },
  title: {
    fontSize: "0.99em !important",
    fontWeight: "bold !important",
    color: "black !important",
    fontFamily: "Open Sans !important",
    textAlign: "center !important",
    paddingTop: "8px !important",
    marginBottom: "0px !important",
  },
  primaryButton: {
    fontFamily: "Open Sans !important",
    fontSize: "0.874em !important",
    fontWeight: "bold !important",
    color: "#f15a22 !important",
    marginBottom: "0px !important",
  },
  centerView: {
    alignSelf: "center !important",
    textAlign: "center !important",
  },
  primaryBtnCenterView: {
    alignSelf: "center !important",
    textAlign: "center !important",
    paddingTop: "16px !important",
    paddingBottom: "30px !important",
  },
  iconCenterView: {
    alignSelf: "center !important",
    textAlign: "center !important",
    padding: "30px 0px 0px 0px !important",
  },
  dialogPaper: {
    borderRadius: "16px !important",
    width: "328px !important",
  },
  dialogMessage: {
    fontFamily: "Open Sans !important",
    fontSize: "0.874em !important",
    textAlign: "center !important",
    color: "black !important",
  },
  icon: {
    height: "32px !important",
    pointerEvents: "none !important",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function ErrorPopUp(props) {
  const classes = useStyles();

  const handleClose = () => {
    props.hideErrorPopup();
  };

  const primaryButtonAction = () => {
    props.hideErrorPopup();
  };

  return (
    <div>
      <Dialog
        open={props.showErrorPopup}
        TransitionComponent={Transition}
        keepMounted
        classes={{ paper: classes.dialogPaper }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.iconCenterView}>
          <img
            src={Images.errImages.ic_error}
            className={classes.icon}
            alt="logo"
          />
        </DialogContent>
        <DialogContent>
          <DialogContentText className={classes.title}>
            Failed
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className={classes.dialogMessage}
          >
            {"Sorry! We faced an issue."}
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-slide-description"
            className={classes.dialogMessage}
          >
            {"Please try again in a few minutes!"}
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions
          className={classes.primaryBtnCenterView}
          onClick={primaryButtonAction}
        >
          <DialogContentText className={classes.primaryButton}>
            OK
          </DialogContentText>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    showErrorPopup: state.CommonReducer.errorPopUp,
    dialogTitle: state.CommonReducer.dialogTitle,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    hideErrorPopup: () => dispatch(hideErrorPopup()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ErrorPopUp);
