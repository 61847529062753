import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import PullToRefresh from "react-simple-pull-to-refresh";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import {
  ButtonOutlined,
  ButtonWithIcon,
} from "../../../Components/Buttons/index";
import Layout from "../../Layout/Layout";
import "./BuyStocksListStyles.scss";
import BodyContainer from "../../../Components/BodyArea/Body";
import { Images } from "../../../Theme/Images";
import { setSelectedStock } from "../../../Redux/Actions/buyStockListingAction";
import {
  getMarketDepth,
  getSecurityStat,
  getSellStatus,
  setOderEditStatus,
  setSelectedSecurityForSell,
} from "../../../Redux/Actions/stockAction";
import { Divider, Typography } from "@mui/material";

class BuysStocks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: false,
      searchData: this.props.companyList
        ? this.props.companyList.slice(0, 20)
        : [],
      showHeader: true,
    };
  }

  componentDidMount() {
    this.props.setOderEditStatus(false);
  }

  onViewPressed(item) {
    if (item.securityOld) {
      item.security = item.securityOld;
      item.companyName = item.companyOld;
    }
    this.props.setSelectedStock(item);
    this.props.setSelectedSecurityForSell(item.security);
    this.props.getSecurityStat();
    this.props.getMarketDepth();
    this.props.getSellStatus();
    setTimeout(() => {
      this.props.history.push("stock-details");
    }, 100);
  }

  onBuyPressed(item) {
    if (item.securityOld) {
      item.security = item.securityOld;
      item.companyName = item.companyOld;
    }
    this.props.setSelectedStock(item);
    setTimeout(() => {
      this.props.history.push("buy-stock");
    }, 100);
    //Action
  }

  handleSearch(e) {
    let str = e.target.value;
    const newArr = this.props.companyList
      .filter(
        (item) =>
          item.security.toLowerCase().includes(str.toLowerCase()) ||
          item.companyName.toLowerCase().includes(str.toLowerCase())
      )
      .map((item) => {
        let newTitle = item.security.replace(
          new RegExp(str, "gi"),
          (match) => `<span style="color: #2598ed;">${match}</span>`
        );
        let newBody = "";
        let setPriority = false;
        if (item.security.toLowerCase().includes(str.toLowerCase())) {
          newBody = item.companyName;
          setPriority = true;
        } else {
          newBody = item.companyName.replace(
            new RegExp(str, "gi"),
            (match) => `<span style="color: #2598ed;">${match}</span>`
          );
        }
        return {
          ...item,
          priority: setPriority,
          securityOld: item.security,
          companyOld: item.companyName,
          security: newTitle,
          companyname: newBody,
        };
      });
    this.setState({
      searchData: newArr.sort(function (a, b) {
        return b.priority - a.priority;
      }),
    });
    if (str == "") {
      this.setState({
        searchData: [],
        search: false,
      });
    } else {
      this.setState({
        search: true,
      });
    }
  }
  createMarkup(html) {
    return { __html: html };
  }

  handleSearchOnClick(data) {
    this.setState(
      {
        showHeader: !this.state.showHeader,
      },
      () => {
        if (!this.state.showHeader) {
          this.setState({
            searchData: [],
          });
        }
      }
    );
  }
  handleClocseClick(data) {
    this.setState(
      {
        showHeader: data,
      },
      () => {
        if (this.state.showHeader) {
          this.setState({
            searchData: this.props.companyList.slice(0, 20),
          });
        }
      }
    );
  }

  handleSearchRightIcon() {
    this.setState({
      searchData: [],
      search: false,
    });
  }

  onLeftIconClick = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Fragment>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft="Buy stocks"
          headerContentRight=""
          rightIcon={Images.dashboard.ic_search}
          rightIconLink="/"
          leftIconLink="dashboard"
          handleRightIconClick={(data) => this.handleSearchOnClick(data)}
          handleClocseIconClick={(data) => this.handleClocseClick(data)}
          handleSearchRightIconClick={() => this.handleSearchRightIcon()}
          onInput={(e) => this.handleSearch(e)}
          handleLeftIconClick={() => this.onLeftIconClick()}
          showHeader={this.state.showHeader}
        >
          <BodyContainer>
            <PullToRefresh refreshingContent=" " pullingContent=" ">
              <header className="App-container">
                <div style={{ width: "100%" }}>
                  {this.state.searchData.length === 0 && this.state.search ? (
                    <h3 style={{ textAlign: "center", marginTop: "50%" }}>
                      No records found
                    </h3>
                  ) : (
                    <div>
                      {this.state.searchData[0] ? (
                        this.state.searchData.map((item, index) => {
                          return (
                            <div key={index}>
                              <div className="row d-flex align-items-center">
                                <div
                                  className="bs-col1 descriptionTxt"
                                  onClick={() => {
                                    this.onViewPressed(item);
                                  }}
                                >
                                  <Typography
                                    className="company-code"
                                    gutterBottom
                                    dangerouslySetInnerHTML={this.createMarkup(
                                      item.security
                                    )}
                                  />
                                  <Typography
                                    className="company-name"
                                    dangerouslySetInnerHTML={this.createMarkup(
                                      item.companyName
                                    )}
                                  />
                                </div>
                                <div className="bs-col2">
                                  <ButtonOutlined
                                    title="View"
                                    onPress={() => {
                                      this.onViewPressed(item);
                                    }}
                                  />
                                </div>
                                <div className="bs-col3">
                                  <ButtonWithIcon
                                    title="Buy"
                                    icon={Images.buyStocks.ic_plus_new}
                                    onPress={() => {
                                      this.onBuyPressed(item);
                                    }}
                                  />
                                </div>
                              </div>
                              <Divider variant="middle" />
                            </div>
                          );
                        })
                      ) : (
                        <h3 style={{ textAlign: "center", marginTop: "50%" }}>
                          No records found
                        </h3>
                      )}
                    </div>
                  )}
                </div>
              </header>
            </PullToRefresh>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAccountvAvailability: state.UserValidationReducer.accountAvailable,
    companyList: state.CommonReducer.companyList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedStock: (data) => dispatch(setSelectedStock(data)),
    getMarketDepth: (data) => dispatch(getMarketDepth(data)),
    getSecurityStat: (data) => dispatch(getSecurityStat(data)),
    getSellStatus: () => dispatch(getSellStatus()),
    setOderEditStatus: (data) => dispatch(setOderEditStatus(data)),
    setSelectedSecurityForSell: (data) =>
      dispatch(setSelectedSecurityForSell(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuysStocks);
