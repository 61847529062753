import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import PullToRefresh from "react-simple-pull-to-refresh";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import Layout from "../../Layout/Layout";
import "./TopGainersStyles.scss";
import BodyContainer from "../../../Components/BodyArea/Body";
import { Images } from "../../../Theme/Images";
import { setSelectedStock } from "../../../Redux/Actions/buyStockListingAction";
import {
  getMarketDepth,
  getSecurityStat,
  getSellStatus,
} from "../../../Redux/Actions/stockAction";
import { Divider, Typography } from "@mui/material";
class TopGainers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: false,
      searchData: this.props.topGainers ? this.props.topGainers : [],
      showHeader: true,
    };
  }

  onViewPressed(item) {
    if (item.securityOld) {
      item.security = item.securityOld;
      item.companyName = item.companyOld;
    }
    this.props.setSelectedStock(item);
    this.props.getSecurityStat();
    this.props.getMarketDepth();
    this.props.getSellStatus();
    setTimeout(() => {
      this.props.history.push("stock-details");
    }, 100);
  }

  numberWithCommas = (x) => {
    let srt = x.substr(0, x.indexOf("."));
    const value = srt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  };

  handleSearch(e) {
    let str = e.target.value;
    const newArr = this.props.companyList
      .filter(
        (item) =>
          item.security.toLowerCase().includes(str.toLowerCase()) ||
          item.companyName.toLowerCase().includes(str.toLowerCase())
      )
      .map((item) => {
        let newTitle = item.security.replace(
          new RegExp(str, "gi"),
          (match) => `<span style="color: #2598ed;">${match}</span>`
        );
        let newBody = "";
        if (item.security.toLowerCase().includes(str.toLowerCase())) {
          newBody = item.companyName;
        } else {
          newBody = item.companyName.replace(
            new RegExp(str, "gi"),
            (match) => `<span style="color: #2598ed;">${match}</span>`
          );
        }
        return {
          ...item,
          securityOld: item.security,
          companyOld: item.companyName,
          security: newTitle,
          companyName: newBody,
        };
      });
    this.setState({
      searchData: newArr
        ? newArr.sort(function (a, b) {
            return b.netchange - a.netchange;
          })
        : [],
    });
    if (str == "") {
      this.setState({
        searchData: [],
        search: false,
      });
    } else {
      this.setState({
        search: true,
      });
    }
  }
  createMarkup(html) {
    return { __html: html };
  }

  handleSearchOnClick(data) {
    this.setState(
      {
        showHeader: !this.state.showHeader,
      },
      () => {
        if (!this.state.showHeader) {
          this.setState({
            searchData: [],
          });
        }
      }
    );
  }
  handleClocseClick(data) {
    this.setState(
      {
        showHeader: data,
      },
      () => {
        if (this.state.showHeader) {
          this.setState({
            searchData: this.props.topGainers,
          });
        }
      }
    );
  }

  handleSearchRightIcon() {
    this.setState({
      searchData: [],
      search: false,
    });
  }

  onLeftIconClick = () => {
    this.props.history.push("market-summary");
  };

  render() {
    return (
      <Fragment>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft="Top Gainers"
          headerContentRight=""
          rightIcon={Images.dashboard.ic_search}
          rightIconLink="/"
          leftIconLink="market-summary"
          handleRightIconClick={(data) => this.handleSearchOnClick(data)}
          handleClocseIconClick={(data) => this.handleClocseClick(data)}
          handleSearchRightIconClick={() => this.handleSearchRightIcon()}
          onInput={(e) => this.handleSearch(e)}
          handleLeftIconClick={() => this.onLeftIconClick()}
          showHeader={this.state.showHeader}
        >
          <BodyContainer>
            <PullToRefresh
              onRefresh={() => window.location.reload()}
              refreshingContent=" "
              pullingContent=" "
            >
              <header className="App-container">
                <div style={{ width: "100%" }}>
                  {this.state.searchData.length === 0 && this.state.search ? (
                    <h3 style={{ textAlign: "center", marginTop: "50%" }}>
                      No records found
                    </h3>
                  ) : (
                    <div>
                      {this.state.searchData.map((item, index) => {
                        return (
                          <div key={index}>
                            <div
                              className="row"
                              onClick={() => this.onViewPressed(item)}
                            >
                              <div className="col-8">
                                <Typography
                                  className="tl-company-code"
                                  gutterBottom
                                  dangerouslySetInnerHTML={this.createMarkup(
                                    item.security
                                  )}
                                />
                                <Typography
                                  className="tl-company-name"
                                  dangerouslySetInnerHTML={this.createMarkup(
                                    item.companyName
                                  )}
                                />
                              </div>
                              {item.tradePrice ? (
                                <div
                                  className="row no-padding"
                                  style={{
                                    width: "30%",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{ width: "60%", textAlign: "right" }}
                                  >
                                    <span className="tg-lkr-text">LKR</span>
                                  </div>
                                  <div style={{ textAlign: "right" }}>
                                    <span className="tg-price-text">
                                      {item.tradePrice &&
                                        this.numberWithCommas(item.tradePrice)}
                                    </span>
                                    <span className="tg-decimal-text">
                                      .
                                      {item.tradePrice &&
                                        item.tradePrice.split(".").pop()}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <span className="lkr-text">
                                    Not available
                                  </span>
                                </div>
                              )}
                            </div>
                            <Divider variant="middle" />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </header>
            </PullToRefresh>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

const dataObj = {
  ASPI: "12333",
  ASPIDecimal: ".00",
};

const mapStateToProps = (state) => {
  return {
    topGainers: state.CommonReducer.topGainers,
    companyList: state.CommonReducer.companyList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedStock: (data) => dispatch(setSelectedStock(data)),
    getMarketDepth: (data) => dispatch(getMarketDepth(data)),
    getSecurityStat: (data) => dispatch(getSecurityStat(data)),
    getSellStatus: () => dispatch(getSellStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopGainers);
