import React from "react";
import { withStyles } from "@mui/styles";
import { TextField } from "@mui/material";

const ValidationTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#2598ed",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2598ed",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#2598ed",
      },
      "&:hover fieldset": {
        borderColor: "#2598ed",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2598ed",
      },
    },
  },
})(TextField);

const TextInputOutlined = (props) => {
  const { dataTestId, label, maxlength, autoFocus, onChange, value } = props;
  const [inputValue, setValue] = React.useState("");

  const handleOnChange = (event) => {
    onChange(event.target.value);
    setValue(event.target.value);
  };

  const MAX_LENGTH = parseInt(maxlength);
  return (
    <ValidationTextField
      data-testid={dataTestId}
      label={label}
      autoFocus={autoFocus}
      onChange={handleOnChange}
      value={inputValue ? inputValue : value}
      inputMode="numeric"
      variant="outlined"
      inputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
        maxLength: MAX_LENGTH,
      }}
      fullWidth
    />
  );
};

export default TextInputOutlined;
