import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, Toolbar, IconButton, Typography, Slide } from "@mui/material";
import { startBrowserBack, stopBrowserBack } from "../../Utils/helper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function InfoComponent(props) {
  const { dropdownTitle, bottomSheetStatus, handleOnClose, dropdownText } =
    props;
  const classes = useStyles();

  const handleClose = () => {
    handleOnClose();
    startBrowserBack();
  };

  useEffect(() => {
    if (bottomSheetStatus) {
      stopBrowserBack(handleClose);
    }
  }, [bottomSheetStatus]);

  return (
    <div>
      <Dialog
        fullScreen={false}
        open={bottomSheetStatus}
        onClose={handleClose}
        TransitionComponent={Transition}
        classes={{ paper: classes.dialogPaper }}
      >
        <div className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton edge="start" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {dropdownTitle}
            </Typography>
          </Toolbar>
        </div>
        <Typography variant="h6" className={classes.text}>
          {dropdownText}
        </Typography>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#ffffff !important",
    color: "#333333 !important",
  },
  subTitle: {
    marginLeft: theme.spacing(8.5),
    width: "100% !important",
    color: "#767676 !important",
    fontSize: "1em !important",
  },
  title: {
    marginLeft: `theme.spacing(2)`,
    width: "90% !important",
    color: "#000000 !important",
    fontSize: "1.3em !important",
  },
  text: {
    marginLeft: `${theme.spacing(2)} !important`,
    width: "90% !important",
    color: "#333333 !important",
    fontSize: "1em !important",
  },
  dialogPaper: {
    // maxHeight: "30em !important",
    position: "fixed !important",
    bottom: "0px !important",
    borderTopLeftRadius: "16px !important",
    borderTopRightRadius: "16px !important",
    margin: "0 !important",
    paddingBottom: "2em",
    borderBottomLeftRadius: "0 !important",
    borderBottomRightRadius: "0 !important",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "90%",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000000",
    },
    "& .MuiOutlinedInput-root.Mui-focused .focusedLabel": {
      color: "#000000",
    },
    "& label.Mui-focused": {
      color: "#000000",
    },
  },
  radio: {
    "&$checked": {
      color: "red",
    },
  },
  checked: {},
}));
