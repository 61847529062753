import * as Types from "./index";

export const getMarketDepth = () => {
  return {
    type: Types.GET_MARKET_DEPTH,
  };
};

export const getSecurityStat = () => {
  return {
    type: Types.GET_SECURITY_STAT,
  };
};

export const setSellStockPrice = (payload) => {
  return {
    type: Types.SET_SELL_STOCK_PRICE,
    payload,
  };
};

export const SetSellNumberOfShares = (payload) => {
  return {
    type: Types.SET_SELL_NUMBER_OF_SHARES,
    payload,
  };
};

export const setSellStockDuration = (payload) => {
  return {
    type: Types.SET_SELL_STOCK_DURATION,
    payload,
  };
};

export const getSellStatus = () => {
  return {
    type: Types.GET_SELL_STATUS,
  };
};

export const sellStockConfirmation = () => {
  return {
    type: Types.SELL_STOCK_CONFIRMATION,
  };
};

export const buyStockConfirmation = () => {
  return {
    type: Types.BUY_STOCK_CONFIRMATION,
  };
};

export const setBuyStockPrice = (payload) => {
  return {
    type: Types.SET_BUY_STOCK_PRICE,
    payload,
  };
};

export const setBuyStockPriceDecimal = (payload) => {
  return {
    type: Types.SET_BUY_STOCK_PRICE_DECIMAL,
    payload,
  };
};

export const setSellStockPriceDecimal = (payload) => {
  return {
    type: Types.SET_SELL_STOCK_PRICE_DECIMAL,
    payload,
  };
};

export const setBuyNumberOfShares = (payload) => {
  return {
    type: Types.SET_BUY_NUMBER_OF_SHARES,
    payload,
  };
};

export const setBuyStockDuration = (payload) => {
  return {
    type: Types.SET_BUY_STOCK_DURATION,
    payload,
  };
};

export const getMarketStatus = () => {
  return {
    type: Types.GET_MARKET_STATUS,
  };
};

export const getBuyCommission = (callback) => {
  return {
    type: Types.GET_BUY_COMMISSION,
    callback,
  };
};

export const getSellCommission = (callback) => {
  return {
    type: Types.GET_SELL_COMMISSION,
    callback,
  };
};

export const getOrderListBuyStatus = (callback) => {
  return {
    type: Types.GET_ORDER_LIST_BUY_STATUS,
    callback,
  };
};

export const setOderEditStatus = (payload) => {
  return {
    type: Types.SET_ORDER_EDIT_STATUS,
    payload,
  };
};

export const setOderEditItem = (payload) => {
  return {
    type: Types.SET_ORDER_EDIT_ITEM,
    payload,
  };
};

export const setSelectedOrderItemPrice = (payload) => {
  return {
    type: Types.SET_SELECTED_OREDR_ITEM_PRICE,
    payload,
  };
};

export const setSelectedOrderItemPriceDecimal = (payload) => {
  return {
    type: Types.SET_SELECTED_ORDER_ITEM_PRICE_DECIMAL,
    payload,
  };
};

export const setSelectedOrderItemQuantity = (payload) => {
  return {
    type: Types.SET_SELECTED_OREDR_ITEM_QUANTITY,
    payload,
  };
};

export const setSelectedOrderItemDuration = (payload) => {
  return {
    type: Types.SET_SELECTED_OREDR_ITEM_DURATION,
    payload,
  };
};

export const cancelQueuedOrder = (callback) => {
  return {
    type: Types.CANCEL_QUEUED_ORDER,
    callback,
  };
};

export const editBuyOrder = () => {
  return {
    type: Types.EDIT_BUY_ORDER,
  };
};

export const editSellOrder = () => {
  return {
    type: Types.EDIT_SELL_ORDER,
  };
};

export const setBottomSheetStatus = (payload) => {
  return {
    type: Types.SET_BOTTOM_SHEET_STATUS,
    payload,
  };
};

export const setSelectedTab = (payload) => {
  return {
    type: Types.SET_SELECTED_TAB,
    payload,
  };
};

export const getMarketSummary = (payload, callback) => {
  return {
    type: Types.GET_MARKET_SUMMARY_DETAILS,
    payload,
    callback,
  };
};

export const getTradeStatus = (payload) => {
  return {
    type: Types.GET_SECURITY_TRADE_STATUS,
    payload,
  };
};

export const setSelectedSecurityForSell = (payload) => {
  return {
    type: Types.SET_SELECTED_SECURITY_FOR_SELL,
    payload,
  };
};


export const triggerEditSellShareAmount = (payload) => {
  return {
    type: Types.TRIGGER_EDIT_SELL_SHARE_AMOUNT,
    payload,
  };
};

export const saveEditSellShareAmount = (payload) => {
  return {
    type: Types.SAVE_EDIT_SELL_SHARE_AMOUNT,
    payload,
  };
};