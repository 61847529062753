import * as Types from "./index";

export const getCompanyList = () => {
  return {
    type: Types.GET_COMPANY_LIST,
  };
};

export const getSnpList = (callback) => {
  return {
    type: Types.GET_SNP_LIST,
    callback,
  };
};

export const showFullLoader = (payload) => {
  return {
    type: Types.FULL_SCREEN_LOADER,
    payload,
  };
};

export const getTopdata = (callback) => {
  return {
    type: Types.GET_TOP_DATA,
    callback,
  };
};

export const showAlertDialog = (payload) => {
  return {
    type: Types.SHOW_ALERT_DIALOG,
    payload,
  };
};

export const hideAlertDialog = () => {
  return {
    type: Types.HIDE_ALERT_DIALOG,
  };
};

export const getSellStockList = (callback) => {
  return {
    type: Types.GET_SELL_STOCK_LIST,
    callback,
  };
};

export const showErrorPopup = () => {
  return {
    type: Types.SHOW_ERROR_POPUP,
  };
};

export const hideErrorPopup = () => {
  return {
    type: Types.HIDE_ERROR_POPUP,
  };
};

export const showAlertPopup = () => {
  return {
    type: Types.SHOW_ALERT_POPUP,
  };
};

export const hideAlertPopup = () => {
  return {
    type: Types.HIDE_ALERT_POPUP,
  };
};

export const gainersFail = (payload) => {
  return {
    type: Types.TOP_GAINERS_FAIL,
    payload,
  };
};

export const securityStatFail = (payload) => {
  return {
    type: Types.SECURITY_STAT_FAIL,
    payload,
  };
};

export const marketDepthFail = (payload) => {
  return {
    type: Types.MARKET_DEPTH_FAIL,
    payload,
  };
};

export const getMarketOpenDays = (callback) => {
  return {
    type: Types.GET_MARKET_OPEN_DAYS,
    callback,
  };
};

export const cdsAccountValidation = (payload, callback) => {
  return {
    type: Types.CDS_ACCOUNT_VALIDATION,
    payload,
    callback,
  };
};

export const commonKYCInitiation = (callback) => {
  return {
    type: Types.COMMON_KYC_INITIATION,
    callback,
  };
};

export const getCustomerStatus = (payload) => {
  return {
    type: Types.GET_CUSTOMER_STATUS,
    payload,
  };
};

export const storeCustomerStatus = (payload) => {
  return {
    type: Types.STORE_CUSTOMER_STATUS,
    payload,
  };
};
