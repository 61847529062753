import React from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import COLORS from "../../Assets/Colors";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButton-startIcon": {
      marginRight: "0px",
    },
  },
  button: {
    borderRadius: 50,
    fontSize: 15,
    backgroundColor: "white",
    color: `${COLORS.GENIE_ORANGE} !important`,
    width: 95,
    fontWeight: `600 !important`,
    textTransform: "none",
    fontFamily: "Open Sans !important",
    "& .MuiButton-startIcon": {
      marginRight: 15,
    },
  },
}));

export default function ButtonLogout(props) {
  const classes = useStyles();
  const { onPress, title, icon, buttonWidth } = props;

  return (
    <div>
      <Button
        className={classes.button}
        style={{ width: buttonWidth }}
        startIcon={<img src={icon} alt="" />}
        onClick={onPress}
      >
        {title}
      </Button>
    </div>
  );
}
