import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import Layout from "../../Layout/Layout";
import "./UserVerificationStyles.scss";
import BodyContainer from "../../../Components/BodyArea/Body";
import { Images } from "../../../Theme/Images";
import * as kycActions from "../../../Redux/Actions/KycActions";
import ErrorCodes from "../../../Assets/ErrorCodes";

class UserVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAccountButtonStatus: true,
      token: "",
      sesToken: "",
      resendButtonClicked: false,
    };
  }

  buttonOnPress = () => {
    const { emailVerificationStateForFreshUser, triggerResendEmail } =
      this.props;
    if (!this.state.resendButtonClicked) {
      if (
        emailVerificationStateForFreshUser &&
        emailVerificationStateForFreshUser ===
          ErrorCodes.EMAIL_VERIFICATION.EMAIL_NOT_VERIFIED
      ) {
        this.setState({ resendButtonClicked: true });
        triggerResendEmail();
      } else {
        window.ReactNativeWebView.postMessage("Exit");
      }
    } else return;
  };

  componentDidMount() {
    setTimeout(() => {
      this.props.checkFreshUserEmailVerificationState();
    }, 500);
  }

  render() {
    const { emailVerificationStateForFreshUser } = this.props;
    const { resendButtonClicked } = this.state;
    return (
      <Fragment>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft={
            emailVerificationStateForFreshUser &&
            emailVerificationStateForFreshUser ===
              ErrorCodes.EMAIL_VERIFICATION.EMAIL_NOT_VERIFIED
              ? `Please verify your email`
              : `We’re verifying`
          }
          headerContentRight=""
          rightIcon=""
          rightIconLink="/"
          leftIconLink="exitWebView"
        >
          <BodyContainer>
            <header className="App-container" data-testid="app-container">
              <div style={{ width: "100%" }}>
                <div className="uv-messageTemplate">
                  <span>
                    {emailVerificationStateForFreshUser &&
                    emailVerificationStateForFreshUser ===
                      ErrorCodes.EMAIL_VERIFICATION.EMAIL_NOT_VERIFIED
                      ? `Once verified your online trading account will be created in due course`
                      : `Hold on! We're verifying your details. Once verified your online trading account will be created in due course`}
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10%",
                  }}
                >
                  <img
                    src={Images.common.img_user_verifing}
                    alt="logo"
                    className="icon"
                  />
                </div>
    
                <div
                  className="row no-padding"
                  style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
                >
                  <div className="col-12">
                    <div className="bottomInfoTxt">
                      {emailVerificationStateForFreshUser &&
                      emailVerificationStateForFreshUser ===
                        ErrorCodes.EMAIL_VERIFICATION.EMAIL_NOT_VERIFIED
                        ? "Didn't receive email for verification yet ?"
                        : null}
                    </div>
                    <a style={{ color: "white" }}>
                      <button
                        className={
                          emailVerificationStateForFreshUser &&
                          emailVerificationStateForFreshUser ===
                            ErrorCodes.EMAIL_VERIFICATION.EMAIL_NOT_VERIFIED &&
                          resendButtonClicked
                            ? "btn-transaction-disabled"
                            : "btn-transaction remove-outline"
                        }
                        onClick={() => {
                          this.buttonOnPress();
                        }}
                      >
                        {emailVerificationStateForFreshUser &&
                        emailVerificationStateForFreshUser ===
                          ErrorCodes.EMAIL_VERIFICATION.EMAIL_NOT_VERIFIED
                          ? "RESEND"
                          : "OK"}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </header>
          </BodyContainer>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    emailVerificationStateForFreshUser:
      state.KYCreducer.emailVerificationStateForFreshUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkFreshUserEmailVerificationState: () =>
      dispatch(kycActions.checkFreshUserEmailVerificationState()),
    triggerResendEmail: () => dispatch(kycActions.triggerResendEmail()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserVerification);
