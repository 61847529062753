import React from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButton-startIcon": {
      marginRight: "0px",
    },
  },
  button: {
    borderRadius: "50px !important",
    backgroundColor: "#2598ed !important",
    color: "white !important",
    width: "95px !important",
    textTransform: "none !important",
    "& .MuiButton-startIcon": {
      marginRight: 5,
    },
  },
}));

export default function ButtonWithIcon(props) {
  const classes = useStyles();
  const { onPress, title, icon, buttonWidth } = props;

  return (
    <div>
      <Button
        className={classes.button}
        style={{ width: buttonWidth }}
        startIcon={<img src={icon} alt="icon" />}
        onClick={onPress}
      >
        {title}
      </Button>
    </div>
  );
}
