import { put, call } from "redux-saga/effects";
import * as types from "../Actions";
import { getBrokersService } from "../Services/userValidationService";

export function* getBrokerListSaga() {
  try {
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: true, text: "" },
    });
    let response = yield call(getBrokersService);
    if (response.data.STATUS === "ACCEPTED") {
      yield put({ type: types.GET_BROKER_LIST_SUCCESS, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
    } else {
      yield put({ type: types.GET_BROKER_LIST_FAIL, response });
      yield put({
        type: types.FULL_SCREEN_LOADER,
        payload: { visibility: false, text: "" },
      });
      yield put({ type: types.SHOW_ERROR_POPUP });
    }
  } catch (error) {
    yield put({ type: types.GET_BROKER_LIST_FAIL, response: error });
    yield put({
      type: types.FULL_SCREEN_LOADER,
      payload: { visibility: false, text: "" },
    });
    yield put({ type: types.SHOW_ERROR_POPUP });
  }
}
