import React, { Fragment, Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import ArrowLeftIcon from "../../../Assets/Icons/arrow_left.svg";
import Layout from "../../Layout/Layout";
import "./StockDashboardStyles.scss";
import BodyContainer from "../../../Components/BodyArea/Body";
import { Images } from "../../../Theme/Images";
import {
  getDashboardData,
  getAccountSummary,
  getInvestmentAdvisor,
} from "../../../Redux/Actions/dashboardAction";
import CircularLoading from "../../Common/CirculerLoading";
import {
  getSnpList,
  getCompanyList,
  getTopdata,
  getSellStockList,
  showFullLoader,
} from "../../../Redux/Actions/commonAction";
import {
  getOrderListBuyStatus,
  setOderEditStatus,
  getMarketSummary,
  getMarketDepth,
  getSecurityStat,
  getSellStatus,
} from "../../../Redux/Actions/stockAction";
import { fixDecimalPoints } from "../../../Utils/commonFunctions";
import {
  setSelectedBankName,
  setSelectedAccountNumber,
  setCashAvailableForWithdrawal,
  getBankDetails,
  setRequestId,
} from "../../../Redux/Actions/withdrawAction";
import { setSelectedStock } from "../../../Redux/Actions/buyStockListingAction";

const CardComponent = lazy(() => import("./SubComponents/CardComponent"));
const InvestmentAccordion = lazy(() =>
  import("./SubComponents/InvestmentAccordian")
);
const PurchasedStock = lazy(() => import("./SubComponents/PurchasedStock"));
const StockActionButton = lazy(() =>
  import("./SubComponents/StockActionButton")
);
class StockDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      portfolioAmount: "",
      portfolioAmountDecimal: "",
      withdraw: "",
      withdrawDecimal: "",
      invest: "",
      investDecimal: "",
      unsettled: "",
      unsettledDecimal: "",
      marginTrad: "",
      marginTradDeci: "",
      checkInvestValue: 0,
      sellStockList: [],
      checkPortfolioValue: 0,
      showLoading: false,
    };
  }

  componentDidMount() {
    this.setState({
      showLoading: true,
    });
    setTimeout(() => {
      this.props.getAccountSummary();
      this.props.getDashboardData(() => {
        const totalPendingBuyOrderValue = this.props.accountSummary
          .totalPendingBuyOrderValue
          ? parseFloat(
              this.props.accountSummary.totalPendingBuyOrderValue
            ).toFixed(2)
          : 0;
        const marginAmount = this.props.accountSummary.marginAmount
          ? parseFloat(this.props.accountSummary.marginAmount).toFixed(2)
          : 0;
        const subtractedValue =
          parseFloat(totalPendingBuyOrderValue) - parseFloat(marginAmount);
        let cashAvailableForWithdrawal;
        if (subtractedValue > 0.0) {
          cashAvailableForWithdrawal = this.props.accountSummary
            .cashAvailableForWithdrawal
            ? parseFloat(
                this.props.accountSummary.cashAvailableForWithdrawal - 0
              ).toFixed(2)
            : 0;
        } else {
          cashAvailableForWithdrawal = this.props.accountSummary
            .cashAvailableForWithdrawal
            ? parseFloat(
                this.props.accountSummary.cashAvailableForWithdrawal
              ).toFixed(2)
            : 0;
        }
        this.setState({
          portfolioAmount: this.props.dashboardData.portfolioValue
            ? this.numberWithCommas(
                parseFloat(this.props.dashboardData.portfolioValue).toFixed(2)
              )
            : "0",
          portfolioAmountDecimal: this.props.dashboardData.portfolioValue
            ? this.convertDecimal(this.props.dashboardData.portfolioValue)
            : "00",
          withdraw: cashAvailableForWithdrawal
            ? cashAvailableForWithdrawal
            : "0",
          withdrawDecimal: cashAvailableForWithdrawal
            ? this.convertDecimal(cashAvailableForWithdrawal)
            : "00",
          invest: this.props.dashboardData.availableToInvest
            ? parseFloat(this.props.dashboardData.availableToInvest).toFixed(2)
            : "0",
          investDecimal: this.props.dashboardData.availableToInvest
            ? this.convertDecimal(this.props.dashboardData.availableToInvest)
            : "00",
          unsettled: this.props.dashboardData.unsettledCash
            ? this.props.dashboardData.unsettledCash
            : "0",
          unsettledDecimal: this.props.dashboardData.unsettledCash
            ? this.convertDecimal(this.props.dashboardData.unsettledCash)
            : "00",
          checkInvestValue: this.props.dashboardData.availableToInvest
            ? parseInt(
                this.props.dashboardData.availableToInvest.replace(".", "")
              )
            : 0,
          checkPortfolioValue: this.props.dashboardData.portfolioValue
            ? parseInt(this.props.dashboardData.portfolioValue.replace(".", ""))
            : 0,
        });
      });
      this.props.getSellStockList(() => {
        this.setState(
          {
            sellStockList:
              this.props.sellStockList && this.props.sellStockList.length !== 0
                ? this.props.sellStockList
                : [],
          },
          () => {
            this.setState({
              showLoading: false,
            });
          }
        );
      });
      this.props.getCompanyList();
      this.props.setOderEditStatus(false);
      this.props.getBankDetails();
    }, 1500);

    setTimeout(() => {
      this.setState({
        showLoading: false,
      });
    }, 10000);
  }

  convertDecimal = (value) => {
    if (value) {
      if (JSON.stringify(Number(value)).includes(".")) {
        return fixDecimalPoints(JSON.stringify(Number(value)).split(".").pop());
      } else {
        return "00";
      }
    } else {
      return "00";
    }
  };

  numberWithCommas = (x) => {
    if (x) {
      let srt = x.substr(0, x.indexOf("."));
      const value = srt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return value;
    } else {
      return null;
    }
  };

  numberWithCommasAndDecimal = (x) => {
    if (x) {
      if (Math.sign(x) == -1) {
        return "0.00";
      } else {
        const value = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return value;
      }
    } else {
      return null;
    }
  };

  onClickBuyStocks() {
    this.props.history.push("buy-stocks-list");
  }

  onClickSellStocks() {
    this.props.history.push("sell-stocks-list");
  }

  onClickExploreStocks() {
    this.props.getSnpList(() => {
      this.props.history.push("explore-stocks");
    });
  }

  getCurrentDate() {
    let date = new Date();
    const dateString =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    return dateString;
  }

  onClickMarketSummary() {
    this.props.getTopdata(() => {
      const date = this.getCurrentDate();
      this.props.getMarketSummary(date, () => {
        this.props.history.push("market-summary");
      });
    });
  }

  onClickOrderList() {
    this.props.history.push("order-list");
  }

  onClickAccountSummary() {
    this.props.history.push("account-summary");
  }
  handleAddMoney() {
    this.props.history.push("add-money-initiate");
  }
  onClickInvestmentAdvisor() {
    this.props.getInvestmentAdvisor(() => {
      this.props.history.push("investment-advisor");
    });
  }

  handleWithdrawMoney() {
    this.props.setSelectedBankName(this.props.bankName);
    this.props.setSelectedAccountNumber(this.props.accountNumber);
    //TODO - get updated api changes and decide where to use requestId
    this.props.history.push("withdraw-money");
  }

  handleCashAvailableForWithdrawal() {
    const totalPendingBuyOrderValue = parseFloat(
      this.props.accountSummary.totalPendingBuyOrderValue
    ).toFixed(2);
    const marginAmount = parseFloat(
      this.props.accountSummary.marginAmount
    ).toFixed(2);
    const subtractedValue =
      parseFloat(totalPendingBuyOrderValue) - parseFloat(marginAmount);
    let cashAvailableForWithdrawal;
    if (subtractedValue > 0.0) {
      cashAvailableForWithdrawal = this.numberWithCommasAndDecimal(
        parseFloat(
          this.props.accountSummary.cashAvailableForWithdrawal - 0
        ).toFixed(2)
      );
    } else {
      cashAvailableForWithdrawal = this.numberWithCommasAndDecimal(
        parseFloat(
          this.props.accountSummary.cashAvailableForWithdrawal
        ).toFixed(2)
      );
    }
    //const cashAvailableForWithdrawal = "100.00";
    this.props.setCashAvailableForWithdrawal(cashAvailableForWithdrawal);
    return cashAvailableForWithdrawal;
  }

  onLeftIconClick() {
    if (this.props.addMoneyInitiateState) {
      window.ReactNativeWebView.postMessage("goToDashboard");
    } else {
      window.ReactNativeWebView.postMessage("Exit");
    }
  }

  cardList = [
    {
      title: "Order list",
      description: "Queued, sell & purchased orders",
      icon: Images.dashboard.orderList,
      onClick: () => this.onClickOrderList(),
    },
    {
      title: "Market summary",
      description: "View Colombo Stock Exchange",
      icon: Images.dashboard.ic_market_summary,
      onClick: () => this.onClickMarketSummary(),
    },
    {
      title: "Account summary",
      description: "View trading account summary",
      icon: Images.dashboard.ic_account_summary,
      onClick: () => this.onClickAccountSummary(),
    },
    {
      title: "Explore stocks",
      description: "Search stocks",
      icon: Images.dashboard.ic_explore_stocks,
      onClick: () => this.onClickExploreStocks(),
    },
    {
      title: "Investment advisor",
      description: "Contact your advisor for investments",
      icon: Images.dashboard.img_investment_advisor,
      onClick: () => this.onClickInvestmentAdvisor(),
    },
  ];
  renderCardComponent = (data) => {
    return (
      <CardComponent
        title={data.title}
        subTitle={data.description}
        image={data.icon}
        handleClick={data.onClick}
      />
    );
  };

  onViewPressed = (item) => {
    this.props.setSelectedStock(item);
    this.props.getSecurityStat();
    this.props.getMarketDepth();
    this.props.getSellStatus();
    setTimeout(() => {
      this.props.history.push("stock-details");
    }, 100);
  };

  render() {
    return (
      <Fragment>
        <Layout
          leftIcon={ArrowLeftIcon}
          headerContentLeft="Stock"
          headerContentRight=""
          rightIcon=""
          leftIconLink=""
          handleLeftIconClick={() => this.onLeftIconClick()}
        >
          {this.state.showLoading ? (
            <div
              style={{
                textAlign: "center",
                paddingTop: "70%",
              }}
            >
              <CircularLoading />
            </div>
          ) : (
            <BodyContainer>
              <header className="App-container">
                <div style={{ width: "100%" }}>
                  <div
                    className="card card-3"
                    style={{
                      backgroundImage: `url(${Images.dashboard.img_portfolio_button})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="col-9 no-padding">
                      <div className="row no-padding">
                        <span className="dashboard-port">Portfolio value</span>
                      </div>
                      <div className="row no-padding ai-baseline">
                        <span className="dashboard-lkr">LKR</span>
                        <span className="dashboard-amount">
                          {this.state.portfolioAmount
                            ? this.state.portfolioAmount
                            : "0"}
                        </span>
                        <span className="dashboard-messageTemplate">
                          .
                          {parseFloat(
                            this.props.dashboardData.portfolioValue
                          ) >= 0
                            ? fixDecimalPoints(
                                this.state.portfolioAmountDecimal
                              )
                            : "00"}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div>
                     
                      <div
                        className="row no-padding"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          marginTop: "25px",
                          marginBottom: "25px",
                        }}
                      >
                        <Suspense fallback={<div></div>}>
                          <StockActionButton
                            title={"Buy stocks"} condition={parseFloat(this.state.checkInvestValue) > 0}
                            image={Images.dashboard.ic_buy_stocks}
                            handleClick={() => {
                              this.onClickBuyStocks();
                            }}
                          />

                          <div className="col-1"></div>
                          <StockActionButton
                            title={"Sell stocks"} condition={parseFloat(this.state.checkPortfolioValue) > 0 &&
                              this.state.sellStockList[0]}
                            image={Images.dashboard.ic_sell_stocks}
                            handleClick={() => {
                              this.onClickSellStocks();
                            }}
                          />
                        </Suspense>
                      </div>
                  </div>

                  <div
                    className="ia-card ia-card-3"
                    style={{ marginBottom: "25px" }}
                  >
                    <Suspense fallback={<div></div>}>
                      <InvestmentAccordion
                        invest={
                          this.props.dashboardData.availableToInvest
                            ? parseFloat(
                                this.props.dashboardData.availableToInvest
                              ).toFixed(2)
                            : "0"
                        }
                        investDeci={this.state.investDecimal}
                        withdraw={this.props.accountSummary.cashAvailableForWithdrawal}
                        withdrawDeci={this.state.withdrawDecimal}
                        unsettle={this.props.dashboardData.unsettledCash}
                        unsettleDeci={this.state.unsettledDecimal}
                        onAddMoneyPressed={() => this.handleAddMoney()}
                        onWithdrawMoneyPressed={() =>
                          this.handleWithdrawMoney()
                        }
                        cashAvailableForWithdrawal={this.handleCashAvailableForWithdrawal()}
                        bankName={this.props.bankName}
                        accountNumber={this.props.accountNumber}
                      />
                    </Suspense>
                  </div>

                  {this.state.sellStockList[0] ? (
                    <div
                      className="card card-3"
                      style={{ marginBottom: "25px" }}
                    >
                      <Suspense fallback={<div></div>}>
                        <PurchasedStock
                          stocks={
                            this.state.sellStockList
                              ? this.state.sellStockList.sort(function (a, b) {
                                  return a.companyName - b.companyName;
                                })
                              : []
                          }
                          history={this.props.history}
                          onViewPressed={(item) => this.onViewPressed(item)}
                        />
                      </Suspense>
                    </div>
                  ) : null}

                  <Suspense fallback={<div></div>}>
                    {this.cardList.map((data) => {
                      return this.renderCardComponent(data);
                    })}
                  </Suspense>
                </div>
              </header>
            </BodyContainer>
          )}
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboardData: state.DashboardReducer.dashboard,
    loader: state.CommonReducer.showLoader,
    accountSummary: state.DashboardReducer.accountSummary,
    sellStockList: state.CommonReducer.sellStockList,
    bankName: state.WithdrawReducer.bankName,
    accountNumber: state.WithdrawReducer.accountNumber,
    addMoneyInitiateState: state.AddMoneyReducer.addMoneyInitiateState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardData: (callback) => dispatch(getDashboardData(callback)),
    getSnpList: (callback) => dispatch(getSnpList(callback)),
    getAccountSummary: () => dispatch(getAccountSummary()),
    getCompanyList: () => dispatch(getCompanyList()),
    getTopdata: (callback) => dispatch(getTopdata(callback)),
    getSellStockList: (callback) => dispatch(getSellStockList(callback)),
    getOrderListBuyStatus: () => dispatch(getOrderListBuyStatus()),
    setSelectedBankName: (data) => dispatch(setSelectedBankName(data)),
    setSelectedAccountNumber: (data) =>
      dispatch(setSelectedAccountNumber(data)),
    setCashAvailableForWithdrawal: (data) =>
      dispatch(setCashAvailableForWithdrawal(data)),
    setOderEditStatus: (data) => dispatch(setOderEditStatus(data)),
    getBankDetails: () => dispatch(getBankDetails()),
    setRequestId: (data) => dispatch(setRequestId(data)),
    getInvestmentAdvisor: (callback) =>
      dispatch(getInvestmentAdvisor(callback)),
    showFullLoader: (data) => dispatch(showFullLoader(data)),
    getMarketSummary: (data, callback) =>
      dispatch(getMarketSummary(data, callback)),
    setSelectedStock: (data) => dispatch(setSelectedStock(data)),
    getMarketDepth: (data) => dispatch(getMarketDepth(data)),
    getSecurityStat: (data) => dispatch(getSecurityStat(data)),
    getSellStatus: () => dispatch(getSellStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StockDashboard);
