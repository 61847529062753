import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  circularStyle: {
    color: "#767676",
  },
}));

const CircularProgressWithLabel = (props) => {
  const {
    setCircularLoadingFlag,
    setButtonDisable,
    setOtpSend,
    value,
    ...rest
  } = props;
  const classes = useStyles(props);
  useEffect(() => {
    if (value === 100) {
      setCircularLoadingFlag(false);
      setButtonDisable(false);
      setOtpSend(true);
    }
  });
  return (
    <div>
      <CircularProgress
        data-testid="circular-progress-container"
        className={classes.circularStyle}
        variant="determinate"
        value={value}
        {...rest}
      />
    </div>
  );
};

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default CircularProgressWithLabel;
