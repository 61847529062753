import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import rootReducer from "../Reducers";
import rootSaga from "../Sagas";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configreStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  return {
    ...createStore(
      rootReducer,
      composeEnhancers(applyMiddleware(sagaMiddleware))
      // composeEnhancers(applyMiddleware(sagaMiddleware, logger))
    ),
    runSaga: sagaMiddleware.run(rootSaga),
  };
};

export default configreStore;
