import * as Types from "./index";

export const triggerTimerPopUp = (payload) => {
  return {
    type: Types.TRIGGER_TIMER_POPUP,
    payload,
  };
};

export const clearTimerPopUp = (payload) => {
  return {
    type: Types.CLEAR_TIMER_POPUP,
    payload,
  };
};

export const storeOTPmobileNumer = (payload) => {
  return {
    type: Types.STORE_OTP_MOBILE_NUMER,
    payload,
  };
};


