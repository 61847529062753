import * as Types from "./index";

export const setAddmoneyAmount = (payload) => {
  return {
    type: Types.SET_ADD_MONEY_AMOUNT,
    payload,
  };
};

export const setAddmoneyAmountDecimal = (payload) => {
  return {
    type: Types.SET_APP_MONEY_AMOUNT_DECIMAL,
    payload,
  };
};

export const setAddmoneyUniqueId = (payload) => {
  return {
    type: Types.SET_ADD_MONEY_UNIQUE_ID,
    payload,
  };
};

export const preValidateAddMoney = (payload, callback) => {
  return {
    type: Types.PRE_VALIDATE_ADD_MONEY,
    payload,
    callback,
  };
};

export const commonPaymentModuleStatus = (payload, callback) => {
  return {
    type: Types.COMMON_PAYMENT_MODULE_STATE,
    payload,
    callback,
  };
};

export const setAddMoneyInitiateState = (payload) => {
  return {
    type: Types.INITIATE_ADD_MONEY,
    payload,
  };
};

export const atradAddMoney = (payload, callback) => {
  return {
    type: Types.ATRAD_ADD_MONEY,
    payload,
    callback,
  };
};

export const getFundTranferInfoDetails = (payload) => {
  return {
    type: Types.GET_FUND_TRANFER_INFO,
    payload,
  };
};

export const storeFundTranferInfoDetails = (payload) => {
  return {
    type: Types.STORE_FUND_TRANFER_INFO,
    payload,
  };
};
