import React, { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Slide,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { hideAlertDialog } from "../../Redux/Actions/commonAction";
import { startBrowserBack, stopBrowserBack } from "../../Utils/helper";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Open Sans !important",
    fontSize: "0.998em !important",
    fontWeight: "bold !important",
    color: "black !important",
    textAlign: "center !important",
    letterSpacing: "0.016em !important",
  },
  primaryButton: {
    fontFamily: "Open Sans !important",
    fontSize: "0.874em !important",
    fontWeight: "bold !important",
    letterSpacing: "0.062em !important",
    color: "#2598ed !important",
    marginTop: "16px !important",
    marginBottom: "16px !important",
  },
  primaryButtonOnly: {
    fontFamily: "Open Sans !important",
    fontSize: "0.874em !important",
    fontWeight: "bold !important",
    letterSpacing: "0.062em !important",
    color: "#2598ed !important",
    marginTop: "16px !important",
    marginBottom: "30px !important",
  },
  secondaryButton: {
    fontFamily: "Open Sans !important",
    fontSize: "0.89em !important",
    letterSpacing: "0.062em !important",
    fontWeight: "700 !important",
    color: "#2598ed !important",
    marginTop: "16px !important",
    marginBottom: "30px !important",
  },
  centerView: {
    fontFamily: "Open Sans !important",
    fontSize: "0.874em !important",
    fontWeight: "normal !important",
    alignSelf: "center !important",
    textAlign: "center !important",
    marginBottom: "8px !important",
    marginLeft: "6% !important",
    marginRight: "6% !important",
  },
  primaryBtnCenterView: {
    fontFamily: "Open Sans !important",
    fontSize: "0.874em !important",
    fontWeight: "normal !important",
    alignSelf: "center !important",
    textAlign: "center !important",
    padding: "0px !important",
  },
  secondaryBtnCenterView: {
    fontFamily: "Open Sans !important",
    fontSize: "0.874em !important",
    fontWeight: "normal !important",
    alignSelf: "center !important",
    textAlign: "center !important",
    padding: "0px !important",
  },
  dialogPaper: {
    borderRadius: "16px !important",
    width: "343px !important",
  },
  icon: {
    height: "32px !important",
    pointerEvents: "none !important",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function AlertDialog(props) {
  const {
    dialodMessage,
    primaryButton,
    secondaryButton,
    onPrimaryPressed,
    onSecondaryPressed,
    dialogTitle,
    alertIcon,
    onhandleClose,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    stopBrowserBack(handleClose);
  }, []);

  const handleClose = () => {
    props.hideAlertDialog();
    startBrowserBack();
  };

  const primaryButtonAction = () => {
    onPrimaryPressed();
  };

  const secondaryButtonAction = () => {
    onSecondaryPressed();
  };

  const onHandleAction = () => {
    onhandleClose();
  };

  return (
    <div>
      <Dialog
        open={props.showAlertDialod}
        TransitionComponent={Transition}
        keepMounted
        classes={{ paper: classes.dialogPaper }}
        onClose={props.onhandleClose ? onHandleAction : handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.centerView}>
          <img src={alertIcon} className={classes.icon} alt="logo" />
        </DialogContent>
        <DialogContentText className={classes.title}>
          {dialogTitle}
        </DialogContentText>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className={classes.centerView}
          >
            {dialodMessage}
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions
          className={classes.primaryBtnCenterView}
          onClick={primaryButtonAction}
        >
          <DialogContentText
            className={
              secondaryButton
                ? classes.primaryButton
                : classes.primaryButtonOnly
            }
          >
            {primaryButton}
          </DialogContentText>
        </DialogActions>
        <Divider />
        {secondaryButton !== "" ? (
          <DialogActions
            className={classes.secondaryBtnCenterView}
            onClick={secondaryButtonAction}
          >
            <DialogContentText className={classes.secondaryButton}>
              {secondaryButton}
            </DialogContentText>
          </DialogActions>
        ) : null}
      </Dialog>
    </div>
  );
}

AlertDialog.defaultProps = {
  onPrimaryPressed: () => {},
  onSecondaryPressed: () => {},
  onhandleClose: () => {},
};

const mapStateToProps = (state) => {
  return {
    dashboardData: state.DashboardReducer.dashboard,
    showAlertDialod: state.CommonReducer.showAlertDialog,
    dialogTitle: state.CommonReducer.dialogTitle,
    dialodMessage: state.CommonReducer.dialogMessage,
    primaryButton: state.CommonReducer.dialogPrimaryButton,
    secondaryButton: state.CommonReducer.dialogSecondaryButton,
    alertIcon: state.CommonReducer.dialogIcon,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    hideAlertDialog: () => dispatch(hideAlertDialog()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AlertDialog);
